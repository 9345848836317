import config from "config/config";
import {HttpClientService} from "services/httpClient/httpClientService";
import {ApiResponse} from "../apiResponse";
import {AccountService} from "../../account/accountService";
import {CrudKlarmeldingInputModel} from "../../../pages/klarmeldinger/components/crudKlarmeldingInputModel";
import {KlarmeldingResponseModel} from "./klarmeldingResponseModel";
import {KlarmeldingModel} from "../../../pages/klarmeldinger/klarmeldingModel";


export class KlarmeldingApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/klarmelding/";
    private editLaegekompetenceKlarmeldingUrl = () => this.baseUrl() + "editLaegekompetenceKlarmelding/";
    private getKlarmeldingBrugere = () => this.baseUrl() + "getKlarmeldingBrugere/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async editLaegekompetenceKlarmeldinger(inputModel: CrudKlarmeldingInputModel): Promise<string> {
        const result = await this.httpClientService.Post<ApiResponse<string>, CrudKlarmeldingInputModel>(this.editLaegekompetenceKlarmeldingUrl(), inputModel);
        return result.data;
    }

    async getKlarmeldingBrugereByLaegekompetence(laegekompetenceId: string): Promise<KlarmeldingModel> {
        const url = this.getKlarmeldingBrugere() + laegekompetenceId
        const result = await this.httpClientService.Get<ApiResponse<KlarmeldingResponseModel>>(url);
        return new KlarmeldingModel(result.data)
    }
}
