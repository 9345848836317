import {VejlederAfdelingResponseModel} from "../../../services/api/stamdataVejlederApi/vejlederAfdelingResponseModel";
import {dateTimeStringFromApiToDate} from "../../../index";
import {StillingTypeEnum} from "../../../core/sharedmodels/stilling/stillingTypeEnum";

export class VejlederAfdelingerModel{

    constructor(vejlderAfdelingerResponseModel: VejlederAfdelingResponseModel) {
        this.LaegeInstitutionId = vejlderAfdelingerResponseModel.laegeInstitutionId;
        this.LaegeInstitutionNavn = vejlderAfdelingerResponseModel.laegeInstitutionNavn;
        this.AfdelingId = vejlderAfdelingerResponseModel.afdelingId;
        this.AfdelingNavn = vejlderAfdelingerResponseModel.afdelingNavn;
        this.PeriodeSlut = vejlderAfdelingerResponseModel.periodeSlut.length < 1 ? undefined : dateTimeStringFromApiToDate(vejlderAfdelingerResponseModel.periodeSlut);
    }

    public LaegeInstitutionId: string;
    public AfdelingId?: string;
    public LaegeInstitutionNavn: string;
    public AfdelingNavn?: string;
    public PeriodeSlut?: Date;

    public GetStedInfo() {
        return !!this.AfdelingId && !!this.AfdelingNavn
            ? `${this.LaegeInstitutionNavn}, ${this.AfdelingNavn}`
            : this.LaegeInstitutionNavn;
    }
}
