import React, {useEffect, useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {
    BuildExisting,
    CheckIfAllIsChosen,
    CrudKlarmeldingInputModel,
    crudKlarmeldingValidationSchema
} from "./crudKlarmeldingInputModel";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {KlarmeldingApi} from "../../../services/api/klarmelding/klarmeldingApi";
import {useAppSelector} from "../../../app/hooks";
import {ValidationForm} from "../../../core/components/validation/components/validationForm";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {Loading} from "../../../core/components/loading/loading";
import {KlarmeldingCrudFields} from "./klarmeldingCrudFields";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {kompetenceCardReducer, KompetenceCardState} from "../../kompetencerPage/cards/kompetenceCardSlice";
import {KlarmeldingModel} from "../klarmeldingModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useDispatch} from "react-redux";
import {NotificationModule} from "ditmer-embla";

interface EditKlarmeldingProps {
    modalId: string,
}

const klarmeldingApi = new KlarmeldingApi(new HttpClientService(), new AccountService())

export function EditKlarmelding(props: EditKlarmeldingProps) {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;

    const [klarmeldingBrugerModel, setKlarmeldingBrugerModel] = useState<KlarmeldingModel>();

    useEffect(() => {
        const setKlarmeldingBrugere = async () => {
            const klarmeldingModel = await klarmeldingApi.getKlarmeldingBrugereByLaegekompetence(kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId);
            setKlarmeldingBrugerModel(klarmeldingModel);
        }
        setKlarmeldingBrugere().then(r => setIsLoading(false));
    },[kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId]);

    const editKlarmelding = async (inputModel: CrudKlarmeldingInputModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        CheckIfAllIsChosen(inputModel);
        const editKlarmelding = await klarmeldingApi.editLaegekompetenceKlarmeldinger(inputModel);

        if (editKlarmelding) {
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }));
            NotificationModule.showSuccess(Localizer.kompetencePage_klarmeldingUpdated(), "");
        } else {
            dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }));
            NotificationModule.showSuccess(Localizer.kompetencePage_klarmeldingErrorUpdating(), "");
        }
    }

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_getData()}>
            {klarmeldingBrugerModel &&
                <ValidationForm
                    initialValues={BuildExisting(klarmeldingBrugerModel)}
                    validationSchema={crudKlarmeldingValidationSchema}
                    onSubmit={async (values) => {
                        await editKlarmelding(values)
                            .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                                HandleError(err);
                            });
                    }}>
                    {
                        (formik) => {
                            return (
                                <>
                                    <KlarmeldingCrudFields
                                        modalId={props.modalId}
                                        formik={formik}
                                        model={klarmeldingBrugerModel}
                                        editView={true}
                                    />
                                    <div className="modal-actions">
                                        <ValidationFormSubmitButton formikProps={formik}>
                                            {Localizer.global_save()}
                                        </ValidationFormSubmitButton>
                                    </div>
                                </>
                            );

                        }
                    }
                </ValidationForm>
            }
        </Loading>
    )

    return <>{render}</>
}
