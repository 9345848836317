import * as yup from "yup";
import { Localizer } from "../../../infrastructure/localization/localizer";
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import createValidationSchema from "../../components/validation/createValidationSchema";
import moment from "moment";
import {FravaerskodeEnum} from "../../sharedmodels/forloeb/fravaerskoderEnum";

export class CrudForloebInputModel {
    public id: string;
    public startDato?: Date;
    public slutDato?: Date;
    public skalIndgaaIEvalueringsstatistik: boolean;
    public senesteDatoForEvaluering?: Date;
    public seAlleKompetencer: boolean;
    public brugerId: string;
    public stillingId: string;
    public forloebList: ForloebModel[];
    public fravaerStartDato?: Date;
    public fravaerSlutDato?: Date;
    public fravaerskode?: FravaerskodeEnum;
    public erFravaer?: boolean;
    public evalueretDato?: Date;
    public infoTekst?: string;
}

export const BuildExisting = (brugerId: string, stillingId: string, startDato: Date, slutDato: Date, skalIndgaaIEvalueringsstatistik: boolean, seAlleKompetencer: boolean, id: string, forloebList: ForloebModel[], erFravaer: boolean, fravaerskode?: FravaerskodeEnum, evalueretDato?: Date, fravaerStartDato?: Date, fravaerSlutDato?: Date, infoTekst?: string, senesteDatoForEvaluering?: Date): CrudForloebInputModel => {
    let inputModel = new CrudForloebInputModel();
    inputModel.startDato = startDato;
    inputModel.slutDato = slutDato;
    inputModel.id = id;
    inputModel.brugerId = brugerId;
    inputModel.stillingId = stillingId;
    inputModel.forloebList = forloebList;
    inputModel.skalIndgaaIEvalueringsstatistik = skalIndgaaIEvalueringsstatistik;
    inputModel.seAlleKompetencer = seAlleKompetencer;
    inputModel.erFravaer = erFravaer;
    inputModel.fravaerStartDato = fravaerStartDato;
    inputModel.fravaerSlutDato = fravaerSlutDato;
    inputModel.fravaerskode = fravaerskode;
    inputModel.evalueretDato = evalueretDato;
    inputModel.infoTekst = infoTekst;
    inputModel.senesteDatoForEvaluering = senesteDatoForEvaluering;

    return inputModel;
}

export const BuildNew = (brugerId?: string, forloebList?: ForloebModel[]): CrudForloebInputModel => {

    let inputModel = new CrudForloebInputModel();

    inputModel.skalIndgaaIEvalueringsstatistik = true;
    inputModel.startDato = undefined;
    inputModel.slutDato = undefined;
    inputModel.senesteDatoForEvaluering = undefined;
    inputModel.brugerId = brugerId ?? "";
    inputModel.forloebList = forloebList ?? [];
    inputModel.seAlleKompetencer = false;
    inputModel.fravaerStartDato = undefined;
    inputModel.fravaerSlutDato = undefined;
    inputModel.fravaerskode = undefined;
    inputModel.erFravaer = false;
    inputModel.infoTekst = "";

    return inputModel;
}

export const crudForloebValidationSchema = createValidationSchema<CrudForloebInputModel>({
    forloebList: yup.array().notRequired(),
    id: yup.string().notRequired(),
    brugerId: yup.string().required(Localizer.validate_laegeRequired()),
    seAlleKompetencer: yup.string().required(),
    stillingId: yup.string().required(Localizer.validate_stillingRequired()),
    skalIndgaaIEvalueringsstatistik: yup.boolean().required(),
    erFravaer: yup.boolean().notRequired(),
    infoTekst: yup.string().nullable().notRequired(),
    fravaerStartDato: yup.date().notRequired().test(
        "date-validation",
        Localizer.validate_dateInvalid(),
        (value) => {
            return moment(value).isValid();
        })
        .test(
            "date-validation",
            Localizer.validate_fravaerStartBeforeForloebStart(),
            function (value) {
                const parent = this.parent as CrudForloebInputModel;
                if (value) {
                    let dateCopy = new Date(value.getTime());
                    return !value || !parent.startDato || dateCopy.setHours(0,0,0,0) >= parent.startDato.setHours(0,0,0,0)
                }
                return true;
            }
        ),
    fravaerSlutDato: yup.date().notRequired().test(
        "date-validation",
        Localizer.validate_dateInvalid(),
        (value) => {
            return moment(value).isValid();
        })
        .test(
            "date-validation",
            Localizer.validate_slutDatoBeforeStartdate(),
            function (value) {
                const parent = this.parent as CrudForloebInputModel;
                return !value || !parent.fravaerStartDato || value >= parent.fravaerStartDato
            }
        ),
    fravaerskode: yup.number().when('erFravaer', {
        is: true,
        then: yup.number().required(Localizer.validate_fieldRequired(Localizer.Fravaerskode())),
        otherwise: yup.number().notRequired()
    }),
    startDato: yup.date()
        .required(Localizer.validate_dateRequired()).test(
            "date-validation",
            Localizer.validate_dateInvalid(),
            (value) => {
                return moment(value).isValid();
            }),
    slutDato: yup.date()
        .required(Localizer.validate_dateRequired()).test(
            "date-validation",
            Localizer.validate_dateInvalid(),
            (value) => {
                return moment(value).isValid();
        })
        .test(
            "date-validation",
            Localizer.validate_slutDatoBeforeStartdate(),
            function (value) {
                const parent = this.parent as CrudForloebInputModel;
                return !value || !parent.startDato || value > parent.startDato
            }
        ),
        senesteDatoForEvaluering: yup.date().when('skalIndgaaIEvalueringsstatistik', {
            is: true,
            then: yup.date().required(Localizer.validate_dateRequired()),
            otherwise: yup.date().nullable().notRequired()
        }),
});

export const crudForloebWarningSchema = createValidationSchema<CrudForloebInputModel>({
    brugerId: yup.string().notRequired(),
    forloebList: yup.array().notRequired(),
    id: yup.string().notRequired(),
    stillingId: yup.string().notRequired(),
    skalIndgaaIEvalueringsstatistik: yup.boolean().notRequired(),
    seAlleKompetencer: yup.boolean().notRequired(),
    startDato: yup.date().test(
        "date-validation",
        Localizer.validate_startDatoOverlapOtherForloeb(),
        function (value) {
            const parent = this.parent as CrudForloebInputModel;

            if (!value) {
                return true;
            }
            else {
                return !parent.forloebList.some(f => value >= f.startDate && value <= f.endDate && f.id !== parent.id)
            }
        }
    ),
    slutDato: yup.date().test(
        "date-validation",
        Localizer.validate_slutDatoOverlapOtherForloeb(),
        function (value) {
            const parent = this.parent as CrudForloebInputModel;

            if (!value) {
                return true;
            }
            else {
                return !parent.forloebList.some(f => value >= f.startDate && value <= f.endDate && f.id !== parent.id)
            }
        }
    )
})
