import React, {useCallback, useEffect, useState} from "react";
import {KompetenceCard} from "./kompetenceCard";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {forloebReducer, ForloebSliceState} from "core/forloebSlice";
import {
    clearSelectedLaegekompetenceMetas,
    kompetenceCardReducer,
    KompetenceCardState,
    LaegekompetenceMetaState,
    removeActiveLaegekompetenceMeta,
    setActiveLaegekompetenceMeta,
    setIsLoadingList,
    setKompetenceCardStateShowCheckboxes,
    toggleLaegekompetenceListRefreshPing
} from "./kompetenceCardSlice";
import {nameof} from "ts-simple-nameof";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {LaegekompetenceMeta} from "../../../core/sharedmodels/kompetence/laegekompetenceMeta";
import {setSlideinState, SlideInStateEnum} from "core/components/slideIn/slideInSlice";
import {StatusButton} from "core/componentsPage/kompetence/statusButton";
import {CollapseTab} from "core/components/Tabs/collapseTab";
import "./kompetenceCardList.scss"
import {useLocation} from "react-router-dom";
import {NotificationModule} from "ditmer-embla";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {
    CreateLaegekompetenceNotat
} from "../../../core/componentsPage/notat/kompetenceNotat/createLaegekompetenceNotat";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {
    LaegekompetenceStatusEnum,
    LaegekompetenceStatusEnumTranslator
} from "../../../core/sharedmodels/kompetence/laegekompetenceStatusEnum";
import {Loading} from "../../../core/components/loading/loading";
import {groupByAndMap, sortKompetenceBySortOrder} from "../../../core/utils";
import useLogbogApi from "core/hooks/useLogbogApi";
import {Dropdown} from "core/components/dropdown/dropdown";
import useEnumDropdownOptions from "core/components/dropdown/hooks/useEnumDropdownOptions";
import useUser from "core/hooks/useUser";
import {KompetenceListViewTypeEnum} from "../../../core/sharedmodels/kompetence/kompetenceListViewTypeEnum";
import useScreenResolution from "core/hooks/useScreenResolution";
import useStamdataApi from "core/hooks/useStamdataApi";
import IdAndCount from "core/sharedmodels/IdAndCount";
import classNames from "classnames";

export class KompetenceCardCategory {
    title: string;
    catagoryEnum: KompetenceCatagory;
    laegekompetencer: LaegekompetenceMeta[];
    expandByDefault: boolean;
    id?: string;
}

export enum KompetenceCatagory {
    OevrigeKompetencer = 0,
    KompetencerOnForloeb = 1,
    Klarmeldinger = 2,
    Foraeldede = 3
}

export enum KompetenceCardListType {
    KompetencerByBruger = 0,
    KlarmeldteKompetencerByVejleder = 1
}

export type KompetenceCardListProps = {
    kompetenceListType: KompetenceCardListType;
    selectedBrugerMaalbeskrivelseId?: string;
    customSearchPlaceholder?: string;
    customModileSearchPlaceholder?: string;
    getAllRegardlessOfMaalbeskrivelse?: boolean;
    disableFileCount?: boolean;
}

const filterLaegeKompetenceMetas = (metas: LaegekompetenceMeta[], kompetenceStatus?: LaegekompetenceStatusEnum, search?: string) => {
    const searchToLower = search?.toLowerCase();

    const toLowerIncludes = (val: string, searchString: string) => val.toLowerCase().includes(searchString);

    return metas
        // Filter by status
        .filter(lk => kompetenceStatus === undefined || lk.status === kompetenceStatus)
        // Filter by search
        .filter(lk => !searchToLower ||
            (
                toLowerIncludes(lk.fullKompetenceTitleWithNumber, searchToLower)
                || toLowerIncludes(lk.userNavn, searchToLower)
                || (!!lk.underoverskrift && toLowerIncludes(lk.underoverskrift, searchToLower))
                || toLowerIncludes(lk.konkretisering, searchToLower)
                || toLowerIncludes(lk.laeringsstrategi, searchToLower)
                || toLowerIncludes(lk.kompetenceVurderingsmetoder, searchToLower)
            )
        )
}

const createLaegekompetenceNotatModalTarget = "kompetencenotat-create-modal";
const givMeritmModalTarget = "giv-merit-modal";

export function KompetenceCardList({disableFileCount=false, ...props}: KompetenceCardListProps) {
    const location = useLocation<{ planlaeg: boolean }>();
    const dispatch = useAppDispatch();
    const { isMobile } = useScreenResolution();

    const { logbogLaegeKompetenceApi } = useLogbogApi();
    const { currentUser } = useUser();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;

    const [laegekompetenceCatagoriesState, setLaegekompetenceCatagoriesState] = useState<KompetenceCardCategory[]>([])
    const [laegekompetenceCatagoriesSearchedState, setLaegekompetenceCatagoriesSearchedState] = useState<KompetenceCardCategory[]>([])
    const [laegekompetenceMetaState, setLaegekompetenceMetaState] = useState<LaegekompetenceMetaState[]>([])

    const [selectionActiveState, setSelectionActiveState] = useState<boolean>(false)
    const [planlaegActiveState, setPlanlaegActiveState] = useState<boolean>(false)
    const [givMeritActiveState, setGivMeritActiveState] = useState<boolean>(false)
    const [openMeritModal, setOpenMeritModal] = useState<boolean>(false)
    const [planlaegHistoryState, setPlanlaegHistoryState] = useState<boolean>(location?.state?.planlaeg ?? false);
    const [isLoading, setIsLoading] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [brugerId, setBrugerId] = useState(forloebSliceState.forloebState.brugerId);
    const [searchParam, setSearchParam] = useState<string>();
    const [kompetenceStatus, setKompetenceStatus] = useState<LaegekompetenceStatusEnum>();
    const [kompetencestatusTypeDropdownOptions] = useEnumDropdownOptions<LaegekompetenceStatusEnum>(LaegekompetenceStatusEnum, LaegekompetenceStatusEnumTranslator);

    const currentForloebBrugerMaalbeskrivelseId = forloebSliceState.forloebState.brugerMaalbeskrivelseId;
    const isCurrentForloebBrugerMaalbeskrivelseSelected = currentForloebBrugerMaalbeskrivelseId === props.selectedBrugerMaalbeskrivelseId;

    const isBrugerKompetencerList = props.kompetenceListType === KompetenceCardListType.KompetencerByBruger;
    const isKlarmeldingerKompetenceList = props.kompetenceListType === KompetenceCardListType.KlarmeldteKompetencerByVejleder;
    const [kompetenceListViewType, setKompetenceListViewType] = useState<KompetenceListViewTypeEnum>(KompetenceListViewTypeEnum.Planlagt);

    const { stamdataKompetenceApi } = useStamdataApi();

    const [idAndCounts, setIdAndCounts] = useState<IdAndCount[]>([]);
    const [allKompetenceIds, setAllKompetenceIds] = useState<string[]>([]);

    useEffect(() => {
        const fetchCounts = async () => {
            if(disableFileCount || (allKompetenceIds.length < 0 && !forloebSliceState.forloebState.regionId)) return;

            setIdAndCounts(await stamdataKompetenceApi.getSpecialeSpecificFilesCount({
                kompetenceIds: allKompetenceIds, 
                regionId: forloebSliceState.forloebState.regionId,
                brugerMaalbeskrivelseId: forloebSliceState.forloebState.brugerMaalbeskrivelseId
            }));
        }

        fetchCounts();
    }, [allKompetenceIds, forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.regionId, stamdataKompetenceApi, disableFileCount]);


    useEffect(() => {
        if (location?.state?.planlaeg) {
            setPlanlaegHistoryState(true);
        }
        setBrugerId(kompetenceCardState.activeLaegekompetenceMeta.UserId);
    }, [kompetenceCardState.activeLaegekompetenceMeta, location?.state?.planlaeg])

    useEffect(() => {
        const state = laegekompetenceCatagoriesState.map(lkc => ({
            ...lkc,
            laegekompetencer: filterLaegeKompetenceMetas(lkc.laegekompetencer, kompetenceStatus, searchParam)
        }));
        setLaegekompetenceCatagoriesSearchedState(state);
        setAllKompetenceIds(state.map(lkc => lkc.laegekompetencer.map(lk => lk.kompetenceId)).flat());
    }, [searchParam, kompetenceStatus, laegekompetenceCatagoriesState])

    useEffect(() => {
        if (planlaegHistoryState && laegekompetenceCatagoriesState?.length > 0) {
            togglePlanlaeg();
            setPlanlaegHistoryState(false);
        }
    }, [laegekompetenceCatagoriesState, planlaegHistoryState])

    // OBS.: Dont have every dependency (could be buggy)
    useEffect(() => {
        const fetchAndSetLaegekompetencer = async () => {
            setIsLoading(true);
            dispatch(setIsLoadingList(true));
            await setLaegekompetencer();
            if (!planlaegHistoryState) {
                resetSelectionState();
            }
            setIsLoading(false);
            dispatch(setIsLoadingList(false));
        }
        fetchAndSetLaegekompetencer();
        
    }, [kompetenceCardState.laegekompetenceListRefreshPing, kompetenceListViewType, props.selectedBrugerMaalbeskrivelseId]);

    useEffect(() => {
        if (!isMobile) {
            const hasActiveLaegeKompetence = !!kompetenceCardState.activeLaegekompetenceMeta && !!kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId;
            if (!hasActiveLaegeKompetence) {
                if (laegekompetenceCatagoriesSearchedState[0]?.laegekompetencer[0]) {
                    dispatch(setActiveLaegekompetenceMeta(laegekompetenceCatagoriesSearchedState[0].laegekompetencer[0].ToState()))
                } else if (laegekompetenceCatagoriesSearchedState[1]?.laegekompetencer[0]) {
                    dispatch(setActiveLaegekompetenceMeta(laegekompetenceCatagoriesSearchedState[1].laegekompetencer[0].ToState()))
                }
            }
        }
    }, [isMobile, dispatch, kompetenceCardState.activeLaegekompetenceMeta, laegekompetenceCatagoriesState, laegekompetenceCatagoriesSearchedState])

    const toggleProcessMore = () => {
        showCheckboxesOnAllKompetencer();
        setSelectionActiveState(true);
    }

    const togglePlanlaeg = () => {
        showCheckboxesOnKompetencer();
        const laegekompetencerOnForloeb = laegekompetenceCatagoriesState.find(lkc => (lkc.catagoryEnum === KompetenceCatagory.KompetencerOnForloeb))?.laegekompetencer.map(lk => lk.ToState());
        dispatch(clearSelectedLaegekompetenceMetas(laegekompetencerOnForloeb))
        setPlanlaegActiveState(true);
    }

    const toggleGivMerit = () => {
        showCheckboxesOnIkkeGodkendteKompetencer();
        dispatch(clearSelectedLaegekompetenceMetas())
        setGivMeritActiveState(true);
    }

    const showCheckboxesOnKompetencer = () => {
        const alleKompetencer = laegekompetenceCatagoriesState.map(lkc => lkc.laegekompetencer.map(lk => lk.laegekompetenceId)).flat();
        if (alleKompetencer) {
            dispatch(setKompetenceCardStateShowCheckboxes(alleKompetencer));
        }
    }

    const showCheckboxesOnIkkeGodkendteKompetencer = () => {
        const alleIkkeGodkendteKompetencer = laegekompetenceCatagoriesState
            .filter(lkc => lkc.catagoryEnum !== KompetenceCatagory.Foraeldede)
            .map(lkc => lkc.laegekompetencer
                .filter(lk => lk.status !== LaegekompetenceStatusEnum.Godkendt)
                .map(lk => lk.laegekompetenceId))
            .flat();
        if (alleIkkeGodkendteKompetencer) {
            dispatch(setKompetenceCardStateShowCheckboxes(alleIkkeGodkendteKompetencer));
        }
    }

    const showCheckboxesOnAllKompetencer = () => {
        const laegekompetenceIds = laegekompetenceCatagoriesState.map(lkc => lkc.laegekompetencer.map(lk => lk.laegekompetenceId)).flat();
        dispatch(setKompetenceCardStateShowCheckboxes(laegekompetenceIds));
    }

    function addIkkeAktiveBrugerMaalbeskrivelseKompetencer(kompetencerPaaIkkeAktivBrugerMaalbeskrivelserGroupedByBrugerMaalbeskrivelse: any[]): KompetenceCardCategory[] {
        let newKompetenceCatagories: KompetenceCardCategory[] = [];
        kompetencerPaaIkkeAktivBrugerMaalbeskrivelserGroupedByBrugerMaalbeskrivelse.forEach(kompetencerInBrugerMaalbeskrivelse => newKompetenceCatagories.push(
            {
                laegekompetencer: kompetencerInBrugerMaalbeskrivelse,
                title: kompetencerInBrugerMaalbeskrivelse[0].maalbeskrivelseNavn + ` (${kompetencerInBrugerMaalbeskrivelse.length})`,
                catagoryEnum: KompetenceCatagory.Foraeldede,
                expandByDefault: false,
                id: kompetencerInBrugerMaalbeskrivelse[0].laegekompetenceId
            }
        ))
        return newKompetenceCatagories;
    }

    const filterSortAndSplitFetchedKompetencer = (laegekompetencer: LaegekompetenceMeta[], sortBrugerKompetencerByMaalbeskrivelse: boolean, forloebMaalbeskrivelseId: string, maalbeskrivelseId?: string) => {
        sortBrugerKompetencerByMaalbeskrivelse = sortBrugerKompetencerByMaalbeskrivelse && !!maalbeskrivelseId;

        const kompetencerOnBruger = laegekompetencer.filter(lk => !lk.forloebIds.includes(forloebSliceState.forloebState.id) && (!sortBrugerKompetencerByMaalbeskrivelse || lk.brugerMaalbeskrivelseId === maalbeskrivelseId));
        const kompetencerOnBrugerSorted = sortKompetenceBySortOrder(kompetencerOnBruger);
        kompetencerOnBrugerSorted.SortByThenBy(nameof<LaegekompetenceMeta>(x => x.isFavorite), nameof<LaegekompetenceMeta>(x => x.sortOrder), false);

        const kompetencerOnForloeb = laegekompetencer.filter(lk => lk.forloebIds.includes(forloebSliceState.forloebState.id) && lk.brugerMaalbeskrivelseId === forloebMaalbeskrivelseId);
        const kompetencerOnForloebSorted = sortKompetenceBySortOrder(kompetencerOnForloeb);
        kompetencerOnForloebSorted.SortByThenBy(nameof<LaegekompetenceMeta>(x => x.isFavorite), nameof<LaegekompetenceMeta>(x => x.sortOrder), false);

        const samletSortedListe = sortKompetenceBySortOrder(kompetencerOnBruger.concat(kompetencerOnForloeb));

        const filteredLaegekompetencer = laegekompetencer.filter(l => l.brugerMaalbeskrivelseId === maalbeskrivelseId);
        const filteredLaegekompetencerSorted = sortKompetenceBySortOrder(filteredLaegekompetencer);
        filteredLaegekompetencerSorted.SortByThenBy(nameof<LaegekompetenceMeta>(x => x.isFavorite), nameof<LaegekompetenceMeta>(x => x.sortOrder), false);

        const activeLaegekompetence = filteredLaegekompetencerSorted.find(l => kompetenceCardState?.activeLaegekompetenceMeta?.laegekompetenceId === l.laegekompetenceId)?.ToState();

        return {
            kompetencerOnBruger: kompetenceListViewType === KompetenceListViewTypeEnum.Standard ? samletSortedListe : kompetencerOnBrugerSorted,
            kompetencerOnForloeb: kompetenceListViewType === KompetenceListViewTypeEnum.Standard ? [] : kompetencerOnForloebSorted,
            filteredLaegekompetencer: filteredLaegekompetencerSorted,
            activeLaegekompetence
        }
    }

    const setLaegekompetencer = async () => {
        let newKompetenceCatagories: KompetenceCardCategory[] = [];

        if (isBrugerKompetencerList) {

            const brugerMaalbeskrivelseIdToUse = (props.selectedBrugerMaalbeskrivelseId && !isCurrentForloebBrugerMaalbeskrivelseSelected)
                ? props.selectedBrugerMaalbeskrivelseId
                : currentForloebBrugerMaalbeskrivelseId;

            const laegekompetencer = (props.selectedBrugerMaalbeskrivelseId && !isCurrentForloebBrugerMaalbeskrivelseSelected)
                ? await logbogLaegeKompetenceApi.getLaegekompetencerByBrugerMaalbeskrivelse(brugerMaalbeskrivelseIdToUse)
                : await logbogLaegeKompetenceApi.getLaegekompetencerByBrugerAndUddannelseIdAndSpecialeId(forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.uddannelseId, forloebSliceState.forloebState.specialeId);

            const {
                activeLaegekompetence,
                filteredLaegekompetencer,
                kompetencerOnBruger,
                kompetencerOnForloeb
            } = filterSortAndSplitFetchedKompetencer(laegekompetencer, isCurrentForloebBrugerMaalbeskrivelseSelected || props.selectedBrugerMaalbeskrivelseId === undefined, currentForloebBrugerMaalbeskrivelseId, brugerMaalbeskrivelseIdToUse);

            if (!isMobile) {
                dispatch(setActiveLaegekompetenceMeta(activeLaegekompetence ?? filteredLaegekompetencer[0]?.ToState()));
            }
                
            const kompetencerPaaIkkeAktivMaalbeskrivelser = laegekompetencer.filter(lk => lk.brugerMaalbeskrivelseId !== currentForloebBrugerMaalbeskrivelseId);
            const kompetencerPaaIkkeAktivBrugerMaalbeskrivelserGroupedByBrugerMaalbeskrivelse = groupByAndMap(kompetencerPaaIkkeAktivMaalbeskrivelser, nameof<LaegekompetenceMeta>(x => x.brugerMaalbeskrivelseId));

            if(kompetenceListViewType != KompetenceListViewTypeEnum.Standard){
                newKompetenceCatagories = [
                    {
                        laegekompetencer: kompetencerOnForloeb,
                        title: Localizer.forloebpage_InThisForloeb(),
                        catagoryEnum: KompetenceCatagory.KompetencerOnForloeb,
                        expandByDefault: true
                    }
                ];
            }

            newKompetenceCatagories.push(
                {
                    laegekompetencer: kompetencerOnBruger,
                    title: kompetenceListViewType == KompetenceListViewTypeEnum.Standard ? Localizer.global_allekompetencer() : Localizer.global_resterende(),
                    catagoryEnum: KompetenceCatagory.OevrigeKompetencer,
                    expandByDefault: true
                }
            );

            if (kompetencerPaaIkkeAktivBrugerMaalbeskrivelserGroupedByBrugerMaalbeskrivelse.length > 0)
                newKompetenceCatagories.push(...addIkkeAktiveBrugerMaalbeskrivelseKompetencer(kompetencerPaaIkkeAktivBrugerMaalbeskrivelserGroupedByBrugerMaalbeskrivelse))
        }
        else if(isKlarmeldingerKompetenceList){

            const laegekompetencer = await logbogLaegeKompetenceApi.getLaegekompetenceKlarmeldinger();
            const laegekompetencerFiltered = laegekompetencer.filter(k => k.userId !== currentUser.UserId);
            laegekompetencerFiltered.SortByThenBy(nameof<LaegekompetenceMeta>(x => x.userNavn), nameof<LaegekompetenceMeta>(x => x.sortOrder), false);
            newKompetenceCatagories = [
                {
                    laegekompetencer: laegekompetencerFiltered,
                    title: kompetenceListViewType === KompetenceListViewTypeEnum.Standard ? Localizer.global_allekompetencer() : Localizer.global_klarmeldinger(),
                    catagoryEnum: KompetenceCatagory.Klarmeldinger,
                    expandByDefault: true
                }];

            const activeLaegeKompetenceIsInList = !!laegekompetencerFiltered.find(l => kompetenceCardState?.activeLaegekompetenceMeta?.laegekompetenceId === l.laegekompetenceId);

            if (!activeLaegeKompetenceIsInList) {
                if (!isMobile && laegekompetencerFiltered?.length > 0) {
                    dispatch(setActiveLaegekompetenceMeta(laegekompetencerFiltered[0].ToState()))
                } else if (laegekompetencerFiltered?.length === 0) {
                    setLaegekompetenceCatagoriesState([]); //Fix issue: Where active kompetence would be set via "old" searched state (before new searched state would be resolved)
                    dispatch(removeActiveLaegekompetenceMeta());
                }
            }
        }
        setLaegekompetenceCatagoriesState(newKompetenceCatagories);
    }

    const laegekompetenceCardFavoriteToggles = (laegekompetence: LaegekompetenceMeta) => {
        let newKompetenceCatagories: KompetenceCardCategory[] = [];

        laegekompetenceCatagoriesState.forEach(lks => {
            let laegekompetencer = lks.laegekompetencer.map(x => Object.assign({}, x));

            let toggledIndex = laegekompetencer.findIndex(lk => lk.laegekompetenceId === laegekompetence.laegekompetenceId);
            laegekompetencer[toggledIndex] = laegekompetence

            laegekompetencer.SortByThenBy(nameof<LaegekompetenceMeta>(x => x.isFavorite), nameof<LaegekompetenceMeta>(x => x.sortOrder), false);

            newKompetenceCatagories.push(
                {
                    laegekompetencer: laegekompetencer,
                    expandByDefault: lks.expandByDefault,
                    catagoryEnum: lks.catagoryEnum,
                    title: lks.title
                }
            )
        })
        setLaegekompetenceCatagoriesState(newKompetenceCatagories);
    }

    const resetSelectionState = () => {
        if(laegekompetenceCatagoriesSearchedState.length > 0){
            let clone: KompetenceCardCategory[] = Object.assign([], laegekompetenceCatagoriesSearchedState);
            setLaegekompetenceCatagoriesSearchedState(clone);
            setAllKompetenceIds(clone.map(lkc => lkc.laegekompetencer.map(lk => lk.kompetenceId)).flat());
        }

        dispatch(clearSelectedLaegekompetenceMetas())
        dispatch(setKompetenceCardStateShowCheckboxes([]))
        setSelectionActiveState(false);
        setPlanlaegActiveState(false);
        setGivMeritActiveState(false);
    }

    const createLaegekompetenceNotat = () => {
        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: createLaegekompetenceNotatModalTarget}))
    }

    const addKompetencerToForloeb = async () => {
        let chosenKompetencer = kompetenceCardState.selectedLaegekompetenceMetas;
        let kompetenceIds = chosenKompetencer.map(lk => lk.laegekompetenceId);

        let updatedForloeb = await logbogLaegeKompetenceApi.updateKompetencerOnForloeb(forloebSliceState.forloebState.id, kompetenceIds);
        if (updatedForloeb) {
            resetSelectionState();
            dispatch(toggleLaegekompetenceListRefreshPing())
            NotificationModule.showSuccess(Localizer.kompetencerPage_forloebskompetencerEditted(), "");
        }
    }

    const givMeritTilKompetencer = async () => {
        let chosenKompetencer = kompetenceCardState.selectedLaegekompetenceMetas;
        setLaegekompetenceMetaState(chosenKompetencer);
        setOpenMeritModal(true);
    }

    async function godkendValgteKompetencer() {
        let laegekompetencer: LaegekompetenceMeta[] = [];

        if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0) {
            for (const lkm of kompetenceCardState.selectedLaegekompetenceMetas) {
                let laegekompetenceModel = LaegekompetenceMeta.FromState(lkm, forloebSliceState.forloebState);
                if (laegekompetenceModel.status !== LaegekompetenceStatusEnum.Godkendt) {
                    laegekompetenceModel.status = LaegekompetenceStatusEnum.Godkendt;
                    laegekompetencer.push(laegekompetenceModel);
                }

                if (kompetenceCardState.selectedLaegekompetenceMetas.findIndex(lkmState => lkmState.laegekompetenceId === lkm.laegekompetenceId) > -1) {
                    dispatch(setActiveLaegekompetenceMeta(laegekompetenceModel.ToState()));
                }
            }
            await logbogLaegeKompetenceApi.patchStatusMultiple(laegekompetencer);
        }
        setOpenMeritModal(false)

        //update state
        dispatch(toggleLaegekompetenceListRefreshPing())
        dispatch(clearSelectedLaegekompetenceMetas())
        dispatch(setKompetenceCardStateShowCheckboxes([]))
    }

    const getKompetenceCategory = (index: number) => {
        return laegekompetenceCatagoriesState.filter(lk => lk.catagoryEnum !== KompetenceCatagory.Foraeldede || (lk.catagoryEnum === KompetenceCatagory.Foraeldede && givMeritActiveState))[index];
    }

    const kompetencerCount = laegekompetenceCatagoriesState.flatMap(x => x.laegekompetencer).length;
    const noKompetencer = kompetencerCount <= 0;
    const emptyListText = isBrugerKompetencerList ? Localizer.kompetencePage_noKompetencer() : Localizer.klarmeldingerPage_noKlarmeldinger();

    return (
        <div className="div-style card-list-custom style body-flex">
            <Loading 
                isLoading={isLoading}
                //if there is no kompetencer initially: show loading as soon as possible:
                timeout={noKompetencer ? 0 : 1000}
                text={Localizer.kompetencePage_getKompetencer()} 
                emptyText={noKompetencer ? emptyListText : ""} 
                spinnerClasses="margin-m"
                >
                {selectionActiveState &&
                    <div className="card-body d-flex flex-column flex-space-around card-list-custom body-flex">
                        <div className="d-flex wrap flex-space-around">
                            <button type="button" className="btn btn-default"
                                    onClick={resetSelectionState}>
                                {Localizer.global_fortryd()}
                            </button>
                            <button className="btn btn-default" onClick={createLaegekompetenceNotat} data-toggle="modal"
                                    data-target={`#${createLaegekompetenceNotatModalTarget}`}
                                    disabled={((kompetenceCardState.showCheckboxOnIds.length > 0 && (kompetenceCardState.selectedLaegekompetenceMetas.length === 0)) || kompetenceCardState.selectedLaegekompetenceMetas.filter(lk => lk.godkendtDato !== undefined).length > 0 )}>
                                <EmblaIcon iconName="document"/>
                                {Localizer.notatPage_createNotat()}
                            </button>

                            <StatusButton buttonHandleCallback={godkender => setShowSpinner(godkender)} brugerId={brugerId}/>
                        </div>
                    </div>
                }

                {planlaegActiveState &&
                    <div className="card-body flex-space-around card-list-custom body-flex">
                        <p className="">{Localizer.kompetencePage_chooseKompetencer()}</p>
                        <div>
                            <button type="button" className="btn btn-default margin-top-s margin-right-s"
                                    onClick={resetSelectionState}>
                                {Localizer.global_fortryd()}
                            </button>
                            <button type="button" className="btn btn-primary margin-top-s"
                                    onClick={addKompetencerToForloeb}>
                                {Localizer.global_save()}
                            </button>
                        </div>
                    </div>
                }

                {givMeritActiveState &&
                    <div className="card-body flex-space-around card-list-custom body-flex">
                        <div>
                            <button type="button" className="btn btn-default"
                                    onClick={resetSelectionState}>
                                {Localizer.global_fortryd()}
                            </button>
                            <button type="button" className="btn btn-primary margin-left-s"
                                    disabled={kompetenceCardState.showCheckboxOnIds.length > 0 && kompetenceCardState.selectedLaegekompetenceMetas.length === 0}
                                    onClick={givMeritTilKompetencer}>
                                {Localizer.kompetencePage_givMerit()}
                            </button>
                        </div>
                    </div>
                }
                <div className="card-body flex-container-column card-list-custom body-flex">
                    {!selectionActiveState && !planlaegActiveState && !givMeritActiveState && (isCurrentForloebBrugerMaalbeskrivelseSelected || isKlarmeldingerKompetenceList) && !currentUser.IsKonsulentForLaegeligVidereuddannelse() &&
                        <div className="flex-container wrap">
                            <button type="button" className="btn btn-default margin-right-s"
                                    onClick={toggleProcessMore}>
                                {Localizer.global_chooseMultiple()}
                            </button>

                            {(currentUser.IsLaege() && !forloebSliceState.forloebState.godkendtDen) &&
                                <button type="button" className="btn btn-default text-truncate"
                                        onClick={togglePlanlaeg}>
                                    {Localizer.kompetencePage_planlaegKompetencer()}
                                </button>
                            }
                            {currentUser.IsAdminOrVus() &&
                                <button type="button" className="btn btn-default margin-left-s"
                                        onClick={toggleGivMerit}>
                                    {Localizer.kompetencePage_givMerit()}
                                </button>
                            }

                            {(currentUser.IsAdminOrVus() || currentUser.IsVejleder() || currentUser.IsUddannelsesansvarlig()) && !isKlarmeldingerKompetenceList &&
                                <div className={classNames("btn-group", !isMobile ? "visning-kompetence-btngroup" : "margin-top-s")}>
                                    <button type="button"
                                            className={`btn ${kompetenceListViewType === KompetenceListViewTypeEnum.Planlagt ? "btn-primary" : "btn-default"}`}
                                            aria-label="Left"
                                            onClick={() => setKompetenceListViewType(KompetenceListViewTypeEnum.Planlagt)}>
                                        {Localizer.global_planlagt()}
                                    </button>
                                    <button type="button"
                                            className={`btn ${kompetenceListViewType === KompetenceListViewTypeEnum.Standard ? "btn-primary" : "btn-default"}`}
                                            aria-label="Right"
                                            onClick={() => setKompetenceListViewType(KompetenceListViewTypeEnum.Standard)}>
                                        {Localizer.global_standard()}
                                    </button>
                                </div>
                            }
                        </div>
                    }
                    <div className={`d-flex wrap ${isMobile ? "flex-container-column" : ""}`}>
                        {isBrugerKompetencerList &&
                            <div className={"min-w-150 margin-right-s margin-top-s"}>
                                <Dropdown
                                    placeholder={Localizer.global_all()}
                                    options={kompetencestatusTypeDropdownOptions}
                                    onChange={(selectedOption) => setKompetenceStatus(selectedOption?.value)}
                                    isClearable
                                />
                            </div>
                        }
                        <div className={`flex-grow input-icon-container ${isMobile ? "margin-right-s" : ""}`}>
                            <input className="form-control margin-top-s"
                                   onChange={(event) => setSearchParam(event.currentTarget.value)}
                                   value={searchParam}
                                   placeholder={isMobile ? props.customModileSearchPlaceholder ?? Localizer.klarmeldingerPage_searchStringMobile() : props.customSearchPlaceholder ?? Localizer.klarmeldingerPage_searchString()}/>
                            <div className="input-icon margin-top-s">
                                <EmblaIcon iconName="search"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-body padding-0 card-list-scroll position-relative"
                    key={laegekompetenceCatagoriesSearchedState.map(lkc => lkc.laegekompetencer.map(lk => lk.laegekompetenceId + lk.status + lk.notatCount)).flat().join() + givMeritActiveState}>
                    {
                        laegekompetenceCatagoriesSearchedState
                            .filter(lk => lk.catagoryEnum !== KompetenceCatagory.Foraeldede || (lk.catagoryEnum === KompetenceCatagory.Foraeldede && givMeritActiveState))
                            .map((kompetenceCatagory, index) =>
                                <CollapseTab title={selectionActiveState
                                    ? kompetenceCatagory.title + ` (${kompetenceCatagory.laegekompetencer.length}/${getKompetenceCategory(index).laegekompetencer.length}) - ${getKompetenceCategory(index).laegekompetencer.filter(k => kompetenceCardState.selectedLaegekompetenceMetas.flatMap(k => k.laegekompetenceId).includes(k.laegekompetenceId)).length} valgt`
                                    : kompetenceCatagory.title + ` (${kompetenceCatagory.laegekompetencer.length})`}
                                             id={KompetenceCatagory[kompetenceCatagory.catagoryEnum] + kompetenceCatagory?.id}
                                             expandedDefault={kompetenceCatagory.expandByDefault}
                                             categoryEnum={kompetenceCatagory.catagoryEnum}
                                             key={KompetenceCatagory[kompetenceCatagory.catagoryEnum]}>
                                    {
                                        kompetenceCatagory.laegekompetencer.map((laegekompetence) =>
                                            <KompetenceCard
                                                key={laegekompetence.laegekompetenceId + laegekompetence.status?.toString() + laegekompetence.notatCount}
                                                laegekompetence={laegekompetence}
                                                index={laegekompetence.laegekompetenceId}
                                                kompetenceListType={props.kompetenceListType}
                                                onFavoriteToggleCallback={laegekompetenceCardFavoriteToggles}
                                                showSpinner={showSpinner}
                                                kompetenceCategory={kompetenceCatagory.catagoryEnum}
                                                regionId={forloebSliceState.forloebState.regionId}
                                                amountFiles={idAndCounts.find(x => x.id === laegekompetence.kompetenceId)?.count}    
                                            />
                                        )
                                    }
                                </CollapseTab>
                            )
                    }
                </div>
                {openMeritModal && laegekompetenceMetaState.length > 0 &&
                <SlideIn
                    id={givMeritmModalTarget}
                    defaultOpen={true}
                    title={Localizer.notatPage_meritNotat()}
                    actionText={Localizer.notatPage_creatingNotat()}
                    bodyContent={<CreateLaegekompetenceNotat modalId={givMeritmModalTarget}
                                                             laegekompetencer={laegekompetenceMetaState.filter(l => l.status !== LaegekompetenceStatusEnum.Godkendt).map(lk => LaegekompetenceMeta.FromState(lk, forloebSliceState.forloebState))}
                                                             notatType={NotatType.Merit}
                                                             titel={Localizer.notatPage_meritNotat()}/>}
                    actionFinishedCallback={() => godkendValgteKompetencer()}
                    actionOnCloseCallback={() => setOpenMeritModal(false)}
                />}
            </Loading>
        </div>
    );
}
