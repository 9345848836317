import {SpoergsmaalType} from "./spoergsmaalType";
import {SvarmulighedModel} from "./svarmulighedModel";
import {KompetenceSvarModel} from "./KompetencesvarModel";
import {
    logbogKompetenceSpoergsmaalResponseModel
} from "../../../services/api/logbogEvaluering/logbogKompetenceSpoergsmaalResponseModel";

export class KompetenceSpoergsmaalModel {

    public static FromResponseModel(spoergsmaalResponseModel: logbogKompetenceSpoergsmaalResponseModel): KompetenceSpoergsmaalModel{
        let newModel = new KompetenceSpoergsmaalModel();

        newModel.id = spoergsmaalResponseModel.id;
        newModel.titel = spoergsmaalResponseModel.titel;
        newModel.tekst = spoergsmaalResponseModel.tekst;
        newModel.sortOrder = spoergsmaalResponseModel.sortOrder;
        newModel.isRequired = spoergsmaalResponseModel.isRequired;
        newModel.antalSvar = spoergsmaalResponseModel.antalSvar;
        newModel.spoergsmaalTypeEnum = spoergsmaalResponseModel.spoergsmaalTypeEnum;
        newModel.svarmuligheder = spoergsmaalResponseModel.svarmuligheder.map(s => SvarmulighedModel.FromResponseModel(s));
        newModel.evalueringSvar = spoergsmaalResponseModel.evalueringSvar.map(x => KompetenceSvarModel.FromResponseModel(x, spoergsmaalResponseModel.svarmuligheder));
        newModel.isFeedback = spoergsmaalResponseModel.isFeedback;
        return newModel;
    }

    id: string;
    titel?: string;
    tekst: string;
    spoergsmaalTypeEnum: SpoergsmaalType;
    sortOrder: number;
    antalSvar: number;
    isRequired: boolean;
    svarmuligheder: SvarmulighedModel[];
    isFeedback: boolean;
    evalueringSvar: KompetenceSvarModel[];
}
