export class DataTableHeaderAction {
    /** 
     * @param buttonId Id for the button which should trigger the 'clickMethod', when clicked
     * @param clickMethod Method which should be called when action is called. Note: Important to wrap in useCallback!
     * */
    constructor(buttonId: string, clickMethod: () => void | Promise<void>, onLoadingHideContent?: boolean) {
        this.buttonId = buttonId;
        this.clickMethod = clickMethod;
        this.onLoadingHideButtonContent = onLoadingHideContent;
    }

    buttonId : string;
    clickMethod : () => void | Promise<void>;
    onLoadingHideButtonContent? : boolean;
}
