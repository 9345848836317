import React from "react";
import {TopMenu} from "../../layout/topMenu/topMenu";
import {NotificationModule} from "ditmer-embla";
import {HttpResponseError, HttpUnauthorizedError} from "../../../services/httpClient/httpClientService";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {AccountService} from "../../../services/account/accountService";
import * as Sentry from "@sentry/react";

const showErrorToast = (error?: string) => {
    const errorMessage = error ? error : Localizer.global_internalServerError();
    NotificationModule.showError(Localizer.global_error(), errorMessage);
};

const showWarningToast = (warning?: string) => {
    const warningMessage = warning ? warning : Localizer.global_internalServerError();
    NotificationModule.showWarning(Localizer.global_error(), warningMessage);
}

// Async error handler
window.addEventListener('unhandledrejection', async (event) => {
    await HandleError(event);
});

export const HandleError = async (event : any) => {
    if (event instanceof HttpUnauthorizedError){
        const userService = new AccountService();
        await userService.Logout();
    }
    if (event.reason instanceof HttpUnauthorizedError){
        const userService = new AccountService();
        await userService.Logout();
    }
    if (event.reason?.message == "invalid_grant"){
        const userService = new AccountService();
        await userService.Logout();
    }
    else if (event instanceof HttpResponseError || event.reason instanceof HttpResponseError){
        const responseError: HttpResponseError = event instanceof HttpResponseError ? event : event.reason;

        if (responseError.status === 404) {
            // status "Not found":
            showWarningToast(responseError.message);
        } else {
            showErrorToast(responseError.message);
        }
    }
    else {
        showErrorToast();
    }

    console.error(event.reason);

    //TODO: Det her virker ikke helt optimalt... Den logger ikke altid og nogle gange to entries for some reason... Se i App.tsx...
    Sentry.captureException(event);
    //Sentry.captureException(event as Sentry.Exception);
    //Sentry.captureException(event.reason);
    //Sentry.captureMessage(event.reason);
    // Sentry.captureEvent(event as Sentry.Event);
}

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
    constructor(props : any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error : Error) {
        if (error instanceof HttpUnauthorizedError){
            const userService = new AccountService();
            userService.Logout();
        }

        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error);
        Sentry.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={"body-container"}>
                    <TopMenu/>

                    <div className="page-content">
                        <div className="main-content">
                            <div className="body-content body-content-error">
                                <h1>{Localizer.errorpage_errorOccured()}</h1>
                                <br/>
                                <button className={"btn btn-default"} onClick={() => window.location.replace("/")}>
                                    {Localizer.errorpage_goToFrontPage()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
