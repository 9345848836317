
export class UserProfileAvatarModel {
    constructor(name: string,
                initials: string,
                isLaege?: boolean,
                userEmail?: string) {
        this.Name = name;
        this.Initials = initials;
        this.Email = userEmail;
        this.IsLaege = isLaege;
    }

    Name: string;
    Initials: string;
    Email?: string;
    IsLaege?: boolean;
}
