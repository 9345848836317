import {EvalueringType} from "../../../core/sharedmodels/evaluering/evalueringType";

export class KompetenceEvalueringCreateRequestModel {
    constructor(evalueringsskemaId: string, evalueringstype: EvalueringType, kompetenceId: string) {
        this.evalueringsskemaId = evalueringsskemaId;
        this.evalueringTypeEnum = evalueringstype;
        this.kompetenceId = kompetenceId;
    }

    kompetenceId: string;
    evalueringsskemaId: string;
    evalueringTypeEnum: EvalueringType;
}
