import {Localizer} from "../../../infrastructure/localization/localizer";
import {
    erhvervsstyrelsesCookieBekendtgørelseUrl, googleAnalyticsUrl,
    minecookiesUrl
} from "../../../infrastructure/redirectConstant";
import useScreenResolution from "core/hooks/useScreenResolution";


export function CookiesInformationPage() {
    const { isMobile } = useScreenResolution();

    return (
        <div className="container">
            <div className="flex-container-column margin-top-l ">
                <div className="margin-top-m margin-bottom-m">
                    <h3 className={`${isMobile ? "padding-top-l" : "flex-row-center padding-l"}`}>
                        {Localizer.global_cookies()}
                    </h3>
                    <p>
                        {Localizer.landingPage_vilkaarCookieText()}
                    </p>
                </div>
            </div>
            <div flex-container flex-row justify-content-center>
                <div flex-container flex-row justify-content-center>
                    <h3 className={`${isMobile ? "padding-top-l" : "flex-row-center padding-l"}`}>
                        {Localizer.landingPage_vilkaarCookieInform()}
                    </h3>
                    <span>
                        {Localizer.landingPage_vilkaarCookieInformText()} <a href={erhvervsstyrelsesCookieBekendtgørelseUrl}>{Localizer.landingPage_vilkaarCookieUrl()}</a>
                    </span>

                </div>
            </div>
            <div flex-container flex-row justify-content-center>
                <div flex-container flex-row justify-content-center>
                    <h3 className={`${isMobile ? "padding-top-l" : "flex-row-center padding-l"}`}>
                        {Localizer.landingPage_vilkaarCookieDelete()}
                    </h3>
                    <span>{Localizer.landingPage_vilkaarCookieDeleteCookiePreUrl()} <a href={minecookiesUrl}>{Localizer.landingPage_vilkaarCookieDeleteeUrl()}</a> {Localizer.landingPage_vilkaarCookieDeleteCookieUrl()} </span>
                    <span>{Localizer.landingPage_vilkaarCookieGoogleAnalytics} <a href={googleAnalyticsUrl}>{Localizer.landingPage_vilkaarCookieGoogleAnalyticsUrl()}</a> </span>
                </div>
            </div>
        </div>
    )
}
