import React from "react";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../../userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../userProfileAvatar/userProfileAvatarModel";
import {calculateForloebColumnNumber} from "../forloebOverviewUtils/forloebOverviewUtils";
import {LaegeStillingModel} from "../../../sharedmodels/stilling/laegeStillingModel";
import Tooltip from "core/components/tooltips/Tooltip";
import dayjs from "dayjs";
import { calculatePillColPaddings } from "../OverviewComponent/StyleVariables";

export interface LaegeStillingViewProps {
    rowIndex: number;
    laegestilling: LaegeStillingModel;
    collapsed: boolean;
    years: number[];
    handleForloebClicked: (forloebId: string) => void;
}

export const LaegeStillingView = ({rowIndex, laegestilling, collapsed, years, handleForloebClicked}: LaegeStillingViewProps) => {

    const startMonth = laegestilling.startDate.getMonth();
    const startYear = laegestilling.startDate.getFullYear();
    const startColumn = calculateForloebColumnNumber(startMonth, startYear, years);

    const endMonth = laegestilling.endDate.getMonth();
    const endYear = laegestilling.endDate.getFullYear();
    const endColumn = calculateForloebColumnNumber(endMonth, endYear, years, 1);



    const {left, right} = calculatePillColPaddings(dayjs(laegestilling.endDate), dayjs(laegestilling.startDate));

    const style = {
        "--stilling-row-index": rowIndex,
        "--forloeb-column-start": startColumn,
        "--forloeb-column-end": endColumn,
        "--forloeb-column-l-pad": left,
        "--forloeb-column-r-pad": right,
    } as React.CSSProperties;

    return (
        <div
             className="stilling-laege collapse show"
             onClick={() => handleForloebClicked(laegestilling.forloebId)}
             style={style}>
            <>
                <Tooltip className={`laege-stilling-profile text-truncate padding-left-xxs align-self-center`}
                         title={laegestilling.ToDataString()}
                         tipClassName='forloeb-or-laege-tooltip-tip'>
                    <UserProfileAvatar size={UserProfileAvatarSizeEnum.extraExstraSmall}
                                       userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                                       userProfileAvatarModel={new UserProfileAvatarModel(laegestilling.laegeNavn, laegestilling.initialer, true)}
                    />
                </Tooltip>
            </>
        </div>
    );
}
