import React, {useState} from "react";
import {
    ObligatoriskDokumentationModel
} from "../../sharedmodels/obligatoriskDokumentation/obligatoriskDokumentationModel";
import {Table} from "../table/table";
import {DataTableHeader} from "../table/DataTableHeader";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {
    ObligatoriskDokumentationStatusType
} from "../../../services/api/logbogObligatoriskDokumentation/obligatoriskDokumentationStatusType";
import useEffectAsync from "../../../infrastructure/effect";
import {
    LogbogObligatoriskDokumentationApi
} from "../../../services/api/logbogObligatoriskDokumentation/obligatoriskDokumentationApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {
    IsKursusTypeEnum,
    ObligatoriskDokumentationTypeEnum
} from "../../sharedmodels/obligatoriskDokumentation/obligatoriskDokumentationTypeEnum";
import {AccountService} from "../../../services/account/accountService";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import {LogbogExportApi} from "../../../services/api/logbogExport/logbogExportApi";
import {AsyncButton} from "../button/asyncButton";
import {LogbogLaegekursusGruppeApi} from "../../../services/api/laegekursusGruppe/logbogLaegekursusGruppeApi";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import {Loading} from "../loading/loading";
import {EmblaIcons} from "core/emblaIcons";
import { ObligatoriskDokumentationItem } from "./ObligatoriskDokumentationItem";

export interface ObligatoriskDokumentationProps {
    uddannelseId: string
    userId: string
    forloebId: string
    simpleVersion: boolean
    tableId: string

    uddannelsesType?: UddannelseTypeEnum;
    brugerMaalbeskrivelseId?: string
    ansoegningId?: string
    responsive?: boolean
    completionCallback?: (status: boolean, antalObligatoriskDokumentation: number) => void
    fileSelectedCallback?: (ids: string[]) => void
    initialSelectedRows?: string[];

    hideActions?: boolean;
    hideStatus?: boolean;
    showDokumentationTypes?: ObligatoriskDokumentationTypeEnum[];

    parentFunction?: () => void;
}

const obligatoriskDokumentationApi = new LogbogObligatoriskDokumentationApi(new HttpClientService())
const logbogExportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
const laegekursusGruppeApi = new LogbogLaegekursusGruppeApi(new HttpClientService(), new AccountService())

export function ObligatoriskDokumentation(props: ObligatoriskDokumentationProps) {
    const [obligatoriskDokumentation, setObligatoriskDokumentation] = useState<ObligatoriskDokumentationModel[]>([]);
    const [isLoadingData, setIsLoadingData] = useState(true);

    function dokumentationSelected() {
        return <div>
        </div>
    }

    useEffectAsync(async() => {
        setIsLoadingData(true)
        await setObligatoriskDokumentationState();
        setIsLoadingData(false)
    },[])

    useEffectAsync(async() => {
        let dokumentationComplete = false;
        if(props.uddannelsesType) {
            dokumentationComplete = props.uddannelsesType === UddannelseTypeEnum.Hoveduddannelse
                ? obligatoriskDokumentation.filter(d => d.obligatoriskDokumentationType !== ObligatoriskDokumentationTypeEnum.Attestation).every(od => od.obligatoriskDokumentationStatus === ObligatoriskDokumentationStatusType.Godkendt)
                : obligatoriskDokumentation.every(od => od.obligatoriskDokumentationStatus === ObligatoriskDokumentationStatusType.Godkendt);
        }

        props.completionCallback?.(dokumentationComplete, obligatoriskDokumentation.length);
    },[obligatoriskDokumentation])

    const setObligatoriskDokumentationState = async () => {
        const brugerKursusraekkeId = await laegekursusGruppeApi.getKursusRaekkeIdByMaalbeskrivelse(props.brugerMaalbeskrivelseId as string, props.userId);

        let obligatoriskDokumentation;
        if (props.uddannelsesType === UddannelseTypeEnum.Introduktion){
            obligatoriskDokumentation = await obligatoriskDokumentationApi.getObligatoriskDokumentationForIntro(brugerKursusraekkeId, props.brugerMaalbeskrivelseId as string);
        }
        else {
            obligatoriskDokumentation = await obligatoriskDokumentationApi.getObligatoriskDokumentation(props.uddannelseId, props.userId, brugerKursusraekkeId, props.brugerMaalbeskrivelseId as string);
        }
        let dokumentation = obligatoriskDokumentation.filter(x => showDownload(x))
        dokumentation.sort((a, b) => a.obligatoriskDokumentationNavn > b.obligatoriskDokumentationNavn ? 1 : -1)
        dokumentation = dokumentation.concat(obligatoriskDokumentation.filter(x => !showDownload(x)))

        if (props.showDokumentationTypes) {
            dokumentation = dokumentation.filter(d => props.showDokumentationTypes?.includes(d.obligatoriskDokumentationType))
        }

        setObligatoriskDokumentation(dokumentation);
    }

    const downloadPdf = async (type: ObligatoriskDokumentationTypeEnum, laegeKursusGruppeId?: string) => {
        if(type === ObligatoriskDokumentationTypeEnum.LaegeligBeskaeftigelse) {
            await logbogExportApi.getBeskaeftigelse(props.ansoegningId as string, props.userId, props.brugerMaalbeskrivelseId as string, props.uddannelsesType as number);
        }
        else if(type === ObligatoriskDokumentationTypeEnum.Attestation) {
            await logbogExportApi.getAttesationPdf(props.userId, props.brugerMaalbeskrivelseId as string);
        }
        else if (type === ObligatoriskDokumentationTypeEnum.KompetencerGodkendt){
            await logbogExportApi.getPdfKompetencer(props.userId, props.brugerMaalbeskrivelseId as string);
        }
        else if (IsKursusTypeEnum(type) && laegeKursusGruppeId){
            await logbogExportApi.getPdfKursus(laegeKursusGruppeId, props.userId, props.brugerMaalbeskrivelseId as string);
        }
    }

    const getColumnHeaders: () => DataTableHeader[] = () => {
        const columnHeaders: DataTableHeader[] = [
            new DataTableHeader(Localizer.global_title(), false)
        ];

        if (!props.hideStatus)
            columnHeaders.push(new DataTableHeader(Localizer.global_status(), false));

        columnHeaders.push(new DataTableHeader("", false));

        return columnHeaders;
    }

    const showDownload = (dokumentation : ObligatoriskDokumentationModel) => {
        if (dokumentation.obligatoriskDokumentationType === ObligatoriskDokumentationTypeEnum.KompetencerGodkendt) {
            return true;
        }
        if (dokumentation.obligatoriskDokumentationType === ObligatoriskDokumentationTypeEnum.KurserGodkendt) {
            return true;
        }
        if (dokumentation.obligatoriskDokumentationType === ObligatoriskDokumentationTypeEnum.AnsaettelseOgUddannelse) {
            return false;
        }
        if (dokumentation.obligatoriskDokumentationType === ObligatoriskDokumentationTypeEnum.Attestation) {
            return true;
        }
        else {
            return dokumentation.obligatoriskDokumentationStatus === ObligatoriskDokumentationStatusType.Godkendt;
        }
    }

    const renderTable = () => {
        return <Table
            tableIdentifier={props.tableId}
            columnHeaders={getColumnHeaders()}
            renderTableBody={() => renderTableBody()}
            actionsSelectedTextSingle={Localizer.valgt()}
            actionsSelectedTextPlural={Localizer.valgt()}
            renderTableActions={props.simpleVersion ? () => dokumentationSelected() : undefined}
            showLengthChange={false}
            showSearching={false}
            showFilter={false}
            showPaging={false}
            responsive={props.responsive}
            hideHeaderBodies={true}
            objectSelectedCallback={props.fileSelectedCallback}
            initialSelectedRows={props.initialSelectedRows}
        />
    }

    const renderTableBody = () => {
        return (
            <tbody>
            {
                obligatoriskDokumentation.map((dokumentation, index) => {
                    return (
                        <tr key={dokumentation.obligatoriskDokumentationNavn + index} data-toggle="modal" data-id={(dokumentation.obligatoriskDokumentationType, dokumentation.obligatoriskDokumentationType)}>
                            <td className={"align-middle"}>
                                <div>
                                    <ObligatoriskDokumentationItem dokumentation={dokumentation} userId={props.userId} forloebId={props.forloebId} parentFunction={props.parentFunction}  ansoegningId={props.ansoegningId}/>
                                </div>
                            </td>
                            {!props.hideStatus &&
                            <td className={"align-middle"}>
                            {dokumentation.obligatoriskDokumentationStatus === ObligatoriskDokumentationStatusType.Godkendt &&
                                <span className="badge-pill badge-normal success">
                                    <EmblaIcon iconName='success'/>
                                    {Localizer.global_klar()}
                                </span>
                            }
                            {dokumentation.obligatoriskDokumentationStatus === ObligatoriskDokumentationStatusType.Mangler &&
                                <span className="badge-pill badge-normal warning">
                                    <EmblaIcon iconName="warning"/>
                                    {Localizer.global_mangler()}
                                </span>
                            }
                            </td>}
                            <td className={"align-middle"}>
                                {showDownload(dokumentation) &&
                                    <div>
                                        <AsyncButton additionalClasses={"min-w-60"}
                                            clickFunction={async () => await downloadPdf(dokumentation.obligatoriskDokumentationType, dokumentation.laegeKursusgruppeId)}
                                            child={<EmblaIcon iconName={EmblaIcons.Download}/>} />
                                    </div>
                                }
                            </td>
                        </tr>
                    );
                })
            }
            </tbody>
        );
    }

    return (
        <Loading isLoading={isLoadingData} text={Localizer.global_getDokumentation()} spinnerClasses={"margin-m"} timeout={0}>
        {obligatoriskDokumentation.length > 0 &&
                <div className="files-table">
                    {renderTable()}
                </div>
        }
        </Loading>
    )
}
