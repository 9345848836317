import './forloebOverview.scss';
import React, {ReactFragment, useCallback, useEffect, useState} from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {useEffectOnUpdate} from "../../../infrastructure/effect";
import {RoutePaths} from "../../../infrastructure/routes";
import {useHistory} from "react-router-dom";
import {ForloebOverviewConstants} from "./forloebOverviewConstants";
import {useAppDispatch, useAppSelector} from 'app/hooks';
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {PaginationModel} from "../../sharedmodels/filter/PaginationModel";
import {SlideIn} from "../slideIn/slideIn";
import {
    applyFilters,
    clearFilters,
    forloebOverviewFiltersReducer,
    ForloebOverviewFiltersState,
    pingRefreshFilter,
    setSearch
} from "./forloebOverviewFiltersSlice";
import {ForloebOverviewFilterModel, toState} from "./ForloebOverviewFilterModel";
import {ToSelectOptions_Deprecated} from "../../sharedmodels/forSelectModel";
import {FilterType} from "../../sharedmodels/filter/filterType";
import {DropdownButton} from "../dropdownButton/dropdownButton";
import {ForloebOverviewCreateFilter} from "./forloebOverviewCreateFilter";
import {ForloebOverviewEditFilter} from "./forloebOverviewEditFilter";
import {CreateForloeb} from "../../componentsPage/forloeb/createforloeb";
import {FilterParamsModel} from "../../sharedmodels/filter/FilterParamsModel";
import {InitForloebState} from 'pages/forloeb/forloebApi';
import {StorageKeys} from 'infrastructure/storage/storageKeys';
import {CheckboxSwitch} from '../checkbox/checkboxSwitch';
import {ForloebOverviewTypeEnum} from "../../sharedmodels/forloeb/forloebOverviewTypeEnum";
import {ForloebOverviewStillingerView} from "./forloebOverviewStillingerView";
import {cloneFilterFromState} from "./forloebOverviewUtils/forloebOverviewUtils";
import {AsyncButton} from '../button/asyncButton';
import {ForloebOverviewBrugereView} from "./forloebOverviewBrugereView";
import {EmblaIcons} from 'core/emblaIcons';
import Tooltip from '../tooltips/Tooltip';
import PrintButton from '../button/printButton/printButton';
import useLogbogApi from "../../hooks/useLogbogApi";
import useUser from 'core/hooks/useUser';
import useUserFilterPermissions from 'core/hooks/useUserFilterPermissions';
import {Dropdown, DropdownOption} from "../dropdown/dropdown";
import { SearchField } from '../search/searchField';
import {Loading} from "../loading/loading";

export const ForloebOverview = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { forloebApi, logbogFilterApi, logbogExportApi } = useLogbogApi();
    const { currentUser } = useUser();
    const { permissions } = useUserFilterPermissions();

    const forloebOverviewFilterSliceState = useAppSelector(forloebOverviewFiltersReducer) as ForloebOverviewFiltersState;

    const [pageSize, setPageSize] = useState<number>(0);
    const [pageSizeSelectOptions, setPageSizeSelectOptions] = useState<DropdownOption<string>[]>([]);
    const [filter, setFilter] = useState<ForloebOverviewFilterModel>(new ForloebOverviewFilterModel());
    const [filterParams, setFilterParams] = useState<FilterParamsModel>(new FilterParamsModel(new PaginationModel(1, pageSize, 0), forloebOverviewFilterSliceState.search));

    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [createForloebModalOpen, setCreateForloebModalOpen] = useState(false);
    const [selectedFilterToEditId, setSelectedFilterToEditId] = useState<string | undefined>(undefined);
    const [editFilterModalOpen, setEditFilterModelOpen] = useState<boolean>(false);
    const [anyFilteredRecords, setAnyFilteredRecords] = useState<boolean>(false);
    const [forloebOverviewType, setForloebOverviewType] = useState<ForloebOverviewTypeEnum>();

    const [filterToEdit, setFilterToEdit] = useState<ForloebOverviewFilterModel>(new ForloebOverviewFilterModel());

    const [mineFiltreSelectOptions, setMineFiltreSelectOptions] = useState<{ key: string; value: string; }[]>([]);
    const [faellesFiltreSelectOptions, setFaellesFiltreSelectOptions] = useState<{ key: string; value: string; }[]>([]);

    const [selectedFilterName, setSelectedFilterName] = useState(Localizer.forloebOverviewPage_anvendFilter());
    const [filterSelected, setFilterSelected] = useState(false);
    const [showUnoccupiedStillinger, setShowUnoccupiedStillinger] = useState(false);

    const [overlappendeVisningState, setOverlappendeVisningState] = useState<boolean>(true);
    const [visHorizontaleLinjerState, setVisHorizontaleLinjerState] = useState<boolean>(true);
    const [isCurrentPageSatByStorage, setIsCurrentPageSatByStorage] = useState(false);

    const filterModalTarget = "filtermodal";
    const createForloebModalTarget = "create-forloeb-modal"
    const canUseSavedFilters = currentUser.canUseSavedFilters();

    useEffect(() => {
        const savedPageSize = loadPageSize();
        setPageSize(savedPageSize);

        const savedViewOption = loadViewOption();
        setForloebOverviewType(savedViewOption);

        const advancedSearch = loadAdvancedSearch();
        const pageNumber = loadPageNumber();

        setFilterParams(new FilterParamsModel(new PaginationModel(pageNumber, savedPageSize, 0), filterParams.search, advancedSearch))
    }, []);

    const setPageSizeOptions = useCallback((selectedOption?: string) => {
        var forloebOverviewTypeString = Localizer.global_stillinger();

        if (forloebOverviewType == ForloebOverviewTypeEnum.ForloebOverblikBrugereView) {
            forloebOverviewTypeString = Localizer.laeger();
        }

        const pageSizeSelectOptions: DropdownOption<string>[] = ForloebOverviewConstants.pageSizeSelectOptions.map(option => {
            return {
                label: forloebOverviewTypeString + ": " + option.toString(),
                value: option.toString(),
            };
        });

        setPageSizeSelectOptions(pageSizeSelectOptions)
    }, [pageSize, forloebOverviewType]);

    useEffect(() => {
        const setFilterSelectOptions = async () => {

            if (canUseSavedFilters)
            {
                //get filter dropdown options
                const filters = await logbogFilterApi.getFilterSelectOptions();

                const mineFiltre = filters.filter(f => f.groupText === Localizer.forloebOverviewPage_mineFiltre());
                const faellesFiltre = filters.filter(f => f.groupText === Localizer.forloebOverviewPage_faellesFiltre());

                const mineFiltreSelectOptions = ToSelectOptions_Deprecated(mineFiltre, forloebOverviewFilterSliceState.filterId ? [forloebOverviewFilterSliceState.filterId] : [])
                setMineFiltreSelectOptions(mineFiltreSelectOptions);

                const faellesFiltreSelectOptions = ToSelectOptions_Deprecated(faellesFiltre, forloebOverviewFilterSliceState.filterId ? [forloebOverviewFilterSliceState.filterId] : [])
                setFaellesFiltreSelectOptions(faellesFiltreSelectOptions);
            }
        }

        // noinspection JSIgnoredPromiseFromCall
        setFilterSelectOptions();
        setPageSizeOptions();
        localStorage.setItem(StorageKeys.forloebsoverblikAdvancedSearch, filterParams.advancedSearch.toString());
    }, [canUseSavedFilters, filterParams.advancedSearch, forloebOverviewFilterSliceState.filterId, logbogFilterApi, setPageSizeOptions])

    useEffect(() => {
        setFilter(cloneFilterFromState(forloebOverviewFilterSliceState, new ForloebOverviewFilterModel()));
    }, [forloebOverviewFilterSliceState]);

    useEffect(() => {
        localStorage.setItem(StorageKeys.forloebsoverblikPageNumber, filterParams.paginationModel.page.toString());
    }, [filterParams.paginationModel.page]);

    useEffectOnUpdate(async () => {

        if (filterParams.paginationModel.page > 1 && !isCurrentPageSatByStorage) {
            // Reset current page, when we change the filter
            resetPaginationPage();
        }

        if(isCurrentPageSatByStorage) setIsCurrentPageSatByStorage(false);

    },[filter]);

    const navigateToForloeb = (uddannelseslaegeId: string, forloebErFravaer?: boolean) => {
        if (currentUser.IsHrMedarbejder() || !!forloebErFravaer)
            history.push(RoutePaths.ForloebList(uddannelseslaegeId).url)
        else if (
            !currentUser.IsAdminOrVus() &&
            !currentUser.IsUddannelsesansvarlig() &&
            !currentUser.IsVusMedarbejeder() &&
            !currentUser.IsKursusBruger() &&
            !currentUser.IsForskningstraeningsvejleder() &&
            !currentUser.IsSekretaer() &&
            !currentUser.IsKonsulentForLaegeligVidereuddannelse() &&
            !currentUser.IsLedelse()
        )
            history.push(RoutePaths.Uddannelseslaege(uddannelseslaegeId).url)
        else if (currentUser.IsKursusBruger() || currentUser.IsForskningstraeningsvejleder())
            history.push(RoutePaths.Kurser([uddannelseslaegeId]).url)
        else
            history.push(RoutePaths.Forloeb([uddannelseslaegeId]).url);
    }

    const handleOnClickUddannelseslaege = async (uddannelseslaegeId: string) => {
        const forloeb = await InitForloebState(dispatch, null, uddannelseslaegeId);

        navigateToForloeb(forloeb.brugerId, forloeb.erFravaer);
    }

    const handleOnClickForloeb = async (forloeId: string) => {
        const forloeb = await forloebApi.getForloebById(forloeId);
        if (forloeb.erFravaer) return;
        await InitForloebState(dispatch, null, forloeb.brugerId, forloeb.id);
        navigateToForloeb(forloeb.brugerId);
    }

    const filterButtonClicked = () => {
        setFilterModalOpen(true)
    }

    const clearFilterButtonClicked = () => {
        dispatch(clearFilters());
        setFilterSelected(false);
        setSelectedFilterName(Localizer.forloebOverviewPage_anvendFilter());
    }

    const changeAdvancedSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        const clone = Object.assign({}, filterParams);
        clone.advancedSearch = value;
        clone.paginationModel.page = 1;
        setFilterParams(clone);
    }

    const setOverlappendeVisning = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setOverlappendeVisningState(value);
    }

    const setVisHorizontaleLinjer = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setVisHorizontaleLinjerState(value);
    }

    const changeOccupiesStillinger = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked;
        setShowUnoccupiedStillinger(value);
        const clone = Object.assign({}, filterParams);
        clone.showUnoccupiedStillinger = value;
        setFilterParams(clone);
    }

    const setFilterParamsOnPageSizeChange = (pageLength: number) => {
        const clone = Object.assign({}, filterParams);
        clone.paginationModel.page = 1;
        clone.paginationModel.pageLength = pageLength;
        setFilterParams(clone);
    }

    const changeSearch = async (searchQuery: string) => {
        if(searchQuery === undefined) return;
        const clone = Object.assign({}, filterParams);
        clone.search = searchQuery;
        clone.paginationModel.page = 1;
        setFilterParams(clone);

        dispatch(setSearch(searchQuery));
    }

    const handlePagination = async (page: number) => {
        const clone = Object.assign({}, filterParams);
        clone.paginationModel.page = page;
        setFilterParams(clone);
    };

    const filterApplied = () => {
        setFilterModalOpen(false);
        setEditFilterModelOpen(false);
    }

    const filterAppliedCallback = (inputModel: ForloebOverviewFilterModel) => {
        if (inputModel.titel) {
            setSelectedFilterName(inputModel.titel);
        }

        setFilter(inputModel);
        setFilterSelected(inputModel.titel !== Localizer.forloebOverviewPage_anvendFilter());
    }

    const notCurrentSelectedfilterDeletedOrEdittedCallback = () => {
        const clone = Object.assign({}, filter);
        setFilter(clone);
        setFilterToEdit(clone);
    }

    const uddannelseslaegerScrollContainerRef = React.useRef<HTMLDivElement>(null);
    const stillingerScrollContainerRef = React.useRef<HTMLDivElement>(null);

    async function editFilter(filterId: string) {
        let filterToEdit = await logbogFilterApi.getFilterWithItems(FilterType.ForloebFilter, filterId);
        filterAppliedCallback(filterToEdit);
        setFilterToEdit(filterToEdit);
        setSelectedFilterToEditId(filterId);
        setEditFilterModelOpen(true);
    }

    const renderFilterCreate = () => {
        return (
            <ForloebOverviewCreateFilter modalId={filterModalTarget}
                                         paginationModel={filterParams.paginationModel}
                                         filter={filter}
                                         filterAppliedCallback={(inputModel) => filterAppliedCallback(inputModel)}
                                         permissions={permissions}/>
        )
    }

    const renderFilterEdit = () => {
        return (
            <ForloebOverviewEditFilter modalId={filterModalTarget}
                                       paginationModel={filterParams.paginationModel}
                                       filter={filterToEdit}
                                       filterAppliedCallback={(inputModel) => filterAppliedCallback(inputModel)}
                                       notCurrentSelectedfilterDeletedOrEdittedCallback={() => notCurrentSelectedfilterDeletedOrEdittedCallback()}
                                       filterToEditId={selectedFilterToEditId}
                                       permissions={permissions}/>
        )
    }

    async function getFilterItems(selectedOption: string | undefined) {
        if (selectedOption) {
            let filterItems = await logbogFilterApi.getFilterWithItems(FilterType.ForloebFilter, selectedOption);
            dispatch(applyFilters(toState(filterItems, true)))
            setSelectedFilterName(filterItems.titel);
            setFilterSelected(true);

            setFilterToEdit(filterItems);
            setSelectedFilterToEditId(selectedOption);
        }
    }

    const exportExcel = async () => {
        if (forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikBrugereView)
            await logbogExportApi.getFilteredLaegerWithForloebDetails(filter, filterParams);

        if (forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikStillingerView)
            await logbogExportApi.getFilteredStillingerWithForloebDetails(filter, filterParams);
    }

    const openCreateForloebModal = () => {
        setCreateForloebModalOpen(true);
    }

    const forloebCreated = () => {
        setCreateForloebModalOpen(false);
        dispatch(pingRefreshFilter());
    }

    const setPaginationCount = useCallback((filteredRecords: number, recordsTotal:number, xOfYisLoading: boolean = false): ReactFragment => {
        setAnyFilteredRecords(filteredRecords > 0)
        const startItem = ((filterParams.paginationModel.page - 1) * filterParams.paginationModel.pageLength) + 1;
        const totalPageNumber = Math.ceil(filteredRecords / filterParams.paginationModel.pageLength);
        const endItem = totalPageNumber === filterParams.paginationModel.page ? filteredRecords : (filterParams.paginationModel.page * filterParams.paginationModel.pageLength);
        return (
            <div className={"pagination-header"}>
                <div className={"flex-container-row"}>
                    {Localizer.global_filtrerede()}
                    {xOfYisLoading ? <Loading isLoading={xOfYisLoading} spinnerSize={"p-size"} spinnerClasses={"margin-left-xs margin-right-xs"} timeout={2000}/> : <>{filteredRecords}</>}
                    {Localizer.evalueringPage_udAf()}
                    {xOfYisLoading ? <Loading isLoading={xOfYisLoading} spinnerSize={"p-size"} spinnerClasses={"margin-left-xs margin-right-xs"} timeout={2000}/> : <>{recordsTotal}</>}
                </div>
                {recordsTotal === 0 ? <></> :
                    <div className={"flex-container-row"}>
                        {Localizer.global_filtrerede()} {startItem}-{endItem} {Localizer.evalueringPage_udAf()}
                        {xOfYisLoading
                            ? <Loading isLoading={xOfYisLoading} spinnerSize={"p-size"}
                                       spinnerClasses={"margin-left-xs margin-right-xs"}
                                       timeout={2000}/>
                            : <>{filteredRecords}</>}
                    </div>
                }
            </div>
        )
    }, [filterParams.paginationModel.page, filterParams.paginationModel.pageLength]);

    const loadPageSize = () => {
        const storagePageSize = localStorage.getItem(StorageKeys.forloebsoverblikPageSize);
        return storagePageSize ? Number(storagePageSize) : ForloebOverviewConstants.defaultPageSize;
    }

    const loadPageNumber = () => {
        const storagePageNumber = localStorage.getItem(StorageKeys.forloebsoverblikPageNumber);
        setIsCurrentPageSatByStorage(!!storagePageNumber);
        return storagePageNumber ? Number(storagePageNumber) : ForloebOverviewConstants.defaultPageNumber;
    }

    const loadViewOption = () => {
        const storageViewOption = localStorage.getItem(StorageKeys.forloebsoverblikViewOption);
        return storageViewOption ? Number(storageViewOption) : ForloebOverviewTypeEnum.ForloebOverblikStillingerView;
    }

    const loadAdvancedSearch = () => {
        const advancedSearch = localStorage.getItem(StorageKeys.forloebsoverblikAdvancedSearch);
        return Boolean(JSON.parse(advancedSearch!));
    }

    const resetPaginationPage = () => {
        setFilterParams((prev) => ({...prev, paginationModel: { page: 1, pageLength: prev.paginationModel.pageLength, recordCount: prev.paginationModel.recordCount }}));
    }

    function viewOptionChanged(viewoption: ForloebOverviewTypeEnum) {
        localStorage.setItem(StorageKeys.forloebsoverblikViewOption, viewoption.toString());
        setForloebOverviewType(viewoption);
        resetPaginationPage();
    }

    const smallInfoIcon = () : JSX.Element => {
        return (
            <Tooltip title={Localizer.filterDefaultInfo()} className={"text-truncate padding-left-s padding-top-xxs text-warning subtle"}>
                <EmblaIcon iconName="info"/>
            </Tooltip>
        )
    }

    const isForloebOverblikBrugereView = forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikBrugereView;

    return (
        <div id="forloeb-overview-container" className={"card card-no-margin padding-top-m top-level-container"}>
            <div className={"card-header"}>
                <div className={"flex-container-column flex-grow"}>

                    <div className={"d-flex margin-bottom-m"}>
                        <h3>{Localizer.global_forloebOverview()}</h3>

                        <div className="margin-right-s flex-align-r">
                            <Tooltip
                                show={!anyFilteredRecords}
                                title={Localizer.noFilteredForloeb()}>
                                <AsyncButton
                                    clickFunction={() => exportExcel()}
                                    buttonText={Localizer.GetExcel()}
                                    waitText={Localizer.GettingExcel()}
                                    child={<EmblaIcon iconName={EmblaIcons.Incoming}/>}
                                    disabled={!anyFilteredRecords}
                                />
                            </Tooltip>
                        </div>

                        <PrintButton text={Localizer.forloebpage_printForloeb()} hideButtonOnPrint={false} />

                        {(currentUser.IsAdminOrVus() || currentUser.IsVusMedarbejeder() || currentUser.IsHrMedarbejder() || currentUser.IsKonsulentForLaegeligVidereuddannelse()) &&
                        <>
                            {!currentUser.IsKonsulentForLaegeligVidereuddannelse() &&
                                <button className="btn btn-primary margin-left-s"
                                        onClick={() => openCreateForloebModal()}>
                                    <span>{Localizer.forloebpage_createForloeb()}</span>
                                </button>
                            }
                        </>
                        }
                    </div>


                    <div className="d-flex margin-bottom-s" role="group">
                        <div className="btn-group">
                            <button type="button"
                                    className={`btn ${forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikStillingerView ? "btn-primary" : "btn-default"}`}
                                    aria-label="Left"
                                    onClick={() => viewOptionChanged(ForloebOverviewTypeEnum.ForloebOverblikStillingerView)}>
                                {Localizer.global_stillinger()}
                            </button>
                            <button type="button"
                                    className={`btn ${forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikBrugereView ? "btn-primary" : "btn-default"}`}
                                    aria-label="Right"
                                    onClick={() => viewOptionChanged(ForloebOverviewTypeEnum.ForloebOverblikBrugereView)}>
                                {Localizer.global_uddannelseslaeger()}
                            </button>
                        </div>
                        { smallInfoIcon() }
                        <div className="flex-align-r flex-row align-self-center ">
                            <Tooltip title={Localizer.forloebOverviewPage_visHorisontaleLinjer()}
                                        className={"margin-left-s flex-column"}>
                                <CheckboxSwitch
                                    id={"forloeboverview-horizontale-linjer-switch"}
                                    value={visHorizontaleLinjerState}
                                    label={Localizer.forloebOverviewPage_horisontaleLinjer()}
                                    onChange={setVisHorizontaleLinjer}
                                />
                            </Tooltip>
                            <Tooltip title={Localizer.visOverlappende()}
                                     className={"margin-left-s flex-column"}>
                                <CheckboxSwitch
                                    id={"forloeboverview-overlap-switch"}
                                    value={overlappendeVisningState}
                                    label={Localizer.forloebOverviewPage_udvidOverlappende()}
                                    onChange={setOverlappendeVisning}
                                />
                            </Tooltip>
                        </div>
                    </div>

                    <div className="d-flex margin-bottom-m">
                        {forloebOverviewType &&
                            <div className={'margin-right-s'}>
                                <Dropdown
                                    options={pageSizeSelectOptions}
                                    onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                setPageSize(Number(selectedOption.value));
                                                setFilterParamsOnPageSizeChange(Number(selectedOption.value));
                                                localStorage.setItem(StorageKeys.forloebsoverblikPageSize, selectedOption.value);
                                                setPageSizeOptions(selectedOption.value);
                                            }
                                        }}
                                    value={pageSizeSelectOptions.find(x => x.value == pageSize.toString())}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div>
                        }

                        {currentUser.canUseSavedFilters() &&
                            <DropdownButton id={"forloeboversigt-filter-dropdown-btn"}
                                            buttonSelected={filterSelected}
                                            showEditButton={true}
                                            editButtonClickedCallback={(filterId: string) => editFilter(filterId)}
                                            dropdownHeader={Localizer.forloebOverviewPage_mineFiltre()}
                                            emptyDropdownHeader={Localizer.global_noFilters()}
                                            objectClicked={(selectedOption: string) => getFilterItems(selectedOption)}
                                            selectOptions={mineFiltreSelectOptions}
                                            selectedObjectName={selectedFilterName}
                                            dropdownSeperator={Localizer.forloebOverviewPage_faellesFiltre()}
                                            seperatedSelectOptions={faellesFiltreSelectOptions}
                        />}


                        <button
                            className={`margin-left-s btn ${forloebOverviewFilterSliceState.anyFiltersActive ? "btn-primary" : "btn-default"}`}
                            onClick={() => filterButtonClicked()}>
                            <EmblaIcon iconName={EmblaIcons.Filter}/>
                            {Localizer.global_filtrer()}
                        </button>

                        {forloebOverviewFilterSliceState.anyFiltersActive &&
                            <button
                                className={`margin-left-s btn btn-default`}
                                onClick={() => clearFilterButtonClicked()}>
                                <EmblaIcon iconName={EmblaIcons.Remove}/>
                                {Localizer.global_clearFilters()}
                            </button>
                        }

                        {forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikStillingerView   &&
                            <div className="d-flex align-self-end flex-align-l margin-left-s">
                                <CheckboxSwitch
                                    id={"forloeboverview-showUnoccupied-switch"}
                                    label={Localizer.global_unoccupied_stillinger()}
                                    onChange={changeOccupiesStillinger}
                                    value={showUnoccupiedStillinger}/>
                            </div>
                        }

                        <div className="d-flex align-self-end flex-align-r">
                            <CheckboxSwitch
                                id={"forloeboverview-advancedsearch-switch"}
                                label={Localizer.global_advancedSearchLabel()}
                                onChange={changeAdvancedSearch}
                                value={filterParams.advancedSearch}/>
                        </div>

                        <SearchField
                            timeDelay={800}
                            callback={(search) => changeSearch(search)}
                            additionalClasses='min-w-300 align-self-end margin-left-s'
                            preventDefaultEvent
                            data={{
                                tooltip: filterParams.advancedSearch,
                                html: `${filterParams.advancedSearch}`,
                                originalTitle: filterParams.advancedSearch
                                ? isForloebOverblikBrugereView
                                    ? Localizer.global_advancedForloebOversigtSoegningFullText()
                                    : Localizer.global_stillingAdvancedForloebOversigtSoegningFullText()
                                : ""
                            }}
                            placeholder={!filterParams.advancedSearch
                                ? isForloebOverblikBrugereView
                                    ? Localizer.global_laegeForloebOversigtSoegning()
                                    : Localizer.global_stillingForloebOversigtSoegning()
                                : isForloebOverblikBrugereView
                                    ? Localizer.global_advancedForloebOversigtSoegning()
                                    : Localizer.global_stillingAdvancedForloebOversigtSoegning()
                            }
                            initialValue={filterParams.search}
                        />

                        {filterModalOpen &&
                        <SlideIn id={filterModalTarget}
                                 title={Localizer.global_filtrer()}
                                 actionFinishedCallback={() => {
                                     filterApplied()
                                 }}
                                 actionOnCloseCallback={() => filterApplied()}
                                 bodyContent={renderFilterCreate()}
                                 defaultOpen={true}
                        />
                        }

                        {editFilterModalOpen &&
                            <SlideIn id={filterModalTarget}
                                     title={Localizer.forloebOverviewPage_redigerFilter()}
                                     actionFinishedCallback={() => {
                                         filterApplied()
                                     }}
                                     actionOnCloseCallback={() => filterApplied()}
                                     bodyContent={renderFilterEdit()}
                                     defaultOpen={true}
                            />
                        }

                        {createForloebModalOpen &&
                        <SlideIn
                            id={createForloebModalTarget}
                            title={Localizer.forloebpage_createForloeb()}
                            actionText={Localizer.forloebpage_creatingForloeb()}
                            actionFinishedCallback={() => {forloebCreated()}}
                            actionOnCloseCallback={() => setCreateForloebModalOpen(false)}
                            bodyContent={<CreateForloeb modalId={createForloebModalTarget}/>}
                            defaultOpen={true}
                        />
                        }

                    </div>
                </div>
            </div>

            {forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikStillingerView && filterParams && filter &&
                <ForloebOverviewStillingerView
                    overlappendeVisningState={overlappendeVisningState}
                    visHorizontaleLinjerState={visHorizontaleLinjerState}
                    filterParams={filterParams}
                    overviewFilter={filter}
                    handlePaginationCallback={(page: number) => handlePagination(page)}
                    setPaginationCountCallback={(filteredRecords: number, recordsTotal: number, xOfYisLoading) => setPaginationCount(filteredRecords, recordsTotal, xOfYisLoading)}
                    handleForloebClicked={(forloebId: string) => handleOnClickForloeb(forloebId)}
                    scrollContainerRef={stillingerScrollContainerRef}
                />
            }

            {forloebOverviewType === ForloebOverviewTypeEnum.ForloebOverblikBrugereView && filterParams && filter &&
                <ForloebOverviewBrugereView
                    overlappendeVisningState={overlappendeVisningState}
                    visHorizontaleLinjerState={visHorizontaleLinjerState}
                    filterParams={filterParams}
                    filter={filter}
                    pageSize={pageSize}
                    handlePaginationCallback={(page: number) => handlePagination(page)}
                    setPaginationCountCallback={(filteredRecords: number, recordsTotal: number, xOfYisLoading) => setPaginationCount(filteredRecords, recordsTotal, xOfYisLoading)}
                    handleUddannelseslaegeClicked={(uddannelseslaegeId: string) => handleOnClickUddannelseslaege(uddannelseslaegeId)}
                    handleOnClickForloeb={(forloebId: string) => handleOnClickForloeb(forloebId)}
                    scrollContainerRef={uddannelseslaegerScrollContainerRef}
                />
            }
        </div>
    );
}
