import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../../app/store";
import {DateFilterType} from './ForloebOverviewDateFilterModel';
import { toReduxIsoDateString } from 'core/helpers/reduxHelper';
import { toDate } from 'index';

export interface ForloebOverviewFiltersState {
    uddannelsetyper?: Array<number>;
    specialer?: Array<string>;
    regioner?: Array<string>;
    hospitaler?: Array<string>;
    praksis?: Array<string>;
    afdelinger?: Array<string>;
    omraader?: Array<string>;
    gemFilter?: boolean;
    titel?: string;
    regionRettigheder?: Array<string>;
    isPrivate?: boolean;
    filterId?: string;
    anyFiltersActive?: boolean;
    refreshFilterPing: boolean;
    filterSelected: boolean;
    alleRegionerValgt?: boolean;
    dateFilterType: DateFilterType;
    year: number;
    date: string;
    periodFrom: string;
    periodTo: string;
    kbuPeriod?: string;
    kbuPeriodYear?: string;
    search?: string;
}

const initialState: ForloebOverviewFiltersState = {
    uddannelsetyper: undefined,
    specialer: undefined,
    regioner: undefined,
    hospitaler: undefined,
    praksis: undefined,
    afdelinger: undefined,
    omraader: undefined,
    gemFilter: undefined,
    titel: undefined,
    regionRettigheder: undefined,
    isPrivate: true,
    filterId: undefined,
    anyFiltersActive: false,
    refreshFilterPing: false,
    filterSelected: false,
    alleRegionerValgt: false,
    dateFilterType: DateFilterType.Period,
    year: new Date().getUTCFullYear(),
    date: toReduxIsoDateString(new Date(new Date().toDateString())),
    periodFrom: toReduxIsoDateString(new Date((new Date().getUTCFullYear() - 1), 0, 1, 0, 0, 0, 0)),
    periodTo: toReduxIsoDateString(new Date(Date.UTC((new Date().getUTCFullYear() + 10), new Date().getUTCMonth(), new Date().getUTCDate(), 0, 0, 0, 0))),
    kbuPeriod: undefined,
    kbuPeriodYear: undefined,
    search: undefined,
};

export const forloebOverviewFiltersSlice = createSlice({
    name: 'ForloebOverviewFilters',
    initialState,
    reducers: {
        applyFilters: (state, action: PayloadAction<ForloebOverviewFiltersState>) => {
            state.regioner = action.payload.regioner;
            state.uddannelsetyper = action.payload.uddannelsetyper;
            state.specialer = action.payload.specialer;
            state.hospitaler = action.payload.hospitaler;
            state.praksis = action.payload.praksis;
            state.afdelinger = action.payload.afdelinger;
            state.omraader = action.payload.omraader;
            state.titel = action.payload.titel;
            state.regionRettigheder = action.payload.regionRettigheder;
            state.isPrivate = action.payload.isPrivate;
            state.filterId = action.payload.filterId;
            state.filterSelected = action.payload.filterSelected;
            state.alleRegionerValgt = action.payload.alleRegionerValgt;
            state.dateFilterType = action.payload.dateFilterType;
            state.year = action.payload.year;
            state.date = action.payload.date;
            state.periodFrom = action.payload.periodFrom;
            state.periodTo = action.payload.periodTo;
            state.kbuPeriod = action.payload.kbuPeriod;
            state.kbuPeriodYear = action.payload.kbuPeriodYear;

            state.anyFiltersActive = [
                state.regioner,
                state.specialer,
                state.uddannelsetyper,
                state.hospitaler,
                state.praksis,
                state.afdelinger,
                state.omraader,
                state.kbuPeriod,
                state.kbuPeriodYear,
            ].some(el => el !== undefined && (el as []).length > 0) || (state.filterSelected) || isNotDefaultFilter(state.periodFrom, state.periodTo, state.dateFilterType);
        },
        clearFilters: (state) => {
            state.regioner = initialState.regioner;
            state.uddannelsetyper = initialState.uddannelsetyper;
            state.specialer = initialState.specialer;
            state.hospitaler = initialState.hospitaler;
            state.praksis = initialState.praksis;
            state.afdelinger = initialState.afdelinger;
            state.omraader = initialState.omraader;
            state.titel = initialState.titel;
            state.regionRettigheder = initialState.regionRettigheder;
            state.isPrivate = initialState.isPrivate;
            state.filterId = initialState.filterId;
            state.anyFiltersActive = initialState.anyFiltersActive;
            state.filterSelected = initialState.filterSelected;
            state.alleRegionerValgt = initialState.alleRegionerValgt;
            state.dateFilterType = initialState.dateFilterType;
            state.year = initialState.year;
            state.date = initialState.date;
            state.periodFrom = initialState.periodFrom;
            state.periodTo = initialState.periodTo;
            state.kbuPeriod = initialState.kbuPeriod;
            state.kbuPeriodYear = initialState.kbuPeriodYear;
        },
        pingRefreshFilter: (state) => {
            state.refreshFilterPing = !state.refreshFilterPing;
        },
        setSearch: (state, action: PayloadAction<string | undefined>) => {
            state.search = action.payload;
        }
    }
});

const isNotDefaultFilter = (periodFrom: string, periodTo: string, dateFilterType: DateFilterType) : boolean => {
    const today = new Date();
    const fullYear = today.getUTCFullYear();
    const fromInitYear = fullYear - 1;
    const toInitYear = fullYear + 10;
    return toDate(periodFrom) !== new Date(fromInitYear, today.getUTCMonth(), today.getUTCDate(), 0, 0, 0, 0)
        || toDate(periodTo) !== new Date(Date.UTC(toInitYear, today.getUTCMonth(), today.getUTCDate(), 0, 0, 0, 0))
        || dateFilterType !== DateFilterType.Period;
}

export const {applyFilters, clearFilters, pingRefreshFilter, setSearch} = forloebOverviewFiltersSlice.actions;
export const forloebOverviewFiltersReducer = (state: RootState) => state.forloebOverviewFiltersReducer;
export default forloebOverviewFiltersSlice.reducer;
