import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {AccountService} from "../../account/accountService";
import {ApiResponse} from "../apiResponse";
import {CrudLaegekursusInputModel} from "../../../pages/kurserPage/components/crudLaegekursusInputModel";
import {LogbogLaegekursusCrudModel} from "./logbogLaegekursusCrudModel";
import {GodkendPostModel} from "../forloeb/godkendPostModel";
import {LaegekursusGruppeModel} from "./laegekursusGruppeModel";
import {LaegekursusGruppeResponseModel} from "./laegekursusGruppeResponseModel";
import {GetLaegekursusGruppeTranslation} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {LaegekursusModel} from "./laegekursusModel";
import {LaegekursusResponseModel} from "./laegekursusResponseModel";
import {RemoveGodkendelsePostModel} from "./removeGodkendelsePostModel";

export class LogbogLaegekursusApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/laegekursus/";
    private laegekursusGodkendUrl = () => this.baseUrl() + "godkend/";
    private laegekursusFjernGodkendelseUrl = () => this.baseUrl() + "removeGodkendelse/";
    private getDeteletedLaegekurserForUserUrl = () => this.baseUrl() + "deletedLaegekurserByUser/";
    private getLaegekursusGrupperByIdUrl = () => this.baseUrl() + "byGruppeId/";
    private editLaegekursusUrl = () => this.baseUrl() + "editLaegekursus";
    private gendanLaegekursusUrl = () => this.baseUrl() + "gendanLaegekursus/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getKursusGrupper(brugerId: string): Promise<LaegekursusGruppeModel[]> {
        const url = this.getLaegekursusGrupperByIdUrl() + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<LaegekursusGruppeResponseModel[]>>(url);
        return result.data.map(kursus => {
            return new LaegekursusGruppeModel(kursus)
        }).sort((a, b) => GetLaegekursusGruppeTranslation(a.kursusGruppeType).localeCompare(GetLaegekursusGruppeTranslation(b.kursusGruppeType)));;
    }

    async getLaegeKursusGruppe(gruppeId: string): Promise<LaegekursusGruppeModel> {
        const url = this.getLaegekursusGrupperByIdUrl() + gruppeId;
        const result = await this.httpClientService.Get<ApiResponse<LaegekursusGruppeResponseModel>>(url);
        return new LaegekursusGruppeModel(result.data);
    }

    async createLaegekursus(inputModel: CrudLaegekursusInputModel): Promise<string> {
        const postModel = new LogbogLaegekursusCrudModel(inputModel);
        const url = this.baseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogLaegekursusCrudModel>(url, postModel);
        return result.data;
    }

    async editLaegekursus(inputModel: CrudLaegekursusInputModel) {
        const patchModel = new LogbogLaegekursusCrudModel(inputModel);
        const url = this.editLaegekursusUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogLaegekursusCrudModel>(url, patchModel);
        return result.data;
    }

    async deleteLaegekursus(id: string): Promise<string> {
        const url = this.baseUrl() + id;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async godkendLaegekursus(contextId: string, uddannelsessted: string, godkendtPaaAndetGrundlag: boolean, brugerMaalbeskrivelseId?: string, kommentar?: string): Promise<ApiResponse<string>> {
        const body = new GodkendPostModel(contextId, this.userService.getUser().UserId, this.userService.getUser().CurrentRolleTitel, uddannelsessted, kommentar, godkendtPaaAndetGrundlag, brugerMaalbeskrivelseId);
        const url = this.laegekursusGodkendUrl();
        return await this.httpClientService.Post<ApiResponse<string>, GodkendPostModel>(url, body, true);
    }

    async removeGodkendelseLaegekursus(kursusId: string, brugerMaalbeskrivelseId: string): Promise<string> {
        const body = new RemoveGodkendelsePostModel(kursusId, brugerMaalbeskrivelseId);
        const url = this.laegekursusFjernGodkendelseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, RemoveGodkendelsePostModel>(url, body);
        return result.data;
    }

    async getDeletedKurserForUser(userId: string, uddannelseType: string): Promise<LaegekursusModel[]> {
        const urlParamenters = `${userId}/${uddannelseType}`
        const url = this.getDeteletedLaegekurserForUserUrl() + urlParamenters;
        const result = await this.httpClientService.Get<ApiResponse<LaegekursusResponseModel[]>>(url);
        return result.data.map(result => {
            return new LaegekursusModel(result)
        }).sort((a, b) => GetLaegekursusGruppeTranslation(a.gruppeType).localeCompare(GetLaegekursusGruppeTranslation(b.gruppeType)));
    }

    async gendanLaegekursus(laegekursusId: string): Promise<string> {
        const url = this.gendanLaegekursusUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, string>(url, laegekursusId);
        return result.data;
    }
}
