import FileMetadataListComponent from "core/components/fileMetadata/fileMetadataListComponent";
import Title from "core/components/titels/title";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import { Localizer } from "infrastructure/localization/localizer";

type ListProps = {
    files: FileMetadata[];
}

const SpecialeSpecificKompetenceFilesList = ({files} : ListProps) => {

    const hasFiles = files.length > 0;

    return (
        <Title
            title={hasFiles ? Localizer.forloebpage_specialespecifikkeFiler() : undefined}
            subTitle={hasFiles ? undefined : Localizer.global_noFiles()}
            largeSubTitle
        >
            {hasFiles
            ?
                <FileMetadataListComponent
                    fileMetaDataList={files}
                    fileLocation={FileLocationEnum.Stamdata}
                    withDownload
                />
            :
                <></>
            }
        </Title>
    );
}

export default SpecialeSpecificKompetenceFilesList;
