import { Dropdown, DropdownOption } from "core/components/dropdown/dropdown";
import { Localizer } from "infrastructure/localization/localizer";
import { useState } from "react";
import useEnumDropdownOptions from "../../hooks/useEnumDropdownOptions";
import { AdminOrVusRoles, RolleTitelEnum, UserRoleTitleToString } from "infrastructure/enums/rolleTitelEnum";
import useUser from "core/hooks/useUser";
import { MultiValue } from "react-select";

export type UddannelsesTypeDropdownProps = {
    onChangeCallback: (newValue: RolleTitelEnum[]) => void;
    initialSelected?: RolleTitelEnum[];
}

const RolleTitelTypeDropdown = ({initialSelected=[], onChangeCallback} : UddannelsesTypeDropdownProps) => {

    const {currentUser} = useUser();

    const [allRolleDropdownOptions] = useEnumDropdownOptions<RolleTitelEnum>(RolleTitelEnum, UserRoleTitleToString, !currentUser.IsAdminOrVus() ? AdminOrVusRoles : undefined);
    const [rolleDropdownOptions] = useState(allRolleDropdownOptions);
    const [selectedOption, setSelectedOption] = useState(initialSelected);

    const handleSelected = (selectedOption: MultiValue<DropdownOption<RolleTitelEnum>> = []) => {
        const selectedValue = selectedOption.map(e => e.value);
        setSelectedOption(selectedValue);
        onChangeCallback(selectedValue);
    }

    return (
        <div>
            <label>{Localizer.rolle()}</label>

            <Dropdown
                placeholder={Localizer.chooseRole()}
                options={rolleDropdownOptions}
                onChange={(newVal) => handleSelected(newVal)}
                value={rolleDropdownOptions.filter(x => selectedOption.includes(x.value))}
                isClearable
                isMulti
            />
        </div>
    );
}

export default RolleTitelTypeDropdown;