import {KompetenceEvalueringModel} from "./kompetenceEvalueringModel";

export class KompetenceEvalueringsskemaModel {
    titel: string;
    evalueringsskemaId: string;
    aktiv: boolean;
    kompetenceEvalueringer: KompetenceEvalueringModel[];

    public static FromResponseModel(titel: string, evaluerinsskemaId: string, kompetenceEvalueringModels: KompetenceEvalueringModel[], aktiv: boolean): KompetenceEvalueringsskemaModel{
        let model = new KompetenceEvalueringsskemaModel();

        model.titel = titel;
        model.aktiv = aktiv;
        model.evalueringsskemaId = evaluerinsskemaId;
        model.kompetenceEvalueringer = kompetenceEvalueringModels;

        return model;
    }
}
