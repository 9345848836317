import './forloebHeader.scss';
import React, {useRef} from "react";
import {Localizer} from 'infrastructure/localization/localizer';
import {AccountService} from "../../../services/account/accountService";
import {EmblaIcon} from '../emblaIcon/emblaIcon';
import useEffectAsync from 'infrastructure/effect';
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../layout/responsive";
import {RolleEnum} from "../../../infrastructure/enums/userRole";
import {EmblaIllustration} from "../emblaIcon/emblaIllustration";
import {ReactComponent as LeadershipSvg} from "../../../content/images/leadership.svg";
import {VejlederAfdelingerModel} from "../../../pages/uddannelseslaegeOverview/components/vejlederAfdelingerModel";
import {initAllPopovers} from "../../utils";
import './vejlederHeader.scss'
import './headerCards.scss'
import {UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";

export function VejlederHeader(props : {VejlederAfdelinger? : VejlederAfdelingerModel[]}) {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const _currentUser = useRef(new AccountService().getUser());

    useEffectAsync(async () => {
        initAllPopovers();
    }, [])

    const CreateToolTipText = (count: number) => {
        //TODO: Tooltippet skal restyles!
        let text = "<ul>";

        props.VejlederAfdelinger?.map(x => {
            if(x.PeriodeSlut !== undefined) {
                text += `<li>${x.LaegeInstitutionNavn}`;
                if(x.AfdelingNavn){
                    text += `, ${x.AfdelingNavn}`;
                }
                text += `${Localizer.uddannelseslaegeOverviewPage_VejlederPeriodeEnds()}: ${x.PeriodeSlut.dateWithoutTimeFormat(false)}</li>`;
            } else {
                text += `<li>${x.LaegeInstitutionNavn}`;
                if(x.AfdelingNavn){
                    text += `, ${x.AfdelingNavn}`;
                }
            }
        })

        text += "</ul>"

        return (
            <>
                <div className={"flex-container-row"} >
                    <h5 className="margin-bottom-xxs uddannelseslaege-header-txt">{Localizer.uddannelseslaegeOverviewPage_TilknyttetAfdelinger(count)}</h5>
                    <div className={"margin-left-s"} data-toggle={"popover"} data-trigger={"hover"} data-html={true} data-content={text} >
                        <EmblaIcon additionalClasses={"uddannelseslaege-header-image info"} iconName={"info"}/>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className={`card header-card uddannelseslaege-header top-level-container-margins`}>
            <div className={"flex-container-row"}>
                {(_currentUser.current.CurrentRolle === RolleEnum.Vejleder && !IsMobile) &&
                <LeadershipSvg className={"water-mark-svg margin-left-m uddannelseslaege-header-image"}/>
                }
                {(_currentUser.current.CurrentRolle === RolleEnum.Uddannelsesansvarlig ) &&
                <EmblaIllustration illustrationName={"team-leader"}
                                   additionalClasses={"margin-left-m uddannelseslaege-header-image"}/>
                }
                <div className="flex-container-column flex-row-center margin-left-m">
                    <div className="flex-space">
                        {!IsMobile &&
                        <h2 className='uddannelseslaege-header-txt'>
                            {UserRoleTitleToString(_currentUser.current.CurrentRolleTitel)}
                        </h2>
                        }
                        {IsMobile &&
                        <h3 className='uddannelseslaege-header-txt'>
                            {UserRoleTitleToString(_currentUser.current.CurrentRolleTitel)}
                        </h3>
                        }

                    </div>
                    <div className='uddannelseslaege-header-txt'>
                        {props.VejlederAfdelinger?.length === 1 &&
                        <>
                            {!IsMobile &&
                            <>
                                <p className="margin-bottom-xxs large uddannelseslaege-header-txt">
                                    {props.VejlederAfdelinger[0].LaegeInstitutionNavn} {props.VejlederAfdelinger[0].AfdelingNavn && " , " + props.VejlederAfdelinger[0].AfdelingNavn}
                                </p>
                                {props.VejlederAfdelinger[0].PeriodeSlut !== undefined &&
                                  <h5 className="margin-bottom-xxs uddannelseslaege-header-txt">
                                      {Localizer.uddannelseslaegeOverviewPage_VejlederPeriodeEnds() + ": " + props.VejlederAfdelinger[0].PeriodeSlut.dateWithoutTimeFormat(false)}
                                  </h5>
                                }
                            </>
                            }
                            {IsMobile &&
                            <>
                                <h5 className="margin-bottom-xxs uddannelseslaege-header-txt">
                                    {props.VejlederAfdelinger[0].LaegeInstitutionNavn} {props.VejlederAfdelinger[0].AfdelingNavn && " , " + props.VejlederAfdelinger[0].AfdelingNavn}
                                </h5>
                                {props.VejlederAfdelinger[0].PeriodeSlut !== undefined &&
                                  <p className="margin-bottom-xxs small uddannelseslaege-header-txt">
                                      {Localizer.uddannelseslaegeOverviewPage_VejlederPeriodeEnds() + ": " + props.VejlederAfdelinger[0].PeriodeSlut.dateWithoutTimeFormat(false)}
                                  </p>
                                }
                            </>
                            }
                        </>
                        }

                        {(props.VejlederAfdelinger != undefined && props.VejlederAfdelinger?.length > 1) &&
                            CreateToolTipText(props.VejlederAfdelinger?.length)
                        }
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div>
                                <span className="badge badge-pill badge-primary padding-xs">
                                </span>
                            <span className="badge badge-pill badge-default padding-xs">
                                </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
