import {LogbogNotatApi} from "../../../../services/api/logbogNotat/logbogNotatApi";
import {HttpClientService} from "../../../../services/httpClient/httpClientService";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import React, {useState} from "react";
import {setSlideinState, SlideInStateEnum} from "../../../components/slideIn/slideInSlice";
import {ValidationForm} from "../../../components/validation/components/validationForm";
import {BasicNotatCrudFields} from "../basicNotatCrudFields";
import {HandleError} from "../../../infrastructure/errors/errorBoundary";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {ValidationFormSubmitButton} from "../../../components/validation/components/validationFormButton";
import {BasicNotatModel} from "../../../sharedmodels/notat/basicNotatModel";
import {
    BuildExistingNotatCrudModel,
    crudNotatValidationSchema,
    NotatCrudModel
} from "../../../sharedmodels/notat/notatCrudModel";
import {AccountService} from "../../../../services/account/accountService";
import {forloebReducer, ForloebSliceState} from "../../../forloebSlice";

const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService())

export interface EditNotatProps {
    modalId: string;
    notat: BasicNotatModel;
    onFilesUpdated: () => void;
}

export function EditNotat(props: EditNotatProps) {
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const dispatch = useAppDispatch();

    const [editNotatFailed, setEditNotatFailed] = useState(false);

    const editNotat = async (inputModel: NotatCrudModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        const editedNotat = await notatApi.editNotatData(inputModel);
        if (editedNotat) {
            setEditNotatFailed(false);
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
        }
    }

    const deleteTekstNotat = async (inputModel: NotatCrudModel) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))
        const deletedNotat = await notatApi.deleteNotat(inputModel);

        if (deletedNotat) {
            setEditNotatFailed(false);
            dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
        }
    }

    const render = (
        <ValidationForm
            initialValues={BuildExistingNotatCrudModel(props.notat, [forloebSliceState.forloebState.id])}
            validationSchema={crudNotatValidationSchema}
            onSubmit={async (values) => {
                await editNotat(values)
                    .catch(err => {
                        HandleError(err);
                        setEditNotatFailed(true);
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }));
                    });
            }}>
            {(formik) => (
                <>
                    <BasicNotatCrudFields
                        modalId={props.modalId}
                        notatUserId={props.notat.brugerId}
                        formik={formik}
                        fileMetadatas={props.notat.fileMetadatas}
                        notatEditModel={props.notat}
                        onFilesUpdatedCallback={props.onFilesUpdated}
                        notatInfo={{contextId: forloebSliceState.forloebState.id, notatType: props.notat.type, userId: props.notat.brugerId}}
                    />
                    <div className="modal-actions">
                        {editNotatFailed &&
                            <div className="form-group margin-bottom-m">
                                <div className="field-validation-error">{Localizer.notatPage_notatEditFailed()}</div>
                            </div>}

                        <button className="btn btn-danger margin-right-s" onClick={() => deleteTekstNotat(formik.values)}>
                            {Localizer.global_delete()}
                        </button>
                        <ValidationFormSubmitButton formikProps={formik}>
                            {Localizer.global_save()}
                        </ValidationFormSubmitButton>

                    </div>
                </>
            )}
        </ValidationForm>
    )

    return <>{render}</>
}
