import { UserProfileAvatarSizeEnum } from "./userProfileAvatar";
import {ReactComponent as AgentSVG} from "../../../content/icons/agent.svg";
import { EmblaIcon } from "../emblaIcon/emblaIcon";

const resolveSizeClassName = (size?: UserProfileAvatarSizeEnum) => {
    switch (size) {
        case UserProfileAvatarSizeEnum.large: return "";
        case UserProfileAvatarSizeEnum.medium: return "medium";
        case UserProfileAvatarSizeEnum.small: return "small";
        case UserProfileAvatarSizeEnum.extraSmall: return "extra-small";
        case UserProfileAvatarSizeEnum.extraExstraSmall: return "extra-extra-small";
        default: return "";
    }
}

const getEmblaIconSize = (size?: UserProfileAvatarSizeEnum) => {
  switch (size) {
    case UserProfileAvatarSizeEnum.large: 
    case UserProfileAvatarSizeEnum.medium:
    case UserProfileAvatarSizeEnum.small:
      return "";
    case UserProfileAvatarSizeEnum.extraSmall:
    case UserProfileAvatarSizeEnum.extraExstraSmall:
      return "small";
    default: return "";
}
}

type AvatarBadgeProps = {
  initials?: string;
  size?: UserProfileAvatarSizeEnum;
  colorClassName?: string;
  isImpersonating?: boolean;
}

const userProfileAvatarClassName = "user-profile-avatar";

export const AvatarBadge = ({
  initials,
  size,
  colorClassName,
  isImpersonating
}: AvatarBadgeProps) => {

  const sizeClass = resolveSizeClassName(size);
  const emblaIconSizeClass = getEmblaIconSize(size);

  return (
      <div
          className={`${userProfileAvatarClassName} ${sizeClass} ${colorClassName}`}>
          {isImpersonating && <AgentSVG/>}
          {!isImpersonating && (
            !initials 
              ? <EmblaIcon iconName="user" additionalClasses={`${emblaIconSizeClass}`} wrapInSpan={false} />
              : <p className="profile-initials">{initials}</p>
            )
          }
      </div>
  );
}