import { RoutePaths } from "infrastructure/routes";
import { useHistory } from "react-router-dom";
import { NotifikationEventTypeEnum, NotifikationEventTypeEnumToIconName, NotifikationEventTypeEnumToTitleString } from "../models/notificationModel";
import useUser from "core/hooks/useUser";
import { useAppDispatch } from "app/hooks";
import {setActiveKlarmeldingPageTab, setActiveLaegeKompetenceFromOtherContext} from "pages/kompetencerPage/cards/kompetenceCardSlice";
import useLogbogApi from "core/hooks/useLogbogApi";
import { KompetencerPageLinkHistoryState } from "pages/kompetencerPage/kompetencerPage";
import { LinkHistoryState } from "infrastructure/route/useLinkHistory";
import { AccountService } from "services/account/accountService";
import useStamdataApi from "core/hooks/useStamdataApi";
import { KlarmeldingerPageTabIds } from "pages/klarmeldinger/klarmeldingerPage";
import { NotificationModule } from "ditmer-embla";
import { HttpResponseError } from "services/httpClient/httpClientService";
import { Localizer } from "infrastructure/localization/localizer";
import StpsStep from "../../../../pages/sendStpsAnsoegning/stpsStepEnum";

type NotificationTypePathResult = {
  pathUrl: string;
  state?: unknown;
  onDidRedirect?: () => void;
}

const accountService = new AccountService();

const useNotification = (notificationType: NotifikationEventTypeEnum, contextId: string, afsenderBrugerId?: string) => {
  const history = useHistory();
  const { currentUser } = useUser();
  const dispatch = useAppDispatch();

  const { logbogLaegeKompetenceApi, forloebApi, ansoegningApi } = useLogbogApi();
  const { stamdataBrugerRolleApi, stamdataMaalbeskrivelseApi } = useStamdataApi();

  const notificationTitle = NotifikationEventTypeEnumToTitleString(notificationType);
  const notificationIconName = NotifikationEventTypeEnumToIconName(notificationType);

  const getNotificationTypePath = async (type: NotifikationEventTypeEnum, contextId: string, afsenderBrugerId?: string): Promise<NotificationTypePathResult | undefined> => {
    switch(type) {
      case NotifikationEventTypeEnum.KompetenceKlarmeldt: {
          const laegeKompetenceId = contextId;
          const laegeKompetenceUserId = afsenderBrugerId;

          if (laegeKompetenceUserId) {
            const laegeKompetence = await logbogLaegeKompetenceApi.getLaegekompetenceById(laegeKompetenceId, laegeKompetenceUserId);
            const laegeKompetenceState = laegeKompetence.ToState();

            const kompetenceIsGodkendt = laegeKompetenceState.godkendtAfNavn !== null && laegeKompetenceState.godkendtAfRolleTitel !== null;

            if(kompetenceIsGodkendt){
                return {
                  pathUrl: RoutePaths.Kompetencer({uddannelseslaege: laegeKompetenceUserId}).url,
                  state: { isLink: true, laegeKompetenceId: laegeKompetenceState.laegekompetenceId, brugerMaalbeskrivelseId: laegeKompetenceState.brugerMaalbeskrivelseId } as LinkHistoryState<KompetencerPageLinkHistoryState>,
                }
            }

            // If we are navigating to "klarmeldinger"-page, then set redux-state directly (because we haven't implemented routing-params/state for this page)
            dispatch(setActiveLaegeKompetenceFromOtherContext(laegeKompetenceState));
          }

          return { pathUrl: RoutePaths.Klarmeldinger.url };
      }
      case NotifikationEventTypeEnum.KompetenceGodkendt: {
        const laegeId = currentUser.UserId;
        const laegeKompetenceId = contextId;

        // Fetch laegeKompetence, so that we can send "brugerMaalbeskrivelseId" with
        // Maybe optimize later (if its too slow)
        const laegeKompetence = await logbogLaegeKompetenceApi.getLaegekompetenceById(laegeKompetenceId, laegeId);

        return {
          pathUrl: RoutePaths.Kompetencer({uddannelseslaege: laegeId}).url,
          state: { isLink: true, laegeKompetenceId: laegeKompetenceId, brugerMaalbeskrivelseId: laegeKompetence.brugerMaalbeskrivelseId } as  LinkHistoryState<KompetencerPageLinkHistoryState>
        }
      }
      case NotifikationEventTypeEnum.VejlederOprettet: {
        return { pathUrl: RoutePaths.UddannelseslaegeOverview.url };
      }
      case NotifikationEventTypeEnum.VejlederAdgangAendret: {
        return { pathUrl: RoutePaths.UddannelseslaegeOverview.url };
      }
      case NotifikationEventTypeEnum.BrugerNyRolle: {
        const brugerRolleId = contextId;

        stamdataBrugerRolleApi
          .GetBrugerRolleSimple(brugerRolleId)
          .then((brugerRolleInfo) => {
            const newBrugerRolleTitel = brugerRolleInfo.rolleTitel;
            const isNotInNewRole = currentUser.CurrentRolleTitel !== newBrugerRolleTitel;

            if(isNotInNewRole) {
              accountService.updateUserRolleTitel(newBrugerRolleTitel)
            }
          })
          .catch((reason) => {
            if (reason instanceof HttpResponseError && reason.status === 404) {
              NotificationModule.showInfo(Localizer.notifikationer_NyBrugerRolle_NotFoundTitel(), Localizer.notifikationer_NyBrugerRolle_NotFoundMessage())
            }
          });

        return;
      }
      case NotifikationEventTypeEnum.ForloebKanGodkendes: {
        const forloebId = contextId;

        const forloeb = await forloebApi.getForloebById(forloebId);

        const forloebIsGodkendt = forloeb.godkendtAfNavn !== null && forloeb.godkendtAfRolleTitel !== null;

        if(forloebIsGodkendt){
            return { pathUrl: RoutePaths.Forloeb([forloeb.brugerId, forloeb.id], false).url }
        }

        dispatch(setActiveKlarmeldingPageTab(KlarmeldingerPageTabIds.attestationForTidKlarTilGodkendelseId));
        return { pathUrl: RoutePaths.Klarmeldinger.url };
      }
        case NotifikationEventTypeEnum.IndsendStpsDokumentation: {
            const brugerMaalbeskrivelse = await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelse(contextId);
            const ansoegning = await ansoegningApi.getAnsoegningOnBruger(brugerMaalbeskrivelse.brugerId, brugerMaalbeskrivelse.id);

            if(ansoegning != undefined){
                return { pathUrl:RoutePaths.SendStpsDocumentationPage(
                    {uddannelseslaege: ansoegning.brugerId,
                                 ansoegning: ansoegning.id,
                                 step: StpsStep.Beskaeftigelse}).url}
            }

            NotificationModule.showError(Localizer.notifikationer_IndsendStpsAnsoegning_NotFoundTitel(), Localizer.notifikationer_IndsendStpsAnsoegning_NotFoundMessage())
            return;

        }
      case NotifikationEventTypeEnum.NyhedPubliceret: {
        return { pathUrl: RoutePaths.Nyheder.path };
      }
        case NotifikationEventTypeEnum.ForloebEvalueringIndsendt: {
            return { pathUrl: RoutePaths.Evalueringsstatistik.path };
        }
      default:
        throw Error(`NotifikationEventTypeEnum could not be mapped (input: ${type})`);
    }
  }

  const redirectToContextRoute = async (): Promise<boolean> => {
    const routePathResult = await getNotificationTypePath(notificationType, contextId, afsenderBrugerId);

    if(!!routePathResult?.pathUrl) {
      history.push(routePathResult.pathUrl, routePathResult.state);

      if (!!routePathResult.onDidRedirect) {
        routePathResult.onDidRedirect();
      }
      return true;
    }

    return false;
  }

  return {
    notificationTitle,
    notificationIconName,
    redirectToContextRoute
  }
}

export default useNotification;
