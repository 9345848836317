import { UserNameInfoModel } from "core/components/userProfile/userNameInfoModel";
import { EmblaIcons, EmblaIconsType } from "core/emblaIcons";
import { RolleTitelEnum } from "infrastructure/enums/rolleTitelEnum";
import { Localizer } from "infrastructure/localization/localizer";

// Enum ligger også i Logbog og Stamdata:
export enum NotifikationEventTypeEnum {
  KompetenceKlarmeldt = 1,
  KompetenceGodkendt = 2,
  VejlederOprettet = 3,
  VejlederAdgangAendret = 4,
  BrugerNyRolle = 5,
  ForloebKanGodkendes = 6,
  IndsendStpsDokumentation = 7,
  NyhedPubliceret = 8,
  ForloebEvalueringIndsendt = 9,
}

export const NotifikationEventTypeEnumToTitleString = (eventType: NotifikationEventTypeEnum): string => {
  switch (eventType) {
      case NotifikationEventTypeEnum.KompetenceKlarmeldt:
          return Localizer.notifikationer_KompetenceKlarmeldt_Title();
      case NotifikationEventTypeEnum.KompetenceGodkendt:
          return Localizer.notifikationer_KompetenceGodkendt_Title();
      case NotifikationEventTypeEnum.VejlederOprettet:
          return Localizer.notifikationer_VejlederOprettet_Title();
      case NotifikationEventTypeEnum.VejlederAdgangAendret:
          return Localizer.notifikationer_VejlederAdgangAendret_Title();
      case NotifikationEventTypeEnum.BrugerNyRolle:
          return Localizer.notifikationer_BrugerNyRolle_Title();
      case NotifikationEventTypeEnum.ForloebKanGodkendes:
          return Localizer.notifikationer_ForloebKanGodkendes_Title();
      case NotifikationEventTypeEnum.IndsendStpsDokumentation:
          return Localizer.notifikationer_IndsendStpsDokumentation_Title();
    case NotifikationEventTypeEnum.NyhedPubliceret:
        return Localizer.notifikationer_NyhedPubliceret_Title();
      case NotifikationEventTypeEnum.ForloebEvalueringIndsendt:
          return Localizer.notifikationer_ForloebEvalueringIndsendt_Title();
      default:
          return Localizer.notifikationer_NotifikationsTypeEnumFallback();
  }
}

export const NotifikationEventTypeEnumToIconName = (eventType: NotifikationEventTypeEnum): EmblaIconsType => {
  switch (eventType) {
      case NotifikationEventTypeEnum.KompetenceKlarmeldt:
      case NotifikationEventTypeEnum.KompetenceGodkendt:
          return EmblaIcons.Star;
      case NotifikationEventTypeEnum.VejlederOprettet:
      case NotifikationEventTypeEnum.VejlederAdgangAendret:
          return EmblaIcons.UserGroup;
      case NotifikationEventTypeEnum.BrugerNyRolle:
          return EmblaIcons.User;
      case NotifikationEventTypeEnum.NyhedPubliceret:
          return EmblaIcons.Feed;
      default:
          return EmblaIcons.Notifications;
  }
}

export type NotificationAfsenderModel = {
  info?: UserNameInfoModel;
  rolletitelEnum?: RolleTitelEnum;
}

export type NotificationModel = {
  id: string;
  type: NotifikationEventTypeEnum;
  contextId: string;
  message: string;
  erSet: boolean;
  timestamp: Date;
  afsender: NotificationAfsenderModel;
}

export type NotificationEditSeenStatusModel = {
    markAsSeen: boolean;
    notifikationIdList: string[];
}
