import {Localizer} from "../../../infrastructure/localization/localizer";
import {useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";
import PdfComponent from "core/components/pdf/pdfComponent";
import {PdfAttestationHeaderComponent} from "./pdfHeaders/pdfAttestationHeaderComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";

export const AttestationForTidPdf = () => {
    const [forloebs, setForloebs] = useState<ForloebModel[]>()
    const [maalbeskrivelse, setMaalbeskrivelse] = useState<string>()

    const routeMatch = useRouteMatch();
    const routeParams = (routeMatch.params as { brugerId: string, brugerMaalbeskrivelseId: string, forloeb?: string });
    const setError = useFetchWithErrorHandlingPdf();

    const { forloebApi } = useLogbogApi();

    useEffect( () => {
        const fetch = async () => {
            const brugerForloeb = await forloebApi.getForloebByBrugerMaalbeskrivelse(routeParams.brugerMaalbeskrivelseId);
            if (brugerForloeb !== undefined) {

                const forloeb = brugerForloeb.filter(f => !f.erFravaer).filter(f => !routeParams.forloeb || f.id === routeParams.forloeb);
                
                setMaalbeskrivelse(forloeb[0].maalbeskrivelseNavn)
                setForloebs(forloeb)
            }
        }
        fetch().catch(err => setError(err));
        (document as any).pdfReady = true;
    }, [forloebApi, routeParams.brugerMaalbeskrivelseId, routeParams.forloeb, setError])

    const CreateUddannelsestypeStillingsNrDisplayString = (forloeb: ForloebModel) => `${UddannelseTypeEnum[forloeb.uddannelseType]}\n${forloeb.stillingNummer}`;
    
    return (
        <PdfComponent>
            <h2 className={"font-weight-bolder margin-bottom-l"}>{Localizer.attestationPdf_attestationForTid()}</h2>

            <PdfAttestationHeaderComponent
                brugerId = {routeParams.brugerId}
                maalbeskrivelse={maalbeskrivelse}
            />

            <PdfComponent.BlockItem className={"card shadow-none"}>
                <div className={"card-header"}>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.uddannelsestrin()}</div>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.uddannelsessted()}</div>
                    <div className={"col-2 beskaeftigelse-pdf-list-header"}>{Localizer.ansaettelsesperiode()}</div>
                    <div className={"col-6 beskaeftigelse-pdf-list-header"}>{Localizer.global_godkendtAf()}</div>
                </div>
                {forloebs &&
                    forloebs.map(forloeb =>
                        <PdfComponent.NonBreakableItem>
                            <div className={"card-body"}>
                                <div className={"flex-container-row align-content-center"}>
                                    <div className={"col-2 beskaeftigelse-pdf-list-item"}>{CreateUddannelsestypeStillingsNrDisplayString(forloeb)}</div>
                                    <div className={"col-2 beskaeftigelse-pdf-list-item"}>{forloeb.GetStedInfo()}</div>
                                    <div className={"col-2 beskaeftigelse-pdf-list-item"}>
                                        <div className={"beskaeftigelse-pdf-list-item"}>{forloeb.startDate.dateWithoutTimeFormat(false) + " - "}</div>
                                        <div className={"beskaeftigelse-pdf-list-item"}>{forloeb.endDate.dateWithoutTimeFormat(false)}</div>
                                    </div>
                                    <div className={"col-6 beskaeftigelse-pdf-list-item"}>
                                        {forloeb.godkendelseId &&
                                            <>
                                                <div className={"flex-container-row beskaeftigelse-pdf-list-item"}>
                                                    <div
                                                        className={"font-weight-bold margin-right-xs beskaeftigelse-pdf-list-item"}>{Localizer.global_name() + ":"}</div>
                                                    <div>{forloeb.godkendtAfNavn}</div>
                                                </div>
                                                <div className={"flex-container-row beskaeftigelse-pdf-list-item"}>
                                                    <div
                                                        className={" font-weight-bold margin-right-xs beskaeftigelse-pdf-list-item"}>{Localizer.dato() + ":"}</div>
                                                    <div>{forloeb.godkendtDen?.dateWithoutTimeFormat(false)}</div>
                                                </div>
                                                <div className={"flex-container-row beskaeftigelse-pdf-list-item"}>
                                                    <div
                                                        className={"beskaeftigelse-pdf-list-item font-weight-bold margin-right-xs"}>{Localizer.rolle() + ":"}</div>
                                                    <div>{forloeb.godkendtAfRolleTitel}</div>
                                                </div>
                                                <div className={"flex-container-column"}>
                                                    <div
                                                        className={"font-weight-bold margin-right-xs align-self-start"}>{Localizer.uddannelsessted() + ":"}</div>
                                                    <div className={"align-self-start"}>{forloeb.godkendtAfUddannelsessted}</div>
                                                </div>
                                                {forloeb.godkendtKommentar &&
                                                <div className={"flex-container-column"}>
                                                    <div
                                                        className={"font-weight-bold margin-right-xs align-self-start"}>{Localizer.global_comment() + ":"}</div>
                                                    <div className={"align-self-start"}>{forloeb.godkendtKommentar}</div>
                                                </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                                {forloeb.attestationForTidInfoTekst &&
                                    <div className={"flex-container-row beskaeftigelse-pdf-list-item margin-top-l border-top"}>
                                        <div
                                            className={"font-weight-bold col-2 beskaeftigelse-pdf-list-item margin-top-m"}>{Localizer.forloebpage_BemaerkningerTilForloeb()}
                                        </div>
                                        <div
                                            className={"col-10 beskaeftigelse-pdf-list-item align-content-start margin-top-m margin-bottom-m"}>{forloeb.attestationForTidInfoTekst}
                                        </div>
                                    </div>
                                }
                            </div>
                        </PdfComponent.NonBreakableItem>
                    )
                }
            </PdfComponent.BlockItem>
        </PdfComponent>
    );
}
