import * as yup from "yup";
import createValidationSchema from "../../../core/components/validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {KlarmeldingModel, KlarmeldingTilBrugerModel} from "../klarmeldingModel";

export type CrudKlarmeldingInputModel = {
    contextId?: string;
    klarmeldtTilAlleMedAdgang: boolean;
    klarmeldBrugere?: KlarmeldingTilBrugerModel[];
}

export const AlleMedAdgangKey = "AllUsersWithAccess";

export const BuildExisting = (model: KlarmeldingModel): CrudKlarmeldingInputModel => {
    return {
        contextId: model.contextId,
        klarmeldBrugere: model.klarmeldBrugere,
        klarmeldtTilAlleMedAdgang: model.klarmeldtTilAlleMedAdgang,
    };
}
export const BuildNew = (): CrudKlarmeldingInputModel => {
    return {
        contextId: undefined,
        klarmeldBrugere: undefined,
        klarmeldtTilAlleMedAdgang: false
    };
}

export const CheckIfAllIsChosen = (inputModel: CrudKlarmeldingInputModel) => {
    if (inputModel.klarmeldBrugere?.some(x => x.UserId === AlleMedAdgangKey)) {
        inputModel.klarmeldtTilAlleMedAdgang = true;
        inputModel.klarmeldBrugere = [];
    } else {
        inputModel.klarmeldtTilAlleMedAdgang = false;
    }
}

export const crudKlarmeldingValidationSchema = createValidationSchema<CrudKlarmeldingInputModel>({
    contextId: yup.string().notRequired(),
    klarmeldtTilAlleMedAdgang: yup.boolean().notRequired(),
    klarmeldBrugere: yup.array().min(1, Localizer.validate_klarmeldTilEnEllerFlere()).required(Localizer.validate_fieldMustBeDefined(Localizer.kompetencePage_klarmeldTil()))
});

