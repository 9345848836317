import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import {CreateMailInputModel} from "../../../core/components/mail/createMailInputModel";
import {LogbogEmailRequestModel} from "./logbogEmailRequestModel";
import {logbogEmailStpsRequestModel} from "./logbogEmailStpsRequestModel";
import {UserWithForloebAndStillingId} from "../../../core/sharedmodels/email/UserWithForloebAndStillingId";

export class LogbogEmailApi{
    private httpClientService: HttpClientService;

    private emailBaseUrl = () => config.logbogApiUrl + "api/email/";
    public stpsEmailUrl = () => this.emailBaseUrl() + "CreateStpsEmail";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }
    async CreateEmail(inputModel: CreateMailInputModel, userWithForloebAndStilling: Array<UserWithForloebAndStillingId>, emailTypeEnum: number): Promise<boolean> {
        const mail = new LogbogEmailRequestModel(inputModel, userWithForloebAndStilling, emailTypeEnum);
        return (await this.httpClientService.Post<ApiResponse<boolean>, LogbogEmailRequestModel>(this.emailBaseUrl(), mail)).data;
    }

    async SendStpsAnsoegning(requestModel: logbogEmailStpsRequestModel): Promise<number> {
        const apiUrl = this.stpsEmailUrl();
        return (await this.httpClientService.Post<ApiResponse<number>, logbogEmailStpsRequestModel>(apiUrl, requestModel)).data;
    }
}
