import { Localizer } from "infrastructure/localization/localizer";
import FilterTitle from "./filterTitle";
import { useEffect, useState } from "react";
import ForSelectDropdown from "core/components/dropdown/components/forSelectDropdown";
import useStamdataApi from "core/hooks/useStamdataApi";
import { TypedFilterWithPermissionsProps } from "../types/typedFilterProps";
import { mapUserPermissionsToDropdownPermissions } from "core/sharedmodels/userPermissions/userFilterPermissions";
import FilterHierarchicalEnum from "core/sharedmodels/restrictModel/FilterHierarchicalEnum";
import { EducationFilterVisualizationConfig } from "../types/visualizationConfig";

export type EducationFilterResult = {
    specialer: string[],
    uddannelser: string[],
}

export type EducationFilterRestrictModel = {
    filterHierarchicalEnums?: FilterHierarchicalEnum[];
} & EducationFilterResult;

const initialSelectedValues: EducationFilterResult = {
    specialer: [],
    uddannelser: [],
};

const EducationFilter = ({
    initialSelectedValues: selectedValues=initialSelectedValues,
    permissions,
    onChange,
    titled=false,
    additionalFilterTitleClasses,
    restrictModel,
    onFilterMenuCloseCallback,
    disable,
    visualizationConfig
} : TypedFilterWithPermissionsProps<EducationFilterResult, EducationFilterRestrictModel, EducationFilterVisualizationConfig>) => {

    const { stamdataSpecialeApi, stamdataUddannelseApi } = useStamdataApi();

    const [filter, setFilter] = useState<EducationFilterResult>(selectedValues);

    useEffect(() => {
        onChange(filter);
    }, [filter, onChange]);

    return (
        <div>
            {titled &&
                <FilterTitle title={Localizer.global_uddannelse()} additionalClasses={additionalFilterTitleClasses}/>
            }

            <ForSelectDropdown 
                onChangeCallback={newValue => setFilter(prev => ({...prev, specialer: newValue.map(x => x.value)}))} 
                apiFetchPromise={stamdataSpecialeApi.getSpecialerSelect()}
                initialSelectedOption={filter.specialer}
                title={Localizer.forloebOverviewPage_speciale()}
                placeholder={Localizer.forloebOverviewPage_chooseSpeciale()}
                permission={mapUserPermissionsToDropdownPermissions(permissions, p => p.specialeIds)}
                restrictedValues={restrictModel?.specialer}
                onMenuCloseCallback={onFilterMenuCloseCallback}
                isMulti
                disable={disable}
            />

            <ForSelectDropdown 
                onChangeCallback={newValue => setFilter(prev => ({...prev, uddannelser: newValue.map(x => x.value)}))} 
                apiFetchPromise={stamdataUddannelseApi.getUddannelser()}
                initialSelectedOption={filter.uddannelser}
                title={Localizer.forloebOverviewPage_uddannelsetype()}
                placeholder={Localizer.forloebOverviewPage_chooseUddannelsetype()}
                restrictedValues={restrictModel?.uddannelser}
                onMenuCloseCallback={onFilterMenuCloseCallback}
                isMulti
                disable={disable}
            />
            
        </div>
    );
}

export default EducationFilter;