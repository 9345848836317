import { MutableRefObject, Ref, forwardRef, useEffect, useRef } from "react";
import { Localizer } from "../../../infrastructure/localization/localizer";
import { ReactFileUploader, ReactFileUploaderFile, ReactFileUploaderOptions } from "./reactFileUploader";
import { FileArchiveEnum } from "core/sharedmodels/fileMetaData/fileArchiveEnum";
import { EmblaIcon } from "../emblaIcon/emblaIcon";
import { FileLocationEnum } from "../../sharedmodels/file/fileLocationEnum";
import {AllowedFileTypes, GetAllowedFileTypes} from "./allowedFileTypes";
import useForwardedRef from "core/hooks/useForwardedRef";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";

type FileUploaderProps = {
    filesUploadedCallback: (result: ReactFileUploaderFile[]) => void;
    fileType: FileArchiveEnum;
    fileLocation: FileLocationEnum;
    overrideFileTypes?: string[];
    defaultUploadedFileMetadatas?: FileMetadata[];

}

export const FileUploader = forwardRef<ReactFileUploader,FileUploaderProps>((
    props: FileUploaderProps, 
    forwardedRef: Ref<ReactFileUploader>
) => {

    const selectorId = "file-uploader-input";
    const selectorIdString = `#${selectorId}`;
    const fileuploader = useForwardedRef<ReactFileUploader>(forwardedRef);

    useEffect(() => {
        if (!fileuploader.current) {
            const options: ReactFileUploaderOptions = {
                filesUploadedCallback: props.filesUploadedCallback,
                fileType: props.fileType
            };
    
            const defaultUploadedFiles = props.defaultUploadedFileMetadatas?.map(fm => ({ fileMetadata: fm, fileName: fm.fileName } as ReactFileUploaderFile));
            (fileuploader as MutableRefObject<ReactFileUploader>).current = new ReactFileUploader(selectorIdString, options, props.fileLocation, defaultUploadedFiles);
        }
    }, [fileuploader, props.defaultUploadedFileMetadatas, props.fileLocation, props.fileType, props.filesUploadedCallback, selectorIdString])

    return (
        <>
            <input type="file" id={selectorId} className={"inputfile"} accept={props.overrideFileTypes ? GetAllowedFileTypes(props.overrideFileTypes) : GetAllowedFileTypes(AllowedFileTypes)}/>
            <label htmlFor={selectorId}>
                <div className="text">
                    {Localizer.global_vaelgFil()}
                </div>
                <div className="icon">
                    <EmblaIcon iconName="plus" />
                </div>
            </label>
        </>
    )
});

