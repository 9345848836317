import { getTranslation } from "./localizer";
import { LocalizerValidations } from "./localizerValidations";

export class LocalizerPageSpecifics extends LocalizerValidations {
    public static errorpage_errorOccured = () => getTranslation("errorpage_errorOccured", "En uforudset fejl er opstået. Gå til forsiden eller prøv igen.");
    public static errorpage_goToFrontPage = () => getTranslation("errorpage_goToFrontPage", "Gå til forsiden");
    public static notFoundpage_notFound = () => getTranslation("notFoundpage_notFound", "404 - Siden blev ikke fundet");
    public static errorGettingPdf = () => getTranslation("errorGettingPdf", "Fejl ved generering af PDF-fil");
    public static forsoegIgenEllerKontaktSupport = () => getTranslation("forsoegIgenEllerKontaktSupport", "Forsøg igen eller kontakt dit lokale videreuddannelsessekretariat");

    public static forloebpage_NoForloeb = () => getTranslation("forloebpage_NoForloeb", "Ingen forløb");
    public static forloebpage_ForloebErFravaer = () => getTranslation("forloebpage_NoForloeb", "Forløb er fravær");
    public static forloebpage_NoResultsFromFilter = () => getTranslation("forloebpage_NoResultsFromFilter", "Den anvendte filtrering gav ingen resultater. Tilpas filtret og forsøg igen.");
    public static forloebpage_NoStartDate = () => getTranslation("forloebpage_NoStartDate", "Ingen startdato");
    public static forloebpage_NoEndDate = () => getTranslation("forloebpage_NoEndDate", "Ingen slutdato");
    public static forloebpage_Ubegraenset = () => getTranslation("forloebpage_Ubegraenset", "Ubegrænset");
    public static forloebpage_NoStillinger = () => getTranslation("forloebpage_NoStillinger", "Ingen stillinger");
    public static forloebpage_InThisForloeb = () => getTranslation("forloebpage_InThisForloeb", "I dette forløb");
    public static forloebpage_AttestationForTid = () => getTranslation("forloebpage_AttestationForTid", "Attestation for tid");
    public static forloebpage_uddannelsesprogram = () => getTranslation("forloebpage_uddannelsesprogram", "Uddannelsesprogram");
    public static forloebpage_FilerForAttestationForTid = () => getTranslation("forloebpage_FilerForAttestationForTid", "Filer til attestation for tid");
    public static forloebpage_AttestationForTidDescription = () => getTranslation("forloebpage_AttestationForTidDescription", "Har du filer til attestation for tid, skal du tilføje dem her.");
    public static forloebpage_Attestation = () => getTranslation("forloebpage_Attestation", "Attestation");
    public static forloebpage_FraUddannelsesGiver = () => getTranslation("forloebpage_FraUddannelsesGiver", "Fra uddannelsesgiver");
    public static forloebpage_createForloeb = () => getTranslation("forloebpage_createForloeb", "Opret forløb");
    public static forloebpage_printForloeb = () => getTranslation("forloebpage_printForloeb", "Print");
    public static forloebpage_derErForloebSomOverlapperDato = () => getTranslation("forloebpage_derErForloebSomOverlapperDato", "Der findes et overlappende forløb");
    public static forloebpage_fravaersStillingSelectedInfo = () => getTranslation("forloebpage_fravaersStillingSelectedInfo", "Du har valgt en fraværs-stilling - Dette forløb vil blive oprettet som et fraværs-forløb.");
    public static forloebpage_creatingForloeb = () => getTranslation("forloebpage_creatingForloeb", "Opretter forløb");
    public static forloebpage_forloebCreated = () => getTranslation("forloebpage_forloebCreated", "Forløb blev oprettet");
    public static forloebpage_forloebGodkendt = () => getTranslation("forloebpage_forloebGodkendt", "Forløb godkendt");
    public static forloebpage_forloebDeleted = () => getTranslation("forloebpage_forloebDeleted", "Forløb blev slettet");
    public static forloebpage_forloebAfsluttetManuelt = () => getTranslation("forloebpage_forloebAfsluttetManuelt", "Forløb blev afsluttet");
    public static forloebpage_forloebEdited = () => getTranslation("forloebpage_forloebEdited", "Forløb blev redigeret");
    public static forloebpage_forloebSkalIndgaaIEvalueringsstatistikChanged = () => getTranslation("forloebpage_forloebSkalIndgaaIEvalueringsstatistikChanged", "Forløb 'Skal indgå i evalueringsstatistik' blev ændret");
    public static forloebpage_errorForloebEdited = () => getTranslation("forloebpage_errorForloebEdited", "Fejl ved redigering af forløb");
    public static forloebpage_fravaerEdited = () => getTranslation("forloebpage_fravaerEdited", "Fravær blev redigeret");
    public static forloebpage_fejlVedForloebEvalueringOprettelse = () => getTranslation("forloebpage_fejlVedForloebEvalueringOprettelse", "Fejl ved oprettelse af forløbevaluering");
    public static forloebpage_fejlVedForloebEvalueringSletning = () => getTranslation("forloebpage_fejlVedForloebEvalueringSletning", "Fejl ved sletning af forløbevaluering");
    public static forloebpage_errorFravaerEdited = () => getTranslation("forloebpage_errorFravaerEdited", "Fejl ved redigering af fravær");
    public static forloebpage_errorFravaerCreate = () => getTranslation("forloebpage_errorFravaerCreate", "Fejl ved oprettelse af fravær");
    public static forloebpage_forloebAfsluttet = () => getTranslation("forloebpage_forloebAfsluttet", "Forløb afsluttet");
    public static forloebpage_forloebsdetaljer = () => getTranslation("forloebpage_forloebsdetaljer", "Forløbsdetaljer");
    public static forloebpage_godkendTid = () => getTranslation("forloebpage_godkendTid", "Godkend tid");
    public static forloebpage_godkendTidWarning = () => getTranslation("forloebpage_godkendTidWarning", "Det er ikke muligt at godkende tid uden et autorisations-id");
    public static forloebpage_godkendKursusWarning = () => getTranslation("forloebpage_godkendKursusWarning", "Det er ikke muligt at godkende et lægekursus uden et autorisations-id");
    public static forloebpage_fjernGodkendelse = () => getTranslation("forloebpage_fjernGodkendelse", "Fjern Godkendelse");
    public static forloebpage_fjernGodkendelseDescription = () => getTranslation("forloebpage_fjernGodkendelseDescription", "Du er ved at fjerne godkendelsen på dette forløb");
    public static forloebpage_fjernGodkendelseFailed = () => getTranslation("forloebpage_fjernGodkendelseFailed", "Fjern godkendelse fejlede");
    public static forloebpage_fjernGodkendelseSuccess = () => getTranslation("forloebpage_fjernGodkendelseSuccess", "Godkendelse fjernet");
    public static forloebpage_forloebAdgangGivet = () => getTranslation("forloebpage_forloebAdgangGivet", "Forløbadgang givet");
    public static forloebpage_maalbeskrivelseSkiftet = () => getTranslation("forloebpage_maalbeskrivelseSkiftet", "Målbeskrivelse skiftet");
    public static forloebpage_nyMaalbeskrivelse = () => getTranslation("forloebpage_nyMaalbeskrivelse", "Ny målbeskrivelse");
    public static forloebpage_nuvaerendeMaalbeskrivelse = () => getTranslation("forloebpage_nuvaerendeMaalbeskrivelse", "Nuværende målbeskrivelse");
    public static forloebpage_skiftMaalbeskrivelse = () => getTranslation("forloebpage_skiftMaalbeskrivelse", "Skift målbeskrivelse");
    public static forloebpage_huskAtGiveMerit = () => getTranslation("forloebpage_huskAtGiveMerit", "Husk at give merit");
    public static forloebpage_brugerenErPaaNyesteMaalbeskrivelse = () => getTranslation("forloebpage_brugerenErPaaNyesteMaalbeskrivelse", "Brugeren er allerede på den nyeste målbeskrivelse.");
    public static forloebpage_derErIkkeAndreTilgaengeligeMaalbeskrivelser = () => getTranslation("forloebpage_derErIkkeAndreTilgaengeligeMaalbeskrivelser", "Der er ikke andre tilgængelige målbeskrivelser.");
    public static forloebpage_huskMeritBeskrivelse = () => getTranslation("forloebpage_huskMeritBeskrivelse", "Godkendte kurser eller kompetencer på den nuværende målbeskrivelse bliver ikke overført automatisk til den nye målbeskrivelse. Du skal derfor huske at give merit til de kompetencer og kurser der skal overføres.");
    public static forloebpage_tildel = () => getTranslation("forloebpage_tildel", "Tildel");
    public static forloebpage_fortryd = () => getTranslation("forloebpage_fortryd", "Fortryd");
    public static forloebpage_tildelTidsbegraensetAdgang = () => getTranslation("forloebpage_tildelTidsbegraensetAdgang", "Tildel tidsbegrænset adgang");
    public static forloebpage_tidsbegraensetAdgangBeskrivelse = () => getTranslation("forloebpage_tidsbegraensetAdgangBeskrivelse", "Hvis en overlæge eller vejleder har brug for at tilgå en uddannelseslæges forløb, kan du tildele adgang til forløbet i en begrænset periode.");
    public static forloebpage_getUddannelsesplaner = () => getTranslation("forloebpage_getUddannelsesplaner", "Henter uddannelsesplaner");
    public static forloebpage_getSpecialeSpecifikkeFiler= () => getTranslation("forloebpage_getSpecialeSpecifikkeFiler", "Henter specialespecifikke filer");
    public static forloebpage_getUddannelsesprogrammer= () => getTranslation("forloebpage_getUddannelsesprogrammer", "Henter uddannelsesprogrammer");
    public static forloebpage_getUddannelseFiler= () => getTranslation("forloebpage_getUddannelseFiler", "Henter uddannelsefiler");
    public static forloebpage_UddannelsesplaneOverviewEmptyInfo = () => getTranslation("forloebpage_UddannelsesplaneOverviewEmptyInfo", "Du har endnu ikke tilføjet en uddannelsesplan for dette forløb.");
    public static forloebpage_SpecialespecifikkefilerOverviewEmptyInfo = () => getTranslation("forloebpage_SpecialespecifikkefilerOverviewEmptyInfo", "Der blev ikke fundet nogle specialespecifikke filer.");
    public static forloebpage_UddannelsesprogrammerOverviewEmptyInfo = () => getTranslation("forloebpage_UddannelsesprogrammerOverviewEmptyInfo", "Der blev ikke fundet nogle uddannelsesprogrammer.");
    public static forloebpage_UddannelsefilerOverviewEmptyInfo = () => getTranslation("forloebpage_UddannelsefilerOverviewEmptyInfo", "Der blev ikke fundet nogle filer fra uddannelsesstedet");
    public static forloebpage_NotaterOverviewInfo = (count: number) => getTranslation("forloebpage_NotaterOverviewInfo", `Der blev fundet ${count > 1 ? count.toString() + " notater" : count.toString() + " notat"}`);
    public static forloebpage_gaaTilNotater = () => getTranslation("forloebpage_gaaTilNotater", "Gå til notater");
    public static forloebpage_gaaTilForloeb = () => getTranslation("forloebpage_gaaTilForloeb", "Gå til forløb");
    public static forloebpage_markerHaandteret = () => getTranslation("forloebpage_markerHaandteret", "Markér håndteret");
    public static forloebpage_notaterOverviewEmptyInfo = () => getTranslation("forloebpage_notaterOverviewEmptyInfo", "Der blev ikke fundet nogle notater");
    public static forloebpage_kurserOverviewEmptyInfo = () => getTranslation("forloebpage_kurserOverviewEmptyInfo", "Der er ingen kurser på dette forløb");
    public static forloebpage_currentUddannelsesplan = () => getTranslation("forloebpage_currentUddannelsesplan", "Gældende uddannelsesplan");
    public static forloebpage_kompetencerOverviewInfo = (forloebKompetencerCount: string, totalKompetencerCount: string) => getTranslation("forloebpage_kompetencerOverviewInfo", `Du arbejder med ${forloebKompetencerCount} kompetencer i dette forløb og har ${totalKompetencerCount} resterende kompetencer i uddannelsen.`);
    public static forloebpage_kompetencerOverviewOnBrugerInfo = () => getTranslation("forloebpage_kompetencerOverviewOnBrugerInfo", `Du ser kompetencer for hele uddannelsen, da du ikke har valgt kompetencer til dette forløb.`);
    public static forloebpage_kompetencerOverviewEmptyInfo = () => getTranslation("forloebpage_kompetencerOverviewEmptyInfo", "Du kan tilføje kompetencer, som du ønsker at gennemføre i dette forløb for at opnå overblik over din fremgang.");
    public static forloebpage_notAnyPlaned = () => getTranslation("forloebpage_notAnyPlaned", "Lægen har endnu ikke planlagt nogle kompetencer til dette forløb. Gå til kompetencer for at se lægens kompetencer.");
    public static forloebpage_vejlederOverviewInfo = (accepteretSamtalerCount: string, forloebSamtalerCount: string) => getTranslation("forloebpage_vejlederOverviewInfo", `Du har afholdt ${accepteretSamtalerCount} af ${forloebSamtalerCount} vejledersamtaler i dette forløb`);
    public static forloebpage_vejlederOverviewEmptyInfo = () => getTranslation("forloebpage_vejlederOverviewEmptyInfo", "Du har endnu ikke planlagt vejledersamtaler for dette forløb. Du skal mindst have 3 vejledersamtaler accepteret af en vejleder.");
    public static forloebpage_attestationForTidGodkendtAf = (godkendtDato: string, godkendtAfNavn: string) => getTranslation("forloebpage_attestationForTidGodkendtAf", `Attestation for tid er godkendt ${godkendtDato} af ${godkendtAfNavn}`);
    public static forloebpage_KanIkkeRedigeresDaDetErGodkendt = (godkendtDato: string) => getTranslation("forloebpage_KanIkkeRedigeresDaDetErGodkendt", `Forløbet er godkendt d. ${godkendtDato}, og kan derfor ikke redigeres.`);
    public static forloebpage_KunSeAdgangTilForloeb = () => getTranslation("forloebpage_KunSeAdgangTilForloeb", "Oversigt over uddannelseslægens forløb. Du kan ikke tilgå forløb via oversigten.");
    public static forloebpage_BemaerkningerTilForloeb = () => getTranslation("forloebpage_BemaerkningerTilForloeb", "Bemærkninger til forløb");
    public static forloebpage_BemaerkningerTilForloebLabel = () => getTranslation("forloebpage_BemaerkningerTilForloebLabel", "Indtast bemærkninger til forløb");
    public static forloebpage_GemBemaerkningTilForloeb = () => getTranslation("forloebpage_GemBemaerkningTilForloeb", "Gem");
    public static forloebpage_BemaerkningTilForloebAdded = () => getTranslation("forloebpage_BemaerkningTilForloebAdded", "Bemærkninger til forløb tilføjet");
    public static forloebpage_BemaerkningTilForloebError = () => getTranslation("forloebpage_BemaerkningTilForloebError", "Bemærkninger til forløb kunne ikke tilføjes");
    public static forloebpage_IngenBemaerkningerTilForloeb = () => getTranslation("forloebpage_IngenBemaerkningerTilForloeb", "Ingen bemærkninger til forløb");
    public static forloebpage_GodkendtTidTekst = () => getTranslation("forloebpage_GodkendtTidTekst", " Det attesteres, at");
    public static forloebpage_GodkendtTidTekstAndetPunkt = () => getTranslation("forloebpage_GodkendtTidTekstAndetPunkt", "Det bekræftes samtidig, at der ikke har været sygefravær mv. der overstiger 10 % af uddannelsesdelen");
    public static forloebpage_GodkendtTidTekstFoerstePunkt = () => getTranslation("forloebpage_GodkendtTidTekstFoerstePunkt", "Den uddannelsessøgende læge har haft funktion i det anførte tidsrum");
    public static forloebpage_specialespecifikkeFiler = () => getTranslation("forloebpage_specialespecifikkeFiler", "Specialespecifikke filer");
    public static forloebpage_uddannelsesplanInfo = () => getTranslation("forloebpage_uddannelsesplanInfo", "I Uddannelseslæge.dk kan uddannelsesplanen (den individuelle uddannelsesplan) ses af uddannelsesstedets vejledere og uddannelsesansvarlige. Dette er vigtigt for din uddannelse, da uddannelsesplanen er en individuel tilpasning af uddannelsesprogrammet, som er med til at sikre, at du som uddannelseslæge kan opnå dine kompetencer i afdelingen. Den individuelle uddannelsesplan skal indeholde en konkret fastlæggelse af, hvornår og hvordan du kan opnå de enkelte kompetencer og det er derfor afgørende, at uddannelsesstedets vejledere og uddannelsesansvarlige har mulighed for at følge med i dette, så de kan understøtte din kompetenceudvikling i afdelingen.");
    public static forloebpage_uddannelsesprogramInfo = () => getTranslation("forloebpage_uddannelsesprogramInfo", "Du kan som uddannelseslæge uploade dit uddannelsesprogram her, så dine vejledere kan se det. Uddannelsesprogrammet kan her ses på tværs af ansættelser i dit uddannelsesforløb. Uddannelsesansvarlige/tutorlæger har også adgang til at uploade uddannelsesprogrammet.");


    public static vejledersamtalepage_vejledersamtaleCreated = () => getTranslation("vejledersamtalepage_vejledersamtaleCreated", "Vejledersamtale blev oprettet");
    public static vejledersamtalepage_vejledersamtaleEditted = () => getTranslation("vejledersamtalepage_vejledersamtaleEditted", "Vejledersamtale blev redigeret");
    public static vejledersamtalepage_vejledersamtaleAfholdt = () => getTranslation("vejledersamtalepage_vejledersamtaleAfholdt", "Vejledersamtale afholdt");
    public static vejledersamtalepage_vejledersamtaleDeleted = () => getTranslation("vejledersamtalepage_vejledersamtaleDeleted", "Vejledersamtale blev slettet");
    public static forloebpage_getForloeb = () => getTranslation("forloebpage_getForloeb", "Henter forløb");
    public static forloebpage_createFailed = () => getTranslation("forloebpage_createFailed", "Opret forløb fejlede");
    public static forloebpage_godkendTidFailed = () => getTranslation("forloebpage_godkendTidFailed", "Godkend tid fejlede");
    public static forloebEdit_EditForloeb = () => getTranslation("forloebEdit_EditForloeb", "Rediger forløb");
    public static forloebEdit_SaveForloeb = () => getTranslation("forloebEdit_SaveForloeb", "Gem forløb");
    public static forloebEdit_DeleteForloeb = () => getTranslation("forloebEdit_DeleteForloeb", "Slet forløb");
    public static forloebEdit_forloebErEvalueret = () => getTranslation("forloebEdit_forloebErEvalueret", "Hvis forløbets evaluering er indsendt, vil du ikke have mulighed for at ændre på, om forløbet skal indgå i evalueringen. En administrator skal i stedet slette evalueringen, hvis dette ønskes.");
    public static forloebEdit_erDuSikkerDeleteForloeb = () => getTranslation("forloebEdit_erDuSikkerDeleteForloeb", "Er du sikker på, at du vil slette forløbet?");
    public static forloebEdit_OBSDataPaaForloebDuSletterErDuSikkerDeleteForloeb = () => getTranslation("forloebEdit_OBSDataPaaForloebDuSletterErDuSikkerDeleteForloeb", "OBS! Du er ved at slette et forløb, hvor der er gemt uddannelsesdata på. Det kan eksempelvis være godkendte kompetencer/kurser, samtaler eller notater. Vil du slette forløb og det gemte uddannelsesdata?");
    public static forloebEdit_AfslutForloebManuelt = () => getTranslation("forloebEdit_AfslutForloebManuelt", "Afslut forløb");
    public static forloebEdit_CannotDeleteReason = () => getTranslation("forloebEdit_CannotDeleteReason", "Forløb kan ikke slettes, da der er gemt uddannelsesdata på forløbet. Kontakt eventuelt en bruger med administratorrettigheder i dit videreuddannelsessekretariat");
    public static forloebEdit_DeleteForloebAdgangError = () => getTranslation("forloebEdit_DeleteForloebAdgangError", "Forløb adgang kunne ikke fjernes. Prøv igen.");
    public static forloebEdit_DeleteForloebAdgangSuccess = () => getTranslation("forloebEdit_DeleteForloebAdgangSuccess", "Forløb adgang blev fjernet.");
    public static forloebEdit_AddMonths = (months: number) => getTranslation("forloebEdit_AddMonths", `+${months} mdr.`);
    public static forloebEdit_SenesteDatoForEvaluering = () => getTranslation("forloebEdit_SenesteDatoForEvaluering", "Seneste dato for evaluering");
    public static forloebEdit_FileUploadedSuccess = () => getTranslation("forloebEdit_FileUploadedSuccess", `Fil er uploadet.`);
    public static forloebEdit_FileUploadError = () => getTranslation("forloebEdit_FileUploadError", 'Det er påkrævet at specificere speciale eller uddannelse og det sted, hvor filen skal præsenteres.');
    public static forloebEdit_FileRemovedSuccess = () => getTranslation("forloebEdit_FileRemovedSuccess", `Fil fjernet.`);
    public static profilepage_myProfile = () => getTranslation("profilepage_myProfile", "Min profil");
    public static profilepage_getProfil = () => getTranslation("profilepage_getProfil", "Henter profil");
    public static profilepage_getVejledere = () => getTranslation("profilepage_getVejledere", "Henter vejledere");
    public static profilepage_getVejleder = () => getTranslation("profilepage_getVejleder", "Henter vejlederinfo");
    public static profilepage_getVejledersamtaler = () => getTranslation("profilepage_getVejledersamtaler", "Henter vejledersamtaler");
    public static profilepage_ReceivedEmails  = () => getTranslation("profilepage_ReceivedEmails", "Modtagne mails");
    public static profilepage_GivetSamtykke = () => getTranslation("profilepage_GivetSamtykke", "Der er givet samtykke til betingelserne for brugen af systemet.");
    public static profilepage_IkkeGivetSamtykke = () => getTranslation("profilepage_IkkeGivetSamtykke", "Der er ikke givet samtykke til betingelserne for brugen af systemet.");
    public static profilepage_SeBetingelser = () => getTranslation("profilepage_SeBetingelser", "Se betingelser");
    public static vejledersamtalePage_createVejledersamtale = () => getTranslation("vejledersamtalePage_createVejledersamtale", "Opret vejledersamtale");
    public static vejledersamtalePage_createAndKlarmeldVejledersamtale = () => getTranslation("vejledersamtalePage_createAndKlarmeldVejledersamtale", "Opret & klarmeld");
    public static uddannelsesplan_createUddannelsesplan = () => getTranslation("uddannelsesplan_createUddannelsesplan", "Tilføj uddannelsesplan");
    public static uddannelsesplan_createAndKlarmeldUddannelsesplan = () => getTranslation("uddannelsesplan_createAndKlarmeldUddannelsesplan", "Tilføj & klarmeld");
    public static uddannelsesplan_editUddannelsesplan = () => getTranslation("uddannelsesplan_editUddannelsesplan", "Rediger uddannelsesplan");
    public static uddannelsesplan_deleteGodkendelseDescription = () => getTranslation("uddannelsesplan_deleteGodkendelseDescription","Du er ikke enig i uddannelsesplanen");
    public static uddannelsesplan_deleteGodkendelseNotification = () => getTranslation("uddannelsesplan_deleteGodkendelseNotification","Enighed trukket tilbage");
    public static vejledersamtalePage_planVejledersamtale = () => getTranslation("vejledersamtalePage_planVejledersamtale", "Planlæg vejledersamtale");
    public static vejledersamtalePage_creatingVejledersamtale = () => getTranslation("vejledersamtalePage_creatingVejledersamtale", "Opretter vejledersamtale");
    public static vejledersamtalePage_deleteGodkendelseDescription = () => getTranslation("vejledersamtalePage_deleteGodkendelseDescription","Denne vejledersamtale er ikke afholdt");
    public static vejledersamtalePage_deleteGodkendelseNotification = () => getTranslation("vejledersamtalePage_deleteGodkendelseNotification","Vejledersamtale ikke afholdt");
    public static vejledningPage_creatingUddannelsesplan = () => getTranslation("vejledningPage_creatingUddannelsesplan", "Opretter uddannelsesplan");
    public static vejledningPage_uddannelsesplanCreated = () => getTranslation("vejledningPage_uddannelsesplanCreated", "Uddannelsesplan blev oprettet");
    public static vejledningPage_opretUddannelsesplanISamarbejdeMedVejleder = () => getTranslation("vejledningPage_opretUddannelsesplanISamarbejdeMedVejleder", "Opret en uddannelsesplan i samarbejde med din vejleder");
    public static vejledningPage_uddannelsesplanTekstFeltInformation = () => getTranslation("vejledningPage_uddannelsesplanTekstFeltInformation", "Angiv dine læringsbehov/interesser, læringsmål, aktiviteter, vurderingskriterier");
    public static vejledningPage_ingenUddannelsesplanerOprettet = () => getTranslation("vejledningPage_ingenUddannelsesplanerOprettet", "Ingen uddannelsesplaner oprettet");
    public static vejledningPage_uddannelsesplanEditted = () => getTranslation("vejledningPage_uddannelsesplanEditted", "Uddannelsesplan blev redigeret");
    public static vejledningPage_uddannelsesplanEnighedTilkendegivet = () => getTranslation("vejledningPage_uddannelsesplanEnighedTilkendegivet", "Enighed tilkendegivet");
    public static vejledningPage_uddannelsesplanDeleted = () => getTranslation("vejledningPage_uddannelsesplanDeleted", "Uddannelsesplan blev slettet");
    public static vejledningPage_vejlederVarEnigIUddannelsesplan = (navn: string, dato: string) => getTranslation("vejledningPage_vejlederVarEnigIUddannelsesplan", navn + " var enig i denne uddannelsesplan d. " + dato);
    public static vejledningPage_vejlederHarAfholdVejledersamtale = (navn: string, dato: string) => getTranslation("vejledningPage_vejlederHarAfholdVejledersamtale", navn + " har d. " + dato + " bekræftet, at denne samtale er afholdt");
    public static vejledningPage_navnHarTilknyttetUddannelsesplan = (navn: string, dato: string) => getTranslation("vejledningPage_navnHarTilknyttetUddannelsesplan", navn + " har tilknyttet uddannelsesplan d. " + dato);
    public static vejledningPage_enVejleder = () => getTranslation("vejledningPage_enVejleder", "En vejleder");
    public static vejledningPage_sendCalendarInvitation = () => getTranslation("vejledningPage_sendCalendarInvitation", "Send kalenderinvitation");

    public static vejledersamtalepage_editFailed = () => getTranslation("vejledersamtalepage_createFailed", "Redigering af vejledersamtale fejlede");
    public static vejledersamtaleEdit_DeleteFailed = () => getTranslation("vejledersamtaleEdit_DeleteFailed", "Sletning af vejledersamtale fejlede");
    public static vejledersamtaleEdit_DeleteVejledersamtale = () => getTranslation("vejledersamtaleEdit_DeleteVejledersamtale", "Slet vejledersamtale");
    public static vejledersamtaleEdit_EditVejledersamtale = () => getTranslation("vejledersamtaleEdit_EditVejledersamtale", "Rediger vejledersamtale");

    public static vejlederPage_createVejleder = () => getTranslation("vejlederPage_createVejleder", "Opret vejleder");
    public static vejlederPage_creatingVejleder = () => getTranslation("vejlederPage_creatingVejleder", "Opretter vejleder");
    public static vejlederPage_editVejleder = () => getTranslation("vejlederPage_editVejleder", "Rediger vejleder");
    public static vejlederPage_saveVejleder = () => getTranslation("vejlederPage_saveVejleder", "Gem vejleder");
    public static vejlederPage_editingVejleder = () => getTranslation("vejlederPage_editingVejleder", "Opdaterer vejleder");
    public static vejlederpage_vejlederEditted = () => getTranslation("vejlederpage_vejlederEditted", "Vejleder blev redigeret");
    public static vejlederpage_removeVejleder = () => getTranslation("vejlederpage_removeVejleder", "Fjern vejleder");
    public static vejlederpage_vejlederRemoved = () => getTranslation("vejlederpage_vejlederRemoved", "Vejlederen blev fjernet");
    public static vejlederpage_removeVejlederSure = () => getTranslation("vejlederpage_removeVejlederSure", "Er du sikker på at du vil fjerne Vejlederen?");
    public static vejlederpage_vejlederCreated = () => getTranslation("vejlederpage_vejlederCreated", "Vejleder blev oprettet");
    public static vejlederpage_vejlederLastValidated = () => getTranslation("vejlederpage_vejlederLastValidated", "Sidst valideret:");
    public static vejlederpage_noUserInAuthRegister = () => getTranslation("vejlederpage_noUserInAuthRegister", "Ingen bruger fundet i authorisation registeret");
    public static vejlederpage_foundVejleder = () => getTranslation("vejlederpage_foundUser", "Vejlederen blev fundet i systemet");
    public static vejlederpage_foundUser = () => getTranslation("vejlederpage_foundUser", "Brugeren blev fundet i systemet");
    public static vejlederpage_moreInfoNeededToCreateUser = () => getTranslation("vejlederpage_moreInfoNeededToCreateUser", "Vejlederen blev ikke fundet i system. Opret vejlederen herunder");
    public static vejlederpage_noPraksisFound = () => getTranslation("vejlederpage_noPraksisFound", "Der blev ikke fundet nogle praksisser, som du har adgang til");
    public static vejlederpage_fremsoegerKunBrugereMedGyldigAuthId = () => getTranslation("vejlederpage_fremsoegerKunBrugereMedGyldigAuthId", "Fremsøger kun uddannelseslæger, vejledere og uddannelsesansvarlige som har et gyldigt authorisations-id");
    public static vejlederpage_noSygehuseFound = () => getTranslation("vejlederpage_noSygehuseFound", "Der blev ikke fundet nogle sygehuse, som du har adgang til");
    public static vejlederpage_noKurser = () => getTranslation("vejlederpage_noKurser", "Der blev ikke fundet nogle kurser på dette forløb");
    public static vejlederpage_afventerKursusopdatering = () => getTranslation("vejlederpage_afventerKursusopdatering", "En ny kursusrække er blevet publiceret og der afventes kursusopdatering. De forventes opdateret indenfor den næste time - vend tilbage senere.");
    public static vejlederpage_noKurserForKursusleder = () => getTranslation("vejlederpage_noKurserForKursusleder", "kan se følgende kursusgrupper: specialespecifikke- og specialespecifikke for intouddannelsen");
    public static vejlederpage_noKurserForKursusUdbyder = () => getTranslation("vejlederpage_noKurserForKursusUdbyder", "kan se alle kursusgrupper");
    public static vejlederpage_noAfdelingerFound = () => getTranslation("vejlederpage_noAfdelingerFound", "Der blev ikke fundet nogle afdelinger, som du har adgang til");
    public static vejlederpage_uddannelseFilterOnlaeger = () => getTranslation("vejlederpage_uddannelseFilterOnlaeger", "Filtrer i læger der kan gives adgang til");
    public static vejlederpage_giveAccessToLaeger = () => getTranslation("vejlederpage_giveAccessToLaeger", "Læger der skal gives adgang til");
    public static vejlederpage_gaaTilListenNu = () => getTranslation("vejlederpage_gaaTilListenNu", "Gennemgå nu");
    public static vejlederpage_gennemgaaListenSenere = () => getTranslation("vejlederpage_gennemgaaListenSenere", "Gennemgå senere");
    public static vejlederPage_userHasSingleUddannelsessted = () => getTranslation("vejlederPage_userHasSingleUddannelsessted", "Du er kun tilknyttet et enkelt uddannelsessted");
    public static vejlederPage_soegCpr = () => getTranslation("vejlederPage_soegCpr", "Søg på CPR");
    public static vejlederPage_alternativEmail = () => getTranslation("vejlederPage_alternativEmail", "Alternativ email");
    public static vejlederPage_alternativEmailDescription = () => getTranslation("vejlederPage_alternativEmailDescription", "Angiv evt. alternativ e-mailadresse for brugeren.");
    public static vejlederPage_createFailed = () => getTranslation("vejlederPage_createFailed", "Opret vejleder fejlede");
    public static vejlederPage_noAuthIdFound = () => getTranslation("vejlederPage_noAuthIdFound", "Der blev ikke fundet noget authorisations-id på brugeren");
    public static vejlederPage_editFailed = () => getTranslation("vejlederPage_createFailedvejlederPage_editFailed", "Redigering af vejleder fejlede");
    public static vejlederPage_uddannelsesplanGodkendtAf = (godkendtDato: string, godkendtAfNavn: string) => getTranslation("vejlederPage_uddannelsesplanGodkendtAf", `${godkendtAfNavn} meldte sig enig d. ${godkendtDato}`);
    public static vejlederPage_userIsInactiveInfoText = () => getTranslation("vejlederPage_userIsInactiveInfoText", "Brugeren er inaktiv - Brugeren vil blive aktiveret automatisk.");

    public static kompetencePage_searchString = () => getTranslation("klarmeldingerPage_searchString", "Søg på læge, kompetencetitel eller kompetencenummer");
    public static kompetencePage_searchStringMobile = () => getTranslation("klarmeldingerPage_searchString", "Søg på læge, titel eller nummer");
    public static kompetencePage_evalueringOprettetAf = (name: string) => getTranslation("kompetencePage_evalueringOprettetAf", `Kompetencevurdering er oprettet af: `);
    public static kompetencePage_noKompetencerOnForloeb = () => getTranslation("kompetencePage_noKompetencerOnForloeb", "Der blev ikke fundet nogle kompetencer på dette forløb");
    public static setDate = () => getTranslation("setDate", "Sæt dato");
    public static kompetencePage_handleMultiple = () => getTranslation("kompetencePage_handleMultiple", "Behandl flere");
    public static kompetencePage_addToForloeb = () => getTranslation("kompetencePage_addToForloeb", "Tilføj til forløb");
    public static kompetencePage_getKompetencer = () => getTranslation("kompetencePage_getKompetencer", "Henter kompetencer");
    public static kompetencePage_noKompetencer = () => getTranslation("kompetencePage_noKompetencer", "Der er ingen kompetencer");
    public static kompetencePage_planlaegKompetencer = () => getTranslation("kompetencePage_planlaegKompetencer", "Planlæg kompetencer");
    public static kompetencePage_planlagteKompetencer = () => getTranslation("kompetencePage_planlagteKompetencer", "Planlagte kompetencer");
    public static kompetencePage_givMerit = () => getTranslation("kompetencePage_givMerit", "Giv merit");
    public static kompetencePage_givDispensation = () => getTranslation("kompetencePage_givDispensation", "Giv dispensation");
    public static kompetencePage_chooseKompetencer = () => getTranslation("kompetencePage_chooseKompetencer", "Udvælg kompetencer, som du vil arbejde med i dette forløb");
    public static kompetencePage_chooseKompetencerDerSkalHaveMerit = () => getTranslation("kompetencePage_chooseKompetencerDerSkalHaveMerit", "Udvælg kompetencer der skal have merit.");
    public static kompetencePage_kompetencerUpdated = () => getTranslation("kompetencePage_kompetencerUpdated", "Kompetencer opdateret");
    public static kompetencePage_kompetencerKlarmeldt = (antal: string) => getTranslation("kompetencePage_kompetencerKlarmeldt", `${antal} kompetencer blev klarmeldt`);
    public static kompetencePage_kompetencerGodkendt = (antal: string) => getTranslation("kompetencePage_kompetencerGodkendt", `${antal} kompetencer blev godkendt`);
    public static kompetencePage_kompetencerGodkendtFjernet = (antal: string) => getTranslation("kompetencePage_kompetencerGodkendtFjernet", `Godkend fjernet på ${antal} kompetencer`);
    public static kompetencePage_kompetencerKlarmeldFjernet = (antal: string) => getTranslation("kompetencePage_kompetencerKlarmeldFjernet", `Klarmeld fjernet på ${antal} kompetencer`);
    public static kompetencePage_foraeldetKompetence = () => getTranslation("kompetencePage_foraeldetKompetence", `Forældet kompetence`);
    public static kompetencePage_foraeldetKompetenceBeskrivelse = () => getTranslation("kompetencePage_foraeldetKompetenceBeskrivelse", "Denne kompetence er ikke på den aktive målbeskrivelse og du kan derfor ikke behandle den.");
    public static kompetencePage_gaaTilAktivMaalbeskrivelse = () => getTranslation("kompetencePage_gaaTilAktivMaalbeskrivelse", "Gå til aktiv målbeskrivelse");
    public static kompetencePage_laegekompetenceGodkendtAf = (godkendtDato: string, godkendtAfNavn: string, rolle: string, authorisationsId?: string) => getTranslation("kompetencePage_laegekompetenceGodkendtAf", `Godkendt d. ${godkendtDato} af ${godkendtAfNavn}, ${authorisationsId} (${rolle})`);
    public static kompetencePage_laegekompetenceKlarmeldt = () => getTranslation("kompetencePage_laegekompetenceKlarmeldt", "Klarmeldt d. ");
    public static kompetencePage_ingenFaseAngivet = () => getTranslation("kompetencePage_ingenFaseAngivet", `Ingen fase angivet`);
    public static kompetencePage_klarmeldTil = () => getTranslation("kompetencePage_klarmeldTil", `Klarmeld til`);
    public static kompetencePage_editKlarmelding = () => getTranslation("kompetencePage_editKlarmelding", "Rediger klarmelding");
    public static kompetencePage_klarmeldingUpdated = () => getTranslation("kompetencePage_klarmeldingUpdated", "Klarmeling blev redigeret");
    public static kompetencePage_klarmeldingErrorUpdating = () => getTranslation("kompetencePage_klarmeldingErrorUpdating", "Fejl ved redigering af klarmelding");
    public static kompetencePage_klarmeldingtilBrugereIngenBrugereMedAdgang = () => getTranslation("kompetencePage_klarmeldingtilBrugereIngenBrugereMedAdgang", "Ingen har adgang til dit forløb i øjeblikket. Kontakt venligst dit videreuddannelsessekretariat.")

    public static kursusPage_updatingKurser = () => getTranslation("kursusPage_updatingKurser", "Opdaterer kurser");
    public static kursusPage_updateKurserBeskrivelse = () => getTranslation("kursusPage_updateKurserBeskrivelse", "Anvend denne funktionalitet efter publicering af ny kursusrække, for at få opdateret lægernes lægekurser.");
    public static kursusPage_getKurser = () => getTranslation("kursusPage_getKurser", "Henter kurser");
    public static kursusPage_visSlettedeKurser = () => getTranslation("kursusPage_visSlettedeKurser", "Vis slettede kurser ");
    public static kursusPage_slettedeKurser = () => getTranslation("kursusPage_slettedeKurser", "Slettede kurser ");
    public static kursusPage_gendan = () => getTranslation("kursusPage_gendan", "Gendan");
    public static kursusPage_gendannet = () => getTranslation("kursusPage_gendannet", "Kurset blev gendannet");
    public static kursusPage_fejlVedGendannelseafKurset = () => getTranslation("kursusPage_fejlVedGendannelseafKurset", "Der opstod en fejl ved gendannelse af kurset");
    public static kursusPage_udfyld = () => getTranslation("kursusPage_udfyld", "Udfyld");
    public static kursusPage_udfyldt = () => getTranslation("kursusPage_udfyldt", "Udfyldt");
    public static kursusPage_seFiler = () => getTranslation("kursusPage_seFiler", "Se filer");
    public static kursusPage_tilfoejFiler = () => getTranslation("kursusPage_tilfoejFiler", "Tilføj filer");
    public static kursusPage_manglerSamletGodkendelse = () => getTranslation("kursusPage_manglerSamletGodkendelse", "Mangler samlet godkendelse");
    public static kursusPage_tildelSamletGodkendelse = () => getTranslation("kursusPage_tildelSamletGodkendelse", "Tildel samlet godkendelse");
    public static kursusPage_samletGodkendelse = () => getTranslation("kursusPage_samletGodkendelse", "Samlet godkendelse");
    public static kursusPage_samletGodkendelser = () => getTranslation("kursusPage_samletGodkendelser", "Samlet godkendelser");
    public static kursusPage_seSamletGodkendelse = () => getTranslation("kursusPage_seSamletGodkendelse", "Se samlet godkendelse");
    public static kursusPage_samletGodkendelseTildelt = () => getTranslation("kursusPage_samletGodkendelseTildelt", "Samlet godkendelse tildelt");
    public static kursusPage_udfyldKursus = () => getTranslation("kursusPage_udfyldKursus", "Udfyld kursus");
    public static kursusPage_createKursus = () => getTranslation("kursusPage_createKursus", "Tilføj kursus");
    public static kursusPage_godkendKursus = () => getTranslation("kursusPage_godkendKursus", "Godkend kursus");
    public static kursusPage_kursusCreated = () => getTranslation("kursusPage_kursusCreated", "Kursus oprettet");
    public static kursusPage_laegekursusGruppeGodkendelse = () => getTranslation("kursusPage_laegekursusGruppeGodkendelse", "Godkendelse på andet grundlag gennemført");
    public static kursusPage_creatingKursus = () => getTranslation("kursusPage_creatingKursus", "Tilføjer kursus");
    public static kursusPage_tildelerSamletGodkendelse = () => getTranslation("kursusPage_tildelerSamletGodkendelse", "Tildeler samlet godkendelse");
    public static kursusPage_fjernerGodkendelse = () => getTranslation("kursusPage_fjernerGodkendelse", "Fjerner godkendelse");
    public static kursusPage_fjernGodkendelse = () => getTranslation("kursusPage_fjernGodkendelse", "Fjern godkendelse");
    public static kursusPage_godkendelseWarning = () => getTranslation("kursusPage_godkendelseWarning", "Det er ikke muligt at godkende kurser uden et authorisationsid");
    public static kursusPage_eventuelSpecifikationer = () => getTranslation("kursusPage_eventuelSpecifikationer", "Eventuelle specifikationer");
    public static kursusPage_ingenKurserOprettet = () => getTranslation("kursusPage_ingenKurserOprettet", "Ingen kurser oprettet");
    public static kursusPage_AlmenMedicinKurserBeskrivelseEt = () => getTranslation("kursusPage_AlmenMedicinKurserBeskrivelse", "I specialet almen medicin administreres de enkelte specialespecifikke kurser af hovedkursuslederen og dennes kursussekretær i din lokale videreuddannelsesregion.");
    public static kursusPage_AlmenMedicinKurserBeskrivelseTo = () => getTranslation("kursusPage_AlmenMedicinKurserBeskrivelse", "Yderligere information fås hos ");
    public static kursusPage_AlmenMedicinKurserBeskrivelseTre = () => getTranslation("kursusPage_AlmenMedicinKurserBeskrivelse", "Samlet godkendelse gives af hovedkursuslederen.");
    public static kursusPage_opretKurserVedAtKlikkePaaOpretKnappen = () => getTranslation("kursusPage_opretKurserVedAtKlikkePaaOpretKnappen", "Opret kurser på “tilføj” ikonet i øverst højre hjørne");
    public static kursusPage_udfyldLaegekursus = () => getTranslation("kursusPage_udfyldLaegekursus", "Udfyld lægekursus");
    public static kursusPage_kursusEdited = () => getTranslation("kursusPage_kursusEdited", "Kursus redigeret");
    public static kursusPage_kursusDeleted = () => getTranslation("kursusPage_kursusDeleted", "Kursus slettet");
    public static kursusPage_kursusGodkendt = () => getTranslation("kursusPage_kursusGodkendt", "Lægekursus godkendt");
    public static kursusPage_kursusSamletGodkendelseTitelTekst = (kursuskategori: string) => getTranslation("kursusPage_kursusEditTitelTekst", `Samlet godkendelse for ${kursuskategori}`);
    public static kursusPage_kursusEditTitelTekst = (handling: string, kursuskategori: string) => getTranslation("kursusPage_kursusSamletGodkendelseTitelTekst", `${handling} kursus for ${kursuskategori}`);
    public static kursusPage_laegekursusGodkendtAf = (godkendtDato: string, godkendtAfNavn: string, rolleTitel?: string, authorisationsId?: string) => getTranslation("kursusPage_laegekursusGodkendtAf", `Godkendt d. ${godkendtDato} af ${godkendtAfNavn}, ${authorisationsId} (${rolleTitel})`);
    public static kursusPage_samletGodkendelseGodkendtInfo = (kursuskategori: string) => getTranslation("kursusPage_samletGodkendelseGodkendtInfo", `Attestation for, at alle kurser for ${kursuskategori} er gennemført og godkendt`);
    public static kursusPage_samletGodkendelseGodkendtInfoForskningstraening = () => getTranslation("kursusPage_samletGodkendelseGodkendtInfoForskningstraening", `Ansvarlig forskningstræningsvejleders samlede godkendelse af forskningstræning`);
    public static kursusPage_areYourSureTildelSamletGodkendelse = (kursuskategori: string) => getTranslation("kursusPage_kursusEditTitelTekst", `Er du sikker på at du vil tildele den samlede godkendelse i ${kursuskategori}?`);
    public static kursusPage_areYourSureFjernGodkendelse = () => getTranslation("kursusPage_areYourSureFjernGodkendelse", `Er du sikker på at du vil fjerne godkendelsen?`);
    public static kursusPage_areYourSureFjernSamletGodkendelse = (kursuskategori: string) => getTranslation("kursusPage_areYourSureFjernSamletGodkendelse", `Er du sikker på at du vil fjerne den samlede godkendelse i ${kursuskategori}?`);
    public static kursusPage_FjernMeritBesked = () => getTranslation("kursusPage_FjernMeritBesked", "Da denne kursusgruppe er godkendt på andet grundlag, vil fjernelse af den samlede godkendelse resulterer i, at de kurser som blev godkendt i forbindelse med merit-givningen også får fjernet deres godkendelser.");
    public static kursusPage_kursusGruppeHarSamletGodkendelse = () => getTranslation("kursusPage_kursusGruppeHarSamletGodkendelse", "Kursusgruppen er godkendt samlet");
    public static kursusPage_godkendPaaAndetGrundlag = () => getTranslation("kursusPage_godkendPaaAndetGrundlag", "Godkend på et andet grundlag");
    public static kursusPage_godkendtPaaAndetGrundlag = () => getTranslation("kursusPage_godkendtPaaAndetGrundlag", "Godkendt på et andet grundlag");
    public static kursusPage_godkendelseFjernet = () => getTranslation("kursusPage_godkendelsefFjernet", "Godkendelse fjernet");
    public static kursusPage_samletGodkendelsePaaAndetGrundlag = () => getTranslation("kursusPage_samletGodkendelsePaaAndetGrundlag", "Samlet godkendelse på et andet grundlag");
    public static kursusPage_denneGruppeErGodkendtPaaAndetGrundlag = () => getTranslation("kursusPage_denneGruppeErGodkendtPaaAndetGrundlag", "\nDenne samlet godkendelse er godkendt på et andet grundlag");
    public static kursusPage_kommentarTilGodkendelse = () => getTranslation("kursusPage_kommentarTilGodkendelse", "Kommentaren herunder gemmes kun i forbindelse med godkendelse. Dette er en kommentar til godkendelsen af kurset og ikke til selve kurset. ");
    public static kursusPage_laegekursusAreYouSureDelete = (kursusnavn: string) => getTranslation("kursusPage_laegekursusAreYouSureDelete", `Er du sikker på, at du vil slette kurset "${kursusnavn}"?`);
    public static kursusPage_laegekursusBeskedOmDobbeltKurser = () => getTranslation("kursusPage_laegekursusBeskedOmDobbeltKurser", `"OBS! Hvis nogle kurser er registreret mere end én gang på denne side, er der mulighed for at slette de duplikerede kurser ved at klikke på knappen ’Udfyld’ og herefter vælge ’Slet’. Der kan være enkelte tilfælde, hvor du ikke selv kan slette et registreret kursus. Hvis du oplever dette, skal du sende en mail til dit lokale videreuddannelsessekretariat."`);
    public static kursusPage_laegekursusSamletGodkendelseHovedkursusleder = () => getTranslation("kursusPage_laegekursusSamletGodkendelseHovedkursusleder", "Den samlede godkendelse kan kun gives af specialets hovedkursusleder.");
    public static forloebOverviewPage_skiftVisning = () => getTranslation("forloebOverviewPage_skiftVisning", "Skift visning");
    public static forloebOverviewPage_getForloeb = () => getTranslation("forloebOverviewPage_getForloeb", "Henter forløb");
    public static forloebOverviewPage_getFilteredCount = (filteredRecords?: number, recordsTotal?: number) => getTranslation("forloebOverviewPage_getFilteredCount", `Filtrerede ${filteredRecords ?? "-"} ud af ${recordsTotal ?? "-"}`);
    public static forloebOverviewPage_getStillinger = () => getTranslation("forloebOverviewPage_getForloeb", "Henter stillinger");
    public static forloebOverviewPage_omraade = () => getTranslation("forloebOverviewPage_omraade", "Område");
    public static forloebOverviewPage_chooseOmraade = () => getTranslation("forloebOverviewPage_chooseOmraade", "Vælg område");
    public static forloebOverviewPage_region = () => getTranslation("forloebOverviewPage_omraade", "Region");
    public static forloebOverviewPage_chooseRegion = () => getTranslation("forloebOverviewPage_chooseRegion", "Vælg region");
    public static forloebOverviewPage_chooseRegions = () => getTranslation("forloebOverviewPage_chooseRegions", "Vælg regioner");
    public static forloebOverviewPage_chooseHospitals = () => getTranslation("forloebOverviewPage_chooseHospitals", "Vælg hospitalsenheder");
    public static forloebOverviewPage_filter = () => getTranslation("forloebOverviewPage_filter", "Filter");
    public static forloebOverviewPage_chooseFilter = () => getTranslation("forloebOverviewPage_chooseFilter", "Vælg filter");
    public static forloebOverviewPage_speciale = () => getTranslation("forloebOverviewPage_speciale", "Speciale");
    public static forloebOverviewPage_chooseSpeciale = () => getTranslation("forloebOverviewPage_chooseSpeciale", "Vælg speciale");
    public static forloebOverviewPage_uddannelsetype = () => getTranslation("forloebOverviewPage_uddannelsetype", "Uddannelsestype");
    public static forloebOverviewPage_chooseUddannelsetype = () => getTranslation("forloebOverviewPage_chooseUddannelsetype", "Vælg uddannelsestype");
    public static forloebOverviewPage_sygehus = () => getTranslation("forloebOverviewPage_sygehus", "Sygehus");
    public static forloebOverviewPage_chooseSygehus = () => getTranslation("forloebOverviewPage_chooseSygehus", "Vælg sygehus");
    public static forloebOverviewPage_praksis = () => getTranslation("forloebOverviewPage_praksis", "Praksis");
    public static forloebOverviewPage_choosePraksis = () => getTranslation("forloebOverviewPage_choosePraksis", "Vælg praksis");
    public static forloebOverviewPage_afdeling = () => getTranslation("forloebOverviewPage_afdeling", "Afdeling");
    public static forloebOverview_overfoerData = () => getTranslation("forloebOverview_overfoerData", "Overfør data");
    public static forloebOverview_overfoerDataBeskrivelse = () => getTranslation("forloebOverview_overfoerDataBeskrivelse", "Denne side kan anvendes af administratorer og videreuddannelsessekretariater til at overføre data fra et deaktiveret forløb til et nyt synkroniseret forløb, som er blevet overført til uddannelseslæge fra videreuddannelsen.");
    public static forloebOverview_overfoerDataBeskrivelseTillaegsaftale = () => getTranslation("forloebOverview_overfoerDataBeskrivelseTillaegsaftale", "Nye forløb oprettes i forbindelse med, at der uploades en ny tillægsaftale i videreuddannelsen. Her vil kun kompetencer og kurser overføres fra de deaktiverede forløb til de nye, hvorfor der manuelt skal flyttes resterende data.");
    public static forloebOverview_overfoerDataFraDeaktiveretForloeb = () => getTranslation("forloebOverview_overfoerDataFraDeaktiveretForloeb", "Overfør data fra deaktiveret forløb: ");
    public static forloebOverviewPage_synkroniseredeForloeb = () => getTranslation("forloebOverviewPage_synkroniseredeForloeb", "Synkroniserede forløb fra videreuddannelsen");
    public static forloebOverviewPage_horisontaleLinjer = () => getTranslation("forloebOverviewPage_horisontaleLinjer", "Horisontale linjer");
    public static forloebOverviewPage_visHorisontaleLinjer = () => getTranslation("forloebOverviewPage_visHorisontaleLinjer", "Vis horisontale linjer");
    public static forloebOverviewPage_udvidOverlappende = () => getTranslation("forloebOverviewPage_udvidOverlappende", "Udvid overlap");
    public static forloebOverviewPage_chooseAfdeling = () => getTranslation("forloebOverviewPage_chooseAfdeling", "Vælg afdeling");
    public static forloebOverviewPage_startAar = () => getTranslation("forloebOverviewPage_startAar", "Start år");
    public static forloebOverviewPage_chooseStartAar = () => getTranslation("forloebOverviewPage_chooseStartAar", "Vælg start år");
    public static forloebOverviewPage_fase = () => getTranslation("forloebOverviewPage_fase", "Fase");
    public static forloebOverviewPage_filterApplied = () => getTranslation("forloebOverviewPage_filterApplied", "Filtrering anvendes");
    public static forloebOverviewPage_filterDeleted = () => getTranslation("forloebOverviewPage_filterDeleted", "Filter slettet");
    public static forloebOverviewPage_anvendFilter = () => getTranslation("forloebOverviewPage_anvendFilter", "Anvend filter");
    public static forloebOverviewPage_redigerFilter = () => getTranslation("forloebOverviewPage_redigerFilter", "Rediger filter");
    public static forloebOverviewPage_mineFiltre = () => getTranslation("forloebOverviewPage_mineFiltre", "Mine filtre");
    public static forloebOverviewPage_faellesFiltre = () => getTranslation("forloebOverviewPage_faellesFiltre", "Fælles filtre");
    public static forloebOverviewPage_gemFilter = () => getTranslation("forloebOverviewPage_gemFilter", "Gem filter");
    public static forloebOverviewPage_alleFiltreOprettesPrivat = () => getTranslation("forloebOverviewPage_alleFiltreOprettesPrivat", "Alle filtre oprettes privat");
    public static forloebOverviewPage_opretFilter = () => getTranslation("forloebOverviewPage_opretFilter", "Opret filter");
    public static forloebOverviewPage_deletedFilter = () => getTranslation("forloebOverviewPage_deletedFilter", "Filter slettet");
    public static forloebOverviewPage_edittedFilter = () => getTranslation("forloebOverviewPage_edittedFilter", "Filter redigeret");
    public static forloebOverviewPage_createdForloeb = () => getTranslation("forloebOverviewPage_createdForloeb", "Forløb oprettet");
    public static forloebOverviewPage_laegekurserOpdateret = () => getTranslation("forloebOverviewPage_laegekurserOpdateret", "Lægekurser blev opdateret på forløb");
    public static forloebOverviewPage_ingenLaegekurserOpdateret = () => getTranslation("forloebOverviewPage_ingenLaegekurserOpdateret", "Der var ingen lægekurser, som skulle opdateres");
    public static forloebOverviewPage_erDuSikkerManueltAfslut = () => getTranslation("forloebOverviewPage_erDuSikkerManueltAfslut", "Du er ved at afslutte forløbet, hvilket resulterer i at samtlige forløb på samme målbeskrivelse vil blive afslutet. Alle disse forløb vil fremadrettet ikke være tilgængelige i systemet");
    public static forloebOverviewPage_denneHandlingKanIkkeFortrydes = () => getTranslation("forloebOverviewPage_denneHandlingKanIkkeFortrydes", "Denne handling kan ikke fortrydes!");
    public static forloebOverviewPage_evalueretDato = () => getTranslation("forloebOverviewPage_evalueretDato", "Evalueret d. ");
    public static forloebOverviewPage_godkendtDato = () => getTranslation("forloebOverviewPage_godkendtDato", "Godkendt d. ");
    public static forloebOverviewPage_sletForloebEvaluering = () => getTranslation("forloebOverviewPage_sletForloebEvaluering", "Slet forløbsevaluering");
    public static forloebOverviewPage_areYouSureSletForloebEvaluering = () => getTranslation("forloebOverviewPage_areYouSureFjernForloebEvaluering", "Da forløbet står til at skulle evalueres, er der en evalueringskladde, som kan være delvist udfyldt. Tjek eventuelt evalueringskladden, inden du går videre. Er du sikker på, at du vil skiftes status til 'skal ikke evalueres'?");

    public static uddannelseOverview_quickguideLinkTooltip = () => getTranslation("uddannelseOverview_quickguideLinkTooltip", "Link til quickguide");
    public static uddannelseOverview_ansoegOmSelvstaendigtVirke = () => getTranslation("uddannelseOverview_ansoegOmSelvstaendigtVirke", "Ansøg om selvstændigt virke");
    public static uddannelseOverview_forberedDokumentationTilSTPS = () => getTranslation("uddannelseOverview_forberedDokumentationTilSTPS", "Forbered dokumentation til STPS");
    public static uddannelseOverview_ansaettelseOgUddannelsesaftaler = () => getTranslation("uddannelseOverview_ansaettelseOgUddannelsesaftaler", "Ansættelses- og uddannelsesaftaler");
    public static uddannelseOverview_documentationSentToSTPS = () => getTranslation("uddannelseOverview_documentationSentToSTPS", "Dokumentation sendt til STPS");
    public static uddannelseOverview_documentationSentLaterToSTPS = () => getTranslation("uddannelseOverview_documentationSentLaterToSTPS", "Dokumentation eftersendt til STPS");

    public static ansoegOmSelvstaendigVirke_laegeligBeskaeftigelse = () => getTranslation("ansoegOmSelvstaendigVirke_laegeligBeskaeftigelse", "Lægelig beskæftigelse");
    public static ansoegOmSelvstaendigVirke_ansaettelseOgUddannelsesaftaler = () => getTranslation("ansoegOmSelvstaendigVirke_ansaettelseOgUddannelsesaftaler", "Ansættelses- og uddannelsesaftaler");
    public static ansoegOmSelvstaendigVirke_dokumentationTilSTPS = () => getTranslation("ansoegOmSelvstaendigVirke_dokumentationTilSTPS", "Dokumentation til STPS");
    public static ansoegOmSelvstaendigVirke_afsendelseAfDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_afsendelseAfDokumentation", "Afsendelse af dokumentation");
    public static ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktEt = () => getTranslation("ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktEt", "Her skal du vedhæfte din originale Ansættelses - og uddannelsesaftale og alle eventuelle tillægsaftaler i dit uddannelsesforløb. Du skal have modtaget en tillægsaftale, hvis du f.eks. har holdt barsel eller anden orlov, eller hvis der sket andre ændringer i ansættelsessted.");
    public static ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktTo = () => getTranslation("ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktTo", "Såfremt du har været ansat i almen praksis i din KBU, skal du huske at vedlægge en 'Ansættelses og uddannelsesaftale', hvoraf navnet på den tildelte praksis fremgår");
    public static ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktTre = () => getTranslation("ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktTre", "Hvis du har en anden form for dokumentation for dit uddannelsesforløb f.eks. et brev fra dit videreuddannelsessekretariat, vedhæftes dette i stedet for en 'Ansættelses - og uddannelsesaftale");
    public static ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktFire = () => getTranslation("ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktFire", "Du har mulighed for at vedhæfte flere filer.");

    public static ansoegOmSpeciallaegeanerkendelse_uddannelsesaftaleBeskrivelse = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_uddannelsesaftaleBeskrivelse", "Her skal du vedhæfte din Ansættelses- og Uddannelsesaftale. Du kan altid finde din sidst reviderede aftale på www.videreuddannelsen.dk.");
    public static ansoegOmSpeciallaegeanerkendelse_uddannelsesaftaleAfslutning = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_uddannelsesaftaleAfslutning", "Du har mulighed for at vedhæfte flere filer.");
    public static ansoegOmSpeciallaegeanerkendelse_uddannelsesaftalePunktEt = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_uddannelsesaftalePunktEt", "Hvis du har fået merit for delelementer i din uddannelse, skal du også vedhæfte dit meritbrev");
    public static ansoegOmSpeciallaegeanerkendelse_uddannelsesaftalePunktTo = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_uddannelsesaftalePunktTo", "Hvis du har en anden form for dokumentation for dit uddannelsesforløb f.eks. et brev fra dit videreuddannelsessekretariat, vedhæftes dette");

    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseOverskrift = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseOverskrift", "Ved udfyldelse, bør du være opmærksom på følgende:");
    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktNul = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktNul", "Angiv ansættelser, som indgår i den kliniske basisuddannelse i kronologisk rækkefølge:");
    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktNul = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktNul", "For perioder med deltidsansættelse angives ugentligt timetal. Ved varierende ugentligt timetal i et uddannelseselement, skal du dele den samlede periode op, ved at indsætte en linje pr. ugentligt timetal.");
    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktEt = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktEt", "Fraværsperioder (fx orlov og længere sygeperioder) under den kliniske basisuddannelse skal angives. Hvis der ikke er tale om en sammenhængende fraværsperiode påføres antallet af fraværsdage i den konkrete ansættelse.");
    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktTo = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktTo", "For praksisreservelægestillingen skal tutorlægens navn, praksisnavn og praksisadresse angives.");
    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktTre = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelseUnderpunktTre", "Stillingsnummer for hver ansættelse skal angives (Stillingsnumre findes i dine senest opdaterede ansættelsespapirer). Der skal ikke angives stillingsnummer ved fraværsperioder.");
    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktEt = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktEt", "Med indsendelse af oversigten bekræfter du, at du ikke har haft sygefravær mv., der overstiger 10 % af ansættelsestiden i det enkelte uddannelseselement.");
    public static ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktTo = () => getTranslation("ansoegOmSelvstaendigVirke_beskaefigelseBeskrivelsePunktTo", "Ifølge straffelovens § 163 er det strafbart i retsforhold, der vedkommer det offentlige, skriftligt at afgive urigtig erklæring.");

    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseOverskrift = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseOverskrift", "Ved udfyldelse, bør du være opmærksom på følgende: ");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktNul = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktNul", "Angiv ansættelser, som indgår i speciallægeuddannelsen i kronologisk rækkefølge:");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktNul = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktNul", "Angiv den introduktionsstilling, som indgår i din speciallægeuddannelse.");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktEt = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktEt", "For perioder med deltidsansættelse angives ugentligt timetal. Ved varierende ugentligt timetal i et uddannelseselement, skal du dele den samlede periode op, ved at indsætte en linje pr. ugentligt timetal.");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktTo = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktTo", "Fraværsperioder (fx fravær pga. merit, orlov og længere sygeperioder) under hoveduddannelsen skal angives. Hvis der ikke er tale om en sammenhængende fraværsperiode påføres antallet af fraværsdage i den konkrete ansættelse.");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktTre = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktTre", "Stillingsnummer for hver ansættelse skal angives (Stillingsnumre findes i dine senest opdaterede ansættelsespapirer). Der skal ikke angives stillingsnummer ved fraværsperioder.");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktFire = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelseUnderpunktFire", "Angiv meritoverførte stillinger, fx tidligere introduktionsstillinger dvs. uddannelsesstillinger, som har medført merit for elementer under din hoveduddannelse (fx introduktionsstilling i et andet speciale eller uddannelseselementer fra en anden speciallægeuddannelse).");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktEt = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktEt", "Med indsendelse af oversigten bekræfter du, at du ikke har haft sygefravær mv., der overstiger 10 % af ansættelsestiden i introduktions- eller hoveduddannelsen.");
    public static ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktTo = () => getTranslation("ansoegOmSpeciallaegeanerkendelse_beskaefigelseBeskrivelsePunktTo", "Ifølge straffelovens § 163 er det strafbart i retsforhold, der vedkommer det offentlige, skriftligt at afgive urigtig erklæring.");

    public static ansoegOmSelvstaendigVirke_dineAnsaettelser = () => getTranslation("ansoegOmSelvstaendigVirke_dineAnsaettelser", "Dine ansættelser:");

    public static ansoegOmSelvstaendigVirke_afsendelseBeskrivelseOverskrift = () => getTranslation("ansoegOmSelvstaendigVirke_afsendelseBeskrivelseOverskrift", "Før du sender din dokumentation, bør du være opmærksom på følgende:");
    public static ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktEtKBU = () => getTranslation("ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktEtKBU", "Husk at udfylde selve ansøgningen om selvstændigt virke og betale gebyr på Styrelsen for Patientsikkerhed's hjemmeside");
    public static ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktEtHU = () => getTranslation("ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktEtHU", "Husk at udfylde selve ansøgningen om speciallægeanerkendelse og betale gebyr på Styrelsen for Patientsikkerhed's hjemmeside");
    public static ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktTo = () => getTranslation("ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktTo", "Husk eventuelle attestationer for tid, som du har fået på papir");
    public static ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktTre = () => getTranslation("ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktTre", "Hvis kompetencer er godkendt på baggrund af tidligere godkendte papirlogbøger, så skal de relevante sider fra papirlogbogen tilføjes som fil.");
    public static ansoegOmSelvstaendigVirke_seTidligereAfsendtDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_seTidligereAfsendtDokumentation", "Se tidligere afsendt dokumentation til STPS");
    public static ansoegOmSelvstaendigVirke_tidligereAfsendtDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_tidligereAfsendtDokumentation", "Tidligere afsendt dokumentation til STPS");
    public static ansoegOmSelvstaendigVirke_klargoerDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_klargoerDokumentation", "Klargør dokumentation");
    public static ansoegOmSelvstaendigVirke_obligatoriskDokumentationErPaakraevetVedFoersteAfsendelse = () => getTranslation("ansoegOmSelvstaendigVirke_obligatoriskDokumentationErPaakraevetVedFoersteAfsendelse", "Obligatorisk dokumentation filer er påkrævet ved afsendelse");
    public static ansoegOmSelvstaendigVirke_obligatoriskDokumentationErValgfritVedEftersendelse = () => getTranslation("ansoegOmSelvstaendigVirke_obligatoriskDokumentationErValgfritVedEftersendelse", "Obligatorisk dokumentation filer er ikke påkrævet ved eftersendelse");

    public static ansoegOmSelvstaendigVirke_overblikOverskrift = () => getTranslation("ansoegOmSelvstaendigVirke_overblikOverskrift", "Overblik over dokumentation");
    public static ansoegOmSelvstaendigVirke_overblikPunktEt = () => getTranslation("ansoegOmSelvstaendigVirke_overblikPunktEt", "Som udgangspunkt skal alle dokumenter på foregående side være valgt, når du sender dokumentation til Styrelsen for Patientsikkerhed.");
    public static ansoegOmSelvstaendigVirke_overblikPunktTo = () => getTranslation("ansoegOmSelvstaendigVirke_overblikPunktTo", "I nogle tilfælde er der behov for at eftersende dokumentation. I de tilfælde skal det kun være de udvalgte relevante dokumenter, du skal sende.");
    public static ansoegOmSelvstaendigVirke_overblikPunktTre = () => getTranslation("ansoegOmSelvstaendigVirke_overblikPunktTre", "Nedenfor er en oversigt over de filer du har valgt, og som sendes til Styrelsen for Patientsikkerhed ved at trykke på knappen 'Afsend dokumentation' i bunden af siden.");
    public static ansoegOmSelvstaendigVirke_overblikPunktFire = () => getTranslation("ansoegOmSelvstaendigVirke_overblikPunktFire", "Du vil i løbet af 5 arbejdsdage modtage en kvittering i e-Boks fra Styrelsen for Patientsikkerhed på, at de har modtaget din dokumentation.");

    public static ansoegOmSelvstaendigVirke_ingenObligatoriskDokumentationValgt = () => getTranslation("ansoegOmSelvstaendigVirke_ingenObligatoriskDokumentationValgt", "Ingen obligatorisk dokumentation valgt");
    public static ansoegOmSelvstaendigVirke_ingenValgfriDokumentationValgt = () => getTranslation("ansoegOmSelvstaendigVirke_ingenValgfriDokumentationValgt", "Ingen valgfri dokumentation valgt");

    public static ansoegOmSelvstaendigVirke_beskaeftigelse = () => getTranslation("ansoegOmSelvstaendigVirke_beskaeftigelse", "Beskæftigelse");
    public static ansoegOmSelvstaendigVirke_beskaeftigelseSlettet = () => getTranslation("ansoegOmSelvstaendigVirke_beskaeftigelseSlettet", "Beskæftigelse slettet");
    public static ansoegOmSelvstaendigVirke_uddannelsesaftaler = () => getTranslation("ansoegOmSelvstaendigVirke_uddannelsesaftaler", "Uddannelsesaftaler");
    public static ansoegOmSelvstaendigVirke_afsendelse = () => getTranslation("ansoegOmSelvstaendigVirke_afsendelse", "Afsendelse");
    public static ansoegOmSelvstaendigVirke_afsendDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_afsendDokumentation", "Afsend dokumentation");
    public static ansoegOmSelvstaendigVirke_areYourSureAfsendDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_areYourSureAfsendDokumentation", `Er du sikker på at du vil afsende den valgte dokumentation til STPS?`)
    public static ansoegOmSelvstaendigVirke_dokumentationAfsendtKBU = () => getTranslation("ansoegOmSelvstaendigVirke_dokumentationAfsendtKBU", "Dokumentation til ansøgning om selvstændigt virke er sat til afsendelse afsendt");
    public static ansoegOmSelvstaendigVirke_dokumentationAfsendtHU = () => getTranslation("ansoegOmSelvstaendigVirke_dokumentationAfsendtHU", "Dokumentation til ansøgning om speciallægeanerkendelse er afsendt");
    public static ansoegOmSelvstaendigVirke_invalidUddannelseType = () => getTranslation("ansoegOmSelvstaendigVirke_invalidUddannelseType", "Uddannelsestype er ikke understøttet");
    public static ansoegOmSelvstaendigVirke_egenDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_egenDokumentation", "Tilføj egen dokumentation");

    public static ansoegOmSelvstaendigVirke_eftersendAddDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_eftersendAddDokumentation", "Tilføj dokumentation til eftersendelse");
    public static ansoegOmSelvstaendigVirke_eftersendDokumentation = () => getTranslation("ansoegOmSelvstaendigVirke_eftersendDokumentation", "Eftersend dokumentation");

    public static ansoegOmSelvstaendigVirke_dokumentationKlargoeres = () => getTranslation("ansoegOmSelvstaendigVirke_dokumentationKlargoeres", "Dokumentation til STPS klargøres");
    public static ansoegOmSelvstaendigVirke_ansoegningAfventerAfsendelse = () => getTranslation("ansoegOmSelvstaendigVirke_ansoegningAfventerAfsendelse", "Ansøgningen til STPS afventer afsendelse, og du vil modtage en bekræftelsesmail, når ansøgningen er afsendt.");
    public static ansoegOmSelvstaendigVirke_ansoegningAfsendt = () => getTranslation("ansoegOmSelvstaendigVirke_ansoegningAfsendt", "Ansøgning afsendt til STPS");

    public static ansoegOmSelvstaendigVirke_AlleKompetencerGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_AlleKompetencerGodkendt", "Alle kompetencer er godkendt");
    public static ansoegOmSelvstaendigVirke_ForskningstraeningGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_ForskningstraeningGodkendt", "Forskningstræning kurser godkendt");
    public static ansoegOmSelvstaendigVirke_GenerelleObilgatoriskeKurserGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_GenerelleObilgatoriskeKurserGodkendt", "Generelle obligatoriske kurser i hoveduddannelsen godkendt");
    public static ansoegOmSelvstaendigVirke_SpecialespecifikkeKurserGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_SpecialespecifikkeKurserGodkendt", "Specialespecifikke kurser i hoveduddannelsen godkendt");
    public static ansoegOmSelvstaendigVirke_FaellesInternMedicinKurserGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_FaellesInternMedicinKurserGodkendt", "Fælles intern medicin kurser godkendt");
    public static ansoegOmSelvstaendigVirke_GrundkursusIPsykoterapi = () => getTranslation("ansoegOmSelvstaendigVirke_GrundkursusIPsykoterapi", "Grundkursus i psykoterapi");
    public static ansoegOmSelvstaendigVirke_IntroduktionsuddannelseKurserGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_IntroduktionsuddannelseKurserGodkendt", "Generelle obligatoriske kurser i introduktionsuddannelse er godkendt");
    public static ansoegOmSelvstaendigVirke_KliniskBasisKurserGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_KliniskBasisKurserGodkendt", "Klinisk basisuddannelse kurser godkendt");
    public static ansoegOmSelvstaendigVirke_SpecialespecifikkeIntroKurserGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_SpecialespecifikkeIntroKurserGodkendt", "Specialespecifikke kurser i introuddannelsen kurser godkendt");
    public static ansoegOmSelvstaendigVirke_AttestationForTidsmaessigtGennemfoertUddannelse = () => getTranslation("ansoegOmSelvstaendigVirke_AttestationForTidsmaessigtGennemfoertUddannelse", "Attestation for tidsmæssigt gennemført uddannelse");
    public static ansoegOmSelvstaendigVirke_AnsaettelseOgUddannelsesaftaleUploaded = () => getTranslation("ansoegOmSelvstaendigVirke_AnsaettelseOgUddannelsesaftaleUploaded", "Ansættelses og Uddannelsesaftale uploadet");
    public static ansoegOmSelvstaendigVirke_SkemaOverLaegeligBeskaeftigelseUdfyldt = () => getTranslation("ansoegOmSelvstaendigVirke_SkemaOverLaegeligBeskaeftigelseUdfyldt", "Skema over lægelig beskæftigelse udfyldt");
    public static ansoegOmSelvstaendigVirke_AlmenMedicinKurserGodkendt = () => getTranslation("ansoegOmSelvstaendigVirke_AlmenMedicinKurserGodkendt", "Almen medicin kurser godkendt");

    //KursusGruppeTyper
    public static kursusGruppeType_Hoveduddannelse = () => getTranslation("kursusGruppeType_Hoveduddannelse", "Hoveduddannelse");
    public static kursusGruppeType_Forskningstraening = () => getTranslation("kursusGruppeType_Forskningstraening", "Forskningstræning");
    public static kursusGruppeType_FaellesInternMedicin = () => getTranslation("kursusGruppeType_FaellesInternMedicin", "Fælles intern medicin");
    public static kursusGruppeType_Specialespecifikke = () => getTranslation("kursusGruppeType_Specialespecifikke", "Specialespecifikke");
    public static kursusGruppeType_GrundkursusIPsykoterapi = () => getTranslation("kursusGruppeType_GrundkursusIPsykoterapi", "Grundkursus i Psykoterapi");
    public static kursusGruppeType_Introduktionsuddannelsen = () => getTranslation("kursusGruppeType_Introduktionsuddannelsen", "Introduktionsuddannelsen");
    public static kursusGruppeType_KliniskBasisuddannelse = () => getTranslation("kursusGruppeType_KliniskBasisuddannelse", "Klinisk basisuddannelse");
    public static kursusGruppeType_SpecialespecifikkeIntrouddannelsen = () => getTranslation("kursusGruppeType_SpecialespecifikkeIntrouddannelsen", "Specialespecifik introuddannelse");
    public static kursusGruppeType_AlmenMedicin = () => getTranslation("kursusGruppeType_AlmenMedicin", "Almen medicin");

    public static beskaeftigelse_stillingsbetegnelse = () => getTranslation("beskaeftigelse_stillingsbetegnelse", "Stillingsbetegnelse");
    public static beskaeftigelse_angivStillingsbetegnelse = () => getTranslation("beskaeftigelse_angivStillingsbetegnelse", "Indtast stillingsbetegnelse");
    public static beskaeftigelse_angivStillingsnummer = () => getTranslation("beskaeftigelse_angivStillingsnummer", "Indtast stillingsnummer");
    public static beskaeftigelse_ansaettelsessted = () => getTranslation("beskaeftigelse_ansaettelsessted", "Ansættelsessted");
    public static beskaeftigelse_angivAnsaettelsessted = () => getTranslation("beskaeftigelse_angivAnsaettelsessted", "Indtast ansættelsessted");

    public static beskaeftigelse_startdato = () => getTranslation("beskaeftigelse_startdato", "Startdato");
    public static beskaeftigelse_slutdato = () => getTranslation("beskaeftigelse_slutdato", "Slutdato");
    public static beskaeftigelse_maaneder = () => getTranslation("beskaeftigelse_maaneder", "Måneder");
    public static beskaeftigelse_maaned = () => getTranslation("beskaeftigelse_maaned", "Måned");
    public static beskaeftigelse_angivMaaneder = () => getTranslation("beskaeftigelse_angivMaaneder", "Indtast antal måneder");
    public static beskaeftigelse_ugentligtTimetal = () => getTranslation("beskaeftigelse_ugentligtTimetal", "Ugentligt timetal");
    public static beskaeftigelse_NedsatTid = () => getTranslation("beskaeftigelse_NedsatTid", "Nedsat tid");
    public static beskaeftigelse_Timer = () => getTranslation("beskaeftigelse_Timer", "Timer");
    public static beskaeftigelse_AngivUgentligtTimetal = () => getTranslation("beskaeftigelse_AngivUgentligtTimetal", "Angiv ugentlig timetal");

    public static beskaeftigelsePdf_skemaTilOversigt = () => getTranslation("beskaeftigelsePdf_skemaTilOversigt", "Skema til oversigt over lægelig beskæftigelse efter 2003 bestemmelserne");
    public static beskaeftigelsePdf_skemaOgMedsend = () => getTranslation("beskaeftigelsePdf_skemaOgMedsend", "Udfyld og medsend nedenstående skema sammen med den krævede dokumentation til Styrelsen for Patientsikkerhed, Islands Brygge 67, 2300 København S.");
    public static beskaeftigelsePdf_skemaUdfylesFoelgende = () => getTranslation("beskaeftigelsePdf_skemaUdfylesFoelgende", "Skemaet udfyldes som følgende");
    public static beskaeftigelsePdf_kronoliskOpstilling = () => getTranslation("beskaeftigelsePdf_kronoliskOpstilling", "Kronologisk opstilling af alle ansættelser fra første introduktionsforløb til gennemført hoveduddannelse");
    public static beskaeftigelsePdf_ansaettelserIndgaar = () => getTranslation("beskaeftigelsePdf_ansaettelserIndgaar", "For ansættelser, der indgår som led i introduktions- eller hoveduddannelsen, anføres");
    public static beskaeftigelsePdf_periodeDeltidsAnsaettelse = () => getTranslation("beskaeftigelsePdf_periodeDeltidsAnsaettelse", "Perioder med deltidsansættelse (med anførsel af ugentligt timetal)");
    public static beskaeftigelsePdf_periodeOrlov = () => getTranslation("beskaeftigelsePdf_periodeOrlov", "Perioder med orlov, længere sygeperioder og graviditetsorlov");
    public static beskaeftigelsePdf_meritStillinger = () => getTranslation("beskaeftigelsePdf_meritStillinger", "Evt. meritoverførte stillinger");
    public static beskaeftigelsePdf_korrektStillingsbetegenelse = () => getTranslation("beskaeftigelsePdf_korrektStillingsbetegenelse", "Anfør korrekt stillingsbetegnelse");
    public static beskaeftigelsePdf_anfoerStillingsNummber = () => getTranslation("beskaeftigelsePdf_anfoerStillingsNummber", "Anfør stillingsnummer for ansættelser gennemført efter 1996-bestemmelserne (inden tiltrædelse i reduceret hoveduddannelsesforløb efter 2003-bestemmelserne)");
    public static beskaeftigelsePdf_sygeFravaer = () => getTranslation("beskaeftigelsePdf_sygeFravaer", "Med indsendelsen af oversigten bekræfter du, at du ikke har haft sygefravær mv. der overstiger 10 % af ansættelsestiden i det enkelte uddannelseselement i introduktions- eller hoveduddannelsen.");
    public static beskaeftigelsePdf_straffeloven = () => getTranslation("beskaeftigelsePdf_straffeloven", "Ifølge straffelovens § 163 er det strafbart i retsforhold, der vedkommer det offentlige, skriftligt at afgive urigtig erklæring.");

    public static attestationPdf_attestationForTid = () => getTranslation("attestationPdf_attestationForTid", "Attestation for tidsmæssigt gennemført forløb");
    public static uddannelsesplanPdf_uddannelsesplan = () => getTranslation("uddannelsesplanPdf_uddannelsesplan", "Uddannelsesplan");
    public static kompetencePdf_Godkendt = (date : string, by : string) => getTranslation("kompetencePdf_Godkendt", `Denne kompetence er godkendt ${date} af ${by}`);

    public static evalueringPdf_Kommentar = () => getTranslation("evalueringPdf_Kommentar", "Evt. kommentar til ovenstående spørgsmål");

    public static notatPage_notatCreated = () => getTranslation("notatPage_notatCreated", "Notat oprettet");
    public static notatPage_notatEditted = () => getTranslation("notatPage_notatEditted", "Notat redigeret");
    public static notatPage_notatCreateFailed = () => getTranslation("notatPage_notatCreateFailed", "Notat opret fejlede");
    public static notatPage_notatEditFailed = () => getTranslation("notatPage_notatEditFailed", "Rediger notat fejlede");
    public static notatPage_createNotat = () => getTranslation("notatPage_createNotat", "Opret notat");
    public static notatPage_editNotat = () => getTranslation("notatPage_editNotat", "Rediger notat");
    public static notatPage_editingNotat = () => getTranslation("notatPage_editingNotat", "Redigerer notat");
    public static notatPage_creatingNotat = () => getTranslation("notatPage_creatingNotat", "Opretter notat");
    public static notatPage_privateLabel = () => getTranslation("notatPage_privateLabel", "Privat (kun synlig for mig)");
    public static notatPage_opretNotat = () => getTranslation("notatPage_opretNotat", "Opret notat");
    public static notatPage_opretUddannelsesprogramNotat = () => getTranslation("notatPage_opretUddannelsesprogramNotat", "Opret uddannelsesprogram notat");
    public static notatPage_gettingNotater = () => getTranslation("notatPage_getNotater", "Henter notater");
    public static notatPage_tekstNotater = () => getTranslation("notatPage_tekstNotat", "Tekstnotater");
    public static notatPage_tekstNotat = () => getTranslation("notatPage_tekstNotat", "Tekstnotat");
    public static notatPage_kompetenceNotater = () => getTranslation("notatPage_kompetenceNotat", "Kompetencenotater");
    public static notatPage_meritNotater = () => getTranslation("notatPage_meritNotater", "Meritnotater");
    public static notatPage_laegekursusNotater = () => getTranslation("notatPage_laegekursusNotater", "Lægekursus notater");
    public static notatPage_laegekursusGruppeNotater = () => getTranslation("notatPage_laegekursusGruppeNotater", "Lægekursusgruppe notater");
    public static notatPage_uddannelsesprogramNotater = () => getTranslation("notatPage_uddannelsesprogramNotater", "Uddannelsesprogram notater");
    public static notatPage_ansoegningNotater = () => getTranslation("notatPage_ansoegningNotater", "Ansøgningsnotater");
    public static notatPage_attestationForTidNotater = () => getTranslation("notatPage_attestationForTidNotater", "Attestation for tid notater");
    public static notatPage_vejledersamtaleNotater = () => getTranslation("notatPage_vejledersamtaleNotater", "Vejledersamtalenotater");
    public static notatPage_meritNotat = () => getTranslation("notatPage_meritNotat", "Meritnotat");
    public static notatPage_kursusNotat = () => getTranslation("notatPage_kursusNotat", "Kursusnotat");
    public static notatPage_uddannelsesplanNotater = () => getTranslation("notatPage_uddannelsesplanNotater", "Uddannelsesplannotater");
    public static notatPage_uddannelseNotater = () => getTranslation("notatPage_uddannelseNotater", "Ansættelses-/ uddannelsesaftalenotater");
    public static notatPage_kanSesAfAlle = () => getTranslation("notatPage_kanSesAfAlle", "Synlig for alle");
    public static notatPage_synligPrivate = () => getTranslation("notatPage_synligPrivate", "Synlig kun for mig (private)");
    public static notatPage_synligForMig = () => getTranslation("notatPage_synligForMig", "Synlig for mig");
    public static notatPage_synligForAlle = () => getTranslation("notatPage_synligForAlle", "Synlig for alle");
    public static notatPage_ansaettelseOgUddannelsesaftalerAltidSynligForAdmin = () => getTranslation("notatPage_ansaettelseOgUddannelsesaftalerAltidSynligForAdmin", "Ansættelses- og uddannelsesaftaler er altid synlig for administratorer og videreuddannelsessekretariaterne");
    public static notatPage_uddannelsesprogrammerAltidSynligForAdmin = () => getTranslation("notatPage_uddannelsesprogrammerAltidSynligForAdmin", "Uddannelsesprogrammer er altid synlig for administratorer og videreuddannelsessekretariaterne");
    public static notatPage_synligForUdvalgte = () => getTranslation("notatPage_synligForUdvalgte", "Synlig for udvalgte");
    public static notatPage_specifikkeBrugere = () => getTranslation("notatPage_specifikkeBrugere", "Specifikke brugere");
    public static notatPage_privat = () => getTranslation("notatPage_privat", "Privat");
    public static notatPage_private = () => getTranslation("notatPage_private", "Private");
    public static notatPage_fileDeleted = () => getTranslation("notatPage_fileDeleted", "Fil slettet");
    public static notatPage_fileUploaded = () => getTranslation("notatPage_fileUploaded", "Fil uploaded");
    public static notatPage_ansaettelseOgUddannelsesaftale = () => getTranslation("notatPage_ansaettelseOgUddannelsesaftale", "Ansættelses-/uddannelsesaftale");
    public static notatPage_laegekursus = () => getTranslation("notatPage_laegekursus", "Lægekursus");
    public static notatPage_ansoegning = () => getTranslation("notatPage_ansoegning", "Dokumentation afsendt til STPS");
    public static notatPage_dokumentation = () => getTranslation("notatPage_dokumentation", "Filer uploadet til STPS");
    public static notatPage_logbogLegacyNotat = () => getTranslation("notatPage_logbogLegacyNotat", "Gammelt logbog.net notat");

    public static logbogLegacyNotat_ansaettelsesOgUddannelsesaftale = () => getTranslation("logbogLegacyNotat_ansaettelsesOgUddannelsesaftale", "Ansættelses-/uddannelsesaftale");
    public static logbogLegacyNotat_obligatoriskeKurserIHoveduddannelsen = () => getTranslation("logbogLegacyNotat_obligatoriskeKurserIHoveduddannelsen", "Obligatoriske kurser i hoveduddannelsen");
    public static logbogLegacyNotat_obligatoriskeKurserForIntrolaeger = () => getTranslation("logbogLegacyNotat_obligatoriskeKurserForIntrolaeger", "Obligatoriske kurser for introlæger");
    public static logbogLegacyNotat_specialespecifikkeKurser = () => getTranslation("logbogLegacyNotat_specialespecifikkeKurser", "Specialespecifikke kurser");
    public static logbogLegacyNotat_forskningstraening = () => getTranslation("logbogLegacyNotat_forskningstraening", "Forskningstræning");
    public static logbogLegacyNotat_merit = () => getTranslation("logbogLegacyNotat_merit", "Merit");
    public static logbogLegacyNotat_attestationForTid = () => getTranslation("logbogLegacyNotat_attestationForTid", "Attestation for tid");
    public static logbogLegacyNotat_introduktionsstilling = () => getTranslation("logbogLegacyNotat_introduktionsstilling", "Introduktionsstilling");
    public static logbogLegacyNotat_generelVurderingIntro = () => getTranslation("logbogLegacyNotat_generelVurderingIntro", "Generel vurdering - Intro");
    public static logbogLegacyNotat_generelVurderingHu = () => getTranslation("logbogLegacyNotat_generelVurderingHu", "Generel vurdering - HU");
    public static logbogLegacyNotat_miniCexIntro = () => getTranslation("logbogLegacyNotat_miniCexIntro", "Mini Cex - Intro");
    public static logbogLegacyNotat_miniCexHu = () => getTranslation("logbogLegacyNotat_miniCexHu", "Mini Cex - HU");
    public static logbogLegacyNotat_kbuKurser = () => getTranslation("logbogLegacyNotat_kbuKurser", "KBU kurser");
    public static logbogLegacyNotat_specialespecifikkeKurserIntro = () => getTranslation("logbogLegacyNotat_specialespecifikkeKurserIntro", "Specialespecifikke kurser Intro");
    public static logbogLegacyNotat_grundkursusIPsykoterapi = () => getTranslation("logbogLegacyNotat_grundkursusIPsykoterapi", "Grundkursus i psykoterapi");
    public static logbogLegacyNotat_faellesInternMedicinskeKurser = () => getTranslation("logbogLegacyNotat_faellesInternMedicinskeKurser", "Fælles intern medicinske kurser");
    public static logbogLegacyNotat_uddannelsesPlan = () => getTranslation("logbogLegacyNotat_uddannelsesPlan", "Uddannelsesplan");
    public static logbogLegacyNotat_vejledersamtale = () => getTranslation("logbogLegacyNotat_vejledersamtale", "Vejledersamtale");

    public static createMail_Message = () => getTranslation("createMail_Message", "Besked");
    public static createMail_MailSentTo = () => getTranslation("createMail_MailSentTo", "Mail sendes til");
    public static createMail_SendMail = () => getTranslation("createMail_SendMail", "Send mail");
    public static createMail_QueuingMail = () => getTranslation("createMail_QueuingMail", "Sætter mails til afsendelse");
    public static createMail_MailsQueued = () => getTranslation("createMail_MailsQueued", "Mails sat til afsendelse");
    public static createMail_SendBy = () => getTranslation("createMail_SendBy", "Sendt af");
    public static createMail_Send = () => getTranslation("createMail_SendAt", "Sendt");
    public static getEmailTemplate = () => getTranslation("getEmailTemplate", "Henter email skabeloner");
    public static klarmeldingerPage_getKlarmeldinger = () => getTranslation("klarmeldingerPage_getKlarmeldinger", "Henter klarmeldinger");
    public static klarmeldingerPage_noKlarmeldinger = () => getTranslation("klarmeldingerPage_noKlarmeldinger", "Der er ingen klarmeldte kompetencer");
    public static klarmeldingerPage_chooseMultipleHavePatience = () => getTranslation("klarmeldingerPage_chooseMultipleHavePatience", "Godkendelse af flere kompetencer på samme tid fungerer - men hav tålmodighed");
    public static klarmeldingerPage_searchString = () => getTranslation("klarmeldingerPage_searchString", "Søg på kompetenceindhold");
    public static klarmeldingerPage_searchStringMobile = () => getTranslation("klarmeldingerPage_searchString", "Søg på indhold");
    public static klarmeldingerPage_dageTilbageAfForloeb = (days: number) => getTranslation("klarmeldingerPage_dageTilbageAfForloeb", `${days} dage tilbage`);
    public static klarmeldingerPage_finished = () => getTranslation("klarmeldingerPage_finished", "Afsluttet");
    public static klarmeldingerPage_klarmeldtUdenDato = () => getTranslation("klarmeldingerPage_klarmeldtUdenDato", "Klarmeldt før 22-11-2022");
    public static klarmeldingerPage_godkendTid_areYouSureRemoveForloeb = () => getTranslation("klarmeldingerPage_godkendTid_areYouSureRemoveForloeb", "Er du sikker på, at du vil fjerne forløbet fra listen? Dermed vil forløbet ikke fremgå på denne liste, men kan stadig godkendes under uddannelseslægens side.");
    public static klarmeldingerPage_godkendTid_describeReasonRemoveForloeb = () => getTranslation("klarmeldingerPage_godkendTid_describeReasonRemoveForloeb", "Beskriv eventuelt årsag til, at du fjerner forløbet fra listen.");
    public static klarmeldingerPage_godkendTid_forloebUdeladtSucces = () => getTranslation("klarmeldingerPage_godkendTid_forloebUdeladtSucces", "Forløbet er udeladt fra listen.");
    public static klarmeldingerPage_godkendTid_forloebUdeladtError = () => getTranslation("klarmeldingerPage_godkendTid_forloebUdeladtError", "En fejl opstod ved udeladelse af forløb.");
    public static klarmeldingerPage_godkendTid_comment = () => getTranslation("klarmeldingerPage_godkendTid_comment", "Kommentar til Videreuddannelsessekretariatet");

    public static evalueringPage_henterStortDataset = () => getTranslation("evalueringPage_henterStortDataset", "Du er ved at hente et stort datasæt, da du har angivet en længere årrække, hvorfor dette dataudtræk kan tage lang tid. Afvent udtrækket eller gå tilbage og filtrer din søgning yderligere.");
    public static evalueringPage_exporterEnkelteEvalueringerTilExcel = () => getTranslation("evalueringPage_exporterEnkelteEvalueringerTilExcel", "Exporter enkelte evalueringer til Excel ");
    public static evalueringPage_dataset = () => getTranslation("evalueringPage_dataset", "Datasæt ");
    public static evalueringPage_uddannelsessteder = () => getTranslation("evalueringPage_uddannelsessteder", "Uddannelsessteder");
    public static evalueringPage_uddannelsessted = () => getTranslation("evalueringPage_uddannelsessted", "Uddannelsessted");
    public static evalueringPage_uddannelsesstederInactiveIncluded = () => getTranslation("evalueringPage_uddannelsesstederInactiveIncluded", " (Inklusiv inaktive) ");
    public static evalueringPage_gennemsnit = () => getTranslation("evalueringPage_gennemsnit", "Gennemsnit");
    public static evalueringPage_visGennemsnit = () => getTranslation("evalueringPage_visGennemsnit", "Vis gennemsnit herunder");
    public static evalueringPage_visGennemsnitOgFiltre = () => getTranslation("evalueringPage_visGennemsnitOgFiltre", "Vis gennemsnit og filtre");
    public static evalueringPage_visGennemsnitOgFiltreForkortet = () => getTranslation("evalueringPage_visGennemsnitOgFiltreForkortet", "Gennemsnit og filtre");
    public static evalueringPage_evalueringAfSted = () => getTranslation("evalueringPage_getEvaluering", "Evaluering af uddannelsessted");
    public static evalueringPage_spoergsmaalsgruppe = () => getTranslation("evalueringPage_spoergsmaalsgruppe", `Spørgsmålsgruppe`);
    public static evalueringPage_getEvaluering = () => getTranslation("evalueringPage_getEvaluering", "Henter evaluering");
    public static evalueringPage_questionsAnswered = () => getTranslation("evalueringPage_questionsAnswered", "Spørgsmål besvaret");
    public static evalueringPage_questionsAnsweredForMulti = () => getTranslation("evalueringPage_questionsAnsweredForMulti", "besvarelser på multispørgsmål");
    public static evalueringPage_afdelingIndsend = () => getTranslation("evalueringPage_afdelingIndsend", "Indsend evaluering af uddannelsessted");
    public static evalueringPage_errorDeleting = () => getTranslation("evalueringPage_errorDeleting", "Fejl ved sletning af evaluering");
    public static evalueringPage_evalueringDeleted = () => getTranslation("evalueringPage_evalueringDeleted", "Evalueringen blev slettet");
    public static evalueringPage_forloebEvalueringDeleted = () => getTranslation("evalueringPage_forloebEvalueringDeleted", "Forløbevalueringen blev slettet, da forløbet ikke længere kræver evaluering");
    public static evalueringPage_forloebEvalueringOprettet = () => getTranslation("evalueringPage_forloebEvalueringOprettet", "Der blev oprettet en forløbevalueringenskladde som kan udfyldes af lægen");
    public static evalueringPage_forloebEvalueringIndsendt = (laegeInstitutionName: string, afdelingName?: string) => getTranslation("evalueringPage_forloebEvalueringIndsendt", `Evalueringen tilføjet til ${!afdelingName ? "" : `${afdelingName}, `}${laegeInstitutionName}`);
    public static evalueringPage_forloebEvalueringIndsendApprove = (laegeInstitutionName: string, afdelingName?: string) => getTranslation("evalueringPage_forloebEvalueringIndsendApprove", `Er du sikker på, at du vil indsende din evaluering af uddannelsesstedet ${!afdelingName ? "" : `${afdelingName}, `}${laegeInstitutionName}?`);
    public static evalueringPage_evalueringAfKompetence = () => getTranslation("evalueringPage_evalueringAfKompetence", "Evaluering af kompetence");
    public static evalueringPage_kompetenceVurdering = () => getTranslation("evalueringPage_kompetenceVurdering", "Kompetencevurdering");
    public static evalueringPage_enkeltvis = () => getTranslation("evalueringPage_enkeltvis", "Enkeltvis");
    public static evalueringPage_grupperet = () => getTranslation("evalueringPage_grupperet", "Grupperet");
    public static evalueringPage_alle = () => getTranslation("evalueringPage_alle", "Alle");
    public static evalueringPage_visSpoergsmaal = () => getTranslation("evalueringPage_visSpoergsmaal", "Vis spørgsmål");
    public static evalueringPage_spoergsmaal = () => getTranslation("evalueringPage_spoergsmaal", "Spørgsmål (viser alle)");
    public static evalueringPage_ogVis = () => getTranslation("evalueringPage_ogVis", "og vis");
    public static evalueringPage_generelComments = () => getTranslation("evalueringPage_ogVis", "Generelle kommentarer");
    public static evalueringPage_evalueringer = () => getTranslation("evalueringPage_evalueringer", "Evalueringer");
    public static evalueringPage_underTreEvalueringer = () => getTranslation("evalueringPage_underTreEvalueringer", "Under 3 evalueringer");
    public static evalueringPage_ingenEvalueringer = () => getTranslation("evalueringPage_ingenEvalueringer", "Ingen evalueringer");
    public static evalueringPage_kommentarer = () => getTranslation("evalueringPage_kommentarer", "Kommentarer");
    public static evalueringPage_kommentar = () => getTranslation("evalueringPage_kommentar", "Kommentar");
    public static evalueringPage_afleveret = () => getTranslation("evalueringPage_afleveret", "Afleveret");
    public static evalueringPage_spoergsmaalHeader = () => getTranslation("evalueringPage_spoergsmaalHeader", "Spørgsmål");
    public static evalueringPage_spoergsmaalsgruppeHeader = () => getTranslation("evalueringPage_spoergsmaalsgruppeHeader", "Spørgsmålsgruppe");
    public static evalueringPage_bagLoginInfoTekst = () => getTranslation("evalueringPage_bagLoginInfoTekst", "Du har adgang til at se evalueringerne på din afdeling eller din praksis (eventuelt flere hvis din rolle giver dig adgang til det). For andre enheder har du adgang til evalueringer på et sumarisk niveau og kan se gennemsnitsscore for enkelte spørgsmål og spørgsmålsgrupper.");
    public static evalueringPage_offentligInfoTekst = () => getTranslation("evalueringPage_offentligInfoTekst", "Du har adgang til evalueringer på et sumarisk niveau og kan se gennemsnitsscore for enkelte spørgsmål og spørgsmålsgrupper.");
    public static evalueringPage_uddannelsesansvarlige = () => getTranslation("evalueringPage_uddannelsesansvarlige", "Uddannelsesansvarlige:");
    public static evalueringPage_uddannelsesansvarligeText = () => getTranslation("evalueringPage_uddannelsesansvarligeText", "Når du indsender din evaluering, får den/de uddannelsesansvarlige, der står angivet ovenfor, besked på, at der er kommet en ny evaluering. Dit navn er ikke angivet på evalueringen.");
    public static evalueringPage_hovedvejleder = () => getTranslation("evalueringPage_hovedvejleder", "Hovedvejleder:");
    public static evalueringPage_indtastHovedvejleder = () => getTranslation("evalueringPage_indtastHovedvejleder", "Indtast hovedvejleder");
    public static evalueringPage_udAf = () => getTranslation("evalueringPage_udAf", " ud af ");
    public static evalueringPage_mulige = () => getTranslation("evalueringPage_mulige", " mulige");
    public static evalueringPage_spoergerammePdfHeaderText = () => getTranslation("evalueringPage_spoergerammePdfHeaderText", "Spørgeramme til evaluering af uddannelsesstedet i den lægelige videreuddannelse");

    public static nyhederPage_EditingNyhed = () => getTranslation("nyhederPage_EditingNyhed ", "Redigerer nyhed");
    public static nyhederPage_EditNyhed = () => getTranslation("nyhederPage_EditNyhed ", "Rediger nyhed");
    public static nyhederPage_CreatingNyhed = () => getTranslation("nyhederPage_CreatingNyhed ", "Opretter nyhed");
    public static nyhederPage_OpretNyhed = () => getTranslation("nyhederPage_OpretNyhed ", "Opret nyhed");
    public static nyhederPage_gettingNyheder = () => getTranslation("nyhederPage_gettingNyheder", "Henter nyheder");
    public static nyhederPage_publiceringsdato = () => getTranslation("nyhederPage_publiceringsdato", "Publiceringsdato");
    public static nyhederPage_notificerRoller = () => getTranslation("nyhederPage_notificerRoller", "Notificer roller");
    public static nyhederPage_notificerRollerInfo = () => getTranslation("nyhederPage_notificerRollerInfo", "Hvis denne er slået til, notificeres udvalgte roller, når nyheden publiseres.");

    public static nyhederPage_nyhedCreated = () => getTranslation("nyhederPage_nyhedCreated", "Nyhed er oprettet");
    public static nyhederPage_nyhedEdited = () => getTranslation("nyhederPage_nyhedEdited", "Nyhed er redigeret");
    public static nyhederPage_noNews = () => getTranslation("nyhederPage_noNews", "Ingen nyheder");

    public static uddannelseslaegeOverviewPage_VejlederPeriodeEnds = () => getTranslation("uddannelseslaegeOverviewPage_VejlederPeriodeEnds", "Vejlederperiode afsluttes");
    public static uddannelseslaegeOverviewPage_TilknyttetAfdelinger = (antal: number) => getTranslation("uddannelseslaegeOverviewPage_TilknyttetAfdelinger", "Tilknyttet " + antal + " uddannelsessteder");
    public static uddannelseslaegeOverviewPage_NoActiveLaeger = () => getTranslation("uddannelseslaegeOverviewPage_NoActiveLaeger", "Ingen aktive læger");

    public static userProfilePage_noWorkEmail = () => getTranslation("userProfilePage_noWorkEmail", "Ingen arbejdsmail");
    public static userProfilePage_noWorkPhone = () => getTranslation("userProfilePage_noWorkPhone", "Ingen arbejdstelefon");
    public static userProfilePage_emailCaption = () => getTranslation("userProfilePage_emailCaption", "Bemærk at email anvendes til login");

    public static landingPage_email = () => getTranslation("landingPage_email", "Email");
    public static landingPage_web = () => getTranslation("landingPage_web", "Web");
    public static landingPage_telefon = () => getTranslation("landingPage_telefon", "Telefon");
    public static landingPage_hvemErDetTilFor = () => getTranslation("landingPage_hvemErDetTilFor", "Hvem anvender uddannelseslæge.dk?");
    public static landingPage_kontakt = () => getTranslation("landingPage_kontakt", "Kontakt");
    public static landingPage_kontaktInfo = () => getTranslation("landingPage_kontaktInfo", "Uddannelseslæge.dk administreres af de tre Videreuddannelsesregioner i samarbejde med de fem driftsregioner.");
    public static landingPage_kontaktBeskrivelse = () => getTranslation("landingPage_kontaktBeskrivelse", "Spørgsmål vedrørende Uddannelseslæge.dk sendes til: ");
    public static landingPage_webtilgaengelighedOgCookies = () => getTranslation("landingPage_webtilgaengelighedOgCookies", "Webtilgængelighed og cookies");
    public static landingPage_privatlivspolitik = () => getTranslation("landingPage_privatlivspolitik", "Privatlivspolitik (cookies)");
    public static landingPage_vilkaar = () => getTranslation("landingPage_vilkaar", "Vilkår");
    public static landingPage_tilgaengelighedserklaering = () => getTranslation("landingPage_tilgaengelighedserklaering", "Tilgængelighedserklæring");

    public static landingPage_velkommen = () => getTranslation("landingPage_velkommen", "Velkommen til Uddannelseslæge.dk");

    public static landingPage_velkommenBeskrivelse = () => getTranslation("landingPage_velkommenBeskrivelse",
        "Uddannelseslæge.dk erstatter de tidligere systemer logbog.net og\n" +
        "evaluer.dk. Har du spørgsmål til systemet eller har brug for hjælp, så se kontaktoplysninger nedenfor eller find hjælp på support-hjemmesiden her:\n");
    public static landingPage_gaaTilLaegeuddannelsenButton = () => getTranslation("landingPage_gaaTilLaegeuddannelsenButton", "Gå til lægeuddannelsen.dk");
    public static landingPage_supportHjemmeside = () => getTranslation("landingPage_supportHjemmeside", "Support-hjemmeside");

    public static landingPage_uddannelseslaeger = () => getTranslation("landingPage_uddannelseslaeger", "Uddannelseslæger");
    public static landingPage_uddannelseslaegerBeskrivelse = () => getTranslation("landingPage_uddannelseslaegerBeskrivelse",
        "Få overblik over din lægelige videreuddannelse.  Saml dokumenter, få godkendt kompetencer og kurser, få attestation for tid, evaluer dit uddannelsesforløb og fremsend relevant dokumentation til Styrelsen for Patientsikkerhed når du skal ansøge om henholdsvis selvstændigt virke og speciallægeanerkendelse.");

    public static landingPage_uddannelsesgivende = () => getTranslation("landingPage_uddannelsesgivende", "Uddannelsesgivende");
    public static landingPage_uddannelsesgivendeBeskrivelse = () => getTranslation("landingPage_uddannelsesgivendeBeskrivelse",
        "Godkend uddannelseslægers kompetencer, når du er vejleder. Godkend uddannelsestid, se evalueringer af uddannelsessteder og få overblik over nuværende og kommende uddannelseslæger, når du er uddannelsesansvarlig overlæge eller tutorlæge.");

    public static landingPage_andre = () => getTranslation("landingPage_andre", "Andre");
    public static landingPage_andreBeskrivelse = () => getTranslation("landingPage_andreBeskrivelse",
        "Få adgang til oversigter og relevante oplysninger om uddannelseslæger i den lægelige videreuddannelse, når du eksempelvis er PKL, uddannelseskoordinerende overlæge, HR, sekretær eller kursusleder. Der er offentlig adgang til evalueringer, som ikke kræver log-in. Vælg 'Evalueringsstatistik' øverst på forsiden af Uddannelseslæge.dk.");

    public static landingPage_videreuddannelsesregionNord = () => getTranslation("landingPage_videreuddannelsesregionNord", "Videreuddannelsesregion Nord");
    public static landingPage_videreuddannelsesregionNordTelefon = () => getTranslation("landingPage_videreuddannelsesregionNordTelefon", "+45 78 41 08 00");
    public static landingPage_videreuddannelsesregionNordEmail = () => getTranslation("landingPage_videreuddannelsesregionNordEmail", "vus@stab.rm.dk");
    public static landingPage_videreuddannelsesregionNordWeb = () => getTranslation("landingPage_videreuddannelsesregionNordWeb", "www.videreuddannelsen-nord.dk");

    public static landingPage_videreuddannelsesregionSyd = () => getTranslation("landingPage_videreuddannelsesregionSyd", "Videreuddannelsesregion Syd");
    public static landingPage_videreuddannelsesregionSydTelefon = () => getTranslation("landingPage_videreuddannelsesregionSydTelefon", "");
    public static landingPage_videreuddannelsesregionSydEmail = () => getTranslation("landingPage_videreuddannelsesregionSydEmail", "videreuddannelsen@rsyd.dk");
    public static landingPage_videreuddannelsesregionSydWeb = () => getTranslation("landingPage_videreuddannelsesregionSydWeb", "www.videreuddannelsen-syd.dk");

    public static landingPage_videreuddannelsesregionOest = () => getTranslation("landingPage_videreuddannelsesregionOest", "Videreuddannelsesregion Øst");
    public static landingPage_videreuddannelsesregionOestTelefon = () => getTranslation("landingPage_videreuddannelsesregionOestTelefon", "+45 38 66 99 30");
    public static landingPage_videreuddannelsesregionOestEmail = () => getTranslation("landingPage_videreuddannelsesregionOestEmail", "laegeuddannelsen@regionh.dk");
    public static landingPage_videreuddannelsesregionOestWeb = () => getTranslation("landingPage_videreuddannelsesregionOestWeb", "www.laegeuddannelsen.dk");


    public static landingPage_regionNordjylland = () => getTranslation("landingPage_regionNordjylland", "Region Nordjylland");
    public static landingPage_regionNordjyllandAdresse = () => getTranslation("landingPage_regionNordjyllandAdresse", "Sundhedsuddannelser");
    public static landingPage_regionNordjyllandWeb = () => getTranslation("landingPage_regionNordjyllandWeb", "www.laegeuddannelsen.dk");
    public static landingPage_regionNordjyllandTelefon = () => getTranslation("landingPage_regionNordjyllandTelefon", "+45 38 66 99 30");

    public static landingPage_regionMidtjylland = () => getTranslation("landingPage_regionMidtjylland", "Region Midtjylland");
    public static landingPage_regionMidtjyllandAdresse = () => getTranslation("landingPage_regionMidtjyllandAdresse", "Sundhedsuddannelser");
    public static landingPage_regionMidtjyllandWeb = () => getTranslation("landingPage_regionMidtjyllandWeb", "www.laegeuddannelsen.dk");
    public static landingPage_regionMidtjyllandTelefon = () => getTranslation("landingPage_regionMidtjyllandTelefon", "+45 38 66 99 30");

    public static landingPage_regionSyddanmark = () => getTranslation("landingPage_regionSyddanmark", "Region Syddanmark");
    public static landingPage_regionSyddanmarkAdresse = () => getTranslation("landingPage_regionSyddanmarkAdresse", "Sundhedsuddannelser");
    public static landingPage_regionSyddanmarkWeb = () => getTranslation("landingPage_regionSyddanmarkWeb", "www.laegeuddannelsen.dk");
    public static landingPage_regionSyddanmarkTelefon = () => getTranslation("landingPage_regionSyddanmarkTelefon", "+45 38 66 99 30");

    public static landingPage_regionHovedstaden = () => getTranslation("landingPage_regionHovedstaden", "Region Hovedstaden");
    public static landingPage_regionHovedstadenAdresse = () => getTranslation("landingPage_regionHovedstadenAdresse", "Sundhedsuddannelser");
    public static landingPage_regionHovedstadenWeb = () => getTranslation("landingPage_regionHovedstadenWeb", "www.laegeuddannelsen.dk");
    public static landingPage_regionHovedstadenTelefon = () => getTranslation("landingPage_regionHovedstadenTelefon", "+45 38 66 99 30");

    public static landingPage_regionSjaelland = () => getTranslation("landingPage_regionSjaelland", "Region Sjælland");
    public static landingPage_regionSjaellandAdresse = () => getTranslation("landingPage_regionSjaellandAdresse", "Sundhedsuddannelser");
    public static landingPage_regionSjaellandWeb = () => getTranslation("landingPage_regionSjaellandWeb", "www.laegeuddannelsen.dk");
    public static landingPage_regionSjaellandTelefon = () => getTranslation("landingPage_regionSjaellandTelefon", "+45 38 66 99 30");

    public static landingPage_driftsforstyrrelser = () => getTranslation("landingPage_driftsforstyrrelser", "Driftsinformation");
    public static kompetenceEvalueringPage_evalueringGemt = () => getTranslation("kompetenceEvalueringPage_evalueringGemt", `Kompetencevurdering er gemt`);
    public static kompetenceEvalueringPage_saveVurderingAfKompetence = () => getTranslation("kompetenceEvalueringPage_saveVurderingAfKompetence", "Gem vurdering af kompetence");

    public static kompetenceEvalueringPage_indsend = () => getTranslation("kompetenceEvalueringPage_indsend", "Indsend vurdering af kompetence");
    public static KompetenceEvalueringPage_evalueringIndsendApprove = () => getTranslation("KompetenceEvalueringPage_evalueringIndsendApprove", `Er du sikker på, at du vil gemme din vurdering af kompetencen?`);

    public static kompetenceEvalueringPage_ForventetNiveau = () => getTranslation("kompetenceEvalueringPage_ForventetNiveau", `Forventet niveau`);
    public static kompetenceEvalueringPage_UnderForventetNiveau = () => getTranslation("kompetenceEvalueringPage_UnderForventetNiveau", `Under forventet niveau`);
    public static kompetenceEvalueringPage_OverForventetNiveau = () => getTranslation("kompetenceEvalueringPage_OverForventetNiveau", `Over forventet niveau`);
    public static kompetenceEvalueringPage_IkkeBedoemt = () => getTranslation("kompetenceEvalueringPage_IkkeBedoemt", `Ikke bedømt`);

    public static kompetencerPage_forloebskompetencerEditted = () => getTranslation("kompetencerPage_forloebskompetencerEditted", "Forløbskompetencer blev redigeret");


    public static uddannelseslaegerPage_nuvaerendeUddannelseslaeger = () => getTranslation("uddannelseslaegerPage_nuvaerendeUddannelseslaeger", "Nuværende uddannelseslæger");

    public static evalueringsstatistikPage_seeSpoergeramme = () => getTranslation("evalueringsstatistikPage_seeSpoergeramme", "Se spørgeramme");
    public static evalueringsstatistikPage_header = () => getTranslation("evalueringsstatistikPage_header", "Velkommen til evalueringsstatistik");
    public static evalueringsstatistikPage_information = () => getTranslation("evalueringsstatistikPage_questions", "Dette er regionernes hjemmeside for elektronisk evaluering af uddannelsesstederne i speciallægeuddannelsen. Evalueringerne foretages af yngre læger under uddannelse og har til formål at styrke og udvikle den lægelige videreuddannelse.");
    public static evalueringsstatistikPage_questions = () => getTranslation("evalueringsstatistikPage_questions", "Har du spørgsmål?");
    public static evalueringsstatistikPage_questionsInformation = () => getTranslation("evalueringsstatistikPage_questions", "Du er velkommen til at kontakte det regionale sekretariat for lægelig videreuddannelse. Du finder kontaktoplysninger på forsiden.");
    public static evalueringsstatistikPage_createStatistic = () => getTranslation("evalueringsstatistikPage_createStatistic", "Opret statistik");
    public static evalueringsstatistikPage_createNewDataset = () => getTranslation("evalueringsstatistikPage_createNewDataset", "Opret nyt datasæt");
    public static evalueringsstatistikPage_konkretEvaluering = () => getTranslation("evalueringsstatistikPage_konkretEvaluering", "Konkret evaluering");

    public static createEvalueringsstatistikPage_addDataset = () => getTranslation("createEvalueringsstatistikPage_addDataset", "Vælg data");
    public static createEvalueringsstatistikPage_afdelingsgennemsnit = () => getTranslation("createEvalueringsstatistikPage_afdelingsgennemsnit", "Afdelingsgennemsnit");
    public static createEvalueringsstatistikPage_statistik = () => getTranslation("createEvalueringsstatistikPage_statistik", "Statistik");
    public static createEvalueringsstatistikPage_uddannelsesstedgennemsnit = () => getTranslation("createEvalueringsstatistikPage_uddannelsesstedgennemsnit", "Uddannelsessted gennemsnit");
    public static createEvalueringsstatistikPage_gennemsnit = () => getTranslation("createEvalueringsstatistikPage_gennemsnit", "Gennemsnit");
    public static createEvalueringsstatistikPage_noDataset = () => getTranslation("createEvalueringsstatistikPage_noDataset", "Der er endnu ikke tilføjet noget datasæt");
    public static createEvalueringsstatistikPage_intetIndhold = () => getTranslation("createEvalueringsstatistikPage_intetIndhold", "Intet indhold");
    public static createEvalueringsstatistikPage_vaelgRegion = () => getTranslation("createEvalueringsstatistikPage_vaelgRegion", "Vælg region");
    public static createEvalueringsstatistikPage_vaelgType = () => getTranslation("createEvalueringsstatistikPage_vaelgType", "Vælg type");
    public static createEvalueringsstatistikPage_Type = () => getTranslation("createEvalueringsstatistikPage_Type", "Type");
    public static createEvalueringsstatistikPage_Hospitalsenhed = () => getTranslation("createEvalueringsstatistikPage_Hospitalsenhed", "Hospitalsenhed");
    public static createEvalueringsstatistikPage_Tidsperiode = () => getTranslation("createEvalueringsstatistikPage_Tidsperiode", "Tidsperiode");
    public static createEvalueringsstatistikPage_Baggrund = () => getTranslation("createEvalueringsstatistikPage_Baggrund", "Baggrund");
    public static createEvalueringsstatistikPage_TitelPaaDataset = () => getTranslation("createEvalueringsstatistikPage_TitelPaaDataset", "Titel på datasæt");
    public static createEvalueringsstatistikPage_StedValgtText = (count: number) => getTranslation("createEvalueringsstatistikPage_StedValgtText", `${count === 1 ? "sted valgt" : "steder valgt"}`);
    public static createEvalueringsstatistikPage_searchSavedInfo = () => getTranslation("createEvalueringsstatistikPage_searchSavedInfo", "Din tidligere søgning er gemt. Du kan anvende dette til at sammenligne steder, tidsperioder, stillingstyper eller specialer");
    public static createEvalueringsstatistikPage_startOver = () => getTranslation("createEvalueringsstatistikPage_startOver", "Start forfra");
    public static createEvalueringsstatistikPage_Uddannelsesspeciale = () => getTranslation("createEvalueringsstatistikPage_Uddannelsesspeciale", "Uddannelsesspeciale");
    public static createEvalueringsstatistikPage_searchUddannelsessted = () => getTranslation("createEvalueringsstatistikPage_searchUddannelsessted", "Søg på uddannelsessted");
    public static createEvalueringsstatistikPage_chooseSygehuse = () => getTranslation("createEvalueringsstatistikPage_chooseSygehuse", "Vælg sygehuse");
    public static createEvalueringsstatistikPage_ingenForloebMedEvalueringer = () => getTranslation("createEvalueringsstatistikPage_ingenForloebMedEvalueringer", "Der blev ikke fundet nogle forløb som er evalueret eller mangler evaluering i den angivede periode");
    public static createEvalueringsstatistikPage_TidsperiodeBeskrivelse = () => getTranslation("createEvalueringsstatistikPage_TidsperiodeBeskrivelse", "Start- og slutdato afgrænser hvilke forløb, der hentes evalueringer for. Der hentes evalueringer for forløb, der afsluttes inden for den valgte tidsperiode.");

    public static evalueringsstatistikPage_addDataset = () => getTranslation("evalueringsstatistikPage_addDataset", "Tilføjer datasæt");
    public static evalueringsstatistikPage_afdelingsgennemsnit = () => getTranslation("evalueringsstatistikPage_afdelingsgennemsnit", "Afdelingsgennemsnit");
    public static evalueringsstatistikPage_noDataset = () => getTranslation("evalueringsstatistikPage_noDataset", "Der er endnu ikke tilføjet noget datasæt");
    public static evalueringsstatistikPage_includeInactive = () => getTranslation("evalueringsstatistikPage_includeInactive", "Inkluder inaktive uddannelsessteder og sygehuse");
    public static evalueringsstatistikPage_generelKommentar = () => getTranslation("evalueringsstatistikPage_generelKommentar", "Generel kommentar");
    public static evalueringsstatistikPage_startNytUdtraek = () => getTranslation("evalueringsstatistikPage_startNytUdtraek", "Start nyt udtræk");
    public static evalueringsstatistikPage_startNytUdtraekInfo = () => getTranslation("evalueringsstatistikPage_startNytUdtraekInfo", "Her starter du forfra med et nyt udtræk.");
    public static evalueringsstatistikPage_senesteUdtraek = () => getTranslation("evalueringsstatistikPage_senesteUdtraek", "Seneste udtræk");
    public static evalueringsstatistikPage_senesteUdtraekInfo = () => getTranslation("evalueringsstatistikPage_senesteUdtraekInfo", "Vis datasæt som er hentet i denne session.");
    public static evalueringsstatistikPage_arbejdVidere = () => getTranslation("evalueringsstatistikPage_arbejdVidere", "Arbejd videre");
    public static evalueringsstatistikPage_arbejdVidereInfo = () => getTranslation("evalueringsstatistikPage_arbejdVidereInfo", "Du kan kun arbejde videre med udtræk, du har lavet i samme login session.");
    public static evalueringsstatistikPage_mineUddannelsessteder = () => getTranslation("evalueringsstatistikPage_mineUddannelsessteder", "Standardvisning");
    public static evalueringsstatistikPage_mineUddannelsesstederInfo = () => getTranslation("evalueringsstatistikPage_mineUddannelsesstederInfo", "Her ser du tre udtræk for det/de uddannelsessteder, du har adgang til. Seneste to hele år og år til dato + en måned.");
    public static evalueringsstatistikPage_mineEvalueringer = () => getTranslation("evalueringsstatistikPage_mineEvalueringer", "Evalueringer listevisning");
    public static evalueringsstatistikPage_mineEvalueringerInfo = () => getTranslation("evalueringsstatistikPage_mineEvalueringerInfo", "Listevisning fra dit/dine uddannelsessteder med de nyeste evalueringer øverst.");
    public static evalueringsstatistikPage_sidstAendret = () => getTranslation("evalueringsstatistikPage_sidstAendret", "Sidst ændret for");
    public static evalueringsstatistikPage_minutterSiden = () => getTranslation("evalueringsstatistikPage_minutterSiden", "minutter siden.");
    public static evalueringsstatistikPage_minutSiden = () => getTranslation("evalueringsstatistikPage_minutSiden", "minut siden.");

    public static landingPage_Privatlivspolitik = () => getTranslation("landingPage_Privatlivspolitik", "Vilkår for brugen af systemet");
    public static landingPage_PrivatlivspolitikLogning = () => getTranslation("landingPage_Privatlivspolitik", "Du er ikke anonym når du bruger systemet. Alle dine aktiviteter bliver af sikkerhedsmæssige årsager logget.\n" + "\n" + "I forbindelse med din aktivitet logger vi bl.a:\n\n" + "\n" +
        "\n Browsertype,\n\n" +
        "\n Operativsystem,\n" +
        "\n IP-adresse,\n" +
        "\n URL-adresse på siden, du besøger,\n" +
        "\n Tidspunkt, siden er besøgt på,\n" +
        "\n Referrer (hvis du har fulgt et link fra en anden hjemmeside til systemet),\n" +
        "\n Skærmopløsning,\n" +
        "Ingen data gives videre eller sælges til tredjepart. Du er velkommen til at kontakte os, hvis du har yderligere spørgsmål.\n"
    );

    public static landingPage_PrivatlivspolitikOpbevaringAfDataText= () => getTranslation("landingPage_PrivatlivspolitikOpbevaringAfDataText", "Systemet er ikke en cloud løsning. Alle data opbevares på sikrede servere placeret i Danmark. Al overførsel af data er sikret med SSL point-to-point kryptering.");
    public static landingPage_PrivatlivspolitikOpbevaringAfData= () => getTranslation("landingPage_PrivatlivspolitikOpbevaringAfData", "Opbevaring af data");
    public static landingPage_PrivatlivspolitikStatistik= () => getTranslation("landingPage_PrivatlivspolitikStatistik", "Statistik\n");
    public static landingPage_PrivatlivspolitikStatistikText= () => getTranslation("landingPage_PrivatlivspolitikStatistikText", "For at kunne udvikle og forbedre systemet fører vi statistik over, hvordan hjemmesiden bliver brugt. Statistikken anvendes udelukkende i opsummeret form, fx til at forbedre hastigheden på siderne eller gøre menusystemet mere effektivt.\n");
    public static landingPage_PrivatlivspolitikCookiesText= () => getTranslation("landingPage_PrivatlivspolitikCookiesText", "Cookies er en lille tekstbesked, der typisk bliver lagt på din computer, når du bruger en internettjeneste. En cookie kan også lægges midlertidigt på din computer i forbindelse med en aktivitet på en tjeneste for så at blive slettet igen, når " +
        "du forlader tjenesten.\n" + "\n" + "Vi bruger kun cookies i forbindelse med log ind - for at sikre at du forbliver logget ind mens du bruger systemet. Vi deler ikke cookies med andre systemer eller leverandører.\n");
    public static landingPage_PrivatlivspolitikDPO = () => getTranslation("landingPage_PrivatlivspolitikDPO", "DPO (Data Protection Officer)");
    //public static landingPage_PrivatlivspolitikDPONavn = () => getTranslation("landingPage_PrivatlivspolitikDPONavn", "Thomas Vangsaa \n");
    //public static landingPage_PrivatlivspolitikDPOWeb = () => getTranslation("landingPage_PrivatlivspolitikDPOWeb", "http://vangsaaconsult.dk ");
    //public static landingPage_PrivatlivspolitikDPOEmail = () => getTranslation("landingPage_PrivatlivspolitikDPOEmail", "thomas@vangsaaconsult.dk ");
    //public static landingPage_PrivatlivspolitikDPOPhone = () => getTranslation("landingPage_PrivatlivspolitikDPOphone", "+ 45 71 99 52 06 ");
    public static landingPage_PrivatlivspolitikDPOText = () => getTranslation("landingPage_PrivatlivspolitikDPOText", "For at efterleve Persondataforordningen (GDPR), har vi tilknyttet en DPO (Data Protection Officer). DPO'ens opgaver er at føre tilsyn med at vi behandler dine persondata i overensstemmelse med loven.\n" +
        "\n" +"Hvis du har spørgsmål til vores behandling af dine persondata, er du altid vilkommen til at kontatke os. Hvis du ikke føler at vi kan svare på dine spørgsmål er du velkommen til at kontakte vores DPO direkte:\n");

    public static landingPage_PrivatlivspolitikMinData = () => getTranslation("landingPage_PrivatlivspolitikMinData", "Min data");
    public static landingPage_PrivatlivspolitikMinDataText = () => getTranslation("landingPage_PrivatlivspolitikMinDataText", "Kontakt os hvis du gerne vil have indsigt i hvilke data systemet indeholder om dig. Ud over indsigt kan du også anmode ændring og/eller sletning af dine data.");
    //public static landingPage_PrivatlivspolitikMinDataMail = () => getTranslation("landingPage_PrivatlivspolitikMinDataMail", "persondata@telemed.dk ");
    //public static landingPage_PrivatlivspolitikMinDataPhone = () => getTranslation("landingPage_PrivatlivspolitikMinDataPhone", "+45 49 25 25 65 ");
    public static landingPage_vilkaarCookieText = () => getTranslation("landingPage_vilkaarCookieText", "Som på de fleste andre hjemmesider efterlader du dig elektroniske spor bl.a. via cookies, når du bruger vores hjemmeside. Oplysningerne er anonyme, og vi kan derfor ikke se, hvem du er. Vi kobler ikke anonymiserede, tekniske oplysninger med specifikke brugere.\n" +
        "\n" + "Cookies er en lille tekstbesked, der typisk bliver lagt på din computer, når du bruger en internettjeneste. En cookie kan også lægges midlertidigt på din computer i forbindelse med en aktivitet på en tjeneste for så at blive slettet igen, når du forlader tjenesten.\n" +
        "\n" + "Vi bruger kun sessions cookies i forbindelse med log ind - for at sikre at du forbliver logget ind mens du bruger systemet. Vi deler ikke cookies med andre systemer eller leverandører.");
    public static landingPage_vilkaarCookieInform = () => getTranslation("landingPage_vilkaarCookieInform", "Hvorfor informerer vi om brug af cookies?");
    public static landingPage_vilkaarCookieUrl = () => getTranslation("landingPage_vilkaarCookieUrl", "Erhvervsstyrelses Cookie-bekendtgørelse.");
    public static landingPage_vilkaarCookieInformText = () => getTranslation("landingPage_vilkaarCookieInform", "Alle danske websteder er forpligtet til at informere om, hvilke cookies der afsættes på brugerens udstyr.\n" +
        "\n" +
        "Læs mere om Cookiebekendtgørelsen her:");
    public static landingPage_vilkaarCookieDelete = () => getTranslation("landingPage_vilkaarCookieDelete", "Sådan sletter du og undgår Cookies");
    public static landingPage_vilkaarCookieDeleteCookiePreUrl = () => getTranslation("landingPage_vilkaarCookieDeleteCookiePreUrl", "På hjemmesiden:");
    public static landingPage_vilkaarCookieDeleteeUrl = () => getTranslation("landingPage_vilkaarCookieDeleteeUrl", "minecookies");
    public static landingPage_vilkaarCookieDeleteCookieUrl = () => getTranslation("landingPage_vilkaarCookieDeleteUrl", " kan du se, hvordan du sletter cookies alt efter hvilken browser, du benytter.");
    public static landingPage_vilkaarCookieDeleteText = () => getTranslation("landingPage_vilkaarCookieDeleteText", "Du kan selv styre, om du vil acceptere cookies ? og om du vil slette dem. Det gør du i indstillingerne i browseren på din computer, telefon eller tablet. Hvis du slår cookies fra i din browser, vil der være funktioner på hjemmesiderne, der ikke virker.\n" +
        "\n" +
        "Cookies, som du har accepteret, kan du efterfølgende slette. Har du en PC med en nyere browser, kan du hurtigt gøre det ved at bruge genvejstasterne CTRL + SHIFT + Delete.");
    public static landingPage_vilkaarCookieGoogleAnalytics = () => getTranslation("landingPage_vilkaarCookieGoogleAnalytics", "Hvis du ønsker at fravælge cookies fra Google Analytics, så tilbyder Google f.eks. værktøjet\n");
    public static landingPage_vilkaarCookieGoogleAnalyticsUrl = () => getTranslation("landingPage_vilkaarCookieGoogleAnalyticsUrl", "Google Analytics Opt-out Browser Add-on.\n");


    public static spoergerammeModal_spoergeramme = () => getTranslation("spoergerammeModal_spoergeramme", "Spørgeramme");
    public static spoergerammeModal_introduktionText = () => getTranslation("spoergerammeModal_introduktionText", "Uddannelseslæger evaluerer deres forløb på afdelinger på tværs af 26 spørgsmål.");
    public static spoergerammeModal_spoergsmaalPreForklaringText = () => getTranslation("spoergerammeModal_spoergsmaalPreForklaringText", "Spørgsmålene besvares på en skala fra 1-6");
    public static spoergerammeModal_skala1 = () => getTranslation("spoergerammeModal_skala1", "i meget ringe grad");
    public static spoergerammeModal_skala2 = () => getTranslation("spoergerammeModal_skala2", "i ringe grad");
    public static spoergerammeModal_skala3 = () => getTranslation("spoergerammeModal_skala3", "i mindre grad");
    public static spoergerammeModal_skala4 = () => getTranslation("spoergerammeModal_skala4", "i nogen grad");
    public static spoergerammeModal_skala5 = () => getTranslation("spoergerammeModal_skala5", "i høj grad");
    public static spoergerammeModal_skala6 = () => getTranslation("spoergerammeModal_skala6", "i meget høj grad");
    public static spoergerammeModal_spoergsmaalPostForklaringText = () => getTranslation("spoergerammeModal_spoergsmaalPostForklaringText", "Det er herudover muligt at svare 'ikke relevant'");
    public static spoergerammeModal_henterSpoergsmaal = () => getTranslation("spoergerammeModal_henterSpoergsmaal", "Henter spørgsmål");

    public static filePage_fileRelationsTitle = () => getTranslation("filePage_fileRelationsTitle", "Vælg hvor filen skal vises");
    public static filePage_uploadFile = () => getTranslation("filePage_uploadFile", "Upload fil");
    public static filePage_uploadWarningMain = () => getTranslation("filePage_uploadWarningMain", "Under 'Filer fra uddannelsesstedet' er der mulighed for at uploade generelle filer med information til dine uddannelseslæger. Det kan fx være introduktionsprogram, huskeliste eller andet relevant. Filer der hører til målbeskrivelsen findes under 'Specialspecifikke filer', mens ALT der vedrører en konkret uddannelseslæge håndteres under uddannelseslægens profil.");
    public static filePage_uploadWarningSub = () => getTranslation("filePage_uploadWarningSub", "Uddannelseslægens personlige filer kan IKKE uploades her.");

    public static letterPage_CreateTemplate = () => getTranslation("letterPage_CreateTemplate", "Opret skabelon");
    public static letterPage_EditTemplate = () => getTranslation("letterPage_EditTemplate", "Rediger skabelon");
    public static letterPage_ChooseTemplate = () => getTranslation("letterPage_ChooseTemplate", "Vælg skabelon");
    public static letterPage_template = () => getTranslation("letterPage_Template", "Skabelon");
    public static letterPage_creationError = () => getTranslation("letterPage_creationError", "Oprettelsen af brevet fejlede");
    public static letterPage_updateError = () => getTranslation("letterPage_creationError", "Opdatering af brevet fejlede");
    public static letterPage_letterTemplateCreated = () => getTranslation("letterPage_letterTemplateCreated", "Brevskabelon oprettet");
    public static letterPage_letterTemplateUpdated = () => getTranslation("letterPage_letterTemplateUpdated", "Brevskabelon opdateret");
    public static letterPage_invalidCrudActionMessage = () => getTranslation("letterPage_invalidCrudActionMessage", "Alle felter skal have en værdi");
    public static letterPage_fetchingLetter = () => getTranslation("letterPage_fetchingLetter", "Henter brev");
    public static letterPage_fetchingLetters = () => getTranslation("letterPage_fetchingLetters", "Henter breve");
    public static letterPage_letterListEmptyInfo = () => getTranslation("letterPage_letterListEmptyInfo", "Der blev ikke fundet nogle brevskabeloner");
    public static letterPage_EmptyPreviwerInfo = () => getTranslation("letterPage_EmptyPreviwerInfo", "Intet af vise");
    public static letterPage_GeneratingPreview = () => getTranslation("letterPage_GeneratingPreview", "Genererer forhåndsvisning");
    public static letterPage_GeneratingErrorPreview = () => getTranslation("letterPage_GeneratingErrorPreview", "Fejl i generering af forhåndsvisning");
    public static letterPage_DeleteBrevError = () => getTranslation("letterPage_DeleteBrevError", "Fejl ved sletning af brevskabelon");
    public static letterPage_DeleteBrevSuccess = () => getTranslation("letterPage_DeleteBrevSuccess", "Brevskabelon blev slettet");
    public static letterPage_MergeFieldDataFetchError = () => getTranslation("letterPage_MergeFieldDataFetchError", "Fejl i afhentning af flettefelt data");
    public static letterPage_MergeFields = () => getTranslation("letterPage_MergeFields", "Flettefelter for brevskabeloner");
}
