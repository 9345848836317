import config from "config/config";
import React, {useEffect, useState} from 'react';
import sprite from '../../../content/icons/sprite.symbol.svg'
import {useHistory} from "react-router-dom";
import {Localizer} from "../../../infrastructure/localization/localizer";
import './topMenu.scss';
import {useAppSelector} from "../../../app/hooks";
import {LeftMenuState, leftMenuState} from "../leftMenu/leftMenuSlice";
import {mobileMaxWidthQuery} from "../responsive";
import {SlideIn} from 'core/components/slideIn/slideIn';
import {UserMenuHeader} from 'core/components/userMenuHeader/userMenuHeader';
import {UserMenuBody} from 'core/components/userMenu/userMenuBody';
import {useMediaQuery} from 'react-responsive';
import {UserTitleAndSubtitle} from "./userTitleAndSubtitle";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum
} from "../../components/userProfileAvatar/userProfileAvatar";
import {ReactComponent as Logo} from "../../../content/icons/logo-icon.svg";
import {UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";
import {UserProfileAvatarModel} from "../../components/userProfileAvatar/userProfileAvatarModel";
import { EmblaIcons } from 'core/emblaIcons';
import { EmblaIcon } from 'core/components/emblaIcon/emblaIcon';
import { laegeuddannelsenUrl } from 'infrastructure/redirectConstant';
import ExternalLink from 'core/components/link/ExternalLink';
import Tooltip, { TooltipPlacement } from 'core/components/tooltips/Tooltip';
import useStamdataApi from "../../hooks/useStamdataApi";
import {asPixelString} from "../../components/forloebOverview/OverviewComponent/StyleVariables";
import {
    DriftforstyrrelseModel,
} from "../../../services/api/driftsforstyrrelser/stamdataDriftsforstyrrelserResponseModel";
import NotificationButton from 'core/components/notifications/notificationButton';
import useNotificationCount from 'core/components/notifications/hooks/useNotificationCount';
import useNotificationSignal from 'services/signalRClient/hooks/useNotificationSignal';
import useNotifications from 'core/components/notifications/hooks/useNotifications';
import useUser from "core/hooks/useUser";

const modalTarget = "userMenu-modal";

export function TopMenu() {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const menuState = useAppSelector(leftMenuState) as LeftMenuState;

    const { currentUser } = useUser();

    const history = useHistory();

    const [topMenuSlideInOpen, settopMenuSlideInOpen] = useState(false);
    
    const isImpersonating = currentUser.AuthenticatedUserId !== currentUser.UserId;
    const { stamdataDriftsforstyrrelserApi } = useStamdataApi();
    const [nyesteDriftsforstyrrelse, setNyesteDriftsforstyrrelse] = useState<DriftforstyrrelseModel>();

    const showNotifications = config.showNotifications ?? true;

    const {
        currentUnseenRoleNotificationCount, 
        anyUnseenNotificationsOnOtherRoles, 
        getUnseenRoleNotificationCount, 
        refetchNotifikationerCount 
    } = useNotificationCount(showNotifications);

    const {
        refetchNotifications
    } = useNotifications(false);

    useNotificationSignal(() => {
        refetchNotifikationerCount();
        refetchNotifications();
    });

    const openTopMenuSlideIn = () => {
        settopMenuSlideInOpen(true);
    }

    const back = () => {
        history.goBack()
    }

    useEffect(() => {
        const fetchDriftsforstyrrelser = async () => {
            const driftsforstyrrelser = await stamdataDriftsforstyrrelserApi.getDriftsforstyrrelser();
            const [nyesteDriftforstyrrelse] = driftsforstyrrelser.sort((a: DriftforstyrrelseModel, b: DriftforstyrrelseModel) => a.startDato < b.startDato ? 1 : -1);
            setNyesteDriftsforstyrrelse(nyesteDriftforstyrrelse);
        };
        fetchDriftsforstyrrelser();
    },[stamdataDriftsforstyrrelserApi])

    return (
        <>
            <nav className={`navbar navbar-default navbar-expand-md bg-primary fixed-top ${IsMobile ? "mobile-header" : ""}`}>

                {IsMobile &&
                <>
                    <div className={"flex-container flex-align-c back-container"} onClick={() => back()}>
                        <svg className="embla-icon" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <use href={sprite + "#arrow-left"}/>
                        </svg>
                        <div className={"back-button"}>
                            {Localizer.global_back()}
                        </div>
                    </div>

                    {menuState.topmenuTitle &&
                        <div className={"top-menu-title"}>
                            <div className={"subtle"}>{menuState.topmenuTitle.subtitle}</div>
                            <h4>{menuState.topmenuTitle.title}</h4>
                        </div>
                    }
                </>
                }

                {!IsMobile && nyesteDriftsforstyrrelse &&
                    <div className="flex-row-start flex-align-l ">
                        <div className="driftsforstyrrelser-tekst" style={{"--margin-left-driftsforstyrrelser": asPixelString(menuState.collapsed ? 10 : 200)} as React.CSSProperties}>
                            <Tooltip title={nyesteDriftsforstyrrelse?.tekst} placement={TooltipPlacement.Left}>
                                <EmblaIcon iconName={EmblaIcons.Warning} additionalClasses={"margin-right-xs"}/>
                            </Tooltip>
                            {Localizer.landingPage_driftsforstyrrelser()}
                        </div>
                    </div>
                }

                <div className=" flex-row-center flex-align-r logo-titel" style={{"--margin-right-logo": asPixelString(!!nyesteDriftsforstyrrelse ? 275 : -71)} as React.CSSProperties}>
                    <a href="/" className={"top-menu-logo-link"}>
                        <div className="flex-row-center align-items-center">
                            {!IsMobile &&
                                <>
                                    <Logo/>
                                    <h5 className="title-color">
                                        {Localizer.global_uddannelseslaege()}
                                    </h5>
                                </>
                            }
                        </div>
                    </a>
                </div>

                <div className='top-menu-right-wrapper'>
                    {nyesteDriftsforstyrrelse && IsMobile &&
                        <Tooltip title={nyesteDriftsforstyrrelse?.tekst} placement={TooltipPlacement.Left}>
                            <EmblaIcon iconName={EmblaIcons.Warning} huge/>
                        </Tooltip>
                    }

                    <Tooltip title={Localizer.linkToSupportpage()} placement={TooltipPlacement.Left}>
                        <ExternalLink link={laegeuddannelsenUrl}>
                            <EmblaIcon iconName={EmblaIcons.Question} huge/>
                        </ExternalLink>
                    </Tooltip>

                    {showNotifications && 
                        <NotificationButton notificationCount={currentUnseenRoleNotificationCount ?? 0} additionalClasses="mr-2 flex-align-r" />
                    }

                    <div
                        className={`user-avatar-wrapper ${!IsMobile && "with-name"} top-menu-button ${isImpersonating && "impersonation-border rounded-pill overflow-hidden"}`}
                        onClick={openTopMenuSlideIn}>
                        <UserProfileAvatar
                            size={UserProfileAvatarSizeEnum.small}
                            userProfileAvatarModel={new UserProfileAvatarModel(currentUser.Name, currentUser.Initialer, currentUser.IsLaege())}
                            isImpersonating={isImpersonating}
                            showRedCircle={anyUnseenNotificationsOnOtherRoles}
                        />
                        {!IsMobile &&
                            <UserTitleAndSubtitle Title={currentUser.Name} Subtitle={UserRoleTitleToString(currentUser.CurrentRolleTitel)}/>
                        }
                    </div>
                </div>

            </nav>
            {topMenuSlideInOpen &&
                <SlideIn
                    id={modalTarget}
                    title={currentUser.Name}
                    actionText={Localizer.global_editing()}
                    defaultOpen={true}
                    headerContent={<UserMenuHeader/>}
                    actionOnCloseCallback={() => settopMenuSlideInOpen(false)}
                    bodyContent={<UserMenuBody getRoleNotificationCountCallback={getUnseenRoleNotificationCount}/>}
                />
            }

        </>
    );
}


