import {Route} from 'react-router-dom';
import {LeftMenu} from "../../layout/leftMenu/leftMenu";
import {TopMenu} from "../../layout/topMenu/topMenu";
import {TitleBar} from "../../layout/titleBar/titleBar";
import {AccountService} from "../../../services/account/accountService";
import {RolleEnum} from "../../../infrastructure/enums/userRole";
import {DispatchShowTitlebar, ShouldBeFullscreen} from "../../layout/responsive";
import {BottomMenu} from "../../layout/buttomMenu/bottomMenu";
import {ValiderVejledereWarning} from 'core/components/validerVejledereWarning/validerVejldereWarning';
import {useEffect, useState} from "react";
import useUser from 'core/hooks/useUser';
import { UserModel } from 'services/account/userModel';
import useScreenResolution from 'core/hooks/useScreenResolution';

const accountService = new AccountService();

const resolveIsCurrentUserAllowedToViewPage = (currentUser: UserModel, routeParams: any) => {
    const routeUserRoles = {...routeParams}.roles as Array<RolleEnum>;

    const userIsAuthenticated = currentUser.Authenticated;
    const userHasRouteRole = (routeUserRoles === undefined || currentUser.HasRole(routeUserRoles));
    
    return userIsAuthenticated && userHasRouteRole;
}

export function PrivateRoute({children, ...rest}: { [p: string]: any, children: any }): JSX.Element {
    const { isMobile } = useScreenResolution();
    const [userGranted, setUserGranted] = useState(false);
    const { currentUser } = useUser();

    useEffect(() => {
        const handleUserHasAccess = async () => {
            const isAuthenticatedToAccessRoute = resolveIsCurrentUserAllowedToViewPage(currentUser, rest);
    
            if (!isAuthenticatedToAccessRoute){
                await accountService.SignInRedirect(); 
            }
    
            const userExpired = await accountService.IsUserExpired();
            if (isAuthenticatedToAccessRoute && !userExpired){
                setUserGranted(true);
            }
        }
        handleUserHasAccess();
        
    }, [currentUser, rest])

    const path = {...rest}.path as string;

    const fullscreen = ShouldBeFullscreen(path);
    const titlebarShown = DispatchShowTitlebar(path);

    return (
        <>
            <Route
                {...rest}
                render={({location}) =>
                    userGranted ? (
                        <div className={"body-container"}>
                            {!isMobile &&
                                <LeftMenu/>
                            }
                            <TopMenu/>

                            <div className="page-content">
                                <div className="main-content">
                                    <TitleBar/>
                                    <div className={"body-content" + (fullscreen ? " fullscreen" : "") + (titlebarShown ? " titlebarShown" : "") + (isMobile ? " bottommenuShown" : "")}>
                                        {children}
                                    </div>
                                </div>
                            </div>

                            {isMobile &&
                                <BottomMenu/>
                            }

                            <ValiderVejledereWarning />
                        </div>
                    ) : (
                        <></>
                    )
                }
            />
        </>
    );
}
