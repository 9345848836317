import {useRef} from "react";
import {AccountService} from "../../../services/account/accountService";
import './userMenuHeader.scss';
import {UserTitleAndSubtitle} from "../../layout/topMenu/userTitleAndSubtitle";
import { UserRoleTitleToString } from "infrastructure/enums/rolleTitelEnum";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum
} from "../userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../userProfileAvatar/userProfileAvatarModel";
import useUser from "core/hooks/useUser";

export function UserMenuHeader() {
    const { currentUser } = useUser();

    return (
        <div className='user-avatar-wrapper'>
            <UserProfileAvatar
                size={UserProfileAvatarSizeEnum.small}
                userProfileAvatarModel={new UserProfileAvatarModel(currentUser.Name, currentUser.Initialer, currentUser.IsLaege())}
            />
            <UserTitleAndSubtitle Title={currentUser.Name} Subtitle={UserRoleTitleToString(currentUser.CurrentRolleTitel)} />
        </div>
    )
}
