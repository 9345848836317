import './table.scss';
import {Localizer} from "infrastructure/localization/localizer";
import {TableModule} from "ditmer-embla";
import {useEffect, useState} from "react";
import {tableActionsId, tableFilterId, TableProps, tableTitleId} from "./tableProps";
import {DataTableHeader} from "./DataTableHeader";
import useEffectAsync from 'infrastructure/effect';
import { useHeaderActions } from './useHeaderActions';

export const Table = ({objectSelectedCallback, tableIdentifier, ...props}: TableProps) => {
    const [tableModule, setTableModule] = useState<TableModule>();
    const { initializeHeaderActions } = useHeaderActions(props.renderHeaderActions?.actions);

    useEffectAsync(() => {
        initTable();
        initializeHeaderActions();
    }, [])

    const initTable = () => {
        let actions: any;
        if (props.renderTableActions) {
            actions = {
                actionsSelector: `#${tableActionsId(tableIdentifier)}`,
                selectedTextPlural: props.actionsSelectedTextPlural,
                selectedTextSingle: props.actionsSelectedTextSingle,
            };
        }

        const table = new TableModule({
            tableSelector: `#${tableIdentifier}`,
            titleSelector: props.hideHeaderBodies ? undefined : `#${tableTitleId(tableIdentifier)}`,
            filterSelector: props.hideHeaderBodies ? undefined : `#${tableFilterId(tableIdentifier)}`,
            responsive: props.responsive ?? true,
            lengthChange: props.showLengthChange ?? true,
            paging: props.showPaging ?? true,
            pageLengthOptions: {
                showAllText: Localizer.global_all(),
                values: [10, 25, 50]
            },
            defaultPageLength: 25,
            actions: actions,
            additionalDatatableSettings: {
                stateSave: true,
                searching: props.showSearching ?? true,
                order: props.columnDefaultOrder ? props.columnDefaultOrder : undefined
            },
            noResults: {
                noResultsMessage: Localizer.noResults(),
                noResultsIllustrationName: "failure-mark"
            },
            filtersAsModal: {
                filtersInModal: props.showFilter ? "auto" : "never"
            }
        });

        // If any initial rows are specified, select them when the table loads
        if (props.initialSelectedRows) {
            table.getDatatablesApi().rows().every((rowIdx, tableLoop, rowLoop) => {
                const row = table.getDatatablesApi().row(rowIdx).node();
                const rowId = $(row).data("id");
                if (rowId && props.initialSelectedRows!.includes(rowId)) {
                    table.getDatatablesApi().row(row).select();
                }
            })
        }

        setTableModule(table);
    }

    useEffect(() => {
        const handleChange = (e: any, dt: any, type: any, indexes: any) => {
            if (!objectSelectedCallback) return;

            const selectedRows = tableModule?.getAllSelectedRows();
            const ids: string[] = [];
            selectedRows?.each((value: HTMLElement, index) => {
                ids.push($(value).data("id"));
            });

            objectSelectedCallback(ids);
        };
    
        tableModule?.getDatatablesApi().on('change', handleChange);
    
        return () => {
            tableModule?.getDatatablesApi().off('change', handleChange);
        };
    }, [objectSelectedCallback, tableModule]);

    useEffect(() => {
        $(`#${tableFilterId(tableIdentifier)}` + " select").on("change", (e) => {
            const $filterDropdown = $(e.target);
            const columnToFilterIndex = $filterDropdown.data("filter-column") as number;
            const valueToFilter = $filterDropdown.find("option:selected").val() as string;

            tableModule?.filterColumn(columnToFilterIndex, valueToFilter, false, false);
        });
    }, [tableIdentifier, tableModule]);

    const renderTableHead = (columnHeaders: DataTableHeader[]) => {
        return (
            <thead>
                <tr>
                    {columnHeaders &&
                        columnHeaders.map((columnHeader, index) => {
                            return (
                                <th data-orderable={columnHeader.orderable} key={index}>{columnHeader.title}</th>
                            );
                        })
                    }
                </tr>
            </thead>
        );
    }

    return (
        <div className={`datatable-pretty-init ${props.hideHeaderBodies ? 'header-hidden' : ''}`}>
            <div id={tableTitleId(tableIdentifier)} className="child-pretty-init flex-space">
                <h3>
                    {props.tableHeader}
                </h3>

                {props.renderHeaderActions &&
                    <>
                        {props.renderHeaderActions.renderMethod()}
                    </>
                }
            </div>

            {props.renderTableFilters &&
            <div id={tableFilterId(tableIdentifier)} className="child-pretty-init">
                {props.renderTableFilters()}
            </div>
            }

            {props.renderTableActions &&
            <div id={tableActionsId(tableIdentifier)} className="child-pretty-init">
                {props.renderTableActions()}
            </div>
            }

            <table className="table" id={tableIdentifier}>
                {renderTableHead(props.columnHeaders)}
                {props.renderTableBody()}
            </table>
        </div>
    );
}
