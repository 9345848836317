import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {dateTimeStringFromApiToDate} from "../../../index";
import {LaegekursusResponseModel} from "./laegekursusResponseModel";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {GodkendelseInfoModel} from "../../../core/sharedmodels/godkendelse/godkendelseInfoModel";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";

export class LaegekursusModel {
    public id: string;
    public brugerId: string;
    public forloebId: string;
    public uddannelseType: UddannelseTypeEnum;
    public titel: string;
    public laegekursusGruppeId: string;
    public brugerKursusraekkeId: string;
    public udbyder: string;
    public eventuelSpecifikation?: string;
    public startDato?: Date;
    public slutDato?: Date;
    public beskrivelse: string;
    public status: LaegekursusStatusEnum;
    public aendretEllerOprettetAfLaege: boolean;
    public notatFileCount: number;
    public godkendelsesInfo?: GodkendelseInfoModel;
    public gruppeType: LaegekursusGruppeTypeEnum;
    public kursusId?: string;
    public godkendPaaAndetGrundlag: boolean;
    public sortOrder: number;
    public oprettetAfService: boolean;

    constructor(responseModel : LaegekursusResponseModel) {
        this.id = responseModel.id;
        this.brugerId = responseModel.brugerId;
        this.forloebId = responseModel.forloebId;
        this.brugerKursusraekkeId = responseModel.brugerKursusraekkeId;
        this.uddannelseType = responseModel.uddannelseType;
        this.titel = responseModel.titel;
        this.laegekursusGruppeId = responseModel.laegekursusGruppeId;
        this.udbyder = responseModel.udbyder;
        this.eventuelSpecifikation = responseModel.eventuelSpecifikation;
        this.startDato = responseModel.startDato ? dateTimeStringFromApiToDate(responseModel.startDato) : undefined;
        this.slutDato = responseModel.slutDato ? dateTimeStringFromApiToDate(responseModel.slutDato) : undefined;
        this.beskrivelse = responseModel.beskrivelse;
        this.status = responseModel.status;
        this.aendretEllerOprettetAfLaege = responseModel.aendretEllerOprettetAfLaege;
        this.notatFileCount = responseModel.notatFileCount;
        this.godkendelsesInfo = responseModel.godkendelseResponseModel !== null && responseModel.godkendelseResponseModel !== undefined ? new GodkendelseInfoModel(responseModel.godkendelseResponseModel) : undefined;
        this.gruppeType = responseModel.gruppeType;
        this.kursusId = responseModel.kursusId;
        this.godkendPaaAndetGrundlag = responseModel.godkendtPaaAndetGrundlag;
        this.sortOrder = responseModel.sortOrder;
        this.oprettetAfService = responseModel.oprettetAfService;
    }
}
