import React from "react";
import {StillingForloebOverviewModel} from "../../../sharedmodels/stilling/stillingForloebOverviewModel";

export interface StillingViewProps {
    stillingLookUp: { stillingModel: StillingForloebOverviewModel, rowIndex: number, uddannelsesstedId: string, afdelingId?: string, collapsed: boolean };
    visHorizontaleLinjerState: boolean;
    isOnAfdeling?: boolean;
}

export function StillingView(props: StillingViewProps) {
    return <React.Fragment key={props.stillingLookUp.stillingModel.stillingId}>
        <div className={`${props.visHorizontaleLinjerState ? "border-top" :""} stilling-header ${props.stillingLookUp.collapsed ? "collapse" : ""}`}
             style={{"--stilling-rowindex": (props.stillingLookUp.rowIndex)} as React.CSSProperties}>
            <p className={`margin-left-l`}>{props.stillingLookUp.stillingModel.stillingNummer}</p>
        </div>
    </React.Fragment>
}
