import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useAppSelector} from "../../../app/hooks";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {
    kompetenceCardReducer,
    KompetenceCardState,
    scrollToActiveKompetence,
    setActiveLaegekompetenceMeta,
    toggleLaegekompetenceNotatChangedPing
} from "../cards/kompetenceCardSlice";
import {LaegekompetenceMeta} from "../../../core/sharedmodels/kompetence/laegekompetenceMeta";
import {KompetenceDetails} from "./kompetenceDetails";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {isActiveTab, Tab, Tabs} from "../../../core/components/Tabs/Tabs";
import {StatusButton} from "../../../core/componentsPage/kompetence/statusButton";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
    CreateLaegekompetenceNotat
} from "../../../core/componentsPage/notat/kompetenceNotat/createLaegekompetenceNotat";
import {KompetenceNotatList} from "../../../core/componentsPage/notat/kompetenceNotat/kompetenceNotatList";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {AsyncButton} from "../../../core/components/button/asyncButton";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {RoutePaths} from "../../../infrastructure/routes";
import {useRouteMatch} from "react-router-dom";
import DynamicButton, {DynamicButtonRoundedClasses, TextIconItem} from "core/components/button/dynamicButton";
import {EmblaIcons} from "core/emblaIcons";
import {tryToBool} from "index";
import useUser from "core/hooks/useUser";
import useLogbogApi from "core/hooks/useLogbogApi";
import useStamdataApi from "core/hooks/useStamdataApi";
import {createPortal} from "react-dom";
import SpecialeSpecificKompetenceFilesList
    from "core/componentsPage/notat/kompetenceNotat/specialeSpecificKompetenceFilesList";
import useSpecialeSpecificFiles from "core/hooks/useSpecialeSpecificFiles";
import {KompetencerOverviewPageRouteParams} from "../kompetencerOverviewPage";
import useLinkHistory from "infrastructure/route/useLinkHistory";
import {KompetenceEvalueringsskemaerOverview} from "../evaluering/kompetenceEvalueringsskemaerOverview";
import {appendCounter} from "../../../core/helpers/stringHelper";

export type KompetenceOverviewProps = {
    isReadOnly?: boolean
    currentBrugerMaalbeskrivelse?: string
    getCurrentBrugerMaalbeskrivelse?: (currentBrugerMaalbeskrivelse?: string) => void
    getAllRegardlessOfMaalbeskrivelse?: boolean
    activeTab?: string
    initalKompetence?: string;
    initalUddannelseslaegeId?: string;
}

export const kvTabIcon = EmblaIcons.ListQuestion;
export const NotatTabIcon = EmblaIcons.Comment;
export const SkemaTabIcon = EmblaIcons.Document;

export const KompetenceOverview = ({initalUddannelseslaegeId="", ...props}: KompetenceOverviewProps) =>  {
    const { history } = useLinkHistory(); //Makes sure we automatically resets "linkState", if the user clicks "back"

    const dispatch = useDispatch();
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const routeMatch = useRouteMatch<KompetencerOverviewPageRouteParams>();

    const { logbogExportApi, logbogLaegeKompetenceApi } = useLogbogApi();
    const { stamdataMaalbeskrivelseApi } = useStamdataApi();
    const { currentUser } = useUser();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const currentForloebState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [currentLaegekompetence, setCurrentLaegekompetence] = useState<LaegekompetenceMeta>(new LaegekompetenceMeta());
    const [isReadOnly, setIsReadOnly] = useState<boolean>(props.isReadOnly ?? false);
    const [brugerId, setBrugerId] = useState<string>();
    const [brugerMaalbeskrivelseId, setBrugerMaalbeskrivelseId] = useState<string>();
    const { allFileMetaData } = useSpecialeSpecificFiles(kompetenceCardState.activeLaegekompetenceMeta.kompetenceId, forloebSliceState.forloebState.regionId, kompetenceCardState.activeLaegekompetenceMeta.brugerMaalbeskrivelseId);
    const [activeTabId, setActiveTabId] = useState<string | null>(props.activeTab ?? null);

    const modalTarget = "kompetencenotat-create-modal";

    const getAllRegardlessOfMaalbeskrivelse = IsMobile && routeMatch.params.getAllRegardlessOfMaalbeskrivelse ? tryToBool(routeMatch.params.getAllRegardlessOfMaalbeskrivelse) : props.getAllRegardlessOfMaalbeskrivelse;
    const uddannelseslaegeId = IsMobile ? routeMatch.params.id : initalUddannelseslaegeId;
    const laegeKompetenceId = IsMobile ? routeMatch.params.laegeKompetenceId : props.initalKompetence;

    const [evalueringCount, setEvalueringCount] = useState<number>(kompetenceCardState.activeLaegekompetenceMeta.evalueringCount)

    useEffect(() => {
        const fetchAndSetLaegeKompetence = async () => {
            const overviewKompetenceIsOnCurrentForloeb = uddannelseslaegeId === forloebSliceState.forloebState.brugerId;
            const overviewKompetenceDoesntMatchActiveKompetence = laegeKompetenceId !== kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId;

            if (!!laegeKompetenceId && overviewKompetenceIsOnCurrentForloeb && overviewKompetenceDoesntMatchActiveKompetence) {
                const laegeKompetenceMeta = await logbogLaegeKompetenceApi.getLaegekompetenceById(laegeKompetenceId, uddannelseslaegeId);
                dispatch(setActiveLaegekompetenceMeta(laegeKompetenceMeta.ToState()));
            }
        }
        fetchAndSetLaegeKompetence();

    }, [dispatch, forloebSliceState.forloebState.brugerId, kompetenceCardState.activeLaegekompetenceMeta.laegekompetenceId, laegeKompetenceId, logbogLaegeKompetenceApi, uddannelseslaegeId])

    useEffect(() => {
        if (kompetenceCardState.activeLaegekompetenceMeta?.laegekompetenceId) {

            const hasChangedKompetence = kompetenceCardState.activeLaegekompetenceMeta?.laegekompetenceId !== currentLaegekompetence.laegekompetenceId && !!currentLaegekompetence.laegekompetenceId;
            if(hasChangedKompetence) {
                setActiveTabId(null);
            }

            setBrugerId(kompetenceCardState.activeLaegekompetenceMeta.UserId);
            setBrugerMaalbeskrivelseId(kompetenceCardState.activeLaegekompetenceMeta.brugerMaalbeskrivelseId);
            const laegekompetence = LaegekompetenceMeta.FromState(kompetenceCardState.activeLaegekompetenceMeta, currentForloebState.forloebState);
            setCurrentLaegekompetence(laegekompetence);

            if (!getAllRegardlessOfMaalbeskrivelse && !!forloebSliceState.forloebState.brugerMaalbeskrivelseId) {
                // Check if our active-kompetence is NOT on same "brugerMaalbeskrivelse" as our current forloeb:
                const lageKompetenceIsNotOnForloebBrugerMaalbeskrivelse = !!kompetenceCardState.activeLaegekompetenceMeta.brugerMaalbeskrivelseId && kompetenceCardState.activeLaegekompetenceMeta.brugerMaalbeskrivelseId !== forloebSliceState.forloebState.brugerMaalbeskrivelseId;
                setIsReadOnly(lageKompetenceIsNotOnForloebBrugerMaalbeskrivelse);
            }
            setEvalueringCount(laegekompetence?.evalueringCount)
        }
    }, [currentForloebState.forloebState, currentLaegekompetence.laegekompetenceId, forloebSliceState.forloebState.brugerMaalbeskrivelseId, getAllRegardlessOfMaalbeskrivelse, kompetenceCardState.activeLaegekompetenceMeta, stamdataMaalbeskrivelseApi])

    const tabs: Tab[] = [
        {
            title: Localizer.global_overview(),
            id: Localizer.global_overview(),
            defaultActive: isActiveTab(Localizer.global_overview(), activeTabId) ?? true,
            content: <KompetenceDetails />
        }, {
            hide: !kompetenceCardState.activeLaegekompetenceMeta.hasActiveKvSkema && kompetenceCardState.activeLaegekompetenceMeta.evalueringCount === 0,
            title: appendCounter(Localizer.global_evaluations(), evalueringCount),
            id: Localizer.global_evaluations(),
            defaultActive: isActiveTab(Localizer.global_evaluations(), activeTabId) ?? false,
            content: (<KompetenceEvalueringsskemaerOverview laegeKompetence={currentLaegekompetence} deleteKompetenceEvalueringCallback={() => setEvalueringCount(evalueringCount - 1)}/>),
            icon: kvTabIcon
        }, {
            title: appendCounter(Localizer.global_notes(), currentLaegekompetence.notatCount),
            id: Localizer.global_notes(),
            defaultActive: isActiveTab(Localizer.global_notes(), activeTabId) ?? false,
            content: (<KompetenceNotatList />),
            icon: NotatTabIcon
        }, {
            title: appendCounter(Localizer.global_helpForms(), allFileMetaData.length),
            id: Localizer.global_helpForms(),
            defaultActive: isActiveTab(Localizer.global_helpForms(), activeTabId) ?? false,
            content: (<div className={"margin-m"}><SpecialeSpecificKompetenceFilesList files={allFileMetaData}/></div>),
            icon: SkemaTabIcon
        }
    ]

    const createLaegekompetenceNotat = () => {
        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: modalTarget }))
    }

    const getLaegekompetencePdf = (brugerId: string, brugerMaalbeskrivelseId: string) : Promise<void> => {
        return logbogExportApi.getPdfKompetence(currentLaegekompetence.laegekompetenceId, brugerId, brugerMaalbeskrivelseId);
    }

    const renderActionButtons = (
        <>
            {(!(kompetenceCardState.showCheckboxOnIds.length > 0) && !currentUser.IsKonsulentForLaegeligVidereuddannelse() && brugerId) &&
                <div className={`flex-container flex-space-around margin-bottom-xs ${IsMobile ? "margin-left-xs margin-right-xs" : ""}`}>
                    {brugerId && brugerMaalbeskrivelseId &&
                        <AsyncButton
                            clickFunction={() => getLaegekompetencePdf(brugerId, brugerMaalbeskrivelseId)}
                            waitText={Localizer.gettingPdf()}
                            additionalClasses={IsMobile ? DynamicButtonRoundedClasses : undefined}
                            child={<TextIconItem iconName={EmblaIcons.Incoming} text={Localizer.GetPdf()}/>}
                        />
                    }

                    <DynamicButton
                        onClick={createLaegekompetenceNotat}
                        disabled={kompetenceCardState.activeLaegekompetenceMeta.godkendtDato !== undefined}
                        modal={{ dataTarget: `#${modalTarget}` }}
                    >
                        <DynamicButton.TextIconItem iconName={EmblaIcons.DocumentAdd} text={Localizer.notatPage_createNotat()} />
                    </DynamicButton>

                    <StatusButton key={brugerId} brugerId={brugerId}/>
                </div>
            }
        </>
    )

    const createSlideInFinishedCallback = () => {
        dispatch(scrollToActiveKompetence(true));
        dispatch(setActiveLaegekompetenceMeta({ notatCount: kompetenceCardState.activeLaegekompetenceMeta.notatCount + 1 }));
        dispatch(toggleLaegekompetenceNotatChangedPing());
    }

    function renderKompetenceReadOnlyContent() {
        return <div className="d-flex flex-column">
            <div className="alert alert-warning margin-right-m margin-left-m">
                <b>{Localizer.kompetencePage_foraeldetKompetence()}</b> <br/>
                {Localizer.kompetencePage_foraeldetKompetenceBeskrivelse()} <br/>
                <button className="btn btn-default btn-round margin-top-s margin-bottom-s"
                        onClick={() => !IsMobile ? props.getCurrentBrugerMaalbeskrivelse?.(props.currentBrugerMaalbeskrivelse) : history.push(RoutePaths.Kompetencer({uddannelseslaege: currentForloebState.forloebState.brugerId}).url)}>
                    <b>{Localizer.kompetencePage_gaaTilAktivMaalbeskrivelse()}</b>
                </button>
            </div>
        </div>;
    }

    return (
        <>
            {(!!currentLaegekompetence?.laegekompetenceId && !!kompetenceCardState.activeLaegekompetenceMeta?.laegekompetenceId) &&
                <>
                    {!IsMobile &&
                        <div className="d-flex flex-column">
                            <h3 className="margin-m">{currentLaegekompetence.fullKompetenceTitleWithNumber}</h3>

                            { isReadOnly &&
                                renderKompetenceReadOnlyContent()
                            }

                            <Tabs tabs={tabs} setActiveCallback={(activeTabId) => setActiveTabId(activeTabId)} navTabRightSideContent={isReadOnly ? undefined : renderActionButtons} />
                        </div>
                    }

                    {IsMobile &&
                        <div className="d-flex flex-column">
                            <h3 className="margin-m">{currentLaegekompetence.fullKompetenceTitleWithNumber}</h3>

                            { isReadOnly &&
                                renderKompetenceReadOnlyContent()
                            }

                            {!isReadOnly &&
                                renderActionButtons
                            }

                            <Tabs tabs={tabs} setActiveCallback={(activeTabId) => setActiveTabId(activeTabId)} />
                        </div>
                    }
                </>
            }

            {currentLaegekompetence.laegekompetenceId !== undefined &&
                // Fix scroll-problems with "CreatePortal"
                createPortal(
                    <SlideIn
                        key={currentLaegekompetence.laegekompetenceId}
                        id={modalTarget}
                        title={Localizer.notatPage_createNotat()}
                        actionText={Localizer.notatPage_creatingNotat()}
                        bodyContent={<CreateLaegekompetenceNotat modalId={modalTarget} laegekompetencer={[currentLaegekompetence]} notatType={NotatType.Kompetence}/>}
                        actionFinishedCallback={createSlideInFinishedCallback}
                    />,
                    document.body
                )
            }
        </>
    );
}
