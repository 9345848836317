import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import React, {useCallback} from "react";
import "../../evaluering/evaluering/evalueringSektion.scss";
import {KompetenceSektionModel} from "../../../core/sharedmodels/evaluering/kompetenceSektionModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {KompetenceEvalueringSpoergsmaal} from "../spoergsmaal/kompetenceEvalueringSpoergsmaal";
import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {DeleteEvalueringSvarModel} from "../../../services/api/logbogEvaluering/deleteEvalueringSvarModel";

export interface KompetenceEvalueringSektionProps {
    sektion: KompetenceSektionModel;
    finishSektion: boolean;
    kompetenceEvalueringId: string;
    updateSvarCallback: (svar: KompetenceSvarModel) => void;
    deleteSvarCallback: (svar: DeleteEvalueringSvarModel) => void;
    finishSectionDisable: boolean;
    evalueringIndsendt: boolean;
    answerUpdateLoading: boolean;
    answerChosenCallback: (openNextSection: boolean) => void;
    nextButtonClickedCallback: () => void;
    setShowGodkendModalCallback: (show: boolean) => void;
}

export function KompetenceEvalueringSektion({
                                                sektion,
                                                finishSektion,
                                                answerChosenCallback,
                                                nextButtonClickedCallback,
                                                setShowGodkendModalCallback,
                                                finishSectionDisable,
                                                evalueringIndsendt,
                                                kompetenceEvalueringId,
                                                updateSvarCallback,
                                                deleteSvarCallback,
                                            }: KompetenceEvalueringSektionProps) {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const godkendModalId = "kompetenceEvalueringGodkend";

    const openNextSection = useCallback((spoergsmaalId: string) => {
        const otherQuestionsHasAnswers = sektion.spoergsmaal.filter(s => s.id !== spoergsmaalId).every(spoergsmaal => spoergsmaal.evalueringSvar.length === spoergsmaal.antalSvar);
        const currentQuestion = sektion.spoergsmaal.find(s => s.id === spoergsmaalId);
        //+1 fordi det nuvlrende spørgsmåls antal svar ikke er opdateret endnu, grundet updateSvarCallback ikke er eksekveret endnu - kan refaktureres til at lytte på statet.
        const currentQuestionHasAllPossibleAnswers = currentQuestion?.evalueringSvar && (currentQuestion?.evalueringSvar?.length + 1) >= currentQuestion?.antalSvar;
        if (otherQuestionsHasAnswers && currentQuestionHasAllPossibleAnswers)
            answerChosenCallback(true);
    },[answerChosenCallback, sektion.spoergsmaal])

    return (
        <div className={`max-w-600 d-flex card-body margin-top-0 wrap ${IsMobile ? "" : ""} "collapse show `}
             id={sektion.id}>
            {sektion.subTitle &&
                <h4 className={"subtle margin-bottom-m"}>{sektion.subTitle}</h4>
            }
            <div className={"d-flex flex-full-width"}>
                {sektion.tekst &&
                    <p className={"pre-line"}>{sektion.tekst}</p>
                }
            </div>
            <div>
                {sektion.spoergsmaal.sortBy(x => x.sortOrder).map(spoergsmaal => (
                    <div className={`margin-bottom-m ${IsMobile ? "" : "min-w-550"}`}
                         key={spoergsmaal.id}>
                        <div className="spoergsmaal">
                            <KompetenceEvalueringSpoergsmaal
                                updateSvarCallback={updateSvarCallback}
                                deleteSvarCallback={deleteSvarCallback}
                                kompetenceEvalueringId={kompetenceEvalueringId}
                                sektionId={sektion.id}
                                spoergsmaal={spoergsmaal}
                                indsendt={evalueringIndsendt}
                                answerChosenCallback={() => openNextSection(spoergsmaal.id)}
                            />
                        </div>
                    </div>
                ))}
                {(!finishSektion && !sektion.includedInProgress) &&
                    <button className="btn btn-primary d-flex margin-top-s"
                            onClick={() => nextButtonClickedCallback()}>
                        {Localizer.global_next()}
                    </button>
                }
                {finishSektion &&
                    <button className="btn btn-primary"
                            onClick={() => setShowGodkendModalCallback(true)}
                            disabled={finishSectionDisable}
                            data-toggle="modal"
                            data-target={`#${godkendModalId}`}>
                        {Localizer.global_afslut()}
                    </button>
                }
            </div>
        </div>
    );
}
