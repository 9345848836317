import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {LineChart, LineChartColor, LineChartItem} from "../../core/components/charts/lineChart";
import {Localizer} from "../../infrastructure/localization/localizer";
import {RoutePaths} from "../../infrastructure/routes";
import {NotificationModule} from "ditmer-embla";
import {useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import {ModalAcceptType, ModalSubmitMessage} from "../../core/components/modalMessage/modalSubmitMessage";
import {KompetenceEvalueringSektion} from "./sektion/kompetenceEvalueringSektion";
import {ScoreTypeEnum} from "../../core/componentsPage/score/scoreTypeEnum";
import {EvalueringCollapse} from "../../core/components/Tabs/evalueringCollapse";
import classNames from "classnames";
import useScreenResolution from "../../core/hooks/useScreenResolution";
import useLogbogApi from "../../core/hooks/useLogbogApi";
import {KompetenceEvalueringHookType} from "./useKompetenceEvaluering";
import {findIndexOrUndefined} from "../../core/helpers/filterHelper";
import {KompetenceSpoergsmaalModel,} from "../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import {ScoreModel} from "../../core/sharedmodels/evaluering/scoreModel";

type KompetenceEvalueringProps = {
    hook: KompetenceEvalueringHookType;
};

const getDefaultValues = (hook: KompetenceEvalueringHookType) => {
    const anyAnsweredQuestions = hook.evalueringSections.findIndex(s => s.spoergsmaal.find(spoergsmaal => hook.questionHasAnswer(spoergsmaal))) > 0;
    const firstSektionWithUnansweredQuestion = findIndexOrUndefined(hook.evalueringSections, s => !!s.spoergsmaal.length && s.includedInProgress && !s.spoergsmaal.every(spoergsmaal => hook.questionHasAnswer(spoergsmaal)));

    const openSectionIndex = !anyAnsweredQuestions ? 0 : firstSektionWithUnansweredQuestion ? firstSektionWithUnansweredQuestion : hook.evalueringSections.length - 1;
    const openTabId = hook.evalueringSections[openSectionIndex]?.id ?? undefined;

    return {
        openSectionIndex,
        openTabId
    }
}

export function KompetenceEvaluering({hook}: KompetenceEvalueringProps) {
    const godkendModalId = "kompetenceEvalueringGodkend";

    const history = useHistory();
    const {isMobile} = useScreenResolution();
    const {evalueringApi} = useLogbogApi();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [showGodkendModal, setShowGodkendModal] = useState(false);
    const [sektionIndexState, setSektionIndexState] = useState<number>(getDefaultValues(hook).openSectionIndex);
    const [isIndsendState, setIsIndsendState] = useState(getDefaultValues(hook).openSectionIndex === hook.evalueringSections.length - 1);
    const [expandedTabId, setExpandedTabId] = useState<string>(getDefaultValues(hook).openTabId);

    const generateChartItems = (): LineChartItem[] => {
        return [
            {count: sektionIndexState ?? 0, color: LineChartColor.success},
            {count: (hook.evalueringSections?.length - 1) - (sektionIndexState ?? 0), color: LineChartColor.gray}
        ];
    }

    const indsendEvaluering = async () => {
        if (!hook.kompetenceEvaluering) return;
        hook.setIndsendtStatus(true);
        const indsendtId = await evalueringApi.patchlaegeKompetenceEvaluering(hook.kompetenceEvaluering.laegekompetenceEvalueringId, true, []);
        if (indsendtId) {
            NotificationModule.showSuccess(Localizer.kompetenceEvalueringPage_evalueringGemt(), "");
            history.push(RoutePaths.Kompetencer({
                uddannelseslaege: forloebSliceState.forloebState.brugerId,
                activeTabId: Localizer.global_evaluations()
            }).url)
        }
    }

    const handleOpenSection = (sektionIndexState: number, id: string) => {
        setSektionIndexState(sektionIndexState);
        setIsIndsendState(sektionIndexState === hook.evalueringSections.length - 1);
        setExpandedTabId(id)
    }

    const GetSpoergsmaalScore = (spoergsmaal: KompetenceSpoergsmaalModel[]) => {
        const firstQuestion = spoergsmaal[0];
        const singleAnswer = firstQuestion?.evalueringSvar[0];
        const multipleAnswersInSection = spoergsmaal.length > 1;

        const newObject: ScoreModel = {
            scoreValue: !multipleAnswersInSection ? singleAnswer?.svarVaerdi : undefined,
            notRelevant: singleAnswer?.ikkeRelevant ?? false,
            allQuestionsHasAtleastOneAnswer: multipleAnswersInSection && spoergsmaal.filter(x => x.isRequired && !x.isFeedback).every(x => x.evalueringSvar.length > 0) && spoergsmaal.some(s => s.evalueringSvar.length > 0),
        };
        return newObject;
    }

    return (
        <>

            <div className="card shadow-none sticky-top">
                <div className="card-header justify-content-center">
                    <div className={classNames("w-75 text-center", isMobile ? "padding-m" : "padding-l")}>
                        <LineChart items={generateChartItems()} hideLabels={true}/>
                        <p>{sektionIndexState + 1}/{hook.evalueringSections.length}</p>
                    </div>
                </div>
            </div>
            {hook.evalueringSections.sortBy(x => x.sortOrder).map((sektion, index) => (
                <div className={"card card-small-margin margin-bottom-s shadow-sm"} key={sektion.id}>
                    <EvalueringCollapse additionalClasses={"flex-row"}
                                        id={sektion.id}
                                        title={sektion.title}
                                        expanded={expandedTabId === sektion.id}
                                        toggleCallback={(id: string) => {
                                            handleOpenSection(index, id);
                                        }}
                                        score={GetSpoergsmaalScore(sektion.spoergsmaal)}
                                        feedbackSpoergsmaal={sektion.spoergsmaal.some(s => s.isFeedback) ? sektion.spoergsmaal.filter(x => x.isFeedback) : undefined}
                                        scoreType={ScoreTypeEnum.KompetenceEvalueringScore}>
                        <KompetenceEvalueringSektion
                            sektion={sektion}
                            answerUpdateLoading={hook.answerUpdateLoading}
                            updateSvarCallback={hook.updateSvar}
                            deleteSvarCallback={hook.deleteSvar}
                            kompetenceEvalueringId={hook.kompetenceEvaluering.laegekompetenceEvalueringId}
                            evalueringIndsendt={hook.kompetenceEvaluering?.indsendt ?? false}
                            finishSectionDisable={hook.evalueringSections.flatMap(x => x.spoergsmaal).filter(x => x.isRequired).some(x => x.evalueringSvar.length < 1)}
                            setShowGodkendModalCallback={(show: boolean) => setShowGodkendModal(show)}
                            answerChosenCallback={(openNextSection: boolean) => {
                                if (hook.evalueringSections[index + 1]?.id && openNextSection)
                                    handleOpenSection(index + 1, hook.evalueringSections[index + 1]?.id)
                            }}
                            finishSektion={isIndsendState}
                            nextButtonClickedCallback={() => {
                                handleOpenSection(index + 1, hook.evalueringSections[index + 1].id)
                            }}
                        />
                    </EvalueringCollapse>
                </div>
            ))}

            {showGodkendModal &&
                <ModalSubmitMessage
                    title={Localizer.kompetenceEvalueringPage_saveVurderingAfKompetence()}
                    description={<p>{Localizer.KompetenceEvalueringPage_evalueringIndsendApprove()}</p>}
                    modalAcceptType={ModalAcceptType.primary}
                    modalId={godkendModalId}
                    acceptAction={indsendEvaluering}
                    cancelAction={() => setShowGodkendModal(false)}
                    acceptButtonText={Localizer.global_save()}
                />
            }
        </>
    );
}
