import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {FileMetadata} from "../../sharedmodels/fileMetaData/fileMetaData";
import React, {useState} from "react";
import {ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import {BuildNew, CreateMailInputModel, CreateMailValidationSchema} from "./createMailInputModel";
import {ValidationForm} from "../validation/components/validationForm";
import {MailInputFields} from "./mailInputFields";
import {ValidationFormSubmitButton} from "../validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {setSlideinState, SlideInStateEnum} from "../slideIn/slideInSlice";
import {useDispatch} from "react-redux";
import {SlideIn} from "../slideIn/slideIn";
import {LogbogEmailApi} from "../../../services/api/logbogEmail/logbogEmailApi";
import {ModalSize} from "../slideIn/modalSize";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../layout/responsive";
import {UserWithForloebAndStillingId} from "../../sharedmodels/email/UserWithForloebAndStillingId";
import Tooltip, {TooltipPlacement} from "../tooltips/Tooltip";

interface EmailSlideInProps {
    modalId: string;
    users: { id: string, email: string, forloebId?: string, stillingId?: string }[];
    actionFinishedCallback?: () => void;
}

const logbogEmailApi = new LogbogEmailApi(new HttpClientService())

export function EmailSlideInModal(props: EmailSlideInProps) {
    const dispatch = useDispatch();

    const [fileMetadataState, setFileMetadataState] = useState<FileMetadata[]>([]);

    let emailTypeEnum: number;
    let userEmails = props.users.map(x => x.email).join(", ");

    const filesUploadedCallback = async (uploadedFiles: ReactFileUploaderFile[]) => {
        let newFileMetadatas = uploadedFiles.map(rfu => rfu.fileMetadata);
        setFileMetadataState(arr => [...arr, ...newFileMetadatas]);
    }

    const fileDeletedCallback = async (fileMetadata: FileMetadata) => {
        let updatedFileList = [...fileMetadataState];
        let deleteIndex = updatedFileList.findIndex(f => f.id === fileMetadata.id);

        updatedFileList.splice(deleteIndex, 1);
        setFileMetadataState(updatedFileList);
    }

    const CreateMail = async (inputModel: CreateMailInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}));
        inputModel.fileMetadata = fileMetadataState.map((x) => {
            return x
        });

        await logbogEmailApi.CreateEmail(inputModel, props.users.map(x => new UserWithForloebAndStillingId(x.id, x.forloebId, x.stillingId)), emailTypeEnum);

        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}));
        setFileMetadataState([]);
    };

    const setSelectedTemplateType = (type: number) => {
        emailTypeEnum = type;
    }

    const bodyContent = () => {
        return (
            <ValidationForm
                key={props.modalId}
                initialValues={BuildNew()}
                validationSchema={CreateMailValidationSchema}
                onSubmit={async (values) => {await CreateMail(values)}}>
                {
                    (formik) => (
                        <>
                            <label>{Localizer.createMail_MailSentTo()}</label>
                            <Tooltip className={"text-truncate user-emails margin-bottom-m flex-align-l"}
                                     title={userEmails}
                                     placement={TooltipPlacement.Bottom}>
                                {userEmails}
                            </Tooltip>
                            <MailInputFields modalId={props.modalId}
                                             formik={formik}
                                             templateTypeCallBack={setSelectedTemplateType}
                                             fileUploadedCallback={filesUploadedCallback}
                                             fileDeletedCallback={fileDeletedCallback}/>
                            <div className="modal-actions">
                                <ValidationFormSubmitButton formikProps={formik}>
                                    {Localizer.createMail_SendMail()}
                                </ValidationFormSubmitButton>
                            </div>
                        </>
                    )
                }
            </ValidationForm>
        );
    }

    return (
        <>
            <SlideIn
                id={props.modalId}
                title={Localizer.createMail_SendMail()}
                actionText={Localizer.createMail_QueuingMail()}
                actionOnCloseCallback={props.actionFinishedCallback}
                bodyContent={bodyContent()}
                modalSize={ModalSize.md}
            />
        </>
    );
}
