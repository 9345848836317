import { Localizer } from "infrastructure/localization/localizer";
import FilterTitle from "./filterTitle";
import { useEffect, useState } from "react";
import ForSelectDropdown from "core/components/dropdown/components/forSelectDropdown";
import useStamdataApi from "core/hooks/useStamdataApi";
import { TypedFilterWithPermissionsProps } from "../types/typedFilterProps";
import { mapUserPermissionsToDropdownPermissions } from "core/sharedmodels/userPermissions/userFilterPermissions";
import RegionerMultiDropdown from "core/components/dropdown/components/regionerForSelectDropdown";
import FilterHierarchicalEnum from "core/sharedmodels/restrictModel/FilterHierarchicalEnum";
import { ContainsValue } from "index";
import { PlaceFilterVisualizationConfig } from "../types/visualizationConfig";

export type PlaceFilterResult = {
    regioner: string[];
    omraader: string[];
    afdelinger: string[],
    praksiser: string[],
    sygehuse: string[],
    allRegionsSelected?: boolean,
}

export type PlaceFilterRestrictModel = {
    filterHierarchicalEnums?: FilterHierarchicalEnum[];
} & PlaceFilterResult;

const initialSelectedValues: PlaceFilterResult = {
    regioner: [],
    omraader: [],
    afdelinger: [],
    praksiser: [],
    sygehuse: [],
    allRegionsSelected: false,
};

const defaultVisualizationConfig: PlaceFilterVisualizationConfig = {
    showRegioner: true,
    showOmraader: true,
    showPraksiser: true,
    showSygehuse: true,
    showAfdelinger: true,
}

const PlaceFilter = ({
    initialSelectedValues: selectedValues=initialSelectedValues,
    permissions,
    onChange,
    titled=false,
    additionalFilterTitleClasses,
    restrictModel,
    onFilterMenuCloseCallback,
    disable,
    visualizationConfig = defaultVisualizationConfig,
} : TypedFilterWithPermissionsProps<PlaceFilterResult, PlaceFilterRestrictModel, PlaceFilterVisualizationConfig>) => {

    const { stamdataOmraadeApi, stamdataLaegeinstitutionApi, stamdataAfdelingApi } = useStamdataApi();

    const [filter, setFilter] = useState<PlaceFilterResult>(selectedValues);

    useEffect(() => {
        onChange(filter);
    }, [filter, onChange]);

    const shouldDisableFilter = (hierarchicalEnum: FilterHierarchicalEnum, list: any[]) => ContainsValue(hierarchicalEnum, restrictModel?.filterHierarchicalEnums) && list.length === 0;

    return (
        <div>
            {titled &&
                <FilterTitle title={Localizer.global_sted()} additionalClasses={additionalFilterTitleClasses}/>
            }

            {visualizationConfig.showRegioner &&
                <RegionerMultiDropdown 
                    onChangeCallback={newValue => setFilter(prev => ({...prev, ...{
                        regioner: newValue.regionIds ?? [],
                        allRegionsSelected: newValue.allRegions
                    }}))} 
                    initialSelectedOption={filter.regioner}
                    title={Localizer.forloebOverviewPage_region()}
                    placeholder={Localizer.forloebOverviewPage_chooseRegions()}
                    permission={mapUserPermissionsToDropdownPermissions(permissions, p => p.regionIds)}
                    restrictedValues={restrictModel?.regioner}
                    disable={shouldDisableFilter(FilterHierarchicalEnum.Region, filter.regioner) || disable}
                    onMenuCloseCallback={onFilterMenuCloseCallback}
                />
            }

            {visualizationConfig.showOmraader &&
                <ForSelectDropdown 
                    onChangeCallback={newValue => setFilter(prev => ({...prev, omraader: newValue.map(x => x.value)}))} 
                    apiFetchPromise={stamdataOmraadeApi.getOmraaderSelect()}
                    initialSelectedOption={filter.omraader}
                    title={Localizer.forloebOverviewPage_omraade()}
                    placeholder={Localizer.forloebOverviewPage_chooseOmraade()}
                    permission={mapUserPermissionsToDropdownPermissions(permissions, p => p.omraadeIds)}
                    restrictedValues={restrictModel?.omraader}
                    disable={shouldDisableFilter(FilterHierarchicalEnum.Omraade, filter.omraader) || disable}
                    onMenuCloseCallback={onFilterMenuCloseCallback}
                    isMulti
                />
            }

            {visualizationConfig.showPraksiser &&
                <ForSelectDropdown 
                    onChangeCallback={newValue => setFilter(prev => ({...prev, praksiser: newValue.map(x => x.value)}))} 
                    apiFetchPromise={stamdataLaegeinstitutionApi.getPraksisSelect()}
                    initialSelectedOption={filter.praksiser}
                    title={Localizer.forloebOverviewPage_praksis()}
                    placeholder={Localizer.forloebOverviewPage_choosePraksis()}
                    permission={mapUserPermissionsToDropdownPermissions(permissions, p => p.praksisIds)}
                    restrictedValues={restrictModel?.praksiser}
                    disable={shouldDisableFilter(FilterHierarchicalEnum.Praksis, filter.praksiser) || disable}
                    onMenuCloseCallback={onFilterMenuCloseCallback}
                    isMulti
                />
            }

            {visualizationConfig.showSygehuse &&
                <ForSelectDropdown 
                    onChangeCallback={newValue => setFilter(prev => ({...prev, sygehuse: newValue.map(x => x.value)}))} 
                    apiFetchPromise={stamdataLaegeinstitutionApi.getAllSygehuseSelect()}
                    initialSelectedOption={filter.sygehuse}
                    title={Localizer.forloebOverviewPage_sygehus()}
                    placeholder={Localizer.forloebOverviewPage_chooseSygehus()}
                    permission={mapUserPermissionsToDropdownPermissions(permissions, p => p.sygehusIds)}
                    restrictedValues={restrictModel?.sygehuse}
                    disable={shouldDisableFilter(FilterHierarchicalEnum.Sygehus, filter.sygehuse) || disable}
                    onMenuCloseCallback={onFilterMenuCloseCallback}
                    isMulti
                />
            }

            {visualizationConfig.showAfdelinger &&
                <ForSelectDropdown 
                    onChangeCallback={newValue => setFilter(prev => ({...prev, afdelinger: newValue.map(x => x.value)}))} 
                    apiFetchPromise={stamdataAfdelingApi.getAllAfdelingerSelect()}
                    initialSelectedOption={filter.afdelinger}
                    title={Localizer.forloebOverviewPage_afdeling()}
                    placeholder={Localizer.forloebOverviewPage_chooseAfdeling()}
                    permission={mapUserPermissionsToDropdownPermissions(permissions, p => p.afdelingIds)}
                    restrictedValues={restrictModel?.afdelinger}
                    disable={shouldDisableFilter(FilterHierarchicalEnum.Afdeling, filter.afdelinger) || disable}
                    onMenuCloseCallback={onFilterMenuCloseCallback}
                    isMulti
                />
            }

        </div>
    );
}

export default PlaceFilter;