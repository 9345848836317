import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import {RoutePaths} from "infrastructure/routes";
import {AuthPage} from "pages/auth/authPage";
import {ForloebListPage} from "pages/forloebList/forloebListPage";
import {UserProfilePage} from "pages/userProfile/userProfilePage";
import { ChangeRole } from "../../../pages/auth/changeRole";
import {NotFoundPage} from "../errors/notFoundPage";
import {UddannelseslaegePage} from "pages/uddannelseslaege/uddannelseslaegePage";
import {ErrorBoundary} from "../errors/errorBoundary";
import {PrivateRoute} from "./privateRoute";
import {KompetencerPage} from "../../../pages/kompetencerPage/kompetencerPage";
import {ForloebOverviewPage} from "../../../pages/forloebOverview/forloebOverviewPage";
import {ForloebPage} from "../../../pages/forloeb/forloebPage";
import {VejlederePage} from "../../../pages/vejledere/vejlederePage";
import {NotaterPage} from "../../../pages/notater/notaterPage";
import {NyhederPage} from "../../../pages/nyheder/nyhederPage";
import {KompetencerOverviewPage} from "../../../pages/kompetencerPage/kompetencerOverviewPage";
import {SendStpsAnsoegningPage} from "../../../pages/sendStpsAnsoegning/sendStpsAnsoegningPage";
import {VejledningPage} from "../../../pages/vejledning/vejledningPage";
import {KlarmeldingerPage} from "../../../pages/klarmeldinger/klarmeldingerPage";
import {EvalueringPage} from "../../../pages/evaluering/evalueringPage";
import {Impersonate} from "../../../pages/auth/impersonate";
import {LandingPage} from "../../../pages/landing/landingPage";
import {PublicRoute} from "../../layout/publicRoute";
import {EditKompetenceEvalueringPage} from "../../../pages/kompetenceEvaluering/editKompetenceEvalueringPage";
import {LaegekurserPage} from "../../../pages/kurserPage/laegekurserPage";
import {EvalueringsstatistikPage} from "../../../pages/evalueringsstatistik/evalueringsstatistikPage";
import {CreateEvalueringsstatistikPage} from "../../../pages/evalueringsstatistik/createEvalueringsstatistikPage";
import {Vilkaar} from "../../components/infoPages/vilkaar";
import {CookiesInformationPage} from "../../components/infoPages/privatlivspolitik";
import {BrugerePage} from "../../../pages/bruger/brugerePage";
import {ContactPage} from "../../../pages/landing/contactPage";
import UddannelseslaegeOverviewPage from "pages/uddannelseslaegeOverview/uddannelseslaegeOverviewPage";
import {KursisterOverviewPage} from "../../../pages/kursisterOverview/kursisterOverviewPage";
import {ErrorBoundaryPdf} from "../errors/errorBoundaryPdf";
import {EvalueringSpoergerammePdf} from "../../components/pdf/evalueringSpoergerammePdf";
import {EvalueringStatistikPdf} from "../../components/pdf/evalueringStatistikPdf";
import {KursusPdf} from "../../components/pdf/kursusPdf";
import {KompetencePdf} from "../../components/pdf/kompetencePdf";
import {KompetencerPdf} from "../../components/pdf/kompetencerPdf";
import {LaegeligBeskaeftigelsePdf} from "../../components/pdf/laegeligBeskaeftigelsePdf";
import {AttestationForTidPdf} from "../../components/pdf/attestationForTidPdf";
import NotifikationContextProvider from "core/components/notifications/context/notifikationContextProvider";
import {NotifikationHistorikPage} from "../../../pages/notifikationer/notifikationHistorikPage";
import {DataadministrationOverviewPage} from "../../../pages/forloeb/dataadministration/dataadministrationOverviewPage";
import FileAdministrationPage from "pages/forloeb/filePage/fileAdministrationPage";
import {FilerPage} from "../../../pages/filer/filerPage";
import {UddannelsesplanerPdf} from "../../components/pdf/uddannelsesplanPdf";
import VejledersamtalePdf from "core/components/pdf/VejledersamtalerPdf";
import { AllKompetencerOnUserPdf } from "core/components/pdf/allKompetencerPdf";
import AllVurderingerPdf from "core/components/pdf/allVurderingerPdf";
import AllUddannelseslaegeNotesPdf from "core/components/pdf/AllUddannelseslaegeNotesPdf";
import useUser from "core/hooks/useUser";
import useScreenResolution from "core/hooks/useScreenResolution";
import BrevskabelonPage from "../../../pages/brevskabelon/BrevskabelonPage";
import BrevCreatePage from "pages/brevskabelon/components/BrevCreatePage";
import BrevEditPage from "pages/brevskabelon/components/BrevEditPage";
import BrevGeneratePdfPage from "pages/brevskabelon/components/BrevGeneratePdfPage";

export default function MainRouting() {
    const { currentUser } = useUser();
    const { isMobile } = useScreenResolution();

    const renderIndexRedirect = () => {

        if (!currentUser.Authenticated) {

            return <PublicRoute exact path={RoutePaths.Index.path} isAuthenticated={currentUser.Authenticated}>
                <LandingPage/>
            </PublicRoute>
        }

        if (currentUser.IsAdmin()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.UddannelseslaegeOverview.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsUddannelsesansvarlig()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.UddannelseslaegeOverview.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsVejleder()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: RoutePaths.UddannelseslaegeOverview.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsVusMedarbejeder()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.UddannelseslaegeOverview.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsLaege()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={RoutePaths.Forloeb([currentUser.UserId]).url}
                />
            </PrivateRoute>
        }

        if (currentUser.IsKursusleder()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsKursusudbyder()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsHrMedarbejder()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsKonsulentForLaegeligVidereuddannelse()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        // TODO: Fjern udkommenteret når eval-statistik er implementeret
                        // pathname: RoutePaths.Evalueringsstatistik.path
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsLedelse()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsSekretaer()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
                    }}
                />
            </PrivateRoute>
        }

        if (currentUser.IsForskningstraeningsvejleder()) {
            return <PrivateRoute exact path={RoutePaths.Index.path}>
                <Redirect
                    to={{
                        pathname: !isMobile ? RoutePaths.forloebOverview.path : RoutePaths.Brugere.path
                    }}
                />
            </PrivateRoute>
        }
    }

    return (
        <Router>
            <ErrorBoundary>
                <NotifikationContextProvider>
                    <Switch>
                        {renderIndexRedirect()}

                        <Route path={RoutePaths.SignInCallback.path}>
                            <AuthPage/>
                        </Route>

                        <Route path={RoutePaths.Impersonate.path}>
                            <Impersonate/>
                        </Route>

                        <Route path={RoutePaths.ChangeRole.path}>
                            <ChangeRole/>
                        </Route>

                        <PrivateRoute exact path={RoutePaths.forloebOverview.path}
                                    roles={RoutePaths.forloebOverview.roles}>
                            <ForloebOverviewPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.UddannelseslaegeOverview.path}
                                    roles={RoutePaths.UddannelseslaegeOverview.roles}>
                            <UddannelseslaegeOverviewPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.KursisterOverview.path}
                                    roles={RoutePaths.KursisterOverview.roles}>
                            <KursisterOverviewPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Uddannelseslaege().path}
                                    roles={RoutePaths.Uddannelseslaege().roles}>
                            <UddannelseslaegePage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.UserProfile.path} roles={RoutePaths.UserProfile.roles}>
                            <UserProfilePage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Contact.path} roles={RoutePaths.Contact.roles}>
                            <ContactPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Forloeb().path}
                                    roles={RoutePaths.Forloeb().roles}>
                            <ForloebPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.ForloebList().path}
                                    roles={RoutePaths.ForloebList().roles}>
                            <ForloebListPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Kompetencer().path}
                                    roles={RoutePaths.Kompetencer().roles}>
                            <KompetencerPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Kurser().path}
                                    roles={RoutePaths.Kurser().roles}>
                            <LaegekurserPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.KompetencerOverview().path}
                                    roles={RoutePaths.KompetencerOverview().roles}>
                            <KompetencerOverviewPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.SendStpsDocumentationPage().path}
                                    roles={RoutePaths.SendStpsDocumentationPage().roles}>
                            <SendStpsAnsoegningPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Vejledning().path}
                                    roles={RoutePaths.Vejledning().roles}>
                            <VejledningPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Brevskabeloner().path} roles={RoutePaths.Brevskabeloner().roles}>
                            <BrevskabelonPage />
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.BrevskabelonerCreatePage().path} roles={RoutePaths.BrevskabelonerCreatePage().roles}>
                            <BrevCreatePage />
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.BrevskabelonerEditPage().path} roles={RoutePaths.BrevskabelonerEditPage().roles}>
                            <BrevEditPage />
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.BrevskabelonerGeneratePdfPage().path} roles={RoutePaths.BrevskabelonerGeneratePdfPage().roles}>
                            <BrevGeneratePdfPage />
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Files().path}
                                      roles={RoutePaths.Files().roles}>
                            <FilerPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Notater().path}
                                    roles={RoutePaths.Notater().roles}>
                            <NotaterPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.VejlederNotater.path}
                                    roles={RoutePaths.VejlederNotater.roles}>
                            <NotaterPage hideNoForloebHeader={true}/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Dataadministration.path}
                                    roles={RoutePaths.VejlederNotater.roles}>
                            <DataadministrationOverviewPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.FileAdministration.path} roles={RoutePaths.FileAdministration.roles}>
                            <FileAdministrationPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Vejledere.path}
                                    roles={RoutePaths.Vejledere.roles}>
                            <VejlederePage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Klarmeldinger.path}
                                    roles={RoutePaths.Klarmeldinger.roles}>
                            <KlarmeldingerPage />
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Evaluering().path}
                                    roles={RoutePaths.Evaluering().roles}>
                            <EvalueringPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.EditKompetenceEvaluering().path}
                                    roles={RoutePaths.EditKompetenceEvaluering().roles}>
                            <EditKompetenceEvalueringPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Nyheder.path}
                                    roles={RoutePaths.Nyheder.roles}>
                            <NyhederPage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.Brugere.path}
                                    roles={RoutePaths.Brugere.roles}>
                            <BrugerePage/>
                        </PrivateRoute>

                        <PrivateRoute exact path={RoutePaths.NotifikationHistorik.path}
                                    roles={RoutePaths.NotifikationHistorik.roles}>
                            <NotifikationHistorikPage/>
                        </PrivateRoute>

                        {currentUser.Authenticated
                            ?
                            <PrivateRoute exact path={RoutePaths.CreateEvalueringsstatistik.path}
                                        roles={RoutePaths.Brugere.roles}>
                                <CreateEvalueringsstatistikPage/>
                            </PrivateRoute>
                            :
                            <PublicRoute exact path={RoutePaths.CreateEvalueringsstatistik.path}
                                        isAuthenticated={currentUser.Authenticated}
                                        showTitleBar={true}>
                                <CreateEvalueringsstatistikPage/>
                            </PublicRoute>
                        }

                        {currentUser.Authenticated
                            ?
                            <PrivateRoute exact path={RoutePaths.Evalueringsstatistik.path}
                                        roles={RoutePaths.Brugere.roles}>
                                <EvalueringsstatistikPage/>
                            </PrivateRoute>
                            :
                            <PublicRoute exact path={RoutePaths.Evalueringsstatistik.path}
                                        isAuthenticated={currentUser.Authenticated}
                                        showTitleBar={true}>
                                <EvalueringsstatistikPage/>
                            </PublicRoute>
                        }

                        <PublicRoute exact path={RoutePaths.Privatlivspolitik.path} isAuthenticated={currentUser.Authenticated}>
                            <CookiesInformationPage/>
                        </PublicRoute>

                        <Route exact path={RoutePaths.Privatlivspolitik.path}>
                            <CookiesInformationPage/>
                        </Route>

                        <PublicRoute exact path={RoutePaths.Vilkaar.path} isAuthenticated={currentUser.Authenticated}>
                            <ErrorBoundaryPdf><Vilkaar/></ErrorBoundaryPdf>
                        </PublicRoute>

                        <Route exact path={RoutePaths.LaegeKompetencePdf().path}>
                            <ErrorBoundaryPdf><KompetencePdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.AllKompetencerOnUserPdf().path}>
                            <ErrorBoundaryPdf><AllKompetencerOnUserPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.LaegeKompetencerPdf().path}>
                            <ErrorBoundaryPdf><KompetencerPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.KursusPdf().path}>
                            <ErrorBoundaryPdf><KursusPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.SelvstaendigtVirkePdf().path}>
                            <ErrorBoundaryPdf><LaegeligBeskaeftigelsePdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.UddannelsesplanPdf().path}>
                            <ErrorBoundaryPdf><UddannelsesplanerPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.VejledersamtalePdf().path}>
                            <ErrorBoundaryPdf><VejledersamtalePdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.UddannelseslaegeNotesPdf().path}>
                            <ErrorBoundaryPdf><AllUddannelseslaegeNotesPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.AllVurderingerPdf().path}>
                            <ErrorBoundaryPdf><AllVurderingerPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.AttestationForTidPdf().path}>
                            <ErrorBoundaryPdf><AttestationForTidPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.EvalueringStatistikPdf().path}>
                            <ErrorBoundaryPdf><EvalueringStatistikPdf/></ErrorBoundaryPdf>
                        </Route>

                        <Route exact path={RoutePaths.EvalueringSpoergerammePdf().path}>
                            <ErrorBoundaryPdf><EvalueringSpoergerammePdf/></ErrorBoundaryPdf>
                        </Route>

                        {currentUser.Authenticated
                            ?
                            <PrivateRoute path="*">
                                <NotFoundPage/>
                            </PrivateRoute>
                            :
                            <PublicRoute path="*" isAuthenticated={currentUser.Authenticated}>
                                <NotFoundPage/>
                            </PublicRoute>
                        }
                    </Switch>
                </NotifikationContextProvider>
            </ErrorBoundary>
        </Router>
    );
}
