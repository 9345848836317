import { StorageKeys } from "infrastructure/storage/storageKeys";
import { useEffect, useState } from "react";
import { AccountService } from "services/account/accountService";
import { UserModel } from "services/account/userModel";

const accountService = new AccountService();
const StorageEventType = "storage";

const useUser = () => {
    const [currentUser, setCurrentUser] = useState<UserModel>(accountService.getUser());

    useEffect(() => {
        const onLocalStorageUserUpdatedListener = (ev: StorageEvent) => {
            if(ev.key === StorageKeys.user) {
                const updatedUser = accountService.getUser();
                setCurrentUser(updatedUser);
            }
        }

        window.addEventListener(StorageEventType, onLocalStorageUserUpdatedListener);

        return () => window.removeEventListener(StorageEventType, onLocalStorageUserUpdatedListener);
    }, []);

    return {
        currentUser: currentUser,
        updateCurrentUser: (Initialer: string) => accountService.updateUser({ Initialer: Initialer })
    }
};

export default useUser;