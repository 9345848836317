import React, {useEffect, useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {BuildExisting, crudKursusValidationSchema, CrudLaegekursusInputModel} from "./crudLaegekursusInputModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {LaegekursusCrudFields} from "./laegekursusCrudFields";
import {LaegekursusModel} from "../../../services/api/laegekursus/laegekursusModel";
import {BasicNotatModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {ValidationForm} from "../../../core/components/validation/components/validationForm";
import {Loading} from "../../../core/components/loading/loading";
import {NotificationModule} from "ditmer-embla";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {EmblaIcons} from 'core/emblaIcons';
import {UserModel} from 'services/account/userModel';
import {GodkendKursusButton, godkendLaegeKursus, notifyGodkendKursusSuccess} from "./godkendKursusButton";
import DynamicButton from "../../../core/components/button/dynamicButton";
import {ButtonSubmitActionEnum} from "../../../core/components/button/buttonSubmitActionEnum";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import { RolleTitelEnum } from 'infrastructure/enums/rolleTitelEnum';
import useUser from 'core/hooks/useUser';
import useLogbogApi from 'core/hooks/useLogbogApi';

type EditKursusProps =  {
    modalId: string,
    model: LaegekursusModel,
    laegekursusGruppeGodkendt: boolean,
    areYouSureFjernGodkendelseModalId: string,
    areYouSureDeleteModalId: string,
    openAreYouSureFjernGodkendelseModal: () => void,
    openAreYouSureDeleteModal: () => void,
    forloebId?: string,
    brugerMaalbeskrivelseId: string,
    laegekursusStatusEditCallback: () => void;
}

export const isUserAllowedToFillApproval = (user: UserModel, laegekursusGruppeType: LaegekursusGruppeTypeEnum) => {

    if (laegekursusGruppeType === LaegekursusGruppeTypeEnum.KliniskBasisuddannelse ||
        laegekursusGruppeType === LaegekursusGruppeTypeEnum.Introduktionsuddannelsen ||
        laegekursusGruppeType === LaegekursusGruppeTypeEnum.Hoveduddannelse) {
        return user.IsAdminOrVus() || user.IsKursusudbyder()
    }

    return user.IsUddannelsesansvarlig()
        || user.IsVejleder()
        || user.IsAdminOrVus()
        || user.IsKursusleder()
        || user.IsKursusudbyder()
        || user.IsForskningstraeningsvejleder()
}

export const EditLaegekursus = (props: EditKursusProps) => {
    const dispatch = useAppDispatch();

    const { currentUser } = useUser();
    const { logbogLaegekursusApi, notatApi } = useLogbogApi();

    const isGodkendt = props.model.status === LaegekursusStatusEnum.Godkendt;
    const isHovedkursusleder = currentUser.IsKursusleder() && currentUser.hasRolleTitel(RolleTitelEnum.Hovedkursusleder);
    const showDeleteBtn = !isGodkendt && (currentUser.IsAdminOrVus() || isHovedkursusleder);

    const [isLoading, setIsLoading] = useState(true);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [notat, setNotat] = useState<BasicNotatModel | null>(null);

    const readonly = currentUser.IsSekretaer();

    let submitAction: ButtonSubmitActionEnum = ButtonSubmitActionEnum.Primary;

    const isNotCurrentUsersKursus = () => {
        return forloebSliceState.forloebState.brugerId !== currentUser.UserId;
    }

    const isCurrentUserAllowedToFillApproval = () => {
        return isUserAllowedToFillApproval(currentUser, props.model.gruppeType)
    }

    const isLaegeKursusUdfyldt = props.model.status === LaegekursusStatusEnum.Udfyldt && isCurrentUserAllowedToFillApproval();

    useEffect(() => {
        const getNotat = async () => {
            const notat = await notatApi.getNotatOnContext(NotatType.Laegekursus, forloebSliceState.forloebState.brugerId, props.model.id);
            setNotat(notat);
        };
        getNotat().then(() => setIsLoading(false));
        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
    }, [dispatch, forloebSliceState.forloebState.brugerId, notatApi, props.modalId, props.model.id]);

    const editLaegekursus = async (inputModel: CrudLaegekursusInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}))
        const editedLaegekursusId = await logbogLaegekursusApi.editLaegekursus(inputModel);
        if (editedLaegekursusId) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
            NotificationModule.showSuccess(Localizer.kursusPage_kursusEdited(), "");
        } else {
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
        }
    }

    const notatFilesChangedCallbackOverride = async () => {
        const notat = await notatApi.getNotatOnContext(NotatType.Laegekursus, forloebSliceState.forloebState.brugerId, props.model.id);
        setNotat(notat);
    }

    const showSaveAndApproveButton = () => {
        return !isLaegeKursusUdfyldt && isCurrentUserAllowedToFillApproval() && isNotCurrentUsersKursus();
    }

    const render = (
        <Loading isLoading={isLoading} text={Localizer.global_getData()}>
            <ValidationForm
                initialValues={BuildExisting(props.model.id,
                    props.forloebId ?? forloebSliceState.forloebState.id,
                    props.model.brugerId,
                    props.model.laegekursusGruppeId,
                    props.model.brugerKursusraekkeId,
                    props.model.titel,
                    props.model.status,
                    props.model.aendretEllerOprettetAfLaege,
                    props.model.gruppeType,
                    props.model.godkendPaaAndetGrundlag,
                    props.model.sortOrder,
                    props.model.eventuelSpecifikation,
                    props.model.startDato,
                    props.model.slutDato,
                    props.model.godkendelsesInfo?.kommentar,
                )}
                validationSchema={crudKursusValidationSchema}
                onSubmit={async (values: CrudLaegekursusInputModel) => {
                    await editLaegekursus(values)
                        .then(async () => {
                            if(submitAction === ButtonSubmitActionEnum.Primary) return;
                            const godkendtKursusId = await godkendLaegeKursus(props.model.id, values.godkendtPaaAndetGrundlag, forloebSliceState.forloebState.laegeInstitutionName, props.brugerMaalbeskrivelseId ?? forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.afdelingName, values.godkendelseKommentar);
                            if (godkendtKursusId.data)
                                notifyGodkendKursusSuccess();
                                props.laegekursusStatusEditCallback();
                        })
                        .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                            HandleError(err);
                        });
                }}>
                {
                    (formik) => (
                        <>
                            {isGodkendt && props.model.godkendelsesInfo !== null && props.model.godkendelsesInfo !== undefined &&
                                <div className="alert alert-success left-right-minus-spacing">
                                    {Localizer.kursusPage_laegekursusGodkendtAf(props.model.godkendelsesInfo.godkendtDen.dateWithoutTimeFormat(true), props.model.godkendelsesInfo.godkendtAfNavn, props.model.godkendelsesInfo.rolleTitel, props.model.godkendelsesInfo.authorisationsId ?? "")}
                                </div>
                            }

                            <LaegekursusCrudFields
                                modalId={props.modalId}
                                formik={formik}
                                notatInfo={{
                                    contextId: props.model.id,
                                    notatType: NotatType.Laegekursus,
                                    userId: forloebSliceState.forloebState.brugerId
                                }}
                                notat={notat}
                                readonly={readonly}
                                titleIsReadOnly={props.model.kursusId !== null || props.model.godkendelsesInfo !== undefined}
                                laegekursusKanGodkendes={isLaegeKursusUdfyldt}
                                laegekursusGodkendt={isGodkendt}
                                filesUpdatedCallback={() => notatFilesChangedCallbackOverride()}
                            />

                            <div className="modal-actions">

                                {(currentUser.IsAdminOrVus() && isGodkendt) &&
                                    <DynamicButton
                                        additionalClasses='mr-2'
                                        onClick={() => props.openAreYouSureFjernGodkendelseModal()}
                                        disabled={props.laegekursusGruppeGodkendt}
                                        modal={{
                                            dataTarget: `#${props.areYouSureFjernGodkendelseModalId}`,
                                        }}
                                    >
                                        <DynamicButton.TextIconItem iconName={EmblaIcons.Remove} text={Localizer.kursusPage_fjernGodkendelse()}/>
                                    </DynamicButton>
                                }

                                {isLaegeKursusUdfyldt && isNotCurrentUsersKursus() &&
                                    <GodkendKursusButton
                                        modalId={props.modalId}
                                        laegekursusId={props.model.id}
                                        godkendPaaAndetGrundlag={formik.values.godkendtPaaAndetGrundlag}
                                        kommentar={formik.values.godkendelseKommentar}
                                        brugerMaalbeskrivelseId={props.brugerMaalbeskrivelseId ?? forloebSliceState.forloebState.brugerMaalbeskrivelseId}
                                    />
                                }

                                {!isGodkendt && showSaveAndApproveButton() &&
                                    <button className="btn btn-default mr-2"
                                            type="button"
                                            disabled={readonly}
                                            onClick={() => {
                                              submitAction = ButtonSubmitActionEnum.Secondary;
                                              formik.handleSubmit()
                                            }}>
                                        {Localizer.global_SaveAndApproveKursus()}
                                    </button>
                                }

                                {showDeleteBtn &&
                                    <button type={"button"}
                                            className="btn btn-default mr-2"
                                            disabled={readonly}
                                            data-toggle="modal"
                                            data-target={`#${props.areYouSureDeleteModalId}`}
                                            data-dismiss="modal"
                                            onClick={() => props.openAreYouSureDeleteModal()}>
                                        {Localizer.global_delete()}
                                    </button>
                                }

                                {!isGodkendt &&
                                    <button className="btn btn-primary mr-2"
                                            type="button"
                                            disabled={readonly}
                                            onClick={() => {
                                                submitAction = ButtonSubmitActionEnum.Primary;
                                                formik.handleSubmit()
                                            }}>
                                        {Localizer.global_save()}
                                    </button>
                                }
                            </div>
                        </>
                    )
                }
            </ValidationForm>
        </Loading>

    )
    return <>{render}</>
}
