import React from "react";
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {DeaktiveretForloebDataModel} from "../../../../core/sharedmodels/forloeb/deaktiveretForloebDataModel";
import Title from "../../../../core/components/titels/title";
import CalendarTimeDisplay from "../../../../core/components/timedisplayers/Calendar";
import {ReactComponent as GreenCircle} from "../../../../content/icons/greenCircle.svg";
import {NotatCard} from "../../../../core/componentsPage/notat/notatCard";
import {LaegekompetenceStatusEnum} from "../../../../core/sharedmodels/kompetence/laegekompetenceStatusEnum";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import {ReactComponent as YellowCircle} from "../../../../content/icons/yellowCircle.svg";
import VejlederAppointment from "../../../vejledning/vejledersamtaler/components/vejlederAppointment";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../../core/layout/responsive";

type DeaktiveretForloebDataProps = {
    deaktiveretForloebData: DeaktiveretForloebDataModel
}

export function DeaktiveretForloebData({deaktiveretForloebData}: DeaktiveretForloebDataProps) {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    const noResult = () => {
        return <div className="card-body">
            <div className="flex-space flex-grow">
                <>{Localizer.noResults()}</>
            </div>
        </div>
    }

    const headerTitle = (title: string) => {
        return <div className={"card-header"}>
            <h5 className={"card-header-text-padding"}>
                {title}
            </h5>
        </div>
    }

    return (
        <div className={"d-flex flex-container-row col wrap"}>
            <div className={`card margin-top-m flex-fill ${IsMobile ? "" : "margin-right-m "}`}>
                {headerTitle(Localizer.global_vejledersamtaler())}
                {deaktiveretForloebData.vejledersamtaler?.length > 0 ?
                    <>
                        {deaktiveretForloebData.vejledersamtaler?.map((vejledersamtale) => {
                            return (
                                <div className="card-body" key={vejledersamtale.id}>
                                    <div className="flex-space flex-grow">
                                        <VejlederAppointment samtale={vejledersamtale}/>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </>
                    :
                    noResult()
                }
            </div>
            <div className={`card margin-top-m flex-fill ${IsMobile ? "" : "margin-right-m "}`}>
                {headerTitle(Localizer.global_uddannelsesplaner())}
                {deaktiveretForloebData.uddannelsesplaner?.length > 0 ?
                    <>
                        {deaktiveretForloebData.uddannelsesplaner?.map((uddannelsesplan) => {
                            return (
                                <div className="card-body" key={uddannelsesplan.id}>
                                    <div className="flex-space flex-grow align-items-center wrap">
                                        <Title titleClasses={"margin-right-m"} title={uddannelsesplan.titel}/>
                                        <CalendarTimeDisplay
                                            dato={uddannelsesplan.oprettelsesDato}
                                            startTime={uddannelsesplan.oprettelsesDato}
                                        />
                                        {uddannelsesplan.godkendelseTilkendegivetDato &&
                                            <span
                                                className="badge badge-pill badge-default padding-xs"><GreenCircle/> {uddannelsesplan.godkendelseTilkendegivetDato.dateWithoutTimeFormat(true)}</span>
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }
                    </>
                    :
                    noResult()
                }
            </div>
            <div className={`card margin-top-m flex-fill ${IsMobile ? "" : "margin-right-m "}`}>
                <div className={"card-header "}>
                    <h5 className={"card-header-text-padding"}>
                        {Localizer.global_notater()}
                    </h5>
                </div>
                {deaktiveretForloebData.forloebNotater?.length > 0 ?
                    <>
                        {deaktiveretForloebData.forloebNotater?.map((notat) => {
                            return (
                                <NotatCard notat={notat}
                                           clickEditNotatCallBack={() => {
                                           }}
                                           disableEdit={true}
                                           key={notat.id}/>
                            )
                        })
                        }
                    </>
                    :
                    noResult()
                }
            </div>
            <div className={`card margin-top-m flex-fill ${IsMobile ? "" : "margin-right-m "}`}>
                {headerTitle(Localizer.kompetencePage_planlagteKompetencer())}
                {deaktiveretForloebData.forloebLaegekompetencer?.length > 0 ?
                    <>
                        {deaktiveretForloebData.forloebLaegekompetencer?.map((laegekompetence) => {
                            return (
                                <div className="card-body" key={laegekompetence.laegekompetenceId}>
                                    <div className="flex-grow align-items-center">
                                        <div className={"flex-container justify-content-between"}>
                                            <p className="subtle small">
                                                {laegekompetence.underoverskrift}
                                            </p>
                                        </div>
                                        <div className="margin-bottom-s">
                                            <h5 className="font-weight-bold"
                                                title={laegekompetence.fullKompetenceTitleWithNumber}>
                                                {laegekompetence.fullKompetenceTitleWithNumber}
                                            </h5>
                                        </div>
                                        <div className="flex-container">
                                            <div className="flex-container">
                                                {laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt &&
                                                    <div className="margin-right-m">
                                                        <span
                                                            className="badge badge-pill badge-default padding-xs"> <YellowCircle/> {Localizer.global_klarmeldt()}</span>
                                                    </div>
                                                }
                                                {laegekompetence.status === LaegekompetenceStatusEnum.Godkendt &&
                                                    <div className="margin-right-m">
                                                        <span
                                                            className="badge badge-pill badge-default padding-xs"> <GreenCircle/> {Localizer.global_godkendt()}</span>
                                                    </div>
                                                }
                                                <div
                                                    className={"margin-right-xs card-list-icon " + (laegekompetence.notatCount > 0 ? "" : "subtle")}>
                                                    <EmblaIcon iconName="comment"/>
                                                    <span
                                                        className={"margin-left-xs " + (laegekompetence.notatCount > 0 ? "" : "subtle")}>{laegekompetence.notatCount}</span>
                                                </div>
                                            </div>

                                            {laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt && !laegekompetence.klarmeldtAt &&
                                                <div
                                                    className="margin-left-m">{Localizer.klarmeldingerPage_klarmeldtUdenDato()}</div>
                                            }

                                            {laegekompetence.isFavorite &&
                                                <div className="ml-auto subtle">
                                                    <EmblaIcon iconName="star-filled"/>
                                                </div>
                                            }

                                            {!laegekompetence.isFavorite &&
                                                <div className="ml-auto subtle">
                                                    <EmblaIcon iconName="star"/>
                                                </div>
                                            }
                                        </div>

                                        {laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt && laegekompetence.klarmeldtAt !== undefined &&
                                            <div
                                                className="margin-top-s flex-row flex-container-row wrap align-items-center">
                                                <h6 className={"margin-right-s font-weight-bold d-flex"}>
                                                    {Localizer.kompetencePage_laegekompetenceKlarmeldt() + laegekompetence.klarmeldtAt} {Localizer.global_to().toLowerCase()}:
                                                </h6>
                                                {laegekompetence.klarmeldtTilNavn?.map(navn =>
                                                    <div className="margin-right-s "><span
                                                        className="badge badge-pill badge-default padding-xs"> {navn}</span>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                        }
                    </>
                    :
                    noResult()
                }
            </div>
            <div className={`card margin-top-m flex-fill ${IsMobile ? "" : "margin-right-m "}`}>
                {headerTitle(Localizer.global_godkendt())}
                {deaktiveretForloebData.godkendelser?.length > 0 ?
                    <>
                        {deaktiveretForloebData.godkendelser?.map((godkendelse, i) => {
                            return (
                                <div className={"card-body flex-container-row wrap"} key={i}>
                                    <div className="flex-row margin-right-m">
                                        <p className="font-weight-bold">{Localizer.dato()}</p>
                                        {godkendelse.godkendtDen.dateWithoutTimeFormat(true)}
                                    </div>
                                    <div className="margin-right-m">
                                        <p className="font-weight-bold">{Localizer.global_godkendtAf()}</p>
                                        {godkendelse.godkendtAfNavn}
                                    </div>
                                    <div className="">
                                        <p className="font-weight-bold margin-right-m">{Localizer.global_authorisationId()}</p>
                                        {godkendelse.authorisationsId}
                                    </div>
                                    <div className="">
                                        <p className="font-weight-bold">{Localizer.rolle()}</p>
                                        {godkendelse.rolleTitel}
                                    </div>
                                </div>
                            )
                        })
                        }
                    </>
                    :
                    noResult()
                }
            </div>
        </div>
    )
}
