import { PropsWithChildren } from "react";
import useApplicationInsightsConfig from "./useApplicationInsightsConfig";
import config from "config/config";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

type AppInsightsProviderWrapperProps = {};

const AppInsightsProviderWrapper = ({ children }: PropsWithChildren<AppInsightsProviderWrapperProps>) => {
  const useAppInsights = config.useAppInsights ?? false;

  const { reactPlugin } = useApplicationInsightsConfig(useAppInsights);
  
  if (!useAppInsights) {
    return <>{children}</>;
  }

  console.info("Using application insights")

  return (
      <AppInsightsContext.Provider value={reactPlugin}>
          {children}
      </AppInsightsContext.Provider>
  );
}

export default AppInsightsProviderWrapper;