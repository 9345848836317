import React, {ReactNode, useCallback, useState} from "react";
import {Loading} from "../../core/components/loading/loading";
import useEffectAsync from "../../infrastructure/effect";
import {SetTitleBar} from "../../core/layout/titleBar/titleBarApi";
import {Localizer} from "../../infrastructure/localization/localizer";
import {Wizard} from "../../core/components/wizard/wizard";
import {WizardChildModel} from "../../core/sharedmodels/wizard/wizardChildModel";
import "./sendStpsAnsoegningPage.scss"
import {SlideIn} from "../../core/components/slideIn/slideIn";
import {AnsoegningCreateBeskaeftigelse} from "./ansoegningCreateBeskaeftigelse";
import {LogbogBeskaeftigelseApi} from "../../services/api/logbogBeskaeftigelse/logbogBeskaeftigelseApi";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {AccountService} from "../../services/account/accountService";
import {useHistory, useRouteMatch} from "react-router-dom";
import {BeskaeftigelseModel} from "./beskaeftigelse/beskaeftigelseModel";
import {BeskaeftigelseCard} from "./beskaeftigelse/beskaeftigelseCard";
import {NotatType} from "../../core/sharedmodels/notat/notatType";
import {LogbogNotatApi} from "../../services/api/logbogNotat/logbogNotatApi";
import {BasicNotatModel} from "../../core/sharedmodels/notat/basicNotatModel";
import {FileLocationEnum} from "../../core/sharedmodels/file/fileLocationEnum";
import {FileMetadata} from "../../core/sharedmodels/fileMetaData/fileMetaData";
import {
    ObligatoriskDokumentation
} from "../../core/components/obligatoriskDokumentation/obligatoriskDokumentationTable";
import {FileDownloadHandler} from "../../services/api/logbogFile/fileDownloadHandler";
import {FileMetadataList} from "../../core/components/fileMetadata/fileMetadataList";
import {LogbogAnsoegningApi} from "../../services/api/logbogAnsoegning/logbogAnsoegningApi";
import {RoutePaths} from "../../infrastructure/routes";
import {LogbogEmailApi} from "services/api/logbogEmail/logbogEmailApi";
import {EmailTypeEnum} from "core/sharedmodels/email/emailTypeEnum";
import {NotificationModule} from "ditmer-embla";
import {AnsoegningModel} from "services/api/logbogAnsoegning/logbogAnsoegningModel";
import {logbogEmailStpsRequestModel} from "services/api/logbogEmail/logbogEmailStpsRequestModel";
import {UddannelseTypeEnum} from "core/sharedmodels/stilling/uddannelseTypeEnum";
import {StempelInputModel} from "services/api/stempel/stempelInputModel";
import {ModalSize} from "../../core/components/slideIn/modalSize";
import {DocumentationList} from "../../core/components/obligatoriskDokumentation/documentationList";
import {StamdataMaalbeskrivelseApi} from "../../services/api/stamdataMaalbeskrivelse/stamdataMaalbeskrivelseApi";
import {LaegeligBeskaeftigelseText} from "./beskaeftigelse/beskaeftigelseText";
import {
    ObligatoriskDokumentationTypeEnum
} from "../../core/sharedmodels/obligatoriskDokumentation/obligatoriskDokumentationTypeEnum";
import {EmblaIcon} from "../../core/components/emblaIcon/emblaIcon";
import { userStpsAnsoegnignQueryParams } from "infrastructure/route/queryParams";
import Tooltip from "core/components/tooltips/Tooltip";
import StpsStep from "./stpsStepEnum";
import DynamicButton from "core/components/button/dynamicButton";
import { EmblaIcons } from "core/emblaIcons";
import useUser from "core/hooks/useUser";
import {useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import { EmblaIllustration } from "core/components/emblaIcon/emblaIllustration";
import { LogbogFileApi } from "services/api/logbogFile/logbogFileApi";
import useScreenResolution from "core/hooks/useScreenResolution";
import classNames from "classnames";

const ansoegningApi = new LogbogAnsoegningApi(new HttpClientService(), new AccountService(), new FileDownloadHandler())
const beskaeftigelseApi = new LogbogBeskaeftigelseApi(new HttpClientService(), new AccountService(), new FileDownloadHandler())
const notatApi = new LogbogNotatApi(new HttpClientService(), new AccountService());
const emailApi = new LogbogEmailApi(new HttpClientService());
const userService = new AccountService();
const stamdataMaalbeskrivelseApi = new StamdataMaalbeskrivelseApi(new HttpClientService(), new AccountService());
const fileApi = new LogbogFileApi(new HttpClientService(), new FileDownloadHandler());

export function SendStpsAnsoegningPage() {
    const routeMatch = useRouteMatch<userStpsAnsoegnignQueryParams>();
    const route = routeMatch.params;

    const attachedFilesTableId: string = "attachedFilesTableId"

    const [ansoegning, setAnsoegning] = useState<AnsoegningModel>();
    const [isLoading, setIsLoading] = useState(true);
    const [isSendingFiles, setIsSendingFiles] = useState(false);
    const [beskaeftigelseModalOpen, setBeskaeftigelseModalOpen] = useState(false);
    const [beskaeftigelser, setBeskaeftigelser] = useState<BeskaeftigelseModel[]>();
    const [uddannelseNotat, setUddannelseNotat] = useState<BasicNotatModel | null>();
    const [dokumentationNotat, setDokumentationNotat] = useState<BasicNotatModel | null>();
    const [logbogLegacyNotater, setLogbogLegacyNotater] = useState<BasicNotatModel[]>([]);
    const [meritNotater, setMeritNotater] = useState<BasicNotatModel[]>([]);
    const [kursusNotater, setKursusNotater] = useState<BasicNotatModel[]>([]);
    const [obligatoriskeFilesSelected, setObligatoriskeFilesSelected] = useState<string[]>([]);
    const [userFilesSelected, setUserFilesSelected] = useState<string[]>([]);
    const [obligatoriskDokumentationComplete, setObligatoriskDokumentationComplete] = useState<boolean>(false);
    const [antalObligatoriskDokumentation, setAntalObligatoriskDokumentation] = useState<number>(0);
    const [dokumentationSent, setDokumentationSent] = useState<boolean>(false);
    const [dokumentationFinishedSending, setDokumentationFinishedSending] = useState<boolean>(false);
    const [isUddannelseModalShown, setIsUddannelseModalShown] = useState(false);
    const [documentation, setDocumentation] = useState<FileMetadata[]>([]);
    const [eftersendtDocumentation, setEftersendtDocumentation] = useState<FileMetadata[]>([]);

    const [canSendStpsDocumentation, setCanSendStpsDocumentation] = useState<boolean>(false);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const history = useHistory();
    const { currentUser } = useUser();
    const { isMobile } = useScreenResolution();

    const createBeskaeftigelseModalTarget = "beskaeftigelse-modal";

    SetTitleBar(Localizer.global_stpsDokumentation(), []);

    useEffectAsync(async () => {
        const existingAnsoegning = await ansoegningApi.getAnsoegningForAfsendelse(route.ansoegning);
        setAnsoegning(existingAnsoegning);
        setDokumentationSent(existingAnsoegning.dokumentationAfsendtDen !== null && existingAnsoegning.dokumentationAfsendtDen !== undefined)

        let beskaeftigelser = await beskaeftigelseApi.getBeskaeftigelserByAnsoegningsId(route.ansoegning);
        setBeskaeftigelser(beskaeftigelser);

        const notater = await notatApi.getAnsoegningsnotaterOnUddannelse(existingAnsoegning.brugerId, existingAnsoegning.brugerMaalbeskrivelseId, existingAnsoegning.ansoegningId);
        const documents = notater?.filter(y => !y.eftersendt).selectMany(x => x.fileMetadatas);
        const eftersendt = notater?.filter(y => y.eftersendt).selectMany(x => x.fileMetadatas);

        setDocumentation(documents);
        setEftersendtDocumentation(eftersendt);

        const stpsPermission = await stamdataMaalbeskrivelseApi.canUserSendStpsDocumentation(route.uddannelseslaege, existingAnsoegning.brugerMaalbeskrivelseId, existingAnsoegning.uddannelseType)
        setCanSendStpsDocumentation(stpsPermission);

        setIsLoading(false);
    }, [])

    useEffectAsync(async () => {
        if (ansoegning) {
            await setNotater();
        }
    }, [ansoegning])

    const navigateToForloeb = () => {
        history.push(RoutePaths.Forloeb([route.uddannelseslaege]).url)
    }

    const setNotater = async () => {
        await setUddannelsesNotater();
        await setDokumentationNotater();
        await setMeritNotat();
        await setLogbogLegacyNotat();
        await setKursusNotat();
    }

    const setUddannelsesNotater = async () => {
        if (ansoegning)
        {
            const uddannelseNotat = await notatApi.getNotatOnContext(NotatType.Uddannelse, ansoegning.brugerId, ansoegning.uddannelseId);
            setUddannelseNotat(uddannelseNotat);
        }
    }

    const setDokumentationNotater = async () => {
        if (ansoegning)
        {
            const dokumentationNotat = await notatApi.getNotatOnContext(NotatType.Dokumentation, ansoegning.brugerId, ansoegning.ansoegningId);
            setDokumentationNotat(dokumentationNotat);
        }
    }

    const setMeritNotat = async () => {
        if (ansoegning)
        {
            const meritNotat = await notatApi.getMeritNotaterByBrugerMaalbeskrivelse(ansoegning.brugerId, ansoegning.brugerMaalbeskrivelseId);
            setMeritNotater(meritNotat);
        }
    }

    const setKursusNotat = async () => {
        if (ansoegning)
        {
            const kursusNotat = await notatApi.getKursusGruppeNotaterByBrugerKursusraekke(ansoegning.brugerId, ansoegning.brugerKursusraekkeId);
            setKursusNotater(kursusNotat);
        }
    }

    const setLogbogLegacyNotat = async () => {
        if (ansoegning)
        {
            const logbogLegacyNotat = await notatApi.getLogbogLegacyNotater(ansoegning.brugerId, ansoegning.uddannelseType);
            setLogbogLegacyNotater(logbogLegacyNotat);
        }
    }

    const getNotater = () => {
        let notater = [];
        if (dokumentationNotat) notater.push(dokumentationNotat)
        if (uddannelseNotat) notater.push(uddannelseNotat)
        return notater.length > 0 ? notater : null;
    }

    const getFileMetadatas = (): FileMetadata[] => {
        const dokumentationFileMetadatas = dokumentationNotat?.fileMetadatas;
        const uddannelseFileMetadatas = uddannelseNotat?.fileMetadatas;
        const logbogLegacyNotatMetadatas = logbogLegacyNotater?.flatMap(n => n.fileMetadatas);
        const meritNotaterMetadatas = meritNotater?.flatMap(n => n.fileMetadatas);
        const kursusNotaterMetadatas = kursusNotater.flatMap(n => n.fileMetadatas);

        // Combine the two types of files and order them from most to least recent
        let fileMetadatas: FileMetadata[] = [];
        fileMetadatas = fileMetadatas.concat(dokumentationFileMetadatas ?? [])
        fileMetadatas = fileMetadatas.concat(uddannelseFileMetadatas ?? [])
        fileMetadatas = fileMetadatas.concat(logbogLegacyNotatMetadatas ?? [])
        fileMetadatas = fileMetadatas.concat(meritNotaterMetadatas ?? [])
        fileMetadatas = fileMetadatas.concat(kursusNotaterMetadatas ?? [])

        return fileMetadatas;
    }

    function handleClickOpenSentDocumentationModal(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        setIsUddannelseModalShown(true);
    }

    function tilfoejBeskaeftigelse() {
        setBeskaeftigelseModalOpen(true)
    }

    async function beskaeftigelserChangedCallback() {
        let beskaeftigelser = await beskaeftigelseApi.getBeskaeftigelserByAnsoegningsId(route.ansoegning);
        setBeskaeftigelser(beskaeftigelser);
    }

    function obligatoriskDokumentationCompleteCallback(status: boolean, antalObligatoriskDokumentation:number) {
        setObligatoriskDokumentationComplete(status);
        setAntalObligatoriskDokumentation(antalObligatoriskDokumentation);
    }

    const renderBeskaeftigelseCreate = () => {
        return (
            <AnsoegningCreateBeskaeftigelse modalId={createBeskaeftigelseModalTarget}/>
        )
    }

    const obligatoriskDokumentationSelected = useCallback((ids: string[]) => {
        // Selects all rows in "Egne vedhæftede filer" table when "AnsaettelseOgUddannelse" is selected in "Obligatorisk Dokumentation" table
        // Uses JQuery as the table that stores the data is created using JQuery
        setObligatoriskeFilesSelected(ids)

        if (ids.map(i => parseInt(i)).includes(ObligatoriskDokumentationTypeEnum.AnsaettelseOgUddannelse)
                && !obligatoriskeFilesSelected.map(i => parseInt(i)).includes(ObligatoriskDokumentationTypeEnum.AnsaettelseOgUddannelse))
        {
            const uddannelseNotatIds = (uddannelseNotat?.fileMetadatas ?? []).map(n => n.id);

            // If all uddannelsesnotater has already been selected, return.
            if (uddannelseNotatIds.every(i => userFilesSelected?.includes(i)))
                return;

            const userFilesToAdd: string[] = [];

            if (!isMobile) {
                const table = $(`#table-${attachedFilesTableId}`).DataTable();

                table.rows().every((rowIdx, _tableLoop, _rowLoop) => {
                    const row = table.row(rowIdx).node();
                    const rowId = $(row).data("id");
                    if (rowId && uddannelseNotatIds.includes(rowId)) {
                        (table.row(row) as any).select(); // TypeScript gets confused about types if row is not cast as any, but it works regardless

                        userFilesToAdd.push(rowId);
                    }
                })
            // When Mobile, we use cards instead of table:
            } else {
                const fileMetadatas = getFileMetadatas();
                const fileMetadataAnsaettelsesOgUddannelsesAftalerIds = fileMetadatas.filter(x => uddannelseNotatIds.includes(x.id)).map(x => x.id);
                userFilesToAdd.push(...fileMetadataAnsaettelsesOgUddannelsesAftalerIds);
            }

            const selectedFiles = (userFilesSelected ?? []).concat(userFilesToAdd);
            // Filters out duplicates
            setUserFilesSelected(selectedFiles.filter((v, i, a) => a.indexOf(v) === i));
        }
    }, [getFileMetadatas, isMobile, obligatoriskeFilesSelected, uddannelseNotat?.fileMetadatas, userFilesSelected]);

    const getBeskaeftigelseBeskrivelse = (): ReactNode => {
        return (
            <>
                {ansoegning?.uddannelseType &&
                    <LaegeligBeskaeftigelseText uddannelseTypeEnum={ansoegning?.uddannelseType}/>
                }

                <DynamicButton onClick={() => tilfoejBeskaeftigelse()} additionalClasses="padding-bottom-m">
                    <DynamicButton.TextIconItem text={Localizer.tilfoejBeskaeftigelse()} iconName={EmblaIcons.Plus} />
                </DynamicButton>

                <p className="font-weight-bold margin-bottom-s padding-top-s">{Localizer.ansoegOmSelvstaendigVirke_dineAnsaettelser()}</p>

                {beskaeftigelser &&
                    beskaeftigelser.map((beskaeftigelse, index) =>
                        <div key={index}>
                            <BeskaeftigelseCard
                                beskaeftigelse={beskaeftigelse}
                                beskaeftigelseEditCallback={() => beskaeftigelserChangedCallback()}
                            />
                        </div>
                    )
                }

            </>
        )
    }

    function getUddannelsesaftalerBeskrivelseSelvstaendigtVirke(): ReactNode {
        return (
            <>
                <div>
                    {Localizer.ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktEt()}
                    <ul>
                        <li>
                            {Localizer.ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktTo()}
                        </li>
                        <li>
                            {Localizer.ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktTre()}
                        </li>
                    </ul>
                    {Localizer.ansoegOmSelvstaendigVirke_uddannelsesaftaleBeskrivelsePunktFire()}
                </div>

                <div className={classNames(
                        !isMobile && "aftale-file-picker",
                        isMobile && "aftale-file-picker-mobile"
                    )}>
                    <FileMetadataList
                        showAsTableIdentifier={"aftaleFileTable"}
                        filesUpdatedCallback={() => setUddannelsesNotater()}
                        uploaderTitle={Localizer.tilfoejAftale()}
                        fileMetadatas={uddannelseNotat?.fileMetadatas ?? []}
                        showDeleteButton={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                        notatInfo={[{contextId: ansoegning!.uddannelseId, notatType: NotatType.Uddannelse, userId: ansoegning!.brugerId, isVisibleToEveryone: false}]}
                        notat={uddannelseNotat ? [uddannelseNotat] : null}
                        withUploader={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                        fileLocation={FileLocationEnum.Logbog}
                        withDownload={true}
                        showFileType={true}
                        hideCreatedBy={true}
                        showAsCards={isMobile}
                    />
                </div>
            </>
        )
    }


    function getUddannelsesaftalerBeskrivelseSpeciallaegeanerkendelse(): ReactNode {
        return (
            <>
                <div>
                    {Localizer.ansoegOmSpeciallaegeanerkendelse_uddannelsesaftaleBeskrivelse()}

                    <ul>
                        <li>
                            {Localizer.ansoegOmSpeciallaegeanerkendelse_uddannelsesaftalePunktEt()}
                        </li>
                        <li>
                            {Localizer.ansoegOmSpeciallaegeanerkendelse_uddannelsesaftalePunktTo()}
                        </li>
                    </ul>

                    {Localizer.ansoegOmSpeciallaegeanerkendelse_uddannelsesaftaleAfslutning()}
                </div>


                <div className={classNames(
                        !isMobile && "aftale-file-picker",
                        isMobile && "aftale-file-picker-mobile"
                    )}>
                    <FileMetadataList
                        showAsTableIdentifier={"aftaleFileTable"}
                        filesUpdatedCallback={() => setUddannelsesNotater() }
                        uploaderTitle={Localizer.tilfoejAftale()}
                        fileMetadatas={uddannelseNotat?.fileMetadatas ?? []}
                        showDeleteButton={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                        notatInfo={[{contextId: ansoegning!.uddannelseId, notatType: NotatType.Uddannelse, userId: ansoegning!.brugerId, isVisibleToEveryone: false}]}
                        notat={uddannelseNotat ? [uddannelseNotat] : null}
                        withUploader={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                        fileLocation={FileLocationEnum.Logbog}
                        withDownload={true}
                        showFileType={true}
                        hideCreatedBy={true}
                        showAsCards={isMobile}
                    />
                </div>
            </>
        )
    }

    function getAfsendelseBeskrivelse(): ReactNode {
        return (
            <>
               <div>
                   <div>
                       <div>{Localizer.ansoegOmSelvstaendigVirke_afsendelseBeskrivelseOverskrift()} </div>
                       <ul>
                           <li>
                               {ansoegning?.uddannelseType === UddannelseTypeEnum.KBU
                                   ? Localizer.ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktEtKBU()
                                   : Localizer.ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktEtHU()
                               }
                           </li>
                           <li>
                               {Localizer.ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktTo()}
                           </li>
                           <li>
                               {Localizer.ansoegOmSelvstaendigVirke_afsendelseBeskrivelsePunktTre()}
                           </li>
                       </ul>

                       {dokumentationSent &&
                           <button className={"btn btn-primary"}
                                   onClick={handleClickOpenSentDocumentationModal}>{Localizer.ansoegOmSelvstaendigVirke_seTidligereAfsendtDokumentation()}</button>}

                       <div className="aftale-file-picker margin-top-l">
                           <div className="flex-container-row">
                               <h4>{Localizer.global_dokumentation()}</h4>
                               <Tooltip className="text-truncate"
                                        title={dokumentationSent ? Localizer.ansoegOmSelvstaendigVirke_obligatoriskDokumentationErValgfritVedEftersendelse() : Localizer.ansoegOmSelvstaendigVirke_obligatoriskDokumentationErPaakraevetVedFoersteAfsendelse()}>
                                   <EmblaIcon additionalClasses="margin-left-s subtle" iconName="info"/>
                               </Tooltip>
                           </div>
                           <ObligatoriskDokumentation
                               userId={ansoegning!.brugerId}
                               forloebId={forloebSliceState.forloebState.id}
                               uddannelseId={ansoegning!.uddannelseId}
                               brugerMaalbeskrivelseId={ansoegning!.brugerMaalbeskrivelseId}
                               ansoegningId={route.ansoegning}
                               uddannelsesType={ansoegning?.uddannelseType}
                               tableId={"dokumentationTableId"}
                               simpleVersion={true}
                               completionCallback={obligatoriskDokumentationCompleteCallback}
                               fileSelectedCallback={obligatoriskDokumentationSelected}
                               initialSelectedRows={obligatoriskeFilesSelected}/>
                       </div>

                       <div className="d-flex justify-content-between margin-bottom-s">
                           <div className="d-flex align-items-center">
                               <h4>{Localizer.global_ownFiles()}</h4>
                               <Tooltip className="text-truncate margin-left-s"
                                        title={Localizer.global_stpsFileLimits()}>
                                   <EmblaIcon additionalClasses="subtle" iconName={EmblaIcons.Info}/>
                               </Tooltip>
                           </div>
                       </div>

                       <div className={classNames(
                           !isMobile && "aftale-file-picker",
                           isMobile && "aftale-file-picker-mobile"
                       )}>
                           <FileMetadataList
                               showAsTableIdentifier={attachedFilesTableId}
                               filesUpdatedCallback={() => setNotater()}
                               fileDeletedCallback={(file) => {
                                   if (userFilesSelected.includes(file.id))
                                       setUserFilesSelected(userFilesSelected.filter(id => id !== file.id))
                               }}
                               fileMetadatas={getFileMetadatas()}
                               showDeleteButton={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                               notatInfo={[
                                   {
                                       contextId: ansoegning!.uddannelseId,
                                       notatType: NotatType.Uddannelse,
                                       userId: ansoegning!.brugerId,
                                       isVisibleToEveryone: false
                                   },
                                   {
                                       contextId: ansoegning!.ansoegningId,
                                       notatType: NotatType.Dokumentation,
                                       userId: ansoegning!.brugerId
                                   },
                                   {
                                       contextId: ansoegning!.ansoegningId,
                                       notatType: NotatType.Merit,
                                       userId: ansoegning!.brugerId
                                   }
                               ]}
                               notat={getNotater()}
                               withUploader={currentUser.IsAdminOrVus() || currentUser.IsLaege()}
                               uploadNotatType={NotatType.Dokumentation}
                               fileLocation={FileLocationEnum.Logbog}
                               fileSelectedCallback={setUserFilesSelected}
                               showFileType={true}
                               withDownload={true}
                               hideCreatedBy={true}
                               selectedRows={userFilesSelected}
                               showAsCards={isMobile}
                           />
                       </div>
                   </div>
               </div>
            </>
        )
    }

    function getOverview(): ReactNode {
        return (
            <>
                <div>
                    <div>
                        <p>{Localizer.ansoegOmSelvstaendigVirke_overblikPunktEt()}</p>
                        <p>{Localizer.ansoegOmSelvstaendigVirke_overblikPunktTo()}</p>
                        <p>{Localizer.ansoegOmSelvstaendigVirke_overblikPunktTre()}</p>
                        <p>{Localizer.ansoegOmSelvstaendigVirke_overblikPunktFire()}</p>
                        <div className="margin-top-l">
                            <h4>{`${Localizer.global_dokumentation()} (${getObligatoriskeFilesSelected().length})`}</h4>
                            {obligatoriskeFilesSelected.length > 0
                            ? <ObligatoriskDokumentation
                                    userId={ansoegning!.brugerId}
                                    forloebId={forloebSliceState.forloebState.id}
                                    uddannelseId={ansoegning!.uddannelseId}
                                    brugerMaalbeskrivelseId={ansoegning!.brugerMaalbeskrivelseId}
                                    ansoegningId={route.ansoegning}
                                    uddannelsesType={ansoegning?.uddannelseType}
                                    tableId={"selectedDocumentationTableId"}
                                    simpleVersion={false}
                                    responsive={true}
                                    hideStatus={true}
                                    showDokumentationTypes={getObligatoriskeFilesSelected()}
                                />
                            : <div className="margin-top-s">{Localizer.ansoegOmSelvstaendigVirke_ingenObligatoriskDokumentationValgt()}</div>}
                        </div>

                        <div className="flex-column justify-content-between margin-bottom-s margin-top-l">
                            <h4>{`${Localizer.global_ownFiles()} (${userFilesSelected?.length ?? 0})`}</h4>
                                {(userFilesSelected?.length ?? 0) > 0
                                ? <FileMetadataList
                                    showAsTableIdentifier={"selectedFilesTableId"}
                                    fileMetadatas={getSelectedFiles()}
                                    fileLocation={FileLocationEnum.Logbog}
                                    showFileType={true}
                                    hideFileIcon={true}
                                    hideDate={true}
                                    hideCreatedBy={true}
                                    showDeleteButton={false}
                                    withDownload={true}
                                    showAsCards={isMobile}
                                />
                                : <div className="margin-top-s">{Localizer.ansoegOmSelvstaendigVirke_ingenValgfriDokumentationValgt()}</div>}
                        </div>
                   </div>
               </div>
            </>
        )
    }

    function getSentDocumentation(): ReactNode {
        return (
            <>
                <div>
                    {documentation.length > 0 &&
                        <div className={"padding-left-m"}>
                            <DocumentationList tableTitle={Localizer.uddannelseOverview_documentationSentToSTPS() +  (ansoegning?.dokumentationAfsendtDen ? ` - ${new Date(ansoegning.dokumentationAfsendtDen).dateWithTimeFormat()}` : "")}
                                               documentation={documentation}
                                               hideDate={true}/>
                        </div>
                    }
                    {eftersendtDocumentation.length > 0 &&
                        <div className={"padding-left-m margin-top-l"}>
                            <DocumentationList tableTitle={Localizer.uddannelseOverview_documentationSentLaterToSTPS()}
                                               documentation={eftersendtDocumentation}
                                               hideCreatedBy={true}
                                               showTimeInDatetime={true}/>
                        </div>
                    }
                </div>
            </>
        )
    }

    function AnsoegningsSteps(): WizardChildModel[] {
        let wizardChildList = new Array<WizardChildModel>();
        wizardChildList.push({
            content: getBeskaeftigelseBeskrivelse(),
            stepHeader: Localizer.ansoegOmSelvstaendigVirke_laegeligBeskaeftigelse(),
            stepBeskrivelse: Localizer.ansoegOmSelvstaendigVirke_beskaeftigelse(),
            stepComplete: beskaeftigelser !== undefined && beskaeftigelser.length > 0
        })

        wizardChildList.push({
            content: ansoegning?.uddannelseType == UddannelseTypeEnum.KBU ? getUddannelsesaftalerBeskrivelseSelvstaendigtVirke() : getUddannelsesaftalerBeskrivelseSpeciallaegeanerkendelse(),
            stepHeader: Localizer.ansoegOmSelvstaendigVirke_ansaettelseOgUddannelsesaftaler(),
            stepBeskrivelse: Localizer.ansoegOmSelvstaendigVirke_ansaettelseOgUddannelsesaftaler(),
            stepComplete: (uddannelseNotat?.fileMetadatas.length !== undefined && uddannelseNotat.fileMetadatas.length > 0)
        })

        wizardChildList.push({
            content: getAfsendelseBeskrivelse(),
            stepHeader: Localizer.ansoegOmSelvstaendigVirke_klargoerDokumentation(),
            stepBeskrivelse: Localizer.ansoegOmSelvstaendigVirke_klargoerDokumentation(),
            stepComplete: allowSendAnsoegning()
        })

        wizardChildList.push({
            content: getOverview(),
            stepHeader: Localizer.ansoegOmSelvstaendigVirke_overblikOverskrift(),
            stepBeskrivelse: dokumentationSent ? Localizer.ansoegOmSelvstaendigVirke_eftersendDokumentation() : Localizer.ansoegOmSelvstaendigVirke_afsendelse(),
            stepComplete: false
        })

        return wizardChildList;
    }

    async function sendDocumentation(): Promise<void> {
        setIsSendingFiles(true);
        const filteredUserFiles = getSelectedFiles();
        let emailType: EmailTypeEnum | null = null;
        let successMessage: string = "";

        switch (ansoegning?.uddannelseType) {
            case UddannelseTypeEnum.KBU:
                if (!dokumentationSent)
                    emailType = EmailTypeEnum.StpsSelvstaendigtVirke
                else
                    emailType = EmailTypeEnum.StpsSupplerendeSelvstaendigtVirke

                successMessage = Localizer.ansoegOmSelvstaendigVirke_dokumentationAfsendtKBU();
                break;
            case UddannelseTypeEnum.Hoveduddannelse:
                if (!dokumentationSent)
                    emailType = EmailTypeEnum.StpsSpeciallaege
                else
                    emailType = EmailTypeEnum.StpsSupplerendeSpeciallaege

                successMessage = Localizer.ansoegOmSelvstaendigVirke_dokumentationAfsendtHU();
                break;
            default:
                break;
        }

        if (emailType === null){
            NotificationModule.showError(Localizer.ansoegOmSelvstaendigVirke_invalidUddannelseType(), "");
            return;
        }

        const userInfo = userService.getUser();
        const inputStempel = new StempelInputModel(userInfo.UserId, userInfo.Name, userInfo.AuthorizationId, userInfo.CurrentRolleTitel, userInfo.Initialer);

        const requestModel = new logbogEmailStpsRequestModel({
            userId: ansoegning?.brugerId,
            uddannelsesLaegeId: ansoegning?.brugerId,
            brugerMaalbeskrivelseId: ansoegning?.brugerMaalbeskrivelseId,
            brugerKursusRaekkeId: ansoegning?.brugerKursusraekkeId,
            ansoegningsId: route.ansoegning,
            uddannelsesId: ansoegning?.uddannelseId,
            uddannelseType: ansoegning?.uddannelseType,
            userFiles: filteredUserFiles,
            obligatoriskeFiles: obligatoriskeFilesSelected,
            emailTypeEnum: emailType,
            stempelInputModel: inputStempel,
            eftersendt: dokumentationSent
        });

        try {
            await emailApi.SendStpsAnsoegning(requestModel);
        } catch (e: any) {
            setIsSendingFiles(false);
            NotificationModule.showErrorSmall(e.message);
            return;
        }

        setDokumentationSent(true);
        setIsSendingFiles(false);
        setDokumentationFinishedSending(true);

        NotificationModule.showSuccessSmall(successMessage);
    }

    const getUserFiles = () => {
        return (dokumentationNotat?.fileMetadatas ?? [])
            .concat(uddannelseNotat?.fileMetadatas ?? [])
            .concat(logbogLegacyNotater?.flatMap(n => n.fileMetadatas) ?? [])
            .concat(meritNotater?.flatMap(n => n.fileMetadatas) ?? [])
            .concat(kursusNotater?.flatMap(n => n.fileMetadatas) ?? []);
    }

    const getSelectedFiles = () => {
        const userFiles = getUserFiles();
        return userFiles.filter((u, i) => userFilesSelected?.includes(u.id))
    }

    const getObligatoriskeFilesSelected = () => {
        const files = obligatoriskeFilesSelected.filter(o => o.toString() !== ObligatoriskDokumentationTypeEnum.AnsaettelseOgUddannelse.toString());

        return files.map(o => parseInt(o) as ObligatoriskDokumentationTypeEnum);
    }

    // Allow sending of documentation if
    function allowSendAnsoegning() {
        const ownFilesSelected = userFilesSelected !== undefined && userFilesSelected.length > 0;
        const obligatoriskDokumentationFilesSelected = obligatoriskeFilesSelected !== undefined && obligatoriskeFilesSelected.length > 0;
        const alleObligatoriskeDokumenterValgtVedAfsendelse = obligatoriskDokumentationFilesSelected && antalObligatoriskDokumentation === obligatoriskeFilesSelected.length;
        const minimumOneAnsaettelseOgUddannelsesaftaleDokumentChosen = uddannelseNotat?.fileMetadatas.some(f => userFilesSelected.some(y => y === f.id)) ?? false;

        const userHasSelectedCorrectAmountOfFiles =
            (
                ownFilesSelected ||
                obligatoriskDokumentationFilesSelected
            )
            &&
            (
                dokumentationSent ||
                (
                    alleObligatoriskeDokumenterValgtVedAfsendelse &&
                    minimumOneAnsaettelseOgUddannelsesaftaleDokumentChosen
                )
            );
        return userHasSelectedCorrectAmountOfFiles &&
            !isSendingFiles &&
            obligatoriskDokumentationComplete &&
            canSendStpsDocumentation;
    }

    const updateUrl = useCallback((step?: StpsStep) => {
        const routePath = RoutePaths.SendStpsDocumentationPage({
            uddannelseslaege: routeMatch.params.uddannelseslaege,
            ansoegning: routeMatch.params.ansoegning,
            step: step
        });

        history.push({pathname: routePath.url});
    }, [history, routeMatch.params.ansoegning, routeMatch.params.uddannelseslaege]);

    const downloadQuickguide = async (uddannelseType? : UddannelseTypeEnum) => {
        if(uddannelseType){
            await fileApi.downloadQuickguideFile(uddannelseType);
        }
    }

    return (
        <Loading isLoading={isLoading}>
            {ansoegning &&
                <>
                    {isSendingFiles &&
                        <div className="card top-level-container wizard justify-content-center align-items-center">
                            <Loading isLoading={true}><div/></Loading>
                            <h4 className={"margin-top-m"}>{Localizer.ansoegOmSelvstaendigVirke_dokumentationKlargoeres()}</h4>
                        </div>
                    }
                    {dokumentationFinishedSending &&
                        <div className="card top-level-container wizard justify-content-center align-items-center">
                            <EmblaIllustration illustrationName={"success-mark"}
                                            additionalClasses={"margin-bottom-m"}/>
                            <h4>{Localizer.ansoegOmSelvstaendigVirke_ansoegningAfsendt()}</h4>
                            {/*<h4>{Localizer.ansoegOmSelvstaendigVirke_ansoegningAfventerAfsendelse()}</h4>*/}
                            {/*TODO bst: Byt rundt på de to h4, når vi enabler azure function til emailafsendelse*/}
                            <button className={"btn btn-primary margin-top-m"} onClick={() => navigateToForloeb()}>{Localizer.global_afslut()}</button>
                        </div>
                    }
                    {!isSendingFiles && !dokumentationFinishedSending &&
                        <Wizard
                            wizardChildren={AnsoegningsSteps()}
                            finishButtonText={Localizer.ansoegOmSelvstaendigVirke_afsendDokumentation()}
                            finishCallback={() => sendDocumentation()}
                            canFinish={allowSendAnsoegning()}
                            renderHeaderItem={(model) => (
                                <>
                                    {model.stepHeader}
                                    <a
                                        className={"quickguide-icon-stpsansoegning margin-left-s margin-top-xxs"}
                                        title={Localizer.uddannelseOverview_quickguideLinkTooltip()}
                                        onClick={() => downloadQuickguide(ansoegning.uddannelseType)}>
                                        <EmblaIcon iconName={"question"}/>
                                    </a>
                                </>
                            )}
                            finishModalDescription = {Localizer.ansoegOmSelvstaendigVirke_areYourSureAfsendDokumentation()}
                            finishModalAcceptButtonText = {Localizer.ansoegOmSelvstaendigVirke_afsendDokumentation()}
                            initialStep={route.step}
                            newStepCallback={updateUrl}
                        />
                    }
                </>
            }
            {beskaeftigelseModalOpen &&
            <SlideIn id={createBeskaeftigelseModalTarget}
                     title={Localizer.global_beskaeftigelse()}
                     bodyContent={renderBeskaeftigelseCreate()}
                     defaultOpen={true}
                     actionOnCloseCallback={() => setBeskaeftigelseModalOpen(false)}
                     actionFinishedCallback={() => beskaeftigelserChangedCallback()}
            />
            }
            {isUddannelseModalShown &&
                <SlideIn
                    id={"sent-documentation-modal"}
                    title={Localizer.ansoegOmSelvstaendigVirke_tidligereAfsendtDokumentation()}
                    modalSize={ModalSize.md}
                    additionalClasses={"no-height"}
                    defaultOpen={true}
                    actionOnCloseCallback={() => setIsUddannelseModalShown(false)}
                    bodyContent={getSentDocumentation()}
                />
            }
        </Loading>
    );

}
