import { useEffect, useState } from "react";
import { Dropdown, DropdownOption, DropdownProps } from "../dropdown";
import useRegionerDropdownOptions, { SelectedRegionIdsResult } from "../hooks/useRegionerDropdownOptions";
import { ForSelectDropdownGenericProps } from "./forSelectDropdown";
import useRestrictedDropdownOptions from "../hooks/useRestrictedDropdownOptions";

export type RegionerMultiDropdownProps <T, IsMulti extends boolean = false> = {
    onChangeCallback: (newValue: SelectedRegionIdsResult) => void;
} & ForSelectDropdownGenericProps<T>
& Pick<DropdownProps<T, DropdownOption<T>, false, IsMulti>, "isMulti">;

const RegionerMultiDropdown = ({ initialSelectedOption, onChangeCallback, title="", placeholder="", permission, restrictedValues=[], disable=false, onMenuCloseCallback} : RegionerMultiDropdownProps<string, true>) => {

    const { regionerDropdownOptions, transformSelectedOptionsToRegionIds, amountOfSelectableRegions } = useRegionerDropdownOptions(permission);
    
    const [selectedOptions, setSelectedOptions] = useState<string[]>(transformSelectedOptionsToRegionIds(initialSelectedOption as string[])?.regionIds ?? []);
    const isSelectedDefined = selectedOptions?.length === 0 ?? selectedOptions !== undefined;
    
    const { restrictedDropdownOptions } = useRestrictedDropdownOptions(restrictedValues, regionerDropdownOptions, isSelectedDefined);
    const oneOrNoneRestrictedIds = restrictedDropdownOptions.length <= 1;

    useEffect(() => {
        if(amountOfSelectableRegions === 1 && oneOrNoneRestrictedIds) {
            setSelectedOptions([regionerDropdownOptions[0]?.value]);
        }
    }, [amountOfSelectableRegions, oneOrNoneRestrictedIds, regionerDropdownOptions]);

    const singleOrNoneSelectedOption = oneOrNoneRestrictedIds && !isSelectedDefined && amountOfSelectableRegions <= 1;

    return (
        <div className="margin-top-s">
            {title &&
                <label>{title}</label>
            }

            <Dropdown
                placeholder={placeholder}
                options={restrictedDropdownOptions}
                onChange={newMultiValue => {     
                    const selectedRegionIdsResult = transformSelectedOptionsToRegionIds(newMultiValue.map(x => x.value));
                    setSelectedOptions(selectedRegionIdsResult.regionIds ?? []);
                    onChangeCallback(selectedRegionIdsResult);
                }}
                value={restrictedDropdownOptions.filter(x => selectedOptions.includes(x.value))}
                isClearable
                isDisabled={singleOrNoneSelectedOption || disable}
                isMulti
                onMenuClose={onMenuCloseCallback}
            />

        </div>
    );
}

export default RegionerMultiDropdown;