import React, {useCallback, useEffect, useState} from 'react'
import {Localizer} from "../../../infrastructure/localization/localizer";
import {CrudVejlederInputModel} from "./crudVejlederInputModel";
import {ErrorMessage, FormikProps, useField} from "formik";
import {ValidationDatepicker} from "../../../core/components/validation/components/validationDatePicker";
import {nameof} from "ts-simple-nameof";
import {CrudUddannelsesstedInputModel} from "./crudUddannelsesstedInputModel";
import './vejlederCrudFields.scss';
import {VejlederEditModel} from "./vejlederEditModel";
import {Loading} from "../../../core/components/loading/loading";
import {toDropdownOptions} from "../../../core/sharedmodels/forSelectModel";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {RootTypeEnum} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";
import {ValidationDropdown} from "../../../core/components/validation/components/validationDropdown";
import useEnumDropdownOptions from "../../../core/components/dropdown/hooks/useEnumDropdownOptions";
import {Dropdown, DropdownOption} from "../../../core/components/dropdown/dropdown";
import useStamdataApi from 'core/hooks/useStamdataApi';
import {EmblaIcons} from 'core/emblaIcons';
import {ValidationSwitch} from 'core/components/validation/components/ValidationSwitch';
import useVejlederLaegeOptions, {TransformInput} from '../hooks/useVejlederLaegeOptions';
import {ProcessValidationInput} from 'core/components/validation/ValidationInputHelper';

type VejlederCrudFieldsProps = {
    modalId: string;
    formik: FormikProps<CrudVejlederInputModel>;
    uddannelsesstedNumber: number;
    totalUddannelsessteder: number;
    vejlederId?: string | undefined;
    fjernUddannelsesstedCallback?: (afdelingNumber: number, totalAfdelinger: number) => void;
    vejlederEditModel?: VejlederEditModel;
    readonly?: boolean;
}

const nameOfInputModel = (selector: (obj: CrudVejlederInputModel) => any) => nameof(selector);

const sliderColorClasses: Record<string, string> = {
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleLaeger)]: '',
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleKbuLaeger)]: 'color-alt-slider-1',
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleIntroduktionsLaeger)]: 'color-alt-slider-2',
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleHoveduddannelsesLaeger)]: 'color-alt-slider-3',
}

const pillColorClasses: Record<string, string> = {
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleLaeger)]: '',
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleKbuLaeger)]: 'color-alt-pill-1',
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleIntroduktionsLaeger)]: 'color-alt-pill-2',
    [nameof<CrudUddannelsesstedInputModel>(x => x.alleHoveduddannelsesLaeger)]: 'color-alt-pill-3',
}

export const VejlederCrudFields = ({...props}: VejlederCrudFieldsProps) => {
    const {stamdataAfdelingApi, stamdataLaegeinstitutionApi} = useStamdataApi();

    const isEdit = !!props.vejlederEditModel;

    const [isLoadingUddannelsessteder, setIsLoadingUddannelsessteder] = useState(true);
    const [isFetchingOptions, setIsFetchingOptions] = useState(false);
    const [hasSingleUddannelsessted, setHasSingleUddannelsessted] = useState(false);

    const [selectedLaeger, setSelectedLaeger] = useState<string[]>(isEdit ? props.vejlederEditModel?.LaegerIds ?? [] : props.formik.values.uddannelsessted.laeger);

    // Dropdown formik validation error
    const [field, meta] = useField(nameof<CrudVejlederInputModel>(x => x.uddannelsessted.laeger));
    const laegerDropdownValidationInputResult = ProcessValidationInput(meta.error);

    // Form State
    const [selectedLaegeinstitution, setSelectedLaegeinstitution] = useState<RootTypeEnum>();
    const [selectedUddannelsessted, setSelectedUddannelsessted] = useState<string>();

    const [canDeselectAlleLaeger, setCanDeselectAlleLaeger] = useState<boolean>(props.formik.values.uddannelsessted.alleLaeger);
    const [canDeselectAlleKbuLaeger, setCanDeselectAlleKbuLaeger] = useState<boolean>(props.formik.values.uddannelsessted.alleKbuLaeger);
    const [canDeselectAlleIntroLaeger, setCanDeselectAlleIntroLaeger] = useState<boolean>(props.formik.values.uddannelsessted.alleIntroduktionsLaeger);
    const [canDeselectAlleHuLaeger, setCanDeselectAlleHuLaeger] = useState<boolean>(props.formik.values.uddannelsessted.alleHoveduddannelsesLaeger);

    // Dropdown options
    const [laegeinstitutionsTypeSelectOptions] = useEnumDropdownOptions<RootTypeEnum>(RootTypeEnum);
    const [sygehusSelectOptions, setSygehusSelectOptions] = useState<DropdownOption<string>[]>([]);
    const [praksisSelectOptions, setPraksisSelectOptions] = useState<DropdownOption<string>[]>([]);
    const [afdelingSelectOptions, setAfdelingSelectOptions] = useState<DropdownOption<string>[]>([]);
    const { laegerDropDownOption, transformSelectedOptionsToLaegeIds, allKbuIds, allIntroduktionsLaegerIds, allHoveduddannelsesLaegerIds, allLaegerIds, isLoadingOptions } = useVejlederLaegeOptions(selectedUddannelsessted, props.vejlederId, selectedLaegeinstitution)

    // Alerts
    const showNoPraksisAlert = !isFetchingOptions && (selectedLaegeinstitution === RootTypeEnum.Praksis) && praksisSelectOptions.length === 0;
    const showNoSygehuseAlert = !isFetchingOptions && (selectedLaegeinstitution === RootTypeEnum.Sygehus) && sygehusSelectOptions.length === 0;
    const showNoAfdelingAlert = !isFetchingOptions && (selectedLaegeinstitution === RootTypeEnum.Sygehus) && (!!props.formik.values.uddannelsessted?.sygehusId) && afdelingSelectOptions.length === 0;

    // Formik Helpers
    const formikSetFieldValue = props.formik.setFieldValue;
    const formikSetFieldValueForModel = useCallback((selector: (obj: CrudVejlederInputModel) => any, value: any) => {
        const fieldName = nameof(selector);
        formikSetFieldValue(fieldName, value);
    }, [formikSetFieldValue]);

    const resetFormForUddannelsessted = (uddannelsesstedPatchModel: Partial<CrudUddannelsesstedInputModel>) => {
        props.formik.resetForm({values: { ...props.formik.values, uddannelsessted: { ...props.formik.values.uddannelsessted, ...uddannelsesstedPatchModel }}})
    }

    const isLoading = isLoadingOptions && isLoadingUddannelsessteder;

    useEffect(() => {
        const handleSetInitialLaegeinstitution = async () => {
            if (isEdit) {
                formikSetFieldValueForModel(x => x.createNewUser, false);
                setSelectedLaegeinstitution(props.vejlederEditModel?.LaegeinstitutionsType);
            } else {
                const singleUddannelsesstedModel = await stamdataLaegeinstitutionApi.GetSingleLaegeinstitutionRootType();
                if(singleUddannelsesstedModel.hasAccessToSingleUddannelsessted) {
                    // Single uddannelsessted: Prefill fields
                    setHasSingleUddannelsessted(true);
                    setSelectedLaegeinstitution(singleUddannelsesstedModel.laegeinstitutionRootType);
                    formikSetFieldValueForModel(x => x.uddannelsessted.laegeinstitutionstype, singleUddannelsesstedModel.laegeinstitutionRootType);
                }
            }

            setIsLoadingUddannelsessteder(false);
        }
        handleSetInitialLaegeinstitution();
    }, [isEdit, formikSetFieldValueForModel, props.vejlederEditModel?.LaegeinstitutionsType, stamdataLaegeinstitutionApi]);

    const setAfdelinger = useCallback(async (hospitalsenhedId?: string) => {
        if(!hospitalsenhedId) {
            setAfdelingSelectOptions([]);
            return;
        };

        setIsFetchingOptions(true);

        const afdelinger = await stamdataAfdelingApi.getAfdelingerBySygehusId(hospitalsenhedId);
        setAfdelingSelectOptions(afdelinger.map(x => ({value: x.id, label: x.navn})))

        if (afdelinger.length === 1) {
            // Single afdeling: Prefill fields
            const afdelingId = afdelinger[0].id;
            formikSetFieldValueForModel(x => x.uddannelsessted.afdelingId, afdelingId);
            setSelectedUddannelsessted(afdelingId);
        }

        if (afdelinger.length === 0) {
            setSelectedUddannelsessted(undefined);
        }

        setIsFetchingOptions(false);
    }, [formikSetFieldValueForModel, stamdataAfdelingApi])

    useEffect(() => {
        const fetchSygehusPraksisDropdownOptions = async () => {
            setIsFetchingOptions(true);

            if (selectedLaegeinstitution === RootTypeEnum.Sygehus) {

                // Get sygehuse dropdown options
                const sygehuse = await stamdataLaegeinstitutionApi.getSygehuseSelect();

                if(sygehuse.length === 1){
                    // Single sygehus: Prefill fields
                    const sygehusId = sygehuse[0].id;
                    formikSetFieldValueForModel(x => x.uddannelsessted.sygehusId, sygehusId)
                    await setAfdelinger(sygehusId);
                }

                if (sygehuse.length === 0) {
                    await setAfdelinger();
                }

                setSygehusSelectOptions(toDropdownOptions(sygehuse));
                setPraksisSelectOptions([]);

                if (props.vejlederEditModel?.SygehusId)
                    await setAfdelinger(props.vejlederEditModel.SygehusId);

            } else if (selectedLaegeinstitution === RootTypeEnum.Praksis) {

                // Get praksis dropdown options
                const praksis = await stamdataLaegeinstitutionApi.getPraksisSelect();
                if(praksis.length === 1){
                    // Single praksis: Prefill fields
                    const praksisId = praksis[0].id;
                    setSelectedUddannelsessted(praksisId);
                    formikSetFieldValueForModel(x => x.uddannelsessted.praksisId, praksisId);
                }

                setPraksisSelectOptions(toDropdownOptions(praksis));
                setSygehusSelectOptions([]);
                setAfdelingSelectOptions([]);
            } else {

                // Reset all options
                setSygehusSelectOptions([]);
                setAfdelingSelectOptions([]);
                setPraksisSelectOptions([]);
            }

            if (isEdit) {
                const uddannelsesSted = props.vejlederEditModel?.LaegeinstitutionsType === RootTypeEnum.Sygehus
                    ? props.vejlederEditModel?.AfdelingId
                    : props.vejlederEditModel?.PraksisId;
                setSelectedUddannelsessted(uddannelsesSted);
                setSelectedLaegeinstitution(props.vejlederEditModel?.LaegeinstitutionsType);
            }

            setIsFetchingOptions(false);
        }

        fetchSygehusPraksisDropdownOptions();
    }, [formikSetFieldValueForModel, isEdit, props.vejlederEditModel?.AfdelingId, props.vejlederEditModel?.LaegeinstitutionsType, props.vejlederEditModel?.PraksisId, props.vejlederEditModel?.SygehusId, selectedLaegeinstitution, setAfdelinger, stamdataLaegeinstitutionApi])

    const laegeinstitutionSelected = (selectedLaegeinstitution?: RootTypeEnum) => {
        resetFormForUddannelsessted({ laegeinstitutionstype: selectedLaegeinstitution, sygehusId: undefined, afdelingId: undefined, laeger: [], praksisId: undefined });
        setSelectedLaegeinstitution(selectedLaegeinstitution);
    }

    const sygehusSelected = async (sygehusId?: string) => {
        resetFormForUddannelsessted({ sygehusId: sygehusId, afdelingId: undefined, laeger: [] })
        await setAfdelinger(sygehusId);
    }

    const uddannelsesstedSelected = (laegeinstitutionRootType: RootTypeEnum, uddannelsesstedId?: string) => {
        if (laegeinstitutionRootType === RootTypeEnum.Sygehus) {
            resetFormForUddannelsessted({ afdelingId: uddannelsesstedId, laeger: [] })
        } else {
            resetFormForUddannelsessted({ praksisId: uddannelsesstedId, laeger: [] })
        }

        setSelectedUddannelsessted(uddannelsesstedId);
        setSelectedLaegeinstitution(laegeinstitutionRootType);
    }

    const isInAllKbuLaeger = useCallback((id: string) => props.formik.values.uddannelsessted.alleKbuLaeger && allKbuIds.includes(id), [allKbuIds, props.formik.values.uddannelsessted.alleKbuLaeger]);
    const isInAllIntroLaeger = useCallback((id: string) => props.formik.values.uddannelsessted.alleIntroduktionsLaeger && allIntroduktionsLaegerIds.includes(id), [allIntroduktionsLaegerIds, props.formik.values.uddannelsessted.alleIntroduktionsLaeger]);
    const isInAllHovedUddLaeger = useCallback((id: string) => props.formik.values.uddannelsessted.alleHoveduddannelsesLaeger && allHoveduddannelsesLaegerIds.includes(id), [allHoveduddannelsesLaegerIds, props.formik.values.uddannelsessted.alleHoveduddannelsesLaeger]);
    const isInUddannelsesTypeIdList = useCallback((id: string) => !isInAllKbuLaeger(id) && !isInAllIntroLaeger(id) && !isInAllHovedUddLaeger(id), [isInAllHovedUddLaeger, isInAllIntroLaeger, isInAllKbuLaeger]);
    const getSelectedLaegerForFormik = useCallback((laegerIds: string[]) => laegerIds.filter(id => isInUddannelsesTypeIdList(id)), [isInUddannelsesTypeIdList]);

    const handleUserUncheckedSliders = useCallback((transformInput: TransformInput, selectedIds: string[]) => {

        if(canDeselectAlleLaeger) return [];

        const idsToDeselect = new Set([
            ...canDeselectAlleKbuLaeger && !transformInput.alleKbuLaeger ? allKbuIds : [],
            ...canDeselectAlleIntroLaeger && !transformInput.alleIntroduktionsLaeger ? allIntroduktionsLaegerIds : [],
            ...canDeselectAlleHuLaeger && !transformInput.alleHoveduddannelsesLaeger ? allHoveduddannelsesLaegerIds : [],
        ]);

        return selectedIds.filter(id => !idsToDeselect.has(id));

    }, [allHoveduddannelsesLaegerIds, allIntroduktionsLaegerIds, allKbuIds, canDeselectAlleHuLaeger, canDeselectAlleIntroLaeger, canDeselectAlleKbuLaeger, canDeselectAlleLaeger]);

    const handleLaegerSelected = useCallback((transformInput: TransformInput, newLaegeIds?: string[]) => {
        setSelectedLaeger((prev) => {
            const implicitSelectedLaegeIDs = transformSelectedOptionsToLaegeIds(newLaegeIds ?? handleUserUncheckedSliders(transformInput, prev), {
                alleLaeger: transformInput?.alleLaeger,
                alleKbuLaeger: transformInput?.alleKbuLaeger,
                alleIntroduktionsLaeger: transformInput?.alleIntroduktionsLaeger,
                alleHoveduddannelsesLaeger: transformInput?.alleHoveduddannelsesLaeger,
            }).laegeIds;

            formikSetFieldValueForModel(x => x.uddannelsessted.laeger, getSelectedLaegerForFormik(implicitSelectedLaegeIDs));

            setCanDeselectAlleLaeger(false);
            setCanDeselectAlleKbuLaeger(false);
            setCanDeselectAlleIntroLaeger(false);
            setCanDeselectAlleHuLaeger(false);

            return implicitSelectedLaegeIDs;
        });
    }, [formikSetFieldValueForModel, getSelectedLaegerForFormik, transformSelectedOptionsToLaegeIds, handleUserUncheckedSliders]);

    const uncheckAllUddannelsestypeSwitches = useCallback(() => {
        formikSetFieldValueForModel(x => x.uddannelsessted.alleKbuLaeger, false);
        formikSetFieldValueForModel(x => x.uddannelsessted.alleIntroduktionsLaeger, false);
        formikSetFieldValueForModel(x => x.uddannelsessted.alleHoveduddannelsesLaeger, false);
    }, [formikSetFieldValueForModel]);

    useEffect(() => {
        const isAlleLaeger = props.formik.values.uddannelsessted.alleLaeger;

        handleLaegerSelected({
            alleLaeger: isAlleLaeger,
            alleKbuLaeger: !isAlleLaeger && props.formik.values.uddannelsessted.alleKbuLaeger,
            alleIntroduktionsLaeger: !isAlleLaeger &&  props.formik.values.uddannelsessted.alleIntroduktionsLaeger,
            alleHoveduddannelsesLaeger: !isAlleLaeger &&  props.formik.values.uddannelsessted.alleHoveduddannelsesLaeger,
        });

        if(isAlleLaeger)
            uncheckAllUddannelsestypeSwitches();

    }, [handleLaegerSelected, props.formik.values.uddannelsessted.alleHoveduddannelsesLaeger, props.formik.values.uddannelsessted.alleIntroduktionsLaeger, props.formik.values.uddannelsessted.alleKbuLaeger, props.formik.values.uddannelsessted.alleLaeger, uncheckAllUddannelsestypeSwitches]);

    const updateSwitchFieldValueIfIdRemoved = (shouldCheck: boolean, selector: (obj: CrudVejlederInputModel) => any, selectedIds: string[], idsToCompare: string[]) => {
        if(!shouldCheck) return false;

        if (!idsToCompare.every(x => selectedIds.includes(x))) {
            formikSetFieldValueForModel(selector, false);
            return false;
        }

        return true;
    };

    const getColorClass = useCallback((id: string) => {

        const kbuIdsSet = new Set(allKbuIds);
        const introduktionsLaegerIdsSet = new Set(allIntroduktionsLaegerIds);
        const hoveduddannelsesLaegerIdsSet = new Set(allHoveduddannelsesLaegerIds);

        if (kbuIdsSet.has(id)) return pillColorClasses[nameof<CrudUddannelsesstedInputModel>(x => x.alleKbuLaeger)];
        if (introduktionsLaegerIdsSet.has(id)) return pillColorClasses[nameof<CrudUddannelsesstedInputModel>(x => x.alleIntroduktionsLaeger)];
        if (hoveduddannelsesLaegerIdsSet.has(id)) return pillColorClasses[nameof<CrudUddannelsesstedInputModel>(x => x.alleHoveduddannelsesLaeger)];
        return "";
    }, [allHoveduddannelsesLaegerIds, allIntroduktionsLaegerIds, allKbuIds]);

    const shouldLocationRelatedValidationDropdownBeReadonly = props.readonly || isEdit || hasSingleUddannelsessted;

    return (
        <Loading isLoading={isLoading} text={Localizer.global_getChoices()}>
            <div className="col-sm-12">
                <div className="row crud-div">
                    {hasSingleUddannelsessted &&
                        <div className="col-sm-12 margin-top-m">
                            <div className="alert alert-info" role={"alert"}>
                                {Localizer.vejlederPage_userHasSingleUddannelsessted()}
                            </div>
                        </div>
                    }
                    <div className="col-sm-12">
                        <ValidationDropdown
                            model={{
                                label: Localizer.laegeinstitution(),
                                placeholder: Localizer.vaelgLaegeinstitution(),
                                htmlName: nameOfInputModel(x => x.uddannelsessted.laegeinstitutionstype)
                            }}
                            options={laegeinstitutionsTypeSelectOptions}
                            itemSelected={(newValue) => laegeinstitutionSelected(newValue?.value)}
                            formikProps={props.formik}
                            isClearable
                            readOnly={shouldLocationRelatedValidationDropdownBeReadonly}
                        />
                    </div>
                    {sygehusSelectOptions.length > 0 &&
                    <div className="col-sm-12">
                        <ValidationDropdown
                            model={{
                                label: Localizer.sygehus(),
                                placeholder: Localizer.vaelgSygehus(),
                                htmlName: nameOfInputModel(x => x.uddannelsessted.sygehusId)
                            }}
                            options={sygehusSelectOptions}
                            itemSelected={(selectedOption) => sygehusSelected(selectedOption?.value)}
                            formikProps={props.formik}
                            isClearable={true}
                            readOnly={shouldLocationRelatedValidationDropdownBeReadonly}
                        />
                    </div>
                    }
                    {praksisSelectOptions.length > 0 &&
                    <div className="col-sm-12">
                        <ValidationDropdown
                            model={{
                                label: Localizer.forloebOverviewPage_praksis(),
                                placeholder: Localizer.vaelgPraksis(),
                                htmlName: nameOfInputModel(x => x.uddannelsessted.praksisId)
                            }}
                            options={praksisSelectOptions}
                            itemSelected={(newValue) => uddannelsesstedSelected(RootTypeEnum.Praksis, newValue?.value)}
                            formikProps={props.formik}
                            isClearable
                            readOnly={shouldLocationRelatedValidationDropdownBeReadonly}
                        />

                    </div>
                    }

                    {showNoPraksisAlert &&
                        <div className="col-sm-12">
                            <div className=" alert alert-warning" role={"alert"}>
                                {Localizer.vejlederpage_noPraksisFound()}
                            </div>
                        </div>
                    }

                    {showNoSygehuseAlert &&
                        <div className="col-sm-12">
                            <div className=" alert alert-warning" role={"alert"}>
                                {Localizer.vejlederpage_noSygehuseFound()}
                            </div>
                        </div>
                    }

                    {afdelingSelectOptions.length > 0 &&
                    <div className="col-sm-12">
                        <ValidationDropdown
                            model={{
                                label: Localizer.afdeling(),
                                placeholder: Localizer.vaelgAfdeling(),
                                htmlName: nameOfInputModel(x => x.uddannelsessted.afdelingId)
                            }}
                            options={afdelingSelectOptions}
                            itemSelected={(newValue) => uddannelsesstedSelected(RootTypeEnum.Sygehus, newValue?.value)}
                            formikProps={props.formik}
                            isClearable={true}
                            readOnly={shouldLocationRelatedValidationDropdownBeReadonly}
                        />

                    </div>
                    }
                    {showNoAfdelingAlert &&
                        <div className="col-sm-12">
                            <div className=" alert alert-warning" role={"alert"}>
                                {Localizer.vejlederpage_noAfdelingerFound()}
                            </div>
                        </div>
                    }
                    <div className="col-sm-6">
                        <ValidationDatepicker
                            key={props.uddannelsesstedNumber.toString()}
                            model={{
                                label: Localizer.startdato(),
                                placeholder: Localizer.startdatoPlaceholder(),
                                htmlName: nameOfInputModel(x => x.uddannelsessted.startDato)
                            }}
                            allowClear={true}
                            inputId={nameof<CrudUddannelsesstedInputModel>(x => x.startDato) + props.uddannelsesstedNumber + props.modalId}
                            formikProps={props.formik}
                            readOnly={props.readonly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <ValidationDatepicker
                            key={props.uddannelsesstedNumber.toString()}
                            model={{
                                label: Localizer.slutdato(),
                                placeholder: Localizer.slutdatoPlaceholder(),
                                htmlName: nameOfInputModel(x => x.uddannelsessted.slutDato)
                            }}
                            allowClear={true}
                            inputId={nameof<CrudUddannelsesstedInputModel>(x => x.slutDato) + props.uddannelsesstedNumber + props.modalId}
                            formikProps={props.formik}
                            readOnly={props.readonly}
                        />
                    </div>

                <div className="col-sm-12 categories-dropdown margin-bottom-s margin-top-s">

                        <section>
                            <ValidationSwitch
                                model={{
                                    label: Localizer.alleLaeger(),
                                    placeholder: Localizer.alleLaeger(),
                                    htmlName: nameOfInputModel(x => x.uddannelsessted.alleLaeger),
                                }}
                                inputId={nameof<CrudUddannelsesstedInputModel>(x => x.alleLaeger) + props.modalId}
                                formikProps={props.formik}
                                additionalSliderClasses={sliderColorClasses[nameof<CrudUddannelsesstedInputModel>(x => x.alleLaeger)]}
                                readOnly={props.readonly}
                                onClickCallback={() => setCanDeselectAlleLaeger(true)}

                            />

                            <ValidationSwitch
                                model={{
                                    label: Localizer.alleKbuLaeger(),
                                    placeholder: Localizer.alleKbuLaeger(),
                                    htmlName: nameOfInputModel(x => x.uddannelsessted.alleKbuLaeger),
                                }}
                                inputId={nameof<CrudUddannelsesstedInputModel>(x => x.alleKbuLaeger) + props.modalId}
                                formikProps={props.formik}
                                additionalSliderClasses={sliderColorClasses[nameof<CrudUddannelsesstedInputModel>(x => x.alleKbuLaeger)]}
                                readOnly={props.formik.values.uddannelsessted.alleLaeger || props.readonly}
                                onClickCallback={() => setCanDeselectAlleKbuLaeger(true)}
                            />

                            <ValidationSwitch
                                model={{
                                    label: Localizer.alleIntroduktionsLaeger(),
                                    placeholder: Localizer.alleIntroduktionsLaeger(),
                                    htmlName: nameOfInputModel(x => x.uddannelsessted.alleIntroduktionsLaeger),
                                }}
                                inputId={nameof<CrudUddannelsesstedInputModel>(x => x.alleIntroduktionsLaeger) + props.modalId}
                                formikProps={props.formik}
                                additionalSliderClasses={sliderColorClasses[nameof<CrudUddannelsesstedInputModel>(x => x.alleIntroduktionsLaeger)]}
                                readOnly={props.formik.values.uddannelsessted.alleLaeger || props.readonly}
                                onClickCallback={() => setCanDeselectAlleIntroLaeger(true)}
                            />

                            <ValidationSwitch
                                model={{
                                    label: Localizer.alleHoveduddannelsesLaeger(),
                                    placeholder: Localizer.alleHoveduddannelsesLaeger(),
                                    htmlName: nameOfInputModel(x => x.uddannelsessted.alleHoveduddannelsesLaeger),
                                }}
                                inputId={nameof<CrudUddannelsesstedInputModel>(x => x.alleHoveduddannelsesLaeger) + props.modalId}
                                formikProps={props.formik}
                                additionalSliderClasses={sliderColorClasses[nameof<CrudUddannelsesstedInputModel>(x => x.alleHoveduddannelsesLaeger)]}
                                readOnly={props.formik.values.uddannelsessted.alleLaeger || props.readonly}
                                onClickCallback={() => setCanDeselectAlleHuLaeger(true)}
                            />
                        </section>

                        <div className='form-group padding-bottom-s'>
                            <label>{Localizer.laeger()}</label>
                                <Dropdown
                                    onChange={(newValue) => {
                                        const selectedIds = newValue.map(c => c.value);

                                        const uddannelsessted = props.formik.values.uddannelsessted;
                                        const isAlleLaegerChecked = updateSwitchFieldValueIfIdRemoved(uddannelsessted.alleLaeger, x => x.uddannelsessted.alleLaeger, selectedIds, allLaegerIds);
                                        if(isAlleLaegerChecked) uncheckAllUddannelsestypeSwitches();

                                        const isAlleKbuLaegerChecked = updateSwitchFieldValueIfIdRemoved(uddannelsessted.alleKbuLaeger, x => x.uddannelsessted.alleKbuLaeger, selectedIds, allKbuIds);
                                        const isAlleIntoLaegerChecked = updateSwitchFieldValueIfIdRemoved(uddannelsessted.alleIntroduktionsLaeger, x => x.uddannelsessted.alleIntroduktionsLaeger, selectedIds, allIntroduktionsLaegerIds);
                                        const isAlleHuLaegerChecked = updateSwitchFieldValueIfIdRemoved(uddannelsessted.alleHoveduddannelsesLaeger, x => x.uddannelsessted.alleHoveduddannelsesLaeger, selectedIds, allHoveduddannelsesLaegerIds);

                                        handleLaegerSelected({
                                            alleLaeger: isAlleLaegerChecked,
                                            alleKbuLaeger: isAlleKbuLaegerChecked,
                                            alleIntroduktionsLaeger: isAlleIntoLaegerChecked,
                                            alleHoveduddannelsesLaeger: isAlleHuLaegerChecked,
                                        }, selectedIds);
                                    }}
                                    optionColorOverride={(option) => getColorClass(option.value)}
                                    options={laegerDropDownOption}
                                    value={laegerDropDownOption.filter(x => selectedLaeger.includes(x.value))}
                                    isDisabled={props.readonly}
                                    placeholder={Localizer.vaelgLaeger()}
                                    isMulti
                                    isClearable
                                />

                                <ErrorMessage name={field.name}>
                                    {() => <span className="errorMessage field-validation-error">{laegerDropdownValidationInputResult.Message}</span>}
                                </ErrorMessage>
                        </div>

                        <p id="captionInputText" className="caption small subtle margin-bottom-m remove-margin-top">
                            <EmblaIcon iconName={EmblaIcons.Info}/>
                            {Localizer.vejlederpage_giveAccessToLaeger()}
                        </p>
                    </div>
                </div>
            </div>
        </Loading>
    );

}
