import { PropsWithChildren } from "react";
import Title, { TitleProps } from "../titels/title";
import classNames from "classnames";
import HtmlHeading from "../titels/HtmlHeadings";

type CardHeaderProps = {
    titleConfig?: TitleProps;
    additionalClasses?: string;
    childrenIsHeaderActions?: boolean;
}


const CardHeader = ({additionalClasses, titleConfig={}, children, childrenIsHeaderActions = false}: PropsWithChildren<CardHeaderProps>) => {
    return (
        <div className={classNames("card-header", additionalClasses)}>
            <div className={classNames("margin-bottom-m flex-fill", childrenIsHeaderActions && "flex-container-row")}>
                <Title
                    title={titleConfig.title}
                    titleClasses={titleConfig.titleClasses ?? "margin-top-m"}
                    subTitle={titleConfig.subTitle}
                    subTitleClasses={titleConfig.subTitleClasses}
                    largeSubTitle={titleConfig.largeSubTitle}
                    heading={titleConfig.heading ?? HtmlHeading.H3}
                    nonSubtleSubTitle={titleConfig.nonSubtleSubTitle}
                    titleInfoText={titleConfig.titleInfoText}
                >
                    <div className={classNames("margin-top-m", childrenIsHeaderActions && "flex-align-r")}>
                        {children}
                    </div>
                </Title>
            </div>
        </div>
    );
}

export default CardHeader;
