import {Localizer} from "../../infrastructure/localization/localizer";
import {nameof} from "ts-simple-nameof";
import {TableAsync} from "../../core/components/table/tableAsync";
import {useCallback, useEffect, useState} from "react";
import {StamdataUserResponseModel} from "../../services/api/stamdataUser/StamdataUserResponseModel";
import {UserTableColumnsPostModel} from "../../services/api/stamdataUser/UserTableColumnsPostModel";
import {Spinner} from "../../core/components/spinner/spinner";
import {DataTableHeaderAction} from "../../core/components/table/DataTableHeaderAction";
import {useDispatch} from "react-redux";
import {AsyncTableReducer, AsyncTableState, setFilter, setTableInitialized} from "./asyncTableSlice";
import "./brugerList.scss"
import {BrugerListFilter} from "./brugerListFilter";
import {UddannelseTypeEnum} from "../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {useAppSelector} from "../../app/hooks";
import {RolleTitelEnum, UserRoleTitleToString} from "../../infrastructure/enums/rolleTitelEnum";
import {TablePillOverflow} from "../../core/components/table/tablePillOverflow";
import ReactDOMServer, { renderToString } from 'react-dom/server'
import useStamdataApi from "core/hooks/useStamdataApi";
import useLogbogApi from "core/hooks/useLogbogApi";
import { SlideIn, closeModal } from "core/components/slideIn/slideIn";
import BrugerListeFilter from "./brugerListeFilter";
import ApplyFiltrerButton from "core/components/filter/components/applyFiltrerButton";
import { RemoveFiltrerButton } from "core/components/filter/components/removeFiltrerButton";
import { hasAnyDefinedProperties } from "index";
import useUserFilterPermissions from "core/hooks/useUserFilterPermissions";
import { EmblaIcons } from "core/emblaIcons";
import DynamicButton from "core/components/button/dynamicButton";
import useScreenResolution from "core/hooks/useScreenResolution";

const initialBrugerTableFilters: BrugerListFilter = {
    afdelingIds: [],
    omraadeIds: [],
    praksisIds: [],
    regionIds: [],
    rolleTitels: [],
    specialeIds: [],
    sygehusIds: [],
    uddannelseIds: []
};

/* The button is located in a Datatable, so Jquery is needed.*/
const updateFilterButtonState = (hasFilter: boolean, applyFiltrerBtnId: string, removeFiltrerButtonId: string, removeOnClick: () => void) => {
    const defaultClass = 'btn-default';
    const primaryClass = 'btn-primary';
    const filterBtnSelector = `#${applyFiltrerBtnId}`;
    const removeBtnSelector = `#${removeFiltrerButtonId}`;
    const $filterBtn = $(filterBtnSelector);

    if (hasFilter) {
        $filterBtn.toggleClass(defaultClass, false).toggleClass(primaryClass, true);
        if ($(removeBtnSelector).length) return;

        $filterBtn.parent().parent().parent().append(ReactDOMServer.renderToString(
            <RemoveFiltrerButton Id={removeFiltrerButtonId} additionalClasses="no-title-top-margin" />
        ));

        $(removeBtnSelector).off("click").on("click", removeOnClick);
    } else {
        $filterBtn.toggleClass(primaryClass, false).toggleClass(defaultClass, true);
        $(removeBtnSelector).remove();
    }
}

export const BrugerList = () => {
    const dispatch = useDispatch();

    const { isMobile } = useScreenResolution();
    const {stamdataUserApi,} = useStamdataApi();

    const { logbogExportApi } = useLogbogApi();
    const asyncTableState = useAppSelector(AsyncTableReducer) as AsyncTableState;

    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [brugerListFilter, setBrugerListFilter] = useState<BrugerListFilter>(initialBrugerTableFilters);

    const { permissions } = useUserFilterPermissions();

    const filterModalTarget = "filtermodal";
    const applyFiltrerBtnId = "ApplyFiltrerButton"
    const removeFiltrerButtonId = "removeFiltrerButton"
    const exportButtonId = "export-btn";

    const applyFilters = useCallback((newFilter: BrugerListFilter) => {
        setBrugerListFilter(newFilter);

        dispatch(setFilter({
            regionIds: newFilter.regionIds,
            omraadeIds: newFilter.omraadeIds,
            afdelingIds: newFilter.afdelingIds,
            praksisIds: newFilter.praksisIds,
            rolleTitels: newFilter.rolleTitels,
            specialeIds: newFilter.specialeIds,
            sygehusIds: newFilter.sygehusIds,
            uddannelseIds: newFilter.uddannelseIds,
        }));
        dispatch(setTableInitialized(true));
    }, [dispatch]);

    useEffect(() => {
        const hasfilters = hasAnyDefinedProperties(brugerListFilter);
        updateFilterButtonState(hasfilters, applyFiltrerBtnId, removeFiltrerButtonId, () => applyFilters(initialBrugerTableFilters));
    }, [applyFilters, brugerListFilter]);

    const closeSlideIn = () => {
        closeModal();
        setFilterModalOpen(false);
    };

    const renderTableHead = () => {
        return (
            <thead>
                <tr>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Navn)}
                        data-priority="1">
                        {Localizer.global_name()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Foedselsdato)}
                        data-priority="2">
                        {Localizer.global_foedselsdato()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Email)}
                        data-priority="3">
                        {Localizer.global_email()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.RolleTitle)}
                        data-priority="4">
                        {Localizer.roller()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Uddannenelser)}
                        data-priority="5">
                        {Localizer.global_uddannelsestyper()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Specialer)}
                        data-priority="6">
                        {Localizer.global_specialer()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Regioner)}
                        data-priority="7">
                        {Localizer.global_regioner()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Praksisser)}
                        data-priority="8">
                        {Localizer.afdeling()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Sygehuse)}
                        data-priority="9">
                        {Localizer.sygehus()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Afdelinger)}
                        data-priority="10">
                        {Localizer.global_praksis()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Omraader)}
                        data-priority="11">
                        {Localizer.global_omraader()}
                    </th>
                    <th data-column-name={nameof<UserTableColumnsPostModel>(x => x.Phone)}
                        data-priority="12">
                        {Localizer.global_mobilePhone()}
                    </th>
                </tr>
            </thead>
        );
    }

   const handleExportOfUsers = useCallback(async () => {
        await logbogExportApi.getBrugereDetails(brugerListFilter, asyncTableState.search, asyncTableState.order);
   }, [asyncTableState.search, brugerListFilter, logbogExportApi, asyncTableState.order]);

   const renderTableActions = () => {
       return (
           <div className="flex-container flex-align-c">
                <DynamicButton id={exportButtonId} additionalClasses={'margin-right-s'} useFlex>
                    <DynamicButton.TableHeaderActionLoadingContainer  />
                    <DynamicButton.TextIconItem iconName={EmblaIcons.Incoming} text={Localizer.GetExcel()}/>
                </DynamicButton>
           </div>
       )
   }

    const pillList = (i: number, e: string, data: RolleTitelEnum[] | UddannelseTypeEnum[] | string[]) => {
        if (i < 2)
            return `<div class="badge badge-pill badge-primary margin-right-xs">${e.toString()}</div>`;
        else if (i == 2)
            return renderToString(<TablePillOverflow numberOfRecords={(data.length - 2).toString()} records={data.splice(2).join(", <br/>").toString()}/>)
        else return
    }

    const userTableColums = (): DataTables.ColumnSettings[] => {
        let columns: DataTables.ColumnSettings[] = [];
        columns = columns.concat([
            {
                data: (data: StamdataUserResponseModel) => data.navn,
            },
            {
                data: (data: StamdataUserResponseModel) => data.foedselsdato,
            },
            {
                data: (data: StamdataUserResponseModel) => data.email,
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                    let rolleTitel = UserRoleTitleToString(data.rolleTitle);
                    return `<span class="badge badge-pill badge-primary margin-right-xs">${rolleTitel}</span>`;
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                    const list = data.uddannelser.map((e,i) => {
                        return `<div class="badge badge-pill badge-primary margin-right-xs">${UddannelseTypeEnum[e]}</div>`;
                    });
                    return list.join("");
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                  const list = data.specialer.map((e,i) => {
                      return pillList(i, e, data.specialer);
                  });
                  return list.join("");
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                    const list = data.regioner?.map((e,i) => {
                        return e !== null ? `<div class="badge badge-pill badge-primary margin-right-xs">${e}</div>` : undefined;
                    });
                    return list.join("");
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                    const list = data.afdelinger.map((a, i) => {
                        return a !== null ?  `<div class="badge badge-pill badge-primary margin-right-xs">${a}</div>` : undefined;
                    });
                    return list.join("");
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                    const list = data.sygehuse.map((s, i) => {
                        return s !== null ?  `<div class="badge badge-pill badge-primary margin-right-xs">${s}</div>` : undefined;
                    });
                    return list.join("");
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                    const list = data.praksisser.map((p, i) => {
                        return p !== null ?  `<div class="badge badge-pill badge-primary margin-right-xs">${p}</div>` : undefined;
                    });
                    return list.join("");
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data,
                render: (data: StamdataUserResponseModel) => {
                    const list = data.omraader.map((o,i) => {
                        return o !== null ?  `<div class="badge badge-pill badge-primary margin-right-xs">${o}</div>` : undefined;
                    });
                    return list.join("");
                },
                orderable: false
            },
            {
                data: (data: StamdataUserResponseModel) => data.phone,
                orderable: false
            }
        ]);
        return columns;
    };

    return <>
        <div>
            <TableAsync
                tableIdentifier={`brugerListTable`}
                tableHeader={Localizer.brugere()}
                renderTableFilters={() => ( <ApplyFiltrerButton Id={applyFiltrerBtnId} additionalClasses="no-title-top-margin" /> )}
                renderTableHead={renderTableHead}
                searchPlaceholder={Localizer.soegNavnEmailEllerFoedselsdag()}
                renderHeaderActions={{
                    renderMethod: renderTableActions, actions: [
                        new DataTableHeaderAction(exportButtonId, handleExportOfUsers, isMobile),
                        new DataTableHeaderAction(applyFiltrerBtnId, () => setFilterModalOpen(true))
                    ]
                }}
                asyncUrl={stamdataUserApi.getAllUserUrl()}
                filtersInModal={"never"}
                columns={userTableColums()}
                showFilteredRecordsInfo
            />

            {filterModalOpen &&
                <SlideIn
                    id={filterModalTarget}
                    title={Localizer.global_filtrer()}
                    actionFinishedCallback={closeSlideIn}
                    actionOnCloseCallback={closeSlideIn}
                    bodyContent={<BrugerListeFilter filter={brugerListFilter} filterCallback={filter => applyFilters(filter)} closeModal={closeSlideIn} permissions={permissions}/>}
                    defaultOpen={true}
                />
            }

        </div>
    </>;
}
