import { useAppDispatch, useAppSelector } from "app/hooks";
import DynamicButton from "core/components/button/dynamicButton";
import { SlideInStateEnum, setSlideinState } from "core/components/slideIn/slideInSlice"
import { EmblaIcons } from "core/emblaIcons";
import { ForloebSliceState, forloebReducer } from "core/forloebSlice";
import { NotificationModule } from "ditmer-embla";
import { Localizer } from "infrastructure/localization/localizer";
import { useRef } from "react";
import { AccountService } from "services/account/accountService";
import { LogbogLaegekursusApi } from "services/api/laegekursus/logbogLaegekursusApi";
import { HttpClientService } from "services/httpClient/httpClientService";

export type KursusButtonProps = {
    modalId: string;
    additionalClasses?: string;
}

export type GodkendKursusButtonProps = KursusButtonProps & {
    laegekursusId: string;
    godkendPaaAndetGrundlag?: boolean;
    kommentar?: string;
    brugerMaalbeskrivelseId: string;
    doneCallBack?: () => void;
    useIconWithNoText?: boolean;
}

const laegekursusApi = new LogbogLaegekursusApi(new HttpClientService(), new AccountService())

export const godkendLaegeKursus = async (laegekursusId: string, godkendPaaAndetGrundlag: boolean, laegeInstitutionName: string, brugerMaalbeskrivelseId: string, afdelingName?: string, kommentar?: string) => {
    const uddannelsessted = !afdelingName ? laegeInstitutionName : laegeInstitutionName + ", " + afdelingName
    return await laegekursusApi.godkendLaegekursus(laegekursusId, uddannelsessted, godkendPaaAndetGrundlag, brugerMaalbeskrivelseId, kommentar);
}

export const notifyGodkendKursusSuccess = () => {
    NotificationModule.showSuccess(Localizer.kursusPage_kursusGodkendt(), "");
}

export const GodkendKursusButton = ({modalId, laegekursusId, additionalClasses, brugerMaalbeskrivelseId, godkendPaaAndetGrundlag=false, kommentar, doneCallBack, useIconWithNoText=false} : GodkendKursusButtonProps) => {

    const dispatch = useAppDispatch();
    const _currentUser = useRef(new AccountService().getUser());
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const handleGodkendLaegekursus = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalId}))

        const godkendtLaegekursusId = await godkendLaegeKursus(laegekursusId, godkendPaaAndetGrundlag, forloebSliceState.forloebState.laegeInstitutionName, brugerMaalbeskrivelseId ?? forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.afdelingName, kommentar);
        if (godkendtLaegekursusId.data) {
            if(!doneCallBack)
                dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalId}))
            else
                doneCallBack();

            notifyGodkendKursusSuccess();
        } else
            if(!doneCallBack)
                dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalId}))

        if (godkendtLaegekursusId.apiResponseMessage.errorMessage)
            NotificationModule.showError(Localizer.kursusPage_kursusGodkendt(), godkendtLaegekursusId.apiResponseMessage.errorMessage);
    }

    const shouldDisable = () => {
        const hasCorrectRoles = _currentUser.current.IsUddannelsesansvarlig() || _currentUser.current.IsVejleder();
        return hasCorrectRoles && _currentUser.current.AuthorizationId === undefined;
    }

    return (
            <DynamicButton
                additionalClasses="mr-2"
                onClick={() => handleGodkendLaegekursus()}
                disabled={shouldDisable()}
                rounded={useIconWithNoText}
            >
                <DynamicButton.TextIconItem
                    iconName={EmblaIcons.Success}
                    text={Localizer.kursusPage_godkendKursus()}
                    renderText={!useIconWithNoText}
                />
            </DynamicButton>
    );
}
