import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";
import { Localizer } from "infrastructure/localization/localizer";
import StringBuilder from "../stringBuilder";

export const appendGenerelForloebsoverviewPillData = (
    regionNavn: string,
    specialeNavn: string,
    stedInfo: string,
    stillingNummer: string,
    uddannelseType: UddannelseTypeEnum,
    startDato: Date,
    slutDato: Date,
    evalueretAt?: Date,
    godkendtDen?: Date,
    builder?: StringBuilder
    ) => {

    const sb = builder ?? new StringBuilder("", '<br/>');
    
    sb.append(regionNavn);
    sb.append(specialeNavn);
    sb.append(stedInfo);
    sb.append(stillingNummer);
    sb.append(UddannelseTypeEnum[uddannelseType]);
    sb.append(`${startDato.dateWithoutTimeFormat(true)} - ${slutDato.dateWithoutTimeFormat(true)}`);
    sb.append(`${Localizer.forloebOverviewPage_godkendtDato() + godkendtDen?.dateWithoutTimeFormat(true)}`, !!godkendtDen);
    sb.append(`${Localizer.forloebOverviewPage_evalueretDato() + evalueretAt?.dateWithoutTimeFormat(true)}`, !!evalueretAt);
    return sb;
}