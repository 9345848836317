import {FormikProps} from "formik";
import {CreateMailInputModel} from "./createMailInputModel";
import {FileMetadata} from "../../sharedmodels/fileMetaData/fileMetaData";
import {ReactFileUploaderFile} from "../fileUpload/reactFileUploader";
import {useRef, useState} from "react";
import {EmailTemplateModel} from "../../sharedmodels/emailTemplate/emailTemplateModel";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {StamdataEmailTemplateApi} from "../../../services/api/stamdataEmailTemplate/stamdataEmailTemplateApi";
import {AccountService} from "../../../services/account/accountService";
import useEffectAsync from "../../../infrastructure/effect";
import {nameof} from "ts-simple-nameof";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ValidationInput} from "../validation/components/validationInput";
import {ValidationTextEditor} from "../validation/components/validationTextEditor";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {FileMetadataList} from "../fileMetadata/fileMetadataList";
import {Loading} from "../loading/loading";
import "./mailInputFields.scss"
import {ValidationDropdown} from "../../../core/components/validation/components/validationDropdown";
import {DropdownOption} from "../dropdown/dropdown";
import { toDropdownOptions} from "../../sharedmodels/forSelectModel";

type MailInputProps = {
    modalId: string
    formik: FormikProps<CreateMailInputModel>
    fileMetadata?: FileMetadata[],
    templateTypeCallBack: (type: number) => void,
    fileUploadedCallback?: (result: ReactFileUploaderFile[]) => void,
    fileDeletedCallback?: (result: FileMetadata) => void,
}

export function MailInputFields(props: MailInputProps){
    const [mailTemplateDropdownOption, setMailTemplateDropdownOption] = useState<DropdownOption<string>[]>([]);
    const [selectedTemplate, setselectedTemplate] = useState<EmailTemplateModel>();
    const [isLoading, setIsLoading] = useState(true);

    const stamdataEmailTemplateApi = new StamdataEmailTemplateApi(new HttpClientService(), new AccountService())

    const emailTemplates = useRef<EmailTemplateModel[]>([]);

    useEffectAsync(async ()  => {
        const defaultSelectedTemplateId = await getMailTemplates();
        if(defaultSelectedTemplateId) {
            selectEmailTemplate(defaultSelectedTemplateId);
        }

        setIsLoading(false);
    }, [])

    const getMailTemplates = async (): Promise<string | null> => {
        emailTemplates.current = await stamdataEmailTemplateApi.getMailTemplates();
        if(emailTemplates.current.length === 0)
            return null

        const templateDropdownOptions = emailTemplates.current.map(mailTemplate=>

            ({
                id: mailTemplate.id,
                text : mailTemplate.titel,
                groupText:"",
                subText: "",
            }));

        setMailTemplateDropdownOption(toDropdownOptions(templateDropdownOptions));
        return templateDropdownOptions[0].id;
    }

    const selectEmailTemplate = (id?: string) => {
        let selectedTemplate = emailTemplates.current.find(x => x.id === id);
        props.formik.setFieldValue(nameof<CreateMailInputModel>(x => x.subject), selectedTemplate?.subject ?? "");
        props.formik.setFieldValue(nameof<CreateMailInputModel>(x=>x.message), selectedTemplate?.bodyMarkdown ?? "")
        props.formik.setFieldValue(nameof<CreateMailInputModel>(x=>x.mailSkabelonId), selectedTemplate?.id ?? "")
        setselectedTemplate(selectedTemplate);
        if(selectedTemplate) {
            props.templateTypeCallBack(selectedTemplate.type);
        }
    }

    return (
        <Loading isLoading={isLoading} text={Localizer.getEmailTemplate()}>
            <div className="row">
                <div className="col-sm-12">
                    <ValidationDropdown
                        key={props.formik.values.mailSkabelonId}
                        model={{
                            label: Localizer.EmailTemplates(),
                            placeholder: Localizer.EmailTemplatesPlaceholder(),
                            htmlName: nameof<CreateMailInputModel>(x => x.mailSkabelonId)
                        }}
                        options={mailTemplateDropdownOption}
                        formikProps={props.formik}
                        itemSelected={(selectedOption) => selectEmailTemplate(selectedOption?.value)}
                        defaultValue={selectedTemplate?.id}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <ValidationInput
                        key={selectedTemplate?.subject}
                        model={{
                            label: Localizer.global_subject(),
                            placeholder: "",
                            htmlName: nameof<CreateMailInputModel>(x => x.subject)
                        }}
                        value={selectedTemplate?.subject}
                        formikProps={props.formik}
                        inputId={nameof<CreateMailInputModel>(x => x.subject) + props.modalId}
                    />
                </div>
            </div>
            <div className="row margin-bottom-s">
                <div className="col-sm-12">
                    <ValidationTextEditor
                        key={selectedTemplate?.bodyMarkdown}
                        model={{
                            label: Localizer.createMail_Message(),
                            placeholder: Localizer.createMail_Message(),
                            htmlName: nameof<CreateMailInputModel>(x => x.message)
                        }}
                        value={selectedTemplate?.bodyMarkdown}
                        inputId={nameof<CreateMailInputModel>(x => x.message) + props.modalId}
                        formikProps={props.formik}
                    />
                </div>
            </div>

            <FileMetadataList
                fileMetadatas={props.fileMetadata ?? []}
                withUploader={true}
                showDeleteButton={true}
                fileDeletedCallbackOverride={props.fileDeletedCallback}
                filesUploadedCallbackOverride={props.fileUploadedCallback}
                fileLocation={FileLocationEnum.Email}
            />
        </Loading>
    );
}
