import "./notificationButton.scss";
import { EmblaIcons } from "core/emblaIcons";
import DynamicButton from "../button/dynamicButton";
import classNames from "classnames";
import CounterCircle from "./counterCircle/counterCircle";
import { useState } from "react";
import NotificationOverviewSlideIn from "./notificationOverview/notificationOverviewSlideIn";

export type NotificationButtonProps = {
  notificationCount: number;
  additionalClasses?: string;
}

const NotificationButton = (props: NotificationButtonProps) => {

  const [notificationOverviewIsOpen, setNotificationOverviewIsOpen ] = useState(false);

  return (
    <span className="notification-button-wrapper">
      <DynamicButton
        additionalClasses={classNames("notification-button", props.additionalClasses)}
        rounded={true}
        isPrimary
        onClick={() => setNotificationOverviewIsOpen(true)}
      >
          <>
            <CounterCircle
              additionalClasses="notification-counter"
              size="small"
              count={props.notificationCount}
              transformCountDisplay={(count) => count > 99 ? ".." : count}
            />
            <DynamicButton.TextIconItem huge iconName={EmblaIcons.Notifications} />
          </>
      </DynamicButton>

      <NotificationOverviewSlideIn
        notificationCount={props.notificationCount}
        showSlideIn={notificationOverviewIsOpen}
        onCloseSlideInCallback={() => setNotificationOverviewIsOpen(false)}
      />

    </span>
  )
}

export default NotificationButton;
