import { Loading } from "core/components/loading/loading";
import Title from "core/components/titels/title";
import { Localizer } from "infrastructure/localization/localizer";
import { useCallback, useState } from "react";
import { FileData } from "services/api/stamdataMappe/FilesAndFoldersModel";
import { useFileContext } from "../context/fileContextProvider";
import FileEditSlideIn from "./fileEditSlideIn";
import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";
import NoUddannelsestedFilesInFoldersCard from "./NoUddannelsesstedFilesCard";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import useStamdataApi from "core/hooks/useStamdataApi";
import useUser from "core/hooks/useUser";
import { NotificationModule } from "ditmer-embla";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { FileMetadataList } from "core/components/fileMetadata/fileMetadataList";

const UddannelsesstedFiles = ({permissions} : {permissions: UserFilterPermissions}) => {

    const { currentUser } = useUser();
    const { stamdataFileApi } = useStamdataApi();
    const { state, setFiles } = useFileContext();

    const [fileToEdit, setFileToEdit] = useState<FileData | undefined>();
    const [openSlideIn, setOpenSlideIn] = useState(false);

    const IsAllowedToEditOrDeleteUddannelsesstedFile = useCallback((fileMetadata: FileMetadata) => fileMetadata.oprettetAf === currentUser.UserId || currentUser.IsAdminOrVus(), [currentUser]);

    const handleEditFileOnClick = useCallback((fileMetadataId: string) => {
        const fileToEdit = state.files.find(f => f.fileMetadata.id === fileMetadataId);
        if(!fileToEdit) return;

        if(!IsAllowedToEditOrDeleteUddannelsesstedFile(fileToEdit.fileMetadata)) {
            NotificationModule.showError(Localizer.global_editFileNotAllowedError(), "");
            return;
        }

        setFileToEdit(fileToEdit);
        setOpenSlideIn(true);
    }, [IsAllowedToEditOrDeleteUddannelsesstedFile, state.files]);

    const handleDelete = useCallback(async (fileMetadata: FileMetadata) => {
        if(!IsAllowedToEditOrDeleteUddannelsesstedFile(fileMetadata)) {
            NotificationModule.showError(Localizer.global_deleteFileNotAllowedError(), "");
            return;
        }

        const deletedFile = await stamdataFileApi.deleteFile(fileMetadata.id);
        setFiles(state.files.filter(x => x.fileMetadata.id !== deletedFile.fileMetadata.id));

        NotificationModule.showSuccess(Localizer.global_deleteFileSuccess(), "");
    }, [setFiles, stamdataFileApi, state.files, IsAllowedToEditOrDeleteUddannelsesstedFile]);

    const hasFiles = state.files.length > 0;

    return (
        <Loading isLoading={state.isLoading} text={Localizer.global_fetchingEducationFiles()}>

            <Title title={state.files ? undefined : Localizer.global_noFiles()}>
                {state.files &&
                    <>
                        {hasFiles &&
                            <FileMetadataList
                                key={`UddannelsesstedFiles-${state.files.length}`}
                                fileMetadatas={state.files.map(x => x.fileMetadata)}
                                fileLocation={FileLocationEnum.Stamdata}
                                withUploader={false}
                                hideBorder
                                editFileOnClick={handleEditFileOnClick}
                                fileDeletedCallbackOverride={handleDelete}
                                conditionalShowDeleteButton={IsAllowedToEditOrDeleteUddannelsesstedFile}
                                conditionalShowEditButton={IsAllowedToEditOrDeleteUddannelsesstedFile}
                            />
                        }

                        {!hasFiles &&
                            <NoUddannelsestedFilesInFoldersCard />
                        }
                    </>
                }
            </Title>

            {openSlideIn && fileToEdit &&
                <FileEditSlideIn
                    fileToEdit={fileToEdit}
                    permissions={permissions}
                    onClose={() => setOpenSlideIn(false)}
                />
            }

        </Loading>
    )
}

export default UddannelsesstedFiles;
