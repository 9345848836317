import FileMetadataListComponent from "core/components/fileMetadata/fileMetadataListComponent";
import { FileUploader } from "core/components/fileUpload/fileUploader";
import { ReactFileUploaderFile } from "core/components/fileUpload/reactFileUploader";
import Title from "core/components/titels/title";
import { FileLocationEnum } from "core/sharedmodels/file/fileLocationEnum";
import { FileArchiveEnum } from "core/sharedmodels/fileMetaData/fileArchiveEnum";
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import { Localizer } from "infrastructure/localization/localizer";

type GenericFileListProps = {
    files: FileMetadata[];
    titleText?: string;
    emptyListText?: string;
    allwaysShowTitle?: boolean;
    disableSubTitle?: boolean;
    fileUploadedCallback?: (result: ReactFileUploaderFile[]) => void;
    fileDeletedCallback?: (fileMetadata: FileMetadata) => void;
    showDeleteButton?: boolean;
    fileLocation?: FileLocationEnum;
}

const GenericFileList = ({
        files=[],
        titleText=Localizer.global_files(),
        emptyListText=Localizer.global_noFiles(),
        allwaysShowTitle=false,
        fileUploadedCallback,
        fileDeletedCallback,
        fileLocation=FileLocationEnum.Logbog,
        disableSubTitle=false
    }: GenericFileListProps) => {

    const hasFiles = files.length > 0;

    return (
        <Title
            title={hasFiles || allwaysShowTitle ? titleText : undefined}
            subTitle={hasFiles || disableSubTitle ? undefined : emptyListText}
            largeSubTitle
        >
            {fileUploadedCallback &&
                <FileUploader 
                    filesUploadedCallback={fileUploadedCallback}
                    fileType={FileArchiveEnum.NoteAttachment}
                    fileLocation={fileLocation}
                />
            }

            {hasFiles &&
                <FileMetadataListComponent
                    fileMetaDataList={files}
                    fileLocation={fileLocation}
                    fileDeletedCallback={fileDeletedCallback}
                    showDeleteButton={!!fileDeletedCallback}
                    withDownload={false}
                />
            }

        </Title>
    );
}

export default GenericFileList;