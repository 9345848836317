import React, {useRef} from 'react'
import {Localizer} from '../../../infrastructure/localization/localizer';
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import {GetFravaerskodeTranslation} from "../../sharedmodels/forloeb/fravaerskoderEnum";
import {AccountService} from "../../../services/account/accountService";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";

interface ForloebCardInfoProps {
    forloeb : ForloebModel
}

export function ForloebCardInfo(props: ForloebCardInfoProps) {
    const todayDate = new Date();
    const _currentUser = useRef(new AccountService().getUser());

    return (
        <>
            <div className='forloeb-header-txt'>
                <h5 className="margin-bottom-xxs">{props.forloeb.GetStedInfo()}</h5>
                <div>
                    {props.forloeb.specialeName}, {UddannelseTypeEnum[props.forloeb.uddannelseType]}
                </div>
            </div>
            <div className='forloeb-header-txt d-flex' key={props.forloeb.endDate.toString()}>
                {props.forloeb.startDate.dateWithoutTimeFormat(false)} - {props.forloeb.endDate.dateWithoutTimeFormat(false)}
                {props.forloeb.DaysLeft() <= 30 && props.forloeb.DaysLeft() >= 0 &&
                    <div className='margin-left-s text-lowercase'>
                        <p>({props.forloeb.DaysLeft()} {Localizer.global_daysLeft()})</p>
                    </div>
                }
            </div>
            <div className='flex-align-c' key={""+props.forloeb.startDate + props.forloeb.endDate + props.forloeb.manueltAfsluttet + props.forloeb.evalueretDato + props.forloeb.godkendelseId}>
                <div className={"forloeb-badges"}>
                    {props.forloeb.erFravaer && props.forloeb.fravaerskodeEnum
                        ?  <span className="badge badge-pill badge-default padding-xs padding-right-s padding-left-s margin-right-s">
                                <div>{GetFravaerskodeTranslation(props.forloeb.fravaerskodeEnum)}</div>
                            </span>
                        : <div>
                            {props.forloeb.startDate > todayDate &&
                                <span className="badge badge-pill badge-default padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>{Localizer.global_fremtidigtForloeb()}</div>
                                </span>
                            }
                            {props.forloeb.endDate < todayDate &&
                                <span className="badge badge-pill badge-default padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>{Localizer.global_pastForloeb()}</div>
                                </span>
                            }
                            {props.forloeb.manueltAfsluttet &&
                                <span className="badge badge-pill badge-warning padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>{Localizer.global_manueltAfsluttet()}</div>
                                </span>
                            }
                            {props.forloeb.godkendelseId &&
                                <span
                                    className="badge badge-pill badge-primary-light padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>
                                        <EmblaIcon
                                        iconName="success"/>{Localizer.global_tidGodkendt()} {props.forloeb.godkendtDen?.dateWithoutTimeFormat(true)}
                                    </div>
                                </span>
                            }
                            {props.forloeb.evalueretDato &&
                                <span className="badge badge-pill badge-primary-light padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>
                                        <EmblaIcon iconName="success"/> {Localizer.global_evalueret()}
                                    </div>
                                </span>
                            }
                        </div>
                    }

                </div>
            </div>
        </>
    )
}
