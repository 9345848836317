import {LogbogSvarmulighedResponseModel} from "../../../services/api/logbogEvaluering/logbogSvarmulighedResponseModel";

export class SvarmulighedModel {

    public static FromResponseModel(svarmulighedResponseModel: LogbogSvarmulighedResponseModel): SvarmulighedModel{
        let newModel = new SvarmulighedModel();

        newModel.id = svarmulighedResponseModel.id;
        newModel.tekst = svarmulighedResponseModel.tekst;
        newModel.sortOrder = svarmulighedResponseModel.sortOrder;
        newModel.svarVaerdi = svarmulighedResponseModel.svarVaerdi;

        return newModel;
    }

    id: string;
    tekst: string;
    sortOrder: number;
    svarVaerdi?: number;
}
