import React from "react";
import {TopMenu} from "../../layout/topMenu/topMenu";
import {Localizer} from "../../../infrastructure/localization/localizer";
import * as Sentry from "@sentry/react";

export class ErrorBoundaryPdf extends React.Component<any, { hasError: boolean }> {
    constructor(props : any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error : Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        console.error(error);
        Sentry.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="body-container ">
                    <TopMenu/>
                    <div className="body-content body-content-error flex-fill">
                        <div className="flex-column padding-l border border-danger">
                            <div className={"text-center"}>
                                <h1>{Localizer.errorGettingPdf()}</h1>
                                <br/>
                                <p>
                                    {Localizer.forsoegIgenEllerKontaktSupport()} 
                                </p>
                                <p>
                                    {Localizer.global_uddannelseslaegedkurl()}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
