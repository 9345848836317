import React, {MutableRefObject, forwardRef, useEffect, useRef} from 'react';
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {DatetimePickerModule, DatetimePickerTypeEnum} from "ditmer-embla";
import {Localizer} from "../../../infrastructure/localization/localizer";
import DateInput, { DateFormikField } from './components/dateInput';
import classNames from 'classnames';

type DatepickerProps = {
    id: string,
    label: string;
    setDate: (date: Date) => void;
    allowClear?: boolean
    datePickerType?: DatetimePickerTypeEnum;
    placeholderText?: string;
    minimumDate?: Date;
    maximumDate?: Date;
    defaultValue?: Date
    additionalIconDivClasses?: string;
    additionalClasses?: string;
    appendToInputGroup?: React.ReactNode;
    disabled?: boolean;
    formName?: string;
    htmlName?: string;
    additionalInputClasses?: string;
    formikField?: DateFormikField;
}

export const DefaultDatePickerType = DatetimePickerTypeEnum.Date;

const ResolveDatePickerInputGroupId = (id: string) => `${id}-input-group`;
export const ToggleDatePickerFocus = (datePickerId: string, focus: boolean) => {
    const inputGroupId = ResolveDatePickerInputGroupId(datePickerId);

    if (focus) {
        $(`#${inputGroupId}`).addClass("focus")
    } else {
        $(`#${inputGroupId}`).removeClass("focus")
    }
}

export const Datepicker = forwardRef<DatetimePickerModule, DatepickerProps>(({disabled=false, formName, htmlName, additionalInputClasses="", datePickerType=DefaultDatePickerType, formikField, additionalClasses, ...props}, ref) => {
    const isClearing = useRef(false);
    const isShowing = useRef(false);

    const formatDate = (value: Date | undefined):  string | undefined => {
        return datePickerType !== DatetimePickerTypeEnum.Date
            ?  value?.timeFormat(true)
            :  value?.dateWithoutTimeFormat(true)
    }

    useEffect(() => {
        const datePickerModule = new DatetimePickerModule(`#${props.id}`, {
            type: datePickerType,
            // Is triggered when datepicker is opened (e.g. click on icon) AND when date is selected
            onSetDate: (event, inst) => {
                if (isShowing.current) {
                    isShowing.current = false;
                    return; // Handle that the datepicker-modal is opened - e.g. dont propagate change:
                }

                let newDate = event.date;

                if (isClearing.current) {
                    newDate = undefined; // clear is clicked
                    isClearing.current = false;
                }

                if (datePickerType === DatetimePickerTypeEnum.Time) {
                    if (newDate) {
                        newDate.setDate(new Date().getDate());
                        newDate.setMonth(new Date().getMonth());
                        newDate.setFullYear(new Date().getFullYear());
                        newDate.setUTCFullYear(new Date().getUTCFullYear());
                    }
                }

                props.setDate(newDate);
            },
            allowClear: props.allowClear ?? false,
            min: props.minimumDate,
            max: props.maximumDate,
            allowTyping: {
                openOnInputClicked: false,
            },
            additionalMbscCalenderOptions: {
                clearText: Localizer.global_clear(),
                onClear(event) {
                    isClearing.current = true;
                },
                onBeforeShow(event) {
                    isShowing.current = true;
                },
                // Only works with DatePickerType = "Date"
                defaultValue: props.defaultValue,
            }
        });

        if (ref) {
            (ref as MutableRefObject<DatetimePickerModule>).current = datePickerModule;
        }

        if (props.defaultValue) {
            if (datePickerType === DatetimePickerTypeEnum.Time) {
                // Only way to set "defaultValue" for DatePickerType = "Time"
                datePickerModule.setValue(props.defaultValue);
            }
        }
    }, [])

    const renderDatePickerIcon = () => {
        switch (datePickerType) {
            case 1:
                return <EmblaIcon iconName="clock"/>
            default:
                return <EmblaIcon iconName="calendar"/>
        }
    }

    const datePickerInputGroupId = ResolveDatePickerInputGroupId(props.id);

    return (
        <>
            <label htmlFor={htmlName}>{props.label}</label>
            <div id={datePickerInputGroupId} className={classNames("input-group", additionalClasses)}>

                <DateInput
                    id={props.id}
                    placeholderText={props.placeholderText ?? Localizer.setDate()}
                    defaultValue={formatDate(props.defaultValue)}
                    disabled={disabled}
                    formName={formName}
                    htmlName={htmlName}
                    formikField={formikField}
                    className={additionalInputClasses}
                    datePickerType={datePickerType}
                    allowClear={!!props.allowClear}
                    clearAction={() => { isClearing.current = true; }}
                />

                <div className={"input-group-append"}>
                    <div className={`input-group-text ${props.additionalIconDivClasses ? props.additionalIconDivClasses : ""}`}>
                        {renderDatePickerIcon()}
                    </div>
                </div>

                {props.appendToInputGroup && <div className={"input-group-append"}>{props.appendToInputGroup}</div>}
            </div>

        </>
    );
});
