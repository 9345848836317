import { ErrorMessage, Field, useField } from "formik";
import {forwardRef} from "react";
import { ValidationInputProps } from "../validationModel";
import classNames from "classnames";
import { GenericTextEditorProps, TextEditor, defaultTextEditorClasses } from "core/components/textEditor/textEditor";
import { TextEditorActions } from "core/components/textEditor/useForwardedTextEditorActionsRef";
import useForwardedRef from "core/hooks/useForwardedRef";
import {debounce} from "lodash";

type ValidationTextEditorProps = {
    readOnly?: boolean,
    useDebounce?: boolean,
    large?: boolean
}   & GenericTextEditorProps 
    & ValidationInputProps;

export const ValidationTextEditor = forwardRef<TextEditorActions, ValidationTextEditorProps>(({ readOnly=false, useDebounce=false, large=false, ...props }, forwardedRef) => {
    const [field, meta, helpers] = useField<string | undefined>(props.model.htmlName);

    const textEditorActionsRef = useForwardedRef<TextEditorActions>(forwardedRef);
    
    return (
        <div className={classNames("form-group margin-bottom-m", !large && "text-editor-min-height-override", props.additionalClassNames)}>
            <label htmlFor={field.name}>{props.model.label}</label>

            <TextEditor
                ref={textEditorActionsRef}
                id={props.inputId}
                markdown={field.value}
                onChange={debounce((markdown) => {
                    // The toastui editor does not trigger the change event on the textarea, so we need to manually set the value.
                    if(!meta.touched && !!markdown) {
                        helpers.setTouched(true);
                    }
                    
                    helpers.setValue(markdown);
                }, useDebounce ? 500 : 0)}
                customButtons={props.customButtons}
                showTableItem={props.showTableItem}
                showImageItem={props.showImageItem}
                overrideTextarea={
                    <Field as="textarea" 
                        id={props.inputId}
                        name={field.name}
                        className={classNames(defaultTextEditorClasses, (meta.error && meta.touched) && "input-validation-error")}
                    />
                }
                isViewer={readOnly}
            />

            <ErrorMessage name={field.name}>
                {errMsg => <span className="errorMessage field-validation-error">{errMsg}</span>}
            </ErrorMessage>
        </div>
    );
});
