import React, {useState} from 'react'
import {Localizer} from 'infrastructure/localization/localizer'
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {Form, Formik, FormikProps} from "formik";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {createVejlederValidationSchema, CrudVejlederInputModel} from "./crudVejlederInputModel";
import {useAppDispatch} from "../../../app/hooks";
import {VejlederCrudFields} from "./vejlederCrudFields";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {NotificationModule} from "ditmer-embla";
import {StamdataUserSimpleResponseModel} from "../../../services/api/stamdataUser/stamdataUserSimpleResponseModel";
import {AutorizationRegisterApi} from "../../../services/api/autorizationRegister/autorizationRegisterApi";
import {VejlederNewUserCrudFields} from "./vejlederNewUserCrudFields";
import {nameof} from "ts-simple-nameof";
import useStamdataApi from 'core/hooks/useStamdataApi';
import {ValidationDropdown} from "../../../core/components/validation/components/validationDropdown";
import {toDropdownOptions} from "../../../core/sharedmodels/forSelectModel";
import { CrudUddannelsesstedInputModel } from './crudUddannelsesstedInputModel';
import { ValidationInput } from 'core/components/validation/components/validationInput';

type CreateVejlederPageProps = {
    modalId: string,
}

export const VejlederCrudFormId = 'VejlederCrudForm';

const authorizationRegisterApi = new AutorizationRegisterApi(new HttpClientService())

let uddannelsesstedComponents: JSX.Element[] = [];

const initualUddannelsessted: CrudUddannelsesstedInputModel = {
    afdelingId: "",
    laeger: [],
    praksisId: "",
    sygehusId: "",
    laegeinstitutionstype: undefined,
    slutDato: undefined,
    startDato: undefined,
    alleLaeger: false,
    alleKbuLaeger: false,
    alleIntroduktionsLaeger: false,
    alleHoveduddannelsesLaeger: false
}

const initialFormValues: CrudVejlederInputModel = {
    authId: "",
    brugerId: undefined,
    vejlederTilknytningId: "",
    createNewUser: undefined,
    email: "", //To make sure this field resets correctly (via formik.resetForm)
    mobilePhone: "", //To make sure this field resets correctly (via formik.resetForm)
    uddannelsessted: initualUddannelsessted
}

export function VejlederCrud(props: CreateVejlederPageProps) {
    const dispatch = useAppDispatch();
    const [totalUddannelsessteder, setUddannelsesstedNumber] = useState<number>(0);
    const [createNewUser, setCreateNewUser] = useState<boolean | undefined>(undefined);
    const [stamdataUser, setStamdataUser] = useState<StamdataUserSimpleResponseModel>(new StamdataUserSimpleResponseModel());
    const [vejlederId, setVejlederId] = useState<string>();

    const { stamdataVejlederApi, stamdataUserApi } = useStamdataApi();

    let newtotalUddannelsessteder = totalUddannelsessteder + 1;
    const vejlederCrudIsReadonly = !vejlederId && !createNewUser;

    const createVejleder = async (inputModel: CrudVejlederInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId}));
        const oprettetVejlederId = await stamdataVejlederApi.addVejleder(inputModel);
        setUddannelsesstedNumber(0);
        uddannelsesstedComponents = [];
        if (oprettetVejlederId) {
            NotificationModule.showSuccess(Localizer.vejlederpage_vejlederCreated(), "")
        } else {
            NotificationModule.showError(Localizer.vejlederPage_createFailed(), "")
        }
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
    }

    const fjernUddannelsessted = (uddannelsesstedNumber: number, totalUddannelsessted: number) => {
        uddannelsesstedComponents = uddannelsesstedComponents.filter(x => x.props.children.props.uddannelsesstedNumber != uddannelsesstedNumber);
        setUddannelsesstedNumber(totalUddannelsessted - 1);
    }

    const userSelected = async (formik: FormikProps<CrudVejlederInputModel>, selectedUserAuthId?: string) => {
        const stateToResetTo = {
            ...initialFormValues,
            authId: selectedUserAuthId,
            uddannelsessted: {
                ...initualUddannelsessted,
                afdelingId: formik.values.uddannelsessted.afdelingId,
                praksisId: formik.values.uddannelsessted.praksisId,
                sygehusId: formik.values.uddannelsessted.sygehusId,
                laegeinstitutionstype: formik.values.uddannelsessted.laegeinstitutionstype,
                laeger: formik.values.uddannelsessted.laeger
            } // Some uddannelsessted values is only fetched on page init, if only one laegeinstitution option
        } as CrudVejlederInputModel;
        formik.resetForm({ values: stateToResetTo }); // Always reset form when new user is selected / removed
        
        if (!selectedUserAuthId) {
            setCreateNewUser(undefined);
            formik.setFieldValue(nameof<CrudVejlederInputModel>(x => x.createNewUser), null);
            setVejlederId(undefined);
            return;
        }

        const user = await stamdataUserApi.getUserByAuthId(selectedUserAuthId!);
        setStamdataUser(user);

        if (user.email === null) {
            setCreateNewUser(true);
            setVejlederId(undefined);
            formik.setFieldValue(nameof<CrudVejlederInputModel>(x => x.createNewUser), true);
            formik.setFieldValue(nameof<CrudVejlederInputModel>(x => x.brugerId), undefined);
        } else {
            setCreateNewUser(false);
            formik.setFieldValue(nameof<CrudVejlederInputModel>(x => x.createNewUser), false);
            formik.setFieldValue(nameof<CrudVejlederInputModel>(x => x.brugerId), user.userId);
            setVejlederId(user.userId);
        }
    }

    const fetchAsyncLaegerOptions = async (search: string) => {
        const selectModels = await authorizationRegisterApi.getLaegerSelectForCreateVejleder(search);
        return toDropdownOptions(selectModels);
    }

    const render = (
        <Formik
            initialValues={initialFormValues}
            validationSchema={createVejlederValidationSchema}
            onSubmit={async (values: CrudVejlederInputModel) => {
                await createVejleder(values)
                    .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                        HandleError(err);
                    });
            }}>
            {
                (formik) => (
                    <Form id={VejlederCrudFormId}>
                        <div className="row">
                            <div className="col-sm-12">
                                <h5 className="margin-bottom-s">{Localizer.vejleder()}</h5>
                                <ValidationDropdown
                                    model={{
                                        label: Localizer.soegLaege(),
                                        placeholder: Localizer.soegLaegeKriterier(),
                                        htmlName: nameof<CrudVejlederInputModel>(x => x.authId),
                                    }}
                                    fetchAsyncOptions={fetchAsyncLaegerOptions}
                                    fetchAsyncMinSearchLength={3}
                                    fetchAsyncDebounceTime={1000}
                                    formikProps={formik}
                                    itemSelected={(newAuthid) => userSelected(formik, newAuthid?.value)}
                                />
                            </div>
                            {createNewUser &&
                                <VejlederNewUserCrudFields
                                    modalId={props.modalId}
                                    formik={formik}
                                />
                            }
                            {createNewUser === false &&
                                <>
                                    <div className="col-sm-12 margin-top-m">
                                        <div className="alert alert-success" role={"alert"}>
                                            {`${Localizer.vejlederpage_foundUser()}: `} <b>{`${stamdataUser.navn} (${stamdataUser.email})`}</b>
                                        </div>
                                        {!stamdataUser.active &&
                                            <div className="alert alert-info" role={"alert"}>
                                                {Localizer.vejlederPage_userIsInactiveInfoText()}
                                            </div>
                                        }
                                        <ValidationInput
                                            model={{
                                                label: Localizer.vejlederPage_alternativEmail(),
                                                placeholder: Localizer.vejlederPage_alternativEmail(),
                                                htmlName: nameof<CrudVejlederInputModel>(x => x.alternativEmail)
                                            }}
                                            captionText={Localizer.vejlederPage_alternativEmailDescription()}
                                            inputId={nameof<CrudVejlederInputModel>(x => x.alternativEmail) + props.modalId}
                                            type="email"
                                            formikProps={formik}
                                        />
                                    </div>
                                    
                                </>
                            }
                            <div className="col-sm-12">
                                <div className="flex-space margin-top-l margin-bottom-m">
                                    <div>
                                        <h5>{Localizer.tilknyttetUddannelsessted()}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="margin-bottom-m">
                            <VejlederCrudFields
                                uddannelsesstedNumber={1} //Use the first uddannelsessted right now, because we only support a single uddannelsessted
                                totalUddannelsessteder={newtotalUddannelsessteder}
                                modalId={props.modalId}
                                formik={formik}
                                fjernUddannelsesstedCallback={(uddannelsesstedNumber: number, totalUddannelsessted: number) => fjernUddannelsessted(uddannelsesstedNumber, totalUddannelsessted)}
                                vejlederId={vejlederId}
                                readonly={vejlederCrudIsReadonly}
                            />
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
    return <>{render}</>
}

