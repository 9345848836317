import { DatetimePickerModule } from "ditmer-embla";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import { EmblaIconsType } from "core/emblaIcons";

type DatePickerActionButton = {
  datePickerRef: React.RefObject<DatetimePickerModule>;
  onButtonClickedCallback: (datePickerModule: DatetimePickerModule) => void;
  actionText: string;
  btnIcon?: EmblaIconsType;
} & React.HTMLProps<HTMLButtonElement>;

export const DatePickerActionButton = (props: DatePickerActionButton) => {

  const onClick = () => {
    if(props.disabled || !props.datePickerRef?.current) return;

    props.onButtonClickedCallback(props.datePickerRef?.current);
}

  return (
    <button
        {...props}
        type="button"
        disabled={props.disabled}
        className={"btn btn-default"}
        onClick={onClick}>
        {props.actionText}
        {props.btnIcon &&
            <EmblaIcon iconName={props.btnIcon}/>
        }
    </button>
  )
}
