import {
    LaegeForloebWithKursusGrupperResponseModel
} from "../../../services/api/stamdataUser/laegeForloebWithKursusGrupperResponseModel";
import {ForloebWithKursusGrupperModel} from "../../../services/api/forloeb/forloebWithKursusGrupperModel";

export class LaegeForloebWithKursusGrupperModel {
    public static FromResponseModel(responseModel: LaegeForloebWithKursusGrupperResponseModel): LaegeForloebWithKursusGrupperModel {
        const model = new LaegeForloebWithKursusGrupperModel();
        model.laegeId = responseModel.laegeId;
        model.laegeNavn = responseModel.laegeNavn;
        model.laegeInitialer = responseModel.laegeInitialer;
        model.laegeEmail = responseModel.laegeEmail;
        model.forloebInfo = responseModel.forloebWithKursusGrupperList?.map(lkg => {
              return new ForloebWithKursusGrupperModel(lkg);
        })
        return model;
    }

    public laegeId: string;
    public laegeNavn: string;
    public laegeInitialer: string;
    public brugerKursusraekkeId: string;
    public laegeEmail: string;
    public forloebInfo: ForloebWithKursusGrupperModel[];
}
