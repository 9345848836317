import {ErrorMessage, useField} from "formik";
import React from "react";
import {Dropdown, DropdownOption, DropdownProps} from "../../dropdown/dropdown";
import {ValidationSelectProps} from "../validationModel";
import {ActionMeta, GroupBase, MultiValue, OnChangeValue, OptionsOrGroups, SingleValue} from "react-select";
import { Localizer } from "infrastructure/localization/localizer";
import {debounce} from "lodash";

type ValidationDropdownProps<T, IsMulti extends boolean = false, IsAsync extends boolean = false> =
    ValidationSelectProps<T, IsMulti>
    & React.HTMLProps<HTMLInputElement>
    & Pick<DropdownProps<T, DropdownOption<T>, false, IsMulti, IsAsync>, "isMulti">;

const transformSelectedValuesByStandAloneValues = <TVal extends unknown>(standAloneValues: TVal[], selectedValues: TVal[], newestSelectedValue: TVal) => {
    const newestValueIsStandAloneValue = standAloneValues.includes(newestSelectedValue);
    return newestValueIsStandAloneValue ? [newestSelectedValue] : selectedValues.filter(x => !standAloneValues.includes(x))
}

const asyncNoOptionMessage = (searchValue: string, minLength: number) => {
    const remainingCharacters = minLength - searchValue.length;

    if(remainingCharacters > 0) {
        return Localizer.dropdown_AngivVenligstXTegnMere(remainingCharacters);
    } else {
        return Localizer.noResults();
    }
}

export const ValidationDropdown = <TVal extends unknown, IsMulti extends boolean = false>({
    additionalClasses = "margin-bottom-m",
    options = [],
    fetchAsyncMinSearchLength = 1,
    ...props
     }: ValidationDropdownProps<TVal, IsMulti>) => {

    const [field, meta, helpers] = useField(props.model.htmlName);

    const isAsync = !!props.fetchAsyncOptions;

    const getValueKey = (value: TVal) => !!props.valueToKeyOverride ? props.valueToKeyOverride(value) : value;

    const valueSelected = (selectedValue: OnChangeValue<DropdownOption<TVal>, IsMulti>, actionMeta: ActionMeta<DropdownOption<TVal>>) => {
        const realValueSelected = props.isMulti
            ? (selectedValue as MultiValue<DropdownOption<TVal>>).map(x => x.value)
            : (selectedValue as SingleValue<DropdownOption<TVal>>)?.value;

        const newestOptionValueSelected = actionMeta.option?.value;
        const shouldUseStandAloneValues = !!props.isMulti && !!newestOptionValueSelected && !!props.standAloneValues;
        const valueToSet = shouldUseStandAloneValues
            ? transformSelectedValuesByStandAloneValues(props.standAloneValues!, realValueSelected as TVal[], newestOptionValueSelected!)
            : realValueSelected;

        helpers.setValue(valueToSet, true);

        const transformedSelectedValue = props.isMulti && !!valueToSet
            ? (valueToSet as TVal[]).map(x => options.find(o => getValueKey(o.value) === getValueKey(x))) as MultiValue<DropdownOption<TVal>> as OnChangeValue<DropdownOption<TVal>, IsMulti>
            : selectedValue;

        if (props.itemSelected) {
            props.itemSelected(transformedSelectedValue, actionMeta);
        }
    }

    const defaultValue = isAsync ? props.initialAsyncValue : undefined;
    const selectedValue = isAsync 
        // When fetching options async, we dont have access to the options: if we make value=undefined, the value is managed inside react-select:
        // If wanting a default-value, use "props.initialAsyncValue"
        ? undefined 
        : options.filter(option => props.isMulti 
            ? field.value?.map((x: TVal) => getValueKey(x)).includes(getValueKey(option.value)) 
            : getValueKey(field.value) === getValueKey(option.value)
        )

    /** Handle async search with optional debounce: */
    /** See: https://github.com/JedWatson/react-select/issues/614 */

    const fetchAsyncOptionsWithCallback = (inputValue: string, callback: (options: OptionsOrGroups<DropdownOption<TVal>, GroupBase<DropdownOption<TVal>>>) => void) => {
        if(!!props.fetchAsyncOptions) {
            props.fetchAsyncOptions(inputValue).then((result) => callback(result))
        }
    }

    const maybeDebouncedFetchAsyncOptions = !!props.fetchAsyncDebounceTime
        ? debounce(fetchAsyncOptionsWithCallback, props.fetchAsyncDebounceTime) 
        : fetchAsyncOptionsWithCallback;

    const loadOptionsAsync = (inputValue: string, callback: (options: OptionsOrGroups<DropdownOption<TVal>, GroupBase<DropdownOption<TVal>>>) => void) => {
        const shouldCallFetch = inputValue.length >= fetchAsyncMinSearchLength && !!props.fetchAsyncOptions;
        
        if (!shouldCallFetch) {
            callback([]);
        } else {
            maybeDebouncedFetchAsyncOptions(inputValue, callback);
        }
    };

    return (
        <>
            <div className={`form-group ${additionalClasses}`}>
                {props.model.label && <label htmlFor={field.name}>{props.model.label}</label>} 
                <Dropdown
                    name={field.name}
                    placeholder={props.model.placeholder}
                    options={options}
                    isClearable={props.isClearable}
                    onChange={valueSelected}
                    onBlur={props.formikProps?.handleBlur}
                    defaultValue={defaultValue}
                    value={selectedValue}
                    isDisabled={props.readOnly}
                    isMulti={props.isMulti}
                    noOptionsMessage={isAsync ? (input) => asyncNoOptionMessage(input.inputValue, fetchAsyncMinSearchLength) : () => Localizer.noResults()}
                    cacheOptions={props.fetchAsyncResetCacheOnChange ?? true}
                    isAsync={isAsync}
                    loadOptions={isAsync ? loadOptionsAsync : undefined}
                    showOptionSubLabel={isAsync}
                />

                <ErrorMessage name={field.name}>
                    {(errMsg) => <span className="errorMessage field-validation-error">{errMsg}</span>}
                </ErrorMessage>
            </div>
        </>
    );
}
