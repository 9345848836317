import React, {useCallback, useEffect, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import {UddannelseslaegerFilter, useUddannelseslaegerContext} from "../uddannelseslaegeOverviewContextProvider";
import {Laegekompetence} from "core/sharedmodels/kompetence/laegekompetence";
import {initAllPopovers} from "core/utils";
import {VejlederAfdelingerModel} from "./vejlederAfdelingerModel";
import {MobileFilter} from "core/components/filter/mobileFilter";
import UddannelsesleagerFilter from "./filter/UddannelseslaegerFilter";
import {Uddannelseslaege} from "./uddannelseslaege";
import {VejlederHeader} from "core/components/headerCards/vejlederHeader";
import useScreenResolution from "core/hooks/useScreenResolution";
import useLogbogApi from "core/hooks/useLogbogApi";
import useStamdataApi from "core/hooks/useStamdataApi";
import useUser from "core/hooks/useUser";
import {Loading} from "core/components/loading/loading";
import Container from "core/components/container/container";
import { LaegeWithSimpleForloebModel } from "core/sharedmodels/forloeb/laegeWithForloebModel";
import UddannelseslaegerPageLengthDropdown from "./filter/UddannelseslaegerPageLengthDropdown";
import { Pagination } from "core/components/pagination/pagination";
import classNames from "classnames";
import { PaginationModel } from "core/sharedmodels/filter/PaginationModel";
import { SearchField } from "core/components/search/searchField";
import HtmlHeading from "../../../core/components/titels/HtmlHeadings";

const InitialPage = 1;
const InitialPageSize = 25;

export const getFiltersAppliedCount = (filters: UddannelseslaegerFilter) =>  Object.values(filters).filter(x => !!x && (!Array.isArray(x) || x.length > 0)).length as number;

const UddannelseslaegerList = () => {
    const { currentUser } = useUser();
    const { isMobile } = useScreenResolution();
    const { stamdataVejlederApi, stamdataAfdelingApi} = useStamdataApi();
    const { logbogUserInfoApi, logbogLaegeKompetenceApi } = useLogbogApi();
    const { state, setOriginalLaeger, setFilteredLaeger, setIsLoading, setFilter } = useUddannelseslaegerContext();

    const [pagination, setPagination] = useState(new PaginationModel(InitialPage, InitialPageSize, 0))

    const [klarmeldtKompetencer, setKlarmeldtKompetencer] = useState<Laegekompetence[]>([]);
    const [activeVejlederAfdelinger, setActiveVejlederAfdelinger] = useState<VejlederAfdelingerModel[]>([]);
    const [hasFetchedInitial, setHasFetchedInitial] = useState(false);

    const usedFiltersCount = getFiltersAppliedCount(state.filter);
    const isVejleder = currentUser.IsVejleder();
    const canSeeKompetencer = currentUser.IsAdminOrVus() || currentUser.IsUddannelsesansvarlig();

    const fetchUddannelseslaeger = useCallback(async (filter: UddannelseslaegerFilter, page: number=InitialPage, pageLength: number=InitialPageSize, updateOriginal:boolean = false) => {
        setIsLoading(true);

        const {recordsFiltered, data} = await logbogUserInfoApi.getLaegerWithForloeb(filter, page, pageLength);

        if(updateOriginal)
            setOriginalLaeger(data);

        setFilteredLaeger(data);
        setPagination(curr => ({
            ...curr,
            page: page,
            pageLength: pageLength,
            recordCount: recordsFiltered
        }));

        setIsLoading(false);
    }, [logbogUserInfoApi, setFilteredLaeger, setIsLoading, setOriginalLaeger]);

    useEffect(() => {
        if(hasFetchedInitial) return;

        fetchUddannelseslaeger(state.filter, undefined, undefined, true).then(() =>
            setHasFetchedInitial(true)
        )

    }, [fetchUddannelseslaeger, hasFetchedInitial, state.filter]);

    useEffect(() => {

        const fetchAfdelinger = async () => {
            setActiveVejlederAfdelinger(isVejleder
                ? await stamdataVejlederApi.GetVejlederAfdelinger()
                : await stamdataAfdelingApi.GetUddannelsesansvarligAfdelinger())
        }

        fetchAfdelinger();
    }, [isVejleder, stamdataAfdelingApi, stamdataVejlederApi]);

    useEffect(() => {
        if(state.filtered.length === 0 || !canSeeKompetencer) return;

        const initKlarmeldtKompetencer = async (list: LaegeWithSimpleForloebModel[]) => {
            const userIds = list.map(x => x.laegeId);
            const kompetencer = await logbogLaegeKompetenceApi.getLaegekompetenceKlarmeldingerBrugerIds(userIds);
            setKlarmeldtKompetencer(kompetencer);
        }

        initAllPopovers();
        initKlarmeldtKompetencer(state.filtered);
    }, [canSeeKompetencer, logbogLaegeKompetenceApi, state.filtered]);

    const handlePagination = async (page: number, pageLength: number = pagination.pageLength) => await fetchUddannelseslaeger(state.filter, page, pageLength);

    const onPageLengthChangeCallback = (pageLength: number) => handlePagination(InitialPage, pageLength);

    const handleSearch = async (query: string) => {
        const newFilter = {
            ...state.filter,
            search: query.trim(),
            status: []
        }
        
        setFilter(newFilter);

        await fetchUddannelseslaeger(newFilter);
    };

    return (
        <>
            <VejlederHeader VejlederAfdelinger={activeVejlederAfdelinger}/>

            <Container>

                <Container.Header
                    titleConfig={{
                        heading: HtmlHeading.H3,
                        title: Localizer.global_uddannelseslaeger(),
                    }}>
                    {isMobile &&
                        <div className="flex-container">
                            <UddannelseslaegerPageLengthDropdown
                                onChangeCallback={length => setPagination(curr => ({...curr, pageLength: length}))}
                            />
                            <MobileFilter
                                id={"uddannelseslaeger"}
                                title={Localizer.global_filtrer()}
                                content={<UddannelsesleagerFilter
                                    uddannelsessteder={activeVejlederAfdelinger}
                                    pagination={pagination}
                                    onPageLengthChangeCallback={onPageLengthChangeCallback}
                                    fetchUddannelseslaeger={fetchUddannelseslaeger}
                                />}
                                filterCount={usedFiltersCount}
                            />
                        </div>
                    }

                    {!isMobile &&
                        <UddannelsesleagerFilter
                            uddannelsessteder={activeVejlederAfdelinger}
                            additionalNodes={<SearchField initialValue={state.filter.search}
                                                          callback={(search) => handleSearch(search)}/>}
                            pagination={pagination}
                            onPageLengthChangeCallback={onPageLengthChangeCallback}
                            fetchUddannelseslaeger={fetchUddannelseslaeger}
                        />
                    }
                </Container.Header>

                {isMobile &&
                    <Container.Body additionalClasses="flex-space">
                    <SearchField
                            initialValue={state.filter.search}
                            callback={(search) => handleSearch(search)}
                        />
                    </Container.Body>
                }

                <Loading isLoading={state.isLoading} text={Localizer.global_getData()} spinnerClasses={"margin-m"}>
                    {!state.isLoading &&
                        <>
                            {state.original.length === 0 && usedFiltersCount <= 0 &&
                                <div className='ml-3 mb-3 margin-top-m'>
                                    { Localizer.uddannelseslaegeOverviewPage_NoActiveLaeger() }
                                </div>
                            }

                            {state.filtered.length === 0 && usedFiltersCount > 0 &&
                                <div className='ml-3 mb-3 margin-top-m'>
                                    { Localizer.noResults() }
                                </div>
                            }

                            <Container.Body additionalClasses="padding-0 uddannelseslaeger-list">
                                {state.filtered.map(laege => laege.forloebModels.map(laegeForloeb =>
                                    <div key={`${laege.laegeId}-${laegeForloeb.id}`} className={classNames("card-list card-list-border-bot", laegeForloeb.erFravaer && "color-fravaer-forloeb")} >
                                        <Uddannelseslaege
                                            uddannelseslaege={laege}
                                            forloeb={laegeForloeb}
                                            klarmeldtKompetencer={klarmeldtKompetencer?.filter(x => x.brugerId === laege.laegeId)}
                                        />
                                    </div>
                                ))}
                            </Container.Body>
                        </>
                    }

                </Loading>

                <Container.Footer additionalClasses="flex-row-center">
                    <Pagination
                        handlePagination={(page) => handlePagination(page)}
                        paginationModel={pagination}
                    />
                </Container.Footer>

            </Container>
        </>
    );
}

export default UddannelseslaegerList;


export const ResetDropdownBySelector = (selector: string, value="") => {
    $(selector).val(value).trigger("change");
}
