import {Localizer} from "../../../infrastructure/localization/localizer";

export enum UddannelseTypeEnum {
    KBU = 1,
    Introduktion = 2,
    Hoveduddannelse = 3
}

export function GetUddannelsestypeTranslation(type?: UddannelseTypeEnum) {
    switch (type) {
        case UddannelseTypeEnum.KBU:
            return Localizer.global_KBU();
        case UddannelseTypeEnum.Introduktion:
            return Localizer.global_introduktion();
        case UddannelseTypeEnum.Hoveduddannelse:
            return Localizer.global_hoveduddannelse();
        default:
            return "";
    }
}
