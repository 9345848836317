import React, {useEffect, useState} from 'react'
import {HttpClientService} from '../../../services/httpClient/httpClientService';
import {Localizer} from '../../../infrastructure/localization/localizer';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
    clearSelectedLaegekompetenceMetas,
    kompetenceCardReducer,
    KompetenceCardState, scrollToActiveKompetence,
    setActiveLaegekompetenceMeta,
    setKompetenceCardStateShowCheckboxes,
    toggleLaegekompetenceListRefreshPing
} from '../../../pages/kompetencerPage/cards/kompetenceCardSlice';
import {LogbogLaegekompetenceApi} from '../../../services/api/logbogLaegekompetence/logbogLaegekompetenceApi';
import {EmblaIcon} from 'core/components/emblaIcon/emblaIcon';
import {LaegekompetenceMeta} from 'core/sharedmodels/kompetence/laegekompetenceMeta';
import {LaegekompetenceStatusEnum} from 'core/sharedmodels/kompetence/laegekompetenceStatusEnum';
import {NotificationModule} from "ditmer-embla";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import {AccountService} from "../../../services/account/accountService";
import {forloebReducer, ForloebSliceState} from "../../forloebSlice";
import {SlideIn} from "../../components/slideIn/slideIn";
import {CreateKlarmelding} from "../../../pages/klarmeldinger/components/createKlarmelding";
import {ModalType} from "../../components/slideIn/modalType";
import {CrudKlarmeldingInputModel} from "../../../pages/klarmeldinger/components/crudKlarmeldingInputModel";
import {ModalSize} from "../../components/slideIn/modalSize";

const laegekompetenceApi = new LogbogLaegekompetenceApi(new HttpClientService(), new FileDownloadHandler(), new AccountService())

export interface KlarmeldButtonProps{
    klarmelderCallback?: (klarmelder: boolean)=>void;
}

export function KlarmeldButton(props: KlarmeldButtonProps) {
    const dispatch = useAppDispatch();
    const modalId = "klarmeldModal";

    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const [isDisabledState, setIsDisabledState] = useState<boolean>(false);
    const [isKlarmeldState, setIsKlarmeldState] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const updateKompetenceStatus = async (inputModel: CrudKlarmeldingInputModel) => {
        let laegekompetencer: LaegekompetenceMeta[] = [];

        if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0) {
            if (props.klarmelderCallback) {
                props.klarmelderCallback(true);
            }
            for (const lkm of kompetenceCardState.selectedLaegekompetenceMetas) {
                let laegekompetenceModel = LaegekompetenceMeta.FromState(lkm, forloebSliceState.forloebState);
                laegekompetenceModel.status = getNewStatus();
                laegekompetencer.push(laegekompetenceModel);

                if (kompetenceCardState.selectedLaegekompetenceMetas.findIndex(lkmState => lkmState.laegekompetenceId === lkm.laegekompetenceId) > -1) {
                    dispatch(setActiveLaegekompetenceMeta(laegekompetenceModel.ToState()));
                }
            }
            await laegekompetenceApi.patchStatusMultiple(laegekompetencer, inputModel.klarmeldBrugere, inputModel.klarmeldtTilAlleMedAdgang);
            if (props.klarmelderCallback) {
                props.klarmelderCallback(false);
            }
        } else {
            let activeKompetence = LaegekompetenceMeta.FromState(kompetenceCardState.activeLaegekompetenceMeta, forloebSliceState.forloebState);
            activeKompetence.status = getNewStatus();
            await laegekompetenceApi.patchStatus(activeKompetence, inputModel.klarmeldBrugere, inputModel.klarmeldtTilAlleMedAdgang);
            dispatch(setActiveLaegekompetenceMeta(activeKompetence.ToState()));
        }
        dispatch(scrollToActiveKompetence(true));

        displayNotification();

        //update state
        dispatch(toggleLaegekompetenceListRefreshPing())
        dispatch(clearSelectedLaegekompetenceMetas())
        dispatch(setKompetenceCardStateShowCheckboxes([]))
    }

    const displayNotification = () => {
        let count = kompetenceCardState.selectedLaegekompetenceMetas.length;
        let message = "";

        if(count === 0)
            count = 1;

        if(isKlarmeldState){
            message = Localizer.kompetencePage_kompetencerKlarmeldt(count.toString());
        }else{
            message = Localizer.kompetencePage_kompetencerKlarmeldFjernet(count.toString());
        }

        NotificationModule.showSuccess(message, "");
    }

    const getNewStatus = () => {
        return isKlarmeldState ? LaegekompetenceStatusEnum.Klarmeldt : LaegekompetenceStatusEnum.Planlagt;
    }

    useEffect(() => {
        updateIsDisabledState();
        updateIsKlarmeldState();
    }, [kompetenceCardState.activeLaegekompetenceMeta.status, kompetenceCardState.selectedLaegekompetenceMetas])

    useEffect(() => {
        updateIsDisabledState();
        updateIsKlarmeldState();
    }, [kompetenceCardState.showCheckboxOnIds])


    const updateIsDisabledState = () => {
        let isDisabled = false;

        if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0 || kompetenceCardState.showCheckboxOnIds.length > 0) {
            let someHasInvalidStatus = kompetenceCardState.selectedLaegekompetenceMetas.some(lkm => !canUpdateByStatus(lkm.status));
            let someHasDifferentStatusses = kompetenceCardState.selectedLaegekompetenceMetas.findIndex(lkm => lkm.status !== kompetenceCardState.selectedLaegekompetenceMetas[0].status) > 0;
            let noKompetencerChosen = kompetenceCardState.selectedLaegekompetenceMetas.length === 0;

            isDisabled = noKompetencerChosen || someHasInvalidStatus || someHasDifferentStatusses;
        } else {
            isDisabled = !canUpdateByStatus(kompetenceCardState.activeLaegekompetenceMeta.status);
        }

        setIsDisabledState(isDisabled);
    }

    const updateIsKlarmeldState = () => {
        let isKlarmeldState = true;

        if (kompetenceCardState.selectedLaegekompetenceMetas.length > 0 || kompetenceCardState.showCheckboxOnIds.length > 0) {
            isKlarmeldState = kompetenceCardState.selectedLaegekompetenceMetas.length === 0 || kompetenceCardState.selectedLaegekompetenceMetas.some(lkm => lkm.status !== LaegekompetenceStatusEnum.Klarmeldt);
        } else {
            isKlarmeldState = kompetenceCardState.activeLaegekompetenceMeta.status !== LaegekompetenceStatusEnum.Klarmeldt;
        }

        setIsKlarmeldState(isKlarmeldState);
    }

    const canUpdateByStatus = (status: LaegekompetenceStatusEnum) => {
        return status !== LaegekompetenceStatusEnum.Godkendt
    }

    const render = (
        <>
            <button className={`btn ${isKlarmeldState ? "btn-primary" : "btn-default"}`} onClick={() => setShowModal(true)} data-toggle="modal" data-target={`#${modalId}`} disabled={isDisabledState}>
                <EmblaIcon iconName={isKlarmeldState ? "success" : "remove"} /> {isKlarmeldState ? Localizer.global_klarmeld() : Localizer.global_removeKlarmeld()}
            </button>

            {showModal &&
                <SlideIn id={modalId}
                         title={isKlarmeldState ? Localizer.global_klarmeld() : Localizer.global_removeKlarmeld()}
                         actionOnCloseCallback={() => setShowModal(false)}
                         actionFinishedCallback={() => setShowModal(false)}
                         defaultOpen={true}
                         modalSize={ModalSize.md}
                         modalType={ModalType.originalModal}
                         bodyContent={<CreateKlarmelding modalId={modalId} isKlarmeldState={isKlarmeldState} updateKompetenceStatusCallback={(inputModel: CrudKlarmeldingInputModel) => updateKompetenceStatus(inputModel)}/>}
                />
            }
        </>
    )

    return <>{render}</>
}
