import {MutableRefObject, ReactNode, forwardRef, useEffect, useRef} from "react";
import {RichTextEditorModule} from "ditmer-embla";
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
import useForwardedTextEditorActionsRef, { TextEditorActions } from "./useForwardedTextEditorActionsRef";
import classNames from "classnames";

type CustomButton = {
    options: toastui.ButtonOptions;
    onClick: () => void;
}

export type GenericTextEditorProps = {
    isViewer?: boolean;
    html?: string;
    markdown?: string;
    customButtons?: CustomButton[];
    showTableItem?: boolean;
    showImageItem?: boolean;
    additionalClassNames?: string;
}

type TextEditorProps = {
    id: string
    onChange?: (markdown: string) => void;
    overrideTextarea?: ReactNode;
} & GenericTextEditorProps;

export const defaultTextEditorClasses = "default-rich-text-editor form-control";

export const TextEditor = forwardRef<TextEditorActions, TextEditorProps>(({id, html, markdown, isViewer=false, onChange, additionalClassNames, customButtons=[], showTableItem=false, showImageItem=false, overrideTextarea}, forwardedRef) => {
    const editor = useRef<RichTextEditorModule>(null)
    const viewer = useRef<toastui.Viewer>(null);

    useForwardedTextEditorActionsRef(forwardedRef, editor);

    useEffect(() => {
        const idSelector = "#" + id;

        if(isViewer) {
            (viewer as MutableRefObject<toastui.Viewer>).current = new Viewer({ el: $(idSelector)[0] });
        }
        else {
            if(!editor.current) {

                const editorModule = new RichTextEditorModule({
                    textArea: idSelector,
                    toolbarItems: { 
                        showTable: showTableItem,
                        showImage: showImageItem
                    },
                    onEditorChanged: () => {
                        if(onChange)
                            onChange(editor.current?.getMarkdown() ?? "");
                    }
                });

                const anyEditor = editorModule.editor as any;
                const toolbar = editorModule.editor.getUI().getToolbar();

                customButtons.forEach(btn => {

                    toolbar.addItem({ type: "button", options: btn.options });

                    anyEditor.eventManager?.addEventType(btn.options.event);
                    anyEditor.eventManager?.listen(btn.options.event, btn.onClick);
                });

                (editor as MutableRefObject<RichTextEditorModule>).current = editorModule;
            };
        }

        // Removes data-tomark-pass attribute from all elements - target is the injected mergefields
        // Keeping the attribute will cause the following elements to loose their formatting when generating a PDF
        document.querySelectorAll('[data-tomark-pass]').forEach(e =>  e.removeAttribute('data-tomark-pass'));

    }, [customButtons, id, isViewer, onChange, showImageItem, showTableItem]);

    useEffect(() => {
        if(!isViewer || !markdown) return;

        //Replace bruges for at editoren ikke formaterer \n\n som et <pre> element
        viewer.current?.setMarkdown(markdown.replaceAll("\n\n", "<br><br>"));
    }, [isViewer, markdown]);

    useEffect(() => {
        if(!html) return;

        editor.current?.editor.setHtml(html);
    }, [html]);

    return (
        <>
            <div className="form-group margin-bottom-m">
                { !isViewer &&
                    <>
                        {overrideTextarea && 
                            overrideTextarea
                        }
       
                        {!overrideTextarea && 
                            <textarea
                                id={id}
                                name="default-rich-text-editor"
                                className={classNames(defaultTextEditorClasses, additionalClassNames)}
                                defaultValue={markdown}
                            />
                        }
                    </>
                }

                { isViewer &&
                    <div
                        id={id}
                        className="default-rich-text-editor"
                    />
                }
            </div>


        </>
    );
});
