import { useMemo } from "react";
import { AccountService } from "services/account/accountService";
import { FileDownloadHandler } from "services/api/logbogFile/fileDownloadHandler";
import { StamdataAfdelingApi } from "services/api/stamdataAfdeling/stamdataAfdelingApi";
import { StamdataLaegeinstitutionApi } from "services/api/stamdataLaegeinstitution/stamdataLaegeinstitutionApi";
import { StamdataMaalbeskrivelseApi } from "services/api/stamdataMaalbeskrivelse/stamdataMaalbeskrivelseApi";
import { StamdataOmraadeApi } from "services/api/stamdataOmraade/stamdataOmraadeApi";
import { StamdataRegionApi } from "services/api/stamdataRegion/stamdataRegionApi";
import { StamdataSpecialeApi } from "services/api/stamdataSpeciale/stamdataSpecialeApi";
import { StamdataUddannelseApi } from "services/api/stamdataUddannelse/stamdataUddannelseApi";
import { StamdataUserApi } from "services/api/stamdataUser/stamdataUserApi";
import { StamdataVejlederApi } from "services/api/stamdataVejlederApi/stamdataVejlederApi";
import { HttpClientService } from "services/httpClient/httpClientService";
import {StamdataKbuRundeApi} from "../../services/api/stamdataKbuRundes/stamdataKbuRundeApi";
import {StamdataDriftsforstyrrelserApi} from "../../services/api/driftsforstyrrelser/stamdataDriftsforstyrrelserApi";
import StamdataRestrictApi from "services/api/stamdataRestrict/stamdataRestrictApi";
import { StamdataKompetenceApi } from "services/api/stamdateKompetenceApi/stamdataKompetenceApi";
import {StamdataMappeApi} from "../../services/api/stamdateMappeApi/stamdataMappeApi";
import { StamdataFileApi } from "services/api/stamdataFileApi/stamdataFileApi";
import { StamdataBrugerRolleApi } from "services/api/stamdataBrugerRolleApi/stamdataBrugerRolleApi";
import { StamdataUserAccountApi } from "services/api/stamdataUser/stamdataUserAccountApi";
import { StamdataPostnummerApi } from "services/api/stamdataPostnummer/stamdataPostnummerApi";
import StamdataBrevskabelonApi from "services/api/brevskabelon/brevskabelonApi";
import { StamdataStillingApi } from "services/api/stamdataStilling/stamdataStillingApi";

const useStamdataApi = () => {
    return useMemo(() => {
        return {
            stamdataVejlederApi: new StamdataVejlederApi(new HttpClientService(), new AccountService(), new FileDownloadHandler()),
            stamdataAfdelingApi: new StamdataAfdelingApi(new HttpClientService(), new AccountService()),
            stamdataRegionApi: new StamdataRegionApi(new HttpClientService(), new AccountService()),
            stamdataUserApi: new StamdataUserApi(new HttpClientService(), new AccountService()),
            stamdataOmraadeApi: new StamdataOmraadeApi(new HttpClientService(), new AccountService()),
            stamdataUddannelseApi: new StamdataUddannelseApi(new HttpClientService()),
            stamdataLaegeinstitutionApi: new StamdataLaegeinstitutionApi(new HttpClientService()),
            stamdataSpecialeApi: new StamdataSpecialeApi(new HttpClientService(), new AccountService()),
            stamdataMaalbeskrivelseApi: new StamdataMaalbeskrivelseApi(new HttpClientService(), new AccountService()),
            stamdataKbuRundeApi: new StamdataKbuRundeApi(new HttpClientService(), new AccountService()),
            stamdataDriftsforstyrrelserApi: new StamdataDriftsforstyrrelserApi(new HttpClientService()),
            stamdataRestrictApi: new StamdataRestrictApi(new HttpClientService()),
            stamdataKompetenceApi: new StamdataKompetenceApi(new HttpClientService()),
            stamdataMappeApi: new StamdataMappeApi(new HttpClientService()),
            stamdataFileApi: new StamdataFileApi(new HttpClientService(), new FileDownloadHandler()),
            stamdataBrugerRolleApi: new StamdataBrugerRolleApi(new HttpClientService(), new AccountService()),
            stamdataUserAccountApi: new StamdataUserAccountApi(new HttpClientService(), new AccountService()),
            stamdataPostnummerApi: new StamdataPostnummerApi(new HttpClientService(), new AccountService()),
            brevskabelonApi: new StamdataBrevskabelonApi(new HttpClientService()),
            stamdataStillingApi: new StamdataStillingApi()
        }
    }, []);
};

export default useStamdataApi;
