import React from 'react';
import './spinner.scss';
import classNames from 'classnames';

export interface SpinnerProps {
    text?: string;
    spinnerSize?: "small" | "exstra-small" | "extra-tiny-small" | "p-size";
    additionalClasses? : string;
    additionalLoadingText? : string;
    spinnerAdditionalClassNames?: string;
}

//TODO pkm: Denne skal generaliseres mere når den skal bruges uden tekst et sted.
export function Spinner(props: SpinnerProps) {
    return (
        <div className={`spinner-with-text ${ props.additionalClasses ? props.additionalClasses : "" }`}>
            <div className={classNames("spinner", props.spinnerSize, props.spinnerAdditionalClassNames)}>
                <div className="spinner-primary"/>
            </div>
            {props?.text &&
                <h4 className="margin-left-m">{props?.text}</h4>
            }
            {props.additionalLoadingText &&
                <div className="additional-text margin-top-m alert alert-info">
                    {props.additionalLoadingText}
                </div>
            }
        </div>
    );
}
