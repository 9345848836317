import {Localizer} from "../../../infrastructure/localization/localizer";
import useScreenResolution from "core/hooks/useScreenResolution";

export function Vilkaar() {
    const { isMobile } = useScreenResolution();

    return <>
            <div className="container">
                <div className="flex-container-column margin-top-l ">
                    <div className="margin-top-m margin-bottom-m">
                        <h3 className="padding-l flex-row-center">
                            {Localizer.landingPage_Privatlivspolitik()}
                        </h3>
                    </div>

                    <div>
                        <h5 className={`${isMobile ? "" : "flex-row-center"}`}>
                            {Localizer.global_logning()}
                        </h5>
                        <div className="flex-container flex-row justify-content-center">
                            {Localizer.landingPage_PrivatlivspolitikLogning()}
                        </div>
                        <div className={`${isMobile ? "" : "align-items-center"}`}>
                            <h5 className={`padding-top-m ${isMobile ? "" : "flex-row-center"}`}>
                                {Localizer.landingPage_PrivatlivspolitikOpbevaringAfData()}
                            </h5>
                            <div>
                                {Localizer.landingPage_PrivatlivspolitikOpbevaringAfDataText()}
                            </div>
                        </div>
                        <div>
                            <h5 className={`padding-top-m ${isMobile ? "" : "flex-row-center"}`}>
                                {Localizer.landingPage_PrivatlivspolitikStatistik()}
                            </h5>
                            <div>
                                {Localizer.landingPage_PrivatlivspolitikStatistikText()}
                            </div>
                        </div>
                        <div>
                            <h5 className={`padding-top-m ${isMobile ? "" : "flex-row-center"}`}>
                                {Localizer.global_cookies()}
                            </h5>
                            <div>
                                {Localizer.landingPage_PrivatlivspolitikCookiesText()}
                            </div>
                        </div>

                        <div>
                            <h5 className={`padding-top-m ${isMobile ? "" : "flex-row-center"}`}>
                                {Localizer.landingPage_PrivatlivspolitikDPO()}
                            </h5>
                            <div>
                                {Localizer.landingPage_PrivatlivspolitikCookiesText()}
                            </div>
                            {/*
                            <div className="flex-row-center padding-m flex-space-around mt-3 flex-align-r ">
                                <div>
                                    <b>{Localizer.global_name()}:</b> {Localizer.landingPage_PrivatlivspolitikDPONavn()}
                                </div>
                                <div>
                                    <b>{Localizer.landingPage_web()}</b> {Localizer.landingPage_PrivatlivspolitikDPOWeb()}
                                </div>
                                <div>
                                    <b>{Localizer.global_email()}</b> {Localizer.landingPage_PrivatlivspolitikDPOEmail()}
                                </div>
                                <div>
                                    <b>{Localizer.landingPage_telefon()}</b> {Localizer.landingPage_PrivatlivspolitikDPOPhone()}
                                </div>
                            </div>
                            */}
                        </div>
                        <div>
                            <h5 className={`padding-top-m ${isMobile ? "" : "flex-row-center"}`}>
                                {Localizer.landingPage_PrivatlivspolitikMinData()}
                            </h5>
                            <div>
                                {Localizer.landingPage_PrivatlivspolitikCookiesText()}
                            </div>
                            {/*
                             <div className="flex-row-center flex-space-around mt-3">
                                <p><b>{Localizer.global_email()}</b> {Localizer.landingPage_PrivatlivspolitikMinDataMail()}</p>
                                <p><b>{Localizer.landingPage_telefon()}</b> {Localizer.landingPage_PrivatlivspolitikMinDataPhone()}</p>
                            </div>
                            */}
                        </div>
                    </div>

                </div>
            </div>
    </>
}
