
import { DropdownOption } from "core/components/dropdown/dropdown";
import useLogbogApi from "core/hooks/useLogbogApi";
import { ForSelectModel } from "core/sharedmodels/forSelectModel";
import { RootTypeEnum } from "core/sharedmodels/laegeInstitution/rootTypeEnum";
import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";
import { useState, useEffect, useCallback } from "react";

export type TransformInput = {
    alleLaeger : boolean;
    alleKbuLaeger : boolean;
    alleIntroduktionsLaeger : boolean;
    alleHoveduddannelsesLaeger : boolean;
}

export type SelectedLaegeIdsResult = {
    laegeIds: string[];
}

const defaultSelectedLaegeIdsResult: SelectedLaegeIdsResult = {
    laegeIds: []
}

const getLaegeIdsOnUddannelsesType = (laeger: ForSelectModel[], uddannelse: UddannelseTypeEnum) => laeger.filter(l => parseInt(l.groupText) === uddannelse).map(l => l.id);

const getLaegeIdsByUddannelsesType  = (allLaegeIdsInDropdown?: string[], allUddannelsesTypeIds?: string[], selectedOptionKeys?: string[]) => {
    const allOfTypeSelected  = allLaegeIdsInDropdown?.filter((laegeId) => allUddannelsesTypeIds?.includes(laegeId)) ?? [];
    const allNonOfTypeSelected = allLaegeIdsInDropdown?.filter((laegeId) => !allUddannelsesTypeIds?.includes(laegeId)) ?? [];
    return [
        ...allOfTypeSelected,
        ...allNonOfTypeSelected.filter(x => selectedOptionKeys?.includes(x))
    ]
};

const useVejlederLaegeOptions = (selectedUddannelsesstedId?: string, vejlederId?: string, selectedUddannelsessted?: RootTypeEnum) => {
    const {logbogUserInfoApi} = useLogbogApi();

    const [laegerDropDownOption, setLaegerDropDownOption] = useState<DropdownOption<string>[]>([]);
    const [allLaegeIdsInDropdown, setAllLaegeIdsInDropdown] = useState<string[]>([]);
    const [allLaegerIds, setAllLaegerIds] = useState<string[]>([]);
    const [allKbuIds, setAllKbuIds] = useState <string[]>([]);
    const [allIntroduktionsLaegerIds, setAllIntroduktionsLaegerIds] = useState <string[]>([]);
    const [allHoveduddannelsesLaegerIds, setAllHoveduddannelsesLaegerIds] = useState <string[]>([]);

    const [isLoadingOptions, setIsLoadingOptions] = useState(true);

    useEffect(()=> {
        const fetchLeager = async () => {
            const allLaeger = selectedUddannelsessted && selectedUddannelsesstedId
                ? selectedUddannelsessted === RootTypeEnum.Sygehus
                    ? await logbogUserInfoApi.getLaegerSelectByAfdelingAndUddannelse(selectedUddannelsesstedId, vejlederId)
                    : await logbogUserInfoApi.getLaegerSelectByPraksisAndUddannelse(selectedUddannelsesstedId, vejlederId)
                : [];
            allLaeger.sort((a,b) => a.text > b.text ? 1 : -1)
            const allLeagerDropdownOptions = allLaeger.map(r => ({ label: r.text, value: r.id, groupText: r.groupText}));
            const allLeagerAndKeysOptions = allLeagerDropdownOptions.length > 0 ? allLeagerDropdownOptions : [];
            setLaegerDropDownOption(allLeagerAndKeysOptions);

            setAllLaegeIdsInDropdown(allLaeger.map(x=>x.id));
            setAllLaegerIds(allLaeger.map(l=>l.id));

            setAllKbuIds(getLaegeIdsOnUddannelsesType(allLaeger, UddannelseTypeEnum.KBU))
            setAllIntroduktionsLaegerIds(getLaegeIdsOnUddannelsesType(allLaeger, UddannelseTypeEnum.Introduktion));
            setAllHoveduddannelsesLaegerIds(getLaegeIdsOnUddannelsesType(allLaeger, UddannelseTypeEnum.Hoveduddannelse));
        };

        fetchLeager().then(() => setIsLoadingOptions(false));

    }, [selectedUddannelsesstedId, vejlederId, logbogUserInfoApi, selectedUddannelsessted]);

    const transformSelectedOptionsToLaegeIds = useCallback((selectedOptionKeys?: string[], transformInput?: TransformInput): SelectedLaegeIdsResult => {
        if(isLoadingOptions || allLaegerIds.length <= 0) {
            return {
                ...defaultSelectedLaegeIdsResult,
                laegeIds: [...selectedOptionKeys ?? []]
            }
        }

        if(!!transformInput?.alleLaeger) {
            return {
                ...defaultSelectedLaegeIdsResult,
                laegeIds: [...allLaegerIds]
            }
        }

        const laegerIdsToReturn: string[] = [
            ...!!transformInput?.alleKbuLaeger ? getLaegeIdsByUddannelsesType (allLaegeIdsInDropdown, allKbuIds, selectedOptionKeys) : [],
            ...!!transformInput?.alleIntroduktionsLaeger ? getLaegeIdsByUddannelsesType (allLaegeIdsInDropdown, allIntroduktionsLaegerIds, selectedOptionKeys) : [],
            ...!!transformInput?.alleHoveduddannelsesLaeger ? getLaegeIdsByUddannelsesType (allLaegeIdsInDropdown, allHoveduddannelsesLaegerIds, selectedOptionKeys) : [],
        ];

        const directlySelectedIds = selectedOptionKeys?.filter(x => allLaegerIds.includes(x) && !laegerIdsToReturn.includes(x)) ?? [];

        return {
            laegeIds: [...laegerIdsToReturn, ...directlySelectedIds]
        };

    }, [allHoveduddannelsesLaegerIds, allIntroduktionsLaegerIds, allKbuIds, allLaegeIdsInDropdown, allLaegerIds, isLoadingOptions]);

    return {
        laegerDropDownOption,
        transformSelectedOptionsToLaegeIds,
        allLaegerIds,
        allKbuIds,
        allIntroduktionsLaegerIds,
        allHoveduddannelsesLaegerIds,
        isLoadingOptions,
    }
}

export default useVejlederLaegeOptions;
