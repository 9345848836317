import {useEffect, useState} from 'react'
import {Localizer} from "../../../../infrastructure/localization/localizer";
import {FormikProps} from "formik";
import {ValidationDatepicker} from "../../../../core/components/validation/components/validationDatePicker";
import {nameof} from "ts-simple-nameof";
import {
    CrudVejledersamtaleInputModel,
    PrivateSamtaleKey,
    VisibleToEveryoneKey
} from "./crudVejledersamtaleInputModel";
import {ValidationInput} from "../../../../core/components/validation/components/validationInput";
import {DatetimePickerTypeEnum} from "ditmer-embla";
import {useAppSelector} from "../../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../../core/forloebSlice";
import {VejledersamtaleEnum, VejledersamtaleModel} from "../vejledersamtaleModel";
import {Loading} from "../../../../core/components/loading/loading";
import {
    ForSelectModel,
    toDropdownOptions
} from "../../../../core/sharedmodels/forSelectModel";
import {FileMetadataList} from "../../../../core/components/fileMetadata/fileMetadataList";
import {BasicNotatModel} from "../../../../core/sharedmodels/notat/basicNotatModel";
import {ReactFileUploaderFile} from "../../../../core/components/fileUpload/reactFileUploader";
import {FileMetadata} from "../../../../core/sharedmodels/fileMetaData/fileMetaData";
import {NotatType} from "../../../../core/sharedmodels/notat/notatType";
import {FileLocationEnum} from "../../../../core/sharedmodels/file/fileLocationEnum";
import {DropdownOption} from "../../../../core/components/dropdown/dropdown";
import {ValidationDropdown} from "../../../../core/components/validation/components/validationDropdown";
import Title from "core/components/titels/title";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import useUser from "../../../../core/hooks/useUser";
import useLogbogApi from "core/hooks/useLogbogApi";
import { ValidationSwitch } from "core/components/validation/components/ValidationSwitch";
import { ValidationTextEditor } from 'core/components/validation/components/validationTextEditor';

type VejledersamtaleCrudFieldsProps = {
    modalId: string
    formik: FormikProps<CrudVejledersamtaleInputModel>,
    vejledersamtaleType: VejledersamtaleEnum,
    notatInfo? : {contextId : string, notatType : NotatType, userId: string};
    notat?: BasicNotatModel | null,
    selectedVejlederBrugerId?: string,
    afholdtAfName?: string,
    afholdtDate?: Date
    fileUploadedCallbackOverride?: (result: ReactFileUploaderFile[]) => void,
    fileDeletedCallbackOverride?: (result: FileMetadata) => void,
    visibleToUserIds?: string[];
    createdByName?: string,
    klarmeldtAt?: string,
}
const standaloneForSelectModels = [
    new ForSelectModel({id:VisibleToEveryoneKey, text:Localizer.visibleToEveryoneWithAccessToLaege(), groupText:""}),
    new ForSelectModel({id:PrivateSamtaleKey, text:Localizer.visibleToUddannelseslaegeAndVejleder(), groupText:""})
];

export const VejledersamtaleCrudFields = (props: VejledersamtaleCrudFieldsProps) => {
    const { currentUser } = useUser();
    const { logbogUserInfoApi } = useLogbogApi();
    
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    
    const [isPlaceholderVejledersamtale, setIsPlaceholderVejledersamtale] = useState(false);
    const [vejlederOptions, setVejlederOptions] = useState<DropdownOption<string>[]>([]);
    const [isLoading, setIsLoading] = useState(true)
    const [visibileToUsers, visibleToUsersDropdownOptions] = useState<DropdownOption<string>[]>([]);
    const showKlarmeldButton = currentUser.IsLaege() && (props.notatInfo && props.notatInfo.contextId) && (props.afholdtAfName == undefined && props.afholdtDate == undefined);

    useEffect(() => {
        setIsPlaceholderVejledersamtale(props.formik.values.titel === VejledersamtaleModel.GetTitelFromVejledersamtaleType(props.vejledersamtaleType) && props.formik.values.titel !== "")
    }, [props.formik.values.titel, props.vejledersamtaleType]);

    useEffect( () => {
        const fetchUsers = async () => {
            const personale = await logbogUserInfoApi.getPersonaleSelectOnForloeb(forloebSliceState.forloebState.id);
            const usersWithTidsbegransetAccess = await logbogUserInfoApi.getUsersWithAccessToForloeb(forloebSliceState.forloebState.id, false);
            const usersForSelect = usersWithTidsbegransetAccess.map(u => new ForSelectModel({id: u.userId, text: `${u.navn} - ${u.email}`, groupText: ""}));
            const users = personale.concat(...usersForSelect);
            return {
                users: users,
                vejledere: users.filter((v) => v.id !== forloebSliceState.forloebState.brugerId)
            };
        }

        fetchUsers()
            .then(({users, vejledere}) => {
                visibleToUsersDropdownOptions(toDropdownOptions([
                    ...standaloneForSelectModels,
                    ...users
                ]));
        
                setVejlederOptions(toDropdownOptions(vejledere));
            })
            .finally(() => setIsLoading(false))
        ;
    }, [forloebSliceState.forloebState.brugerId, forloebSliceState.forloebState.id, logbogUserInfoApi]);

    const onVisibilitySelected = (value: string[]) => {
        const isHitPublicVisibility = !!value.find(x=>x === VisibleToEveryoneKey);
        props.formik.setFieldValue(VisibleToEveryoneKey, isHitPublicVisibility);

        const isHitPrivateVisibility = !!value.find(x=>x === PrivateSamtaleKey);
        props.formik.setFieldValue(PrivateSamtaleKey, isHitPrivateVisibility);
    }

    const klarmeld = async () => {
        if (!!props.klarmeldtAt) {
            props.formik.setFieldValue(nameof<CrudVejledersamtaleInputModel>(x => x.klarmeldtAt), undefined);
        } else {
            props.formik.setFieldValue(nameof<CrudVejledersamtaleInputModel>(x => x.klarmeldtAt), new Date());
        }
    }

    const isReadOnly = props.afholdtAfName !== undefined && props.afholdtDate !== undefined;

    return (
        <>
            <Loading isLoading={isLoading} text={Localizer.global_getChoices()}>
                <div className="row">
                    {props.afholdtDate && props.afholdtAfName &&
                        <div className="col-sm-12 padding-left-0 padding-right-0">
                            <div className="alert alert-success" role="alert">
                                {Localizer.vejledningPage_vejlederHarAfholdVejledersamtale(props.afholdtAfName, props.afholdtDate.dateWithoutTimeFormat(false))}
                            </div>
                        </div>
                    }
                    <div className="col-sm-12">
                        <ValidationInput
                            model={{
                                label: Localizer.titel(),
                                placeholder: Localizer.titelPlaceholder(),
                                htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.titel)
                            }}
                            inputId={nameof<CrudVejledersamtaleInputModel>(x => x.titel) + props.modalId}
                            formikProps={props.formik}
                            readOnly={isPlaceholderVejledersamtale}
                        />
                    </div>
                    <div className="col-sm-12">
                        <ValidationDatepicker
                            model={{
                                label: Localizer.dato(),
                                placeholder: Localizer.datoPlaceholder(),
                                htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.dato)
                            }}
                            allowClear={true}
                            inputId={nameof<CrudVejledersamtaleInputModel>(x => x.dato) + props.modalId}
                            formikProps={props.formik}
                            readOnly={isReadOnly}
                        />
                    </div>
                    <div className="col-sm-6">
                        <ValidationDatepicker
                            model={{
                                label: Localizer.starttidspunkt(),
                                placeholder: Localizer.starttidspunktPlaceholder(),
                                htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.starttidspunkt)
                            }}
                            allowClear={true}
                            datePickerType={DatetimePickerTypeEnum.Time}
                            inputId={nameof<CrudVejledersamtaleInputModel>(x => x.starttidspunkt) + props.modalId}
                            formikProps={props.formik}
                            readOnly={isReadOnly}
                        />
                    </div>

                    <div className="col-sm-6">
                        <ValidationDatepicker
                            model={{
                                label: Localizer.sluttidspunkt(),
                                placeholder: Localizer.sluttidspunktPlaceholder(),
                                htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.sluttidspunkt)
                            }}
                            allowClear={true}
                            datePickerType={DatetimePickerTypeEnum.Time}
                            inputId={nameof<CrudVejledersamtaleInputModel>(x => x.sluttidspunkt) + props.modalId}
                            formikProps={props.formik}
                            readOnly={isReadOnly}
                        />
                    </div>

                    <div className="col-sm-12">
                        <ValidationSwitch
                            inputId={nameof<CrudVejledersamtaleInputModel>(x => x.shouldSendCalendarInvi) + props.modalId}
                            model={{
                                label: Localizer.vejledningPage_sendCalendarInvitation(),
                                placeholder: "",
                                htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.shouldSendCalendarInvi)
                            }}
                            defaultChecked
                        />
                    </div>

                    <div className="col-sm-12">
                        <ValidationDropdown
                            model={{
                                label: Localizer.vejleder(),
                                placeholder: Localizer.vejleder(),
                                htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.vejleder),
                            }}
                            options={vejlederOptions}
                            readOnly={isReadOnly}
                            formikProps={props.formik}
                        />

                        <Title subTitle={Localizer.global_createdBy(props.createdByName)}>
                            <ValidationDropdown
                                additionalClasses="margin-bottom-s"
                                model={{
                                    label: Localizer.global_visibilityUddannelseslaegeAndUddannelsesansvarlig(),
                                    placeholder: Localizer.global_chooseVisibility(),
                                    htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.visibleToUserIds)
                                }}
                                options={visibileToUsers}
                                itemSelected={(newValues) => onVisibilitySelected(newValues.map(x => x.value))}
                                formikProps={props.formik}
                                readOnly={false}
                                isMulti
                                isClearable
                                standAloneValues={[VisibleToEveryoneKey, PrivateSamtaleKey]}
                            />
                        </Title>
                    </div>

                    <div className="col-sm-12">
                        <ValidationTextEditor
                            model={{
                                label: Localizer.referat(),
                                placeholder: Localizer.referatPlaceholder(),
                                htmlName: nameof<CrudVejledersamtaleInputModel>(x => x.referat)
                            }}
                            inputId={nameof<CrudVejledersamtaleInputModel>(x => x.referat) + props.modalId}
                            formikProps={props.formik}
                            readOnly={isReadOnly}
                            value={props.formik.values.referat}
                        />
                    </div>

                    <div className="col-sm-12">
                        <FileMetadataList fileMetadatas={props.notat?.fileMetadatas ?? []}
                                          filesUploadedCallbackOverride={props.fileUploadedCallbackOverride}
                                          fileDeletedCallbackOverride={props.fileDeletedCallbackOverride}
                                          fileLocation={FileLocationEnum.Logbog}
                                          notatInfo={props.notatInfo}
                                          notat={props.notat}
                                          showDeleteButton={props.afholdtAfName === undefined && props.afholdtDate == undefined}
                                          withUploader={props.afholdtAfName === undefined && props.afholdtDate == undefined}/>
                    </div>

                    {showKlarmeldButton &&
                        <div className="col-sm-12 margin-top-m">
                            <button className={"btn btn-primary"}
                                    onClick={klarmeld}
                                    type={"submit"}>
                                <EmblaIcon iconName={!!props.klarmeldtAt ? "remove" : "success"}/>
                                {!!props.klarmeldtAt ? Localizer.global_removeKlarmeld() : Localizer.global_klarmeld()}
                            </button>
                        </div>
                    }

                </div>
            </Loading>
        </>
    );
}
