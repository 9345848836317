import { useAppDispatch, useAppSelector } from "app/hooks";
import DynamicButton from "core/components/button/dynamicButton";
import { ModalAcceptType, ModalSubmitMessage } from "core/components/modalMessage/modalSubmitMessage";
import { SlideIn } from "core/components/slideIn/slideIn";
import { TableAsync } from "core/components/table/tableAsync";
import { UserProfileAvatar, UserProfileAvatarSizeEnum } from "core/components/userProfileAvatar/userProfileAvatar";
import { UserProfileAvatarModel } from "core/components/userProfileAvatar/userProfileAvatarModel";
import { GodkendTid } from "core/componentsPage/forloeb/godkendtTid/godkendTid";
import { EmblaIcons } from "core/emblaIcons";
import { ForloebSliceState, forloebReducer } from "core/forloebSlice";
import useLogbogApi from "core/hooks/useLogbogApi";
import { mobileMaxWidthQuery } from "core/layout/responsive";
import { NotificationModule, TableModule } from "ditmer-embla";
import { addDays, dateWithoutTimeFormat } from "index";
import { Localizer } from "infrastructure/localization/localizer";
import { RoutePaths } from "infrastructure/routes";
import { setPingReload } from "pages/bruger/asyncTableSlice";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ReactDOMServer from "react-dom/server";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { AccountService } from "services/account/accountService";
import { ForloebApi } from "services/api/forloeb/forloebApi";
import { ForloebSimpleListModel } from "services/api/forloeb/forloebSimpleListModel";
import { HttpClientService } from "services/httpClient/httpClientService";
import { nameof } from "ts-simple-nameof";


const getPeriodDisplayString = (model: ForloebSimpleListModel) => {
    const startDateDisplay = dateWithoutTimeFormat(model.startDate);
    const endDateDisplay = dateWithoutTimeFormat(model.endDate);
    const periodDisplay = `${startDateDisplay} - ${endDateDisplay}`;

    const periodDayDiffLimit = 30;
    const isAfsluttet = model.periodDayDiff > periodDayDiffLimit;
    const parenthesisDisplay = isAfsluttet ? Localizer.klarmeldingerPage_finished() : Localizer.klarmeldingerPage_dageTilbageAfForloeb(model.periodDayDiff);
    return `${periodDisplay} (${parenthesisDisplay})`;
}

const getUserAvatarString = (model: ForloebSimpleListModel, isMobile: boolean) : string => {
    return ReactDOMServer.renderToString((
        <div className="d-flex">
            { !isMobile &&
                <UserProfileAvatar
                    userProfileAvatarModel={new UserProfileAvatarModel(model.uddannelseslaegeName, model.uddannelseslaegeInitialer, true)}
                    size={UserProfileAvatarSizeEnum.extraSmall}
                />
            }
            <div className="margin-left-s">
                {model.uddannelseslaegeName}
            </div>
        </div>
    ));
}

export const ForloebMedAttestationForTidKlarTilGodkendelse = () => {

    const dispatch = useAppDispatch();
    const history = useHistory();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const isMobile = useMediaQuery(mobileMaxWidthQuery);
    const {forloebApi} = useLogbogApi();

    const tabId = "ForloebMedAttestationForTidKlarTilGodkendelseTab";
    const slideInId = `${tabId}slideIngodkendtid`;
    const modalId = `${tabId}modalUdeladForloeb`;
    const godkendTidBtnClass = "godkend-tid-btn";

    const [isGodkendTidShown, setIsGodkendTidShown] = useState(false);
    const [isModalShown, setIsModalShown] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedModel, setSelectedModel] = useState<ForloebSimpleListModel>();
    const [kommentar, setKommentar] = useState("");

    const dataTableRef = useRef<TableModule>(null);

    useEffect(() => {
        if(!isLoading)
            dispatch(setPingReload())

        setIsLoading(false);
    }, [dispatch, forloebSliceState.refreshForloebPing, isLoading]);

    const setupDelagatedOnClickGodkendTid = (cell: Node, model: ForloebSimpleListModel) => {
        $(cell).on('click', (event) => {
            event.stopPropagation();

            setSelectedModel(model);
            setIsGodkendTidShown(true);
        });
    }

    const setupDelegatedOnClickUdeladForloeb = (cell: Node, model: ForloebSimpleListModel) => {
        $(cell).on('click', (event) => {
            event.stopPropagation();

            setSelectedModel(model);

            // Resets state, so if the modal is closed by clicking outside of it,
            // the modal will be re-rendered and ensures it to be properly displayed.
            setIsModalShown(false);
            setIsModalShown(true);
            setKommentar("");
        });
    }

    const renderTableHeaders = () => {
        return (
            <thead>
            <tr>
                <th data-column-name={nameof<ForloebSimpleListModel>(x => x.uddannelseslaegeName)}
                    data-priority="1">
                    {Localizer.global_uddannelseslaege()}
                </th>
                <th data-column-name={nameof<ForloebSimpleListModel>(x => x.specialeName)}
                    data-priority="2">
                    {Localizer.global_speciale()}
                </th>
                <th data-column-name={nameof<ForloebSimpleListModel>(x => x.sygehusPraksisName)}
                    data-priority="3">
                    {Localizer.sygehusPraksis()}
                </th>
                <th data-column-name={nameof<ForloebSimpleListModel>(x => x.afdelingName)}
                    data-priority="4">
                    {Localizer.afdeling()}
                </th>
                <th data-column-name={nameof<ForloebSimpleListModel>(x => x.periodDayDiff)}
                    data-priority="5">
                    {Localizer.global_periode()}
                </th>
                <th data-column-name={nameof<ForloebSimpleListModel>(x => x.forloebId)}
                    data-priority="1">
                </th>
                <th data-column-name={nameof<ForloebSimpleListModel>(x => x.forloebId + "1")}
                    data-priority="1">
                </th>
            </tr>
            </thead>
        );
    }

    const getTableColums = (): DataTables.ColumnSettings[] => {
        let columns: DataTables.ColumnSettings[] = [];
        columns = columns.concat([
            {
                data: (data: ForloebSimpleListModel) => data,
                render: (data: ForloebSimpleListModel) => {
                    return getUserAvatarString(data, isMobile);
                },
                orderable: true,
            },
            {
                data: (data: ForloebSimpleListModel) => data.specialeName,
                orderable: true,
            },
            {
                data: (data: ForloebSimpleListModel) => data.sygehusPraksisName,
                orderable: true,
            },
            {
                data: (data: ForloebSimpleListModel) => data.afdelingName,
                orderable: true,
            },
            {
                data: (data: ForloebSimpleListModel) => data,
                render: (data: ForloebSimpleListModel) => {
                    return getPeriodDisplayString(data);
                },
                orderable: true,
            },
            {
                data: (data: ForloebSimpleListModel) => data,
                render: (data: ForloebSimpleListModel) => {
                    return getGodkendTidBtnString(data);
                },
                orderable: false,
                createdCell: (cell: Node, cellData: ForloebSimpleListModel, rowData: any, row: number, col: number) => {
                    setupDelagatedOnClickGodkendTid(cell, cellData);
                },
            },
            {
                data: (data: ForloebSimpleListModel) => data,
                render: (data: ForloebSimpleListModel) => {
                    return getUdeladForloebBtnString(data);
                },
                orderable: false,
                createdCell: (cell: Node, cellData: ForloebSimpleListModel, rowData: any, row: number, col: number) => {
                    setupDelegatedOnClickUdeladForloeb(cell, cellData);
                }
            }
        ]);
        return columns;
    };

    const navigateToForloeb = (forloebId: string, uddannelseslaegeId: string) => {
        history.push(RoutePaths.Forloeb([uddannelseslaegeId, forloebId]).url)
    }

    const getGodkendTidBtnString = (model: ForloebSimpleListModel) : string => {
        return ReactDOMServer.renderToString((
            <button type="button"
                    className={"btn btn-success btn-round stop-event " + godkendTidBtnClass}
                    aria-label="Primary"
                    >
                {Localizer.forloebpage_godkendTid()}
            </button>
        ));
    }

    const getUdeladForloebBtnString = (model: ForloebSimpleListModel) : string => {
        return ReactDOMServer.renderToString((
            <DynamicButton rounded additionalClasses="stop-event">
                <DynamicButton.TextIconItem applyDatatableStopEvent iconName={EmblaIcons.Remove}/>
            </DynamicButton>
        ));
    }

    const rowClickAction = (data: ForloebSimpleListModel) => {
        navigateToForloeb(data.forloebId, data.uddannelseslaegeId);
    }

    const initCompleteCallback = () => {
        const tableSelector = `table#${tabId}`;
        const btnSelector = `button.${godkendTidBtnClass}`;
        const tableRowSelector = 'tr';

        $(tableSelector).on("click", btnSelector, (event) => {
            event.stopPropagation();

            const dataTableApi = dataTableRef.current?.getDatatablesApi();
            if(!dataTableApi) return;

            const $parentRow = $(event.target).parents(tableRowSelector);
            const $responsiveCreatedRow = $parentRow.closest(tableRowSelector).first();
            const $originRow = $responsiveCreatedRow.prev(tableRowSelector).first();

            const data = dataTableApi.row($originRow).data();
            if(!data) return;

            setSelectedModel(data as ForloebSimpleListModel)
            setIsGodkendTidShown(true);
            setIsModalShown(true);
        });
    }

    const udeladForloeb = async () => {
        const forloebUdeladt = await forloebApi.setUdeladForloebFraKlarmeldingerGodkendTid(selectedModel!.forloebId, kommentar); 

        if (!forloebUdeladt) {
            NotificationModule.showError(Localizer.klarmeldingerPage_godkendTid_forloebUdeladtError() , "")
            return;
        }
        
        NotificationModule.showSuccess(Localizer.klarmeldingerPage_godkendTid_forloebUdeladtSucces(), "");
        setKommentar("");
        setIsModalShown(false);
        dispatch(setPingReload());
    }

    const handleKommentarChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setKommentar(e.target.value)
    }

    return (
        <>
            <TableAsync
                ref={dataTableRef}
                tableIdentifier={tabId}
                tableHeader={Localizer.forloebpage_AttestationForTid()}
                renderTableHead={renderTableHeaders}
                asyncUrl={forloebApi.getActiveForloebWhichEndsWithin30DaysUrl()}
                columns={getTableColums()}
                rowClickFunction={rowClickAction}
                initOrder={[4, "asc"]}
                initCompleteCallback={initCompleteCallback}
            />

            {isGodkendTidShown && selectedModel &&
                <SlideIn
                    id={slideInId}
                    title={Localizer.forloebpage_godkendTid()}
                    actionText={Localizer.global_editing()}
                    actionOnCloseCallback={() => setIsGodkendTidShown(false)}
                    actionFinishedCallback={() => setIsGodkendTidShown(false)}
                    defaultOpen={true}
                    bodyContent={
                        <GodkendTid
                            modalId={slideInId}
                            forloebId={selectedModel.forloebId}
                            userId={selectedModel.uddannelseslaegeId}
                            uddannelsessted={!selectedModel.afdelingName || selectedModel.afdelingName === ""
                                ? selectedModel.sygehusPraksisName
                                : `${selectedModel.sygehusPraksisName}, ${selectedModel.afdelingName}`
                            }
                            attestationForTidInfoTekst={selectedModel.attestationForTidInfoTekst}
                        />
                    }
                />
            }

            {isModalShown && selectedModel &&
                createPortal(
                    <ModalSubmitMessage
                        modalId={modalId}
                        title={Localizer.areYouSure()}
                        cancelAction={() => {
                            setIsModalShown(false);
                            setKommentar("");
                        }}
                        acceptAction={udeladForloeb}
                        modalAcceptType={ModalAcceptType.primary}
                        acceptButtonText={Localizer.global_remove()}
                        description={
                            <>
                                <p>{Localizer.klarmeldingerPage_godkendTid_areYouSureRemoveForloeb()}</p>

                                <label>{Localizer.klarmeldingerPage_godkendTid_comment()}: </label>

                                <textarea className="form-control"
                                    placeholder={Localizer.klarmeldingerPage_godkendTid_describeReasonRemoveForloeb()}
                                    onChange={handleKommentarChange}
                                />
                            </>
                        }
                        defaultOpen
                    />
                , document.body)
            }
        </>
    )
}
