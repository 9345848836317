import {useEffect, useRef, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import {useRouteMatch} from "react-router-dom";
import {Loading} from "../../core/components/loading/loading";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import {InitForloebState} from "./forloebApi";
import {ForloebKompetencerOverview} from "./forloebOverviews/forloebKompetencerOverview";
import {ForloebVejledersamtalerOverview} from "./forloebOverviews/forloebVejledersamtalerOverview";
import {ForloebNotaterOverview} from "./forloebOverviews/forloebNotaterOverview";
import {ForloebUddannelsesplanerOverview} from "./forloebOverviews/forloebUddannelsesplanerOverview";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import {AccountService} from "../../services/account/accountService";
import {ForloebKurserOverview} from "./forloebOverviews/forloebKurserOverview";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";
import {SpecialespecifikkeFilerOverview} from "./forloebOverviews/specialespecifikkeFilerOverview";
import {UddannelsesprogrammerOverview} from "./forloebOverviews/uddannelsesprogrammerOverview";
import UddannelsesstedFiles from "../filer/components/uddannelsesstedFiles";

export type ForloebPageQueryParam = {
    id?: string;
    showForloebDetails?: string;
}

export function ForloebPage() {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const routeMatch = useRouteMatch();
    const dispatch = useAppDispatch();
    const _currentUser = useRef(new AccountService().getUser());

    const [isLoading, setIsLoading] = useState(true);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;
    const forloebId = (routeMatch.params as { forloebId: string }).forloebId;

    useEffect(() => {
        const fetch = async () => {
            if (forloebId && uddannelseslaegeId)
                await InitForloebState(dispatch, null, uddannelseslaegeId, forloebId);
            else
                await InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId);
            setIsLoading(false);
        }
        fetch()
    },[dispatch, forloebId, forloebSliceState.forloebState, uddannelseslaegeId])

    const shouldHideSeUddannelseAndForloebDetailsButtons = _currentUser.current.IsKursusBruger() || _currentUser.current.IsHrMedarbejder() || _currentUser.current.IsForskningstraeningsvejleder();
    const shouldHideForloebDetailsButton = _currentUser.current.IsKonsulentForLaegeligVidereuddannelse() || _currentUser.current.IsLedelse();
    const canSeeCardOverviews = _currentUser.current.IsAdminOrVus() || _currentUser.current.IsLaege() || _currentUser.current.IsVejleder() || _currentUser.current.IsUddannelsesansvarlig();

    return (
        <Loading isLoading={isLoading} text={Localizer.profilepage_getProfil()}>
            <ForloebSpecificPageWrapper>
                {!IsMobile &&
                    <div>
                        <ForloebHeader hideSeUddannelseAndForloebDetails={shouldHideSeUddannelseAndForloebDetailsButtons} hideForloebDetails={shouldHideForloebDetailsButton}/>
                        <div className="row">
                            {canSeeCardOverviews &&
                                <>
                                    <div className="col-6">
                                        <>
                                            <ForloebKompetencerOverview/>
                                            <ForloebVejledersamtalerOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <ForloebUddannelsesplanerOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                        </>
                                    </div>

                                    <div className="col-6">
                                        <>
                                            <ForloebKurserOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <ForloebNotaterOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <UddannelsesprogrammerOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                            <UddannelsesstedFiles showRightHeaderContent hideContent/>
                                            <SpecialespecifikkeFilerOverview showRightHeaderContent/>
                                        </>
                                    </div>

                                    <div className="col-6">
                                        <>
                                        </>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }

                {IsMobile &&
                    <>
                        <ForloebHeader
                            hideSeUddannelseAndForloebDetails={shouldHideSeUddannelseAndForloebDetailsButtons}
                            hideForloebDetails={shouldHideForloebDetailsButton}/>
                        {canSeeCardOverviews &&
                            <>
                                <ForloebKompetencerOverview/>
                                <ForloebKurserOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <ForloebVejledersamtalerOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <ForloebUddannelsesplanerOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <ForloebNotaterOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <UddannelsesprogrammerOverview showRightHeaderContent={!_currentUser.current.IsKonsulentForLaegeligVidereuddannelse()}/>
                                <SpecialespecifikkeFilerOverview showRightHeaderContent/>
                            </>
                        }
                    </>
                }
            </ForloebSpecificPageWrapper>
        </Loading>
    );
}
