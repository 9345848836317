import {EmblaIcon} from '../emblaIcon/emblaIcon';
import {useState} from 'react';
import DynamicButton from './dynamicButton';
import { EmblaIconsType } from 'core/emblaIcons';

export type ConfirmButtonProps = {
    icon: EmblaIconsType;
    asButton?: boolean;
    buttonTekst?: string;
    additionalClasses?: string;
    confirmCallback: () => void;
    useDynamicButton?: boolean;
    disabled?: boolean;
}

export function ConfirmButton({useDynamicButton=false, disabled=false, ...props}: ConfirmButtonProps) {
    const [confirmationActive, setConfirmationActive] = useState(false);

    const confirm = async (confirmed: boolean) => {
        if (confirmed) {
            setConfirmationActive(true)
            props.confirmCallback();
        } else
            setConfirmationActive(false);
    }
    
    const handleOnClick = () => {
        setConfirmationActive(true);
    }

    const render = (
        <>
            {!confirmationActive &&
            
                <>
                    {useDynamicButton 
                        ?
                            <DynamicButton onClick={handleOnClick} additionalClasses={props.additionalClasses} disabled={disabled}>
                                <DynamicButton.TextIconItem iconName={props.icon} text={props.buttonTekst}/>
                            </DynamicButton>
                        :
                            <div className={`${props.asButton ? "btn btn-default" : ""} ${props.additionalClasses ? props.additionalClasses : ""}`} role='button'
                                onClick={handleOnClick}>
                                {props.buttonTekst &&
                                    <>{props.buttonTekst}</>
                                }
                                <EmblaIcon iconName={props.icon} />
                            </div>
                    }
                </>

            }

            {confirmationActive &&
                <div className={`btn-group confirm ${props.additionalClasses ? props.additionalClasses : ""}`} role="group">
                    <button type="button" className="btn btn-danger btn-icon btn-outline"
                            onClick={() => confirm(false)}>
                        <EmblaIcon iconName='danger'/>
                    </button>
                    <button type="button" className="btn btn-success btn-icon btn-outline"
                            onClick={() => confirm(true)}>
                        <EmblaIcon iconName='success'/>
                    </button>
                </div>
            }
        </>
    )

    return <>{render}</>
}
