import {LeftMenuState, leftMenuState, toogleCollaped} from "./leftMenuSlice";
import LeftMenuLink from "./leftMenuLink";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {RoutePaths} from "../../../infrastructure/routes";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {UddannelsesLaegeMenu} from "./userRoleMenus/uddannelsesLaegeMenu";
import {Loading} from "../../components/loading/loading";
import './left-menu.scss';
import {EmailSlideInModal} from "../../components/mail/emailSlideInModal";
import {UserProfileModel} from "../../components/userProfile/userProfileModel";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../../components/userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../components/userProfileAvatar/userProfileAvatarModel";
import {RoleDynamicMenu} from "./RoleDynamicMenu";
import {KursusudbyderMenu} from "./userRoleMenus/kursusudbyderMenu";
import useUser from "core/hooks/useUser";
import useScreenResolution from "core/hooks/useScreenResolution";
import useLogbogApi from "core/hooks/useLogbogApi";
import { EmblaIcons } from "core/emblaIcons";
import { MenuLinkState } from "../shared/MenuLinkProps";
import {forloebReducer, ForloebSliceState} from "../../forloebSlice";
import BrevskabelonMenuLink from "./menuLinks/brevskabelonMenuLink";

const handleRouteUserNotFoundOrNoAccess = (routeUserId: string, reason?: any) => {
    const warningMessage = `Couldn't retrieve route-user with id ${routeUserId} with reason:`;
    console.info(warningMessage, reason);
    window.location.replace(RoutePaths.Index.path);
}

// Retrieves and handles route-user
// Also makes sure, that we redirect to index, if we don't have access to the user:
const useRouteUser = (routeUserId: string) => {
    const [routeUserState, setRouteUserState] = useState<UserProfileModel>();
    const [isLoading, setIsLoading] = useState(true);
    const { currentUser } = useUser();
    const { logbogUserInfoApi  } = useLogbogApi();

    const userViewingOtherUser = routeUserId !== currentUser.UserId;

    const fetchRouteUser = useCallback(async () => {
        await logbogUserInfoApi.getUserInfo(routeUserId)
            .then((userModel) => {
                if(userModel.userId) {
                    setRouteUserState(userModel)
                } else {
                    handleRouteUserNotFoundOrNoAccess(routeUserId)
                }
            })
            .catch((reason) => handleRouteUserNotFoundOrNoAccess(routeUserId, reason))
            .finally(() => setIsLoading(false))
    }, [logbogUserInfoApi, routeUserId]);

    useEffect(() => {
        const handleFetchRouteUser = () => {
            // Do this, so that we refetch route-user, if currentUser changes (e.g. impersonate / change role - from other tab)
            const currentUserMatch = !!currentUser.UserId && !!currentUser.CurrentRolle;
            if (userViewingOtherUser && !!routeUserId && currentUserMatch) {
                fetchRouteUser();
            }
        }
        handleFetchRouteUser();
    }, [currentUser.CurrentRolle, currentUser.UserId, fetchRouteUser, routeUserId, userViewingOtherUser])

    return {
        routeUserState,
        isLoading,
        userViewingOtherUser
    }
}

const modalTarget = "left-menu-modal"

export const LeftMenu = () => {
    const dispatch = useAppDispatch();
    const routeMatch = useRouteMatch();
    const location = useLocation<MenuLinkState>();
    const queryParams = (routeMatch.params as { uddannelseslaege: string, id: string});
    const routeId = queryParams.id ?? queryParams.uddannelseslaege;

    const leftMenu = useAppSelector(leftMenuState) as LeftMenuState;
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const { currentUser } = useUser();
    const { isMobile } = useScreenResolution();

    const [showEmailSlideIn, setShowEmailSlideIn] = useState<boolean>(false)

    const resolvedUserId = routeId ?? location.state?.userId ?? currentUser.UserId;
    const { routeUserState, isLoading, userViewingOtherUser } = useRouteUser(resolvedUserId);

    const getBackText = () => {
        if (isMobile) {
            return Localizer.global_back();
        } else if (
            currentUser.IsAdminOrVus() ||
            currentUser.IsHrMedarbejder() ||
            currentUser.IsUddannelsesansvarlig() ||
            currentUser.IsKonsulentForLaegeligVidereuddannelse() ||
            currentUser.IsKursusleder() ||
            currentUser.IsKursusudbyder() ||
            currentUser.IsForskningstraeningsvejleder() ||
            currentUser.IsSekretaer() ||
            currentUser.IsLedelse()
        ) {
            return Localizer.global_forloebOverview();
        } else if (currentUser.IsVejleder()) {
            return Localizer.global_uddannelseslaeger();
        } else {
            return Localizer.global_back()
        }
    };

    return (
        <React.Fragment>
            <div className="left-menu">

                <div className="left-menu-header">
                    <div className="left-menu-header-toggle" onClick={() => dispatch(toogleCollaped())}>
                        <button className={`hamburger hamburger ${leftMenu.collapsed ? "" : "is-active"}`}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                            </span>
                        </button>
                    </div>
                </div>

                {(!userViewingOtherUser) &&
                    <div className="left-menu-links">
                        <RoleDynamicMenu wrapperDivClass="left-menu-group" user={currentUser} showNotification={true}/>
                    </div>
                }

                {(userViewingOtherUser && routeUserState) &&
                    <Loading isLoading={isLoading} text={Localizer.getUserRole()} spinnerClasses={"left-menu-spinner"}>
                        <div className="left-menu-links">
                            <div className="left-menu-group">
                                {!leftMenu.collapsed &&
                                    <div className="left-menu-group text-center padding-bottom-m">
                                        <Link to={RoutePaths.Index.url} className={"menu-link"}>
                                            <div className="menu-link-icon">
                                                <EmblaIcon iconName={EmblaIcons.ArrowLeft}/>
                                            </div>
                                            <div className="menu-link-text">{getBackText()}</div>
                                        </Link>

                                        <div className="left-menu-group-separator "/>

                                        <Loading
                                            isLoading={routeUserState.userId === currentUser.UserId}
                                            spinnerClasses={"justify-content-center margin-top-m"}>
                                            <UserProfileAvatar
                                                size={UserProfileAvatarSizeEnum.medium}
                                                userProfileLayoutEnum={UserProfileLayoutEnum.AsHeaderBelowAvatar}
                                                userProfileAvatarModel={new UserProfileAvatarModel(routeUserState.getFullName(), routeUserState.initialer)}
                                            />
                                        </Loading>

                                        {(currentUser.IsAdminOrVus() || currentUser.IsHrMedarbejder() || currentUser.IsKonsulentForLaegeligVidereuddannelse() || currentUser.IsUddannelsesansvarlig() || currentUser.IsLedelse())  &&
                                            <button onClick={() => setShowEmailSlideIn(true)} className={"btn btn-default"}
                                                    data-toggle="modal"
                                                    data-target={`#${modalTarget}`}>
                                                <EmblaIcon iconName={"email"}/>
                                                <span
                                                    className={"margin-left-xs"}>{Localizer.createMail_SendMail()}</span>
                                            </button>
                                        }

                                    </div>
                                }
                                <div className="left-menu-group-separator-margin-bottom">
                                    <div className="left-menu-group-separator"/>
                                </div>

                                <div className={"margin-bottom-m"}>
                                    {currentUser.HasRole(RoutePaths.Uddannelseslaege().roles) &&
                                        <LeftMenuLink
                                            emblaIconName={EmblaIcons.User}
                                            linkText={Localizer.global_profile()}
                                            routePath={RoutePaths.Uddannelseslaege(routeUserState.userId).url}
                                            routePathsToHighlight={[RoutePaths.Uddannelseslaege(routeUserState.userId).url]}
                                        />
                                    }

                                    {currentUser.IsKursusBruger()
                                        ? <KursusudbyderMenu uddannelseslaegeId={routeUserState.userId}/>
                                        : <UddannelsesLaegeMenu uddannelseslaegeId={routeUserState.userId}/>
                                    }
                                </div>

                                <div className="left-menu-group-separator-margin">
                                    <div className="left-menu-group-separator"/>
                                </div>

                                {currentUser.HasRole(RoutePaths.ForloebList().roles) &&
                                    <LeftMenuLink
                                        emblaIconName={EmblaIcons.List}
                                        linkText={Localizer.global_forloebList()}
                                        routePath={RoutePaths.ForloebList(routeUserState.userId).url}
                                        routePathsToHighlight={[ RoutePaths.ForloebList(routeUserState.userId).url]}
                                    />
                                }

                                {currentUser.HasRole(RoutePaths.Brevskabeloner().roles) &&
                                    <BrevskabelonMenuLink uddannelseslaegeId={routeUserState.userId}/>
                                }

                            </div>
                        </div>
                    </Loading>
                }
            </div>
            {showEmailSlideIn && routeUserState &&
                <EmailSlideInModal actionFinishedCallback={() => setShowEmailSlideIn(false)}
                                   modalId={modalTarget}
                                   users={[{id: routeUserState.userId, email: routeUserState.email, forloebId: forloebSliceState?.forloebState?.id, stillingId: forloebSliceState?.forloebState.stillingId}]}/>
            }

        </React.Fragment>
    );
}
