import {IdentityServerApi} from "../api/identityServer/identityServerApi";
import {UserModel} from "./userModel";
import {userManager} from "../../config/oidcConfig";
import {StorageKeys} from "../../infrastructure/storage/storageKeys";
import {StringToUserRole} from "../../infrastructure/enums/userRole";
import config from "config/config";
import {RolleTitelEnum, StringToUserRoleTitle} from "../../infrastructure/enums/rolleTitelEnum";
import {RolleTitelDto} from "./rolleTitelDto";
import {RolleTitelToRolle} from "../../core/rolleMapper";
import { RoutePaths } from "infrastructure/routes";
import ValidBrowserSessionHandler from "./validBrowserSessionHandler";

export class AccountService {
    public async SignInRedirect() {
        await userManager.signinRedirect()
    }

    public SiginSilent() {
        return userManager.signinSilent();
    }

    public WaitForSignIn() {
        return userManager.signinRedirectCallback();
    }

    public Renew() {
        return userManager.startSilentRenew();
    }

    public async IsUserExpired(): Promise<boolean> {
        let user = await userManager.getUser();
        if (!user || user.expired) {
            user = await userManager.signinSilent();
        }

        return user.expired;
    }

    public async Logout() {
        const user = await userManager.getUser();
        await userManager.signoutRedirect({ 'id_token_hint': user?.id_token });
        localStorage.clear();
        ValidBrowserSessionHandler.InvalidateSessionCookie();
    }

    public async StopImpersonateUser() {
        const currentUser = this.getUser();
        const returnUrl = config.baseUrl.replace(/\/$/, '') + RoutePaths.Impersonate.path; // Removes trailing slash from baseurl if it exists
        const redirectUrl = `${config.idpUrl}account/stopimpersonateUser?id=${currentUser.AuthenticatedUserId}&returnUrl=${returnUrl}`;
        window.location.replace(redirectUrl);
    }

    public async setUser() {
        const user = await userManager.getUser();
        let userModel: UserModel = new UserModel();
        if (!user) {
            userModel.Authenticated = false;
        }
        else {
            userModel.Authenticated = true;
            userModel.Name = user.profile.given_name + " " + user.profile.family_name;
            userModel.UserId = user.profile.sub;
            userModel.AuthorizationId = user.profile.authorization;
            userModel.AuthenticatedAuthorizationId = user.profile.authenticated_authorization_id;
            userModel.AuthenticatedUserId = user.profile.authenticated_user_id;
            userModel.Initialer = user.profile.initials;
            userModel.Email = user.profile.email ? user.profile.email : "";
            userModel.CurrentRolle = StringToUserRole(user.profile.current_role);
            userModel.CurrentRolleTitel = StringToUserRoleTitle(user.profile.current_role_title);
            userModel.RolesAndTitles = (user.profile.allrolesandtitles as string).split(";").map(type => {
                const props = type.split(",");
                return RolleTitelDto.CreateDto(StringToUserRole(props[0]), StringToUserRoleTitle(props[1]));
            });
        }

        // When setting the user, we also set a sesion-based cookie
        // This prevents browser-close from persisting the login-session (see: "getUser()")
        ValidBrowserSessionHandler.CreateValidSessionCookie();

        localStorage.setItem(StorageKeys.user, JSON.stringify(userModel));
    }

    public getUser(): UserModel {
        const userIsInStorage = !!localStorage.getItem(StorageKeys.user);
        ValidBrowserSessionHandler.HandleCleanupIfInvalid(() => localStorage.clear(), userIsInStorage);

        const user = localStorage.getItem(StorageKeys.user);
        if (!user) {
            return UserModel.CreateNotAuthenticatedUser();
        }

        return UserModel.CreateUser(JSON.parse(user as string) as UserModel);
    }

    public updateUser(newUserModelFields: Partial<Pick<UserModel, "Initialer">>) {

        const currentUserModel = this.getUser();

        const updatedUserModel = {
            ...currentUserModel,
            ...newUserModelFields
        }

        localStorage.setItem(StorageKeys.user, JSON.stringify(updatedUserModel));
    }

    public async getAccesToken(): Promise<string> {
        const user = await userManager.getUser();
        return user ? user.access_token : "";
    }

    public updateUserRolleTitel(rolleTitelEnum: RolleTitelEnum) {
        //TODO PKM: Check det her efter...
        // const currentUser = this.getUser();
        // currentUser.CurrentRolleTitel = rolleTitelEnum;
        // currentUser.CurrentRolle = RolleTitelToRolle(rolleTitelEnum);
        // localStorage.setItem(StorageKeys.user, JSON.stringify(currentUser));

        const identityServerApi = new IdentityServerApi();
        identityServerApi.setCurrentRolleAndTitel(this.getUser().UserId, RolleTitelToRolle(rolleTitelEnum), rolleTitelEnum);
    }
}
