import { LaegeWithSimpleForloebResponseModel } from "services/api/forloeb/laegeWithSimpleForloebResponseModel";
import {LaegeWithForloebListResponseModel} from "../../../services/api/forloeb/laegeWithForloebListResponseModel";
import { ForloebModel } from "./forloebModel";
import { ForloebSimpleInfoModel } from "./forloebSimpleInfoModel";

// Used in ForloebOverview:
export class LaegeWithForloebModel {
    public static FromResponseModel(responseModel: LaegeWithForloebListResponseModel): LaegeWithForloebModel {
        const model = new LaegeWithForloebModel();
        model.laegeId = responseModel.laegeId;
        model.laegeNavn = responseModel.laegeNavn;
        model.laegeInitialer = responseModel.laegeInitialer;
        model.laegeEmail = responseModel.laegeEmail;
        model.forloebModels = responseModel.forloebResponseModels.map(ForloebModel.FromResponseModel)

        return model;
    }

    public laegeId: string;
    public laegeNavn: string;
    public laegeInitialer: string;
    public laegeEmail: string;
    public forloebModels: ForloebModel[];
}

// Used in Uddannelseslaeger-list:
export class LaegeWithSimpleForloebModel {
    public static FromResponseModel(responseModel: LaegeWithSimpleForloebResponseModel): LaegeWithSimpleForloebModel {
        const model = new LaegeWithSimpleForloebModel();
        model.laegeId = responseModel.laegeId;
        model.laegeNavn = responseModel.laegeNavn;
        model.laegeInitialer = responseModel.laegeInitialer;
        model.laegeEmail = responseModel.laegeEmail;
        model.forloebModels = responseModel.forloebResponseModels.map(ForloebSimpleInfoModel.FromResponseModel)

        return model;
    }

    public laegeId: string;
    public laegeNavn: string;
    public laegeInitialer: string;
    public laegeEmail: string;
    public forloebModels: ForloebSimpleInfoModel[];
}
