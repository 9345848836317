import React, {useState} from 'react'
import {AccountService} from "../../../services/account/accountService";
import './userMenuBody.scss';
import {EmblaIcon} from '../emblaIcon/emblaIcon';
import {Link} from 'react-router-dom';
import {UserRoleToColorClass} from '../../../infrastructure/enums/userRole';
import {Localizer} from '../../../infrastructure/localization/localizer';
import {RoutePaths} from "../../../infrastructure/routes";
import {closeModal} from "../slideIn/slideIn";
import {laegeuddannelsenUrl} from "../../../infrastructure/redirectConstant";
import {RolleTitelEnum, UserRoleTitleToString} from "../../../infrastructure/enums/rolleTitelEnum";
import CounterCircle from '../notifications/counterCircle/counterCircle';
import useUser from 'core/hooks/useUser';
import NotificationSettingsSlideIn from '../notifications/notificationSettings/notificationSettingsSlideIn';
import config from 'config/config';

export type UserMenuProps = {
    getRoleNotificationCountCallback: (rolleTitel: RolleTitelEnum) => number;
}

const userService = new AccountService();

export function UserMenuBody(props: UserMenuProps) {

    const [ settingsModalIsOpen, setSettingsModalIsOpen ] = useState(false);
    const { currentUser } = useUser();

    const isImpersonating = currentUser.AuthenticatedUserId !== currentUser.UserId;
    const showNotifications = config.showNotifications ?? true;

    const logout = async () => {
        await userService.Logout()
    };

    const stopImpersonation = async () => {
        await userService.StopImpersonateUser()
    };

    const handleOnClickChangeRolleTitel = (rolleTitelEnum: RolleTitelEnum) => {
        userService.updateUserRolleTitel(rolleTitelEnum);
    }

    const rolesToSwitchTo = () => {
        return currentUser.RolesAndTitles.filter((roleTitle) => roleTitle.Rolle !== currentUser.CurrentRolle);
    }

    return (
        <>
            {currentUser &&
                <div>
                    {rolesToSwitchTo().map((role, index) => {
                        return (
                            <a key={index} className={`user-menu-item`} onClick={() => handleOnClickChangeRolleTitel(role.RolleTitel)}>
                                <div className="d-flex">
                                    <div className={"role-switch-icon-wrapper " + UserRoleToColorClass(role.Rolle)}>
                                        <EmblaIcon iconName={"user"}/>
                                    </div>
                                    <p className="sub-menu-link-text margin-left-s">
                                        {Localizer.global_skiftTilRolle(UserRoleTitleToString(role.RolleTitel))}
                                    </p>
                                </div>
                                <CounterCircle size="large" count={props.getRoleNotificationCountCallback(role.RolleTitel)} />
                            </a>
                        )
                    })}

                    <Link to={RoutePaths.UserProfile.path} className="user-menu-item" onClick={() => {closeModal()}}>
                        <p>{Localizer.global_personligeOplysninger()}</p>
                    </Link>

                    {showNotifications &&
                        <a className="user-menu-item" onClick={() => setSettingsModalIsOpen(true)}>
                            <p>{Localizer.notifikationer_NotifikationsIndstillinger()}</p>
                        </a>
                    }

                    <Link to={{pathname: laegeuddannelsenUrl}} target="_blank" className="user-menu-item" onClick={() => {closeModal()}}>
                        <p>{Localizer.global_hjaelp()}</p>
                    </Link>

                    <Link to={RoutePaths.Contact.path} className="user-menu-item" onClick={() => {closeModal()}}>
                        <p>{Localizer.global_kontakt()}</p>
                    </Link>

                    <a className="user-menu-item" onClick={logout}>
                        <p>{Localizer.global_logout()}</p>
                    </a>

                    {/*<InstallPWA/>*/}

                    {isImpersonating &&
                        <a className="user-menu-item" onClick={stopImpersonation}>
                            <p>{Localizer.global_stopImpersonation()}</p>
                        </a>
                    }

                    <NotificationSettingsSlideIn
                        showSlideIn={settingsModalIsOpen}
                        onCloseSlideInCallback={() => setSettingsModalIsOpen(false)}
                    />

                </div>
            }


        </>
    )
}
