import React, {useEffect, useRef, useState} from 'react'
import {useAppSelector} from '../../../app/hooks';
import {kompetenceCardReducer, KompetenceCardState} from '../../../pages/kompetencerPage/cards/kompetenceCardSlice';
import {KlarmeldButton} from './klarmeldButton';
import {GodkendButton} from './godkendButton';
import {AccountService} from '../../../services/account/accountService';

interface StatusButtonProps {
    brugerId?: string;
    buttonHandleCallback?: (godkender: boolean) => void;
}

export function StatusButton(props: StatusButtonProps) {
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const userService = new AccountService();
    const _currentUser = useRef(userService.getUser());

    const [showKlarmeldState, setShowKlarmeldState] = useState<boolean>(false);
    const [showGodkendState, setShowGodkendState] = useState<boolean>(false);

    const ShowKlarmeldButton = (): boolean => {
        return _currentUser.current.IsLaege();
    }
    const ShowGodkendButton = (): boolean => {
        return ((_currentUser.current.IsVejleder() || _currentUser.current.IsUddannelsesansvarlig() || _currentUser.current.IsAdminOrVus()) && (_currentUser.current.UserId !== props.brugerId));
    }

    useEffect(() => {
        setShowKlarmeldState(ShowKlarmeldButton());
        setShowGodkendState(ShowGodkendButton());
    }, [kompetenceCardState.activeLaegekompetenceMeta])

    const render = (
        <>
            {showKlarmeldState &&
                <KlarmeldButton klarmelderCallback={props.buttonHandleCallback}/>}

            {showGodkendState &&
                <GodkendButton godkenderCallback={props.buttonHandleCallback}/>}
        </>
    )

    return <>{render}</>
}
