import React, {useEffect, useState} from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LaegekompetenceMeta} from "../../../core/sharedmodels/kompetence/laegekompetenceMeta";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {
    kompetenceCardReducer,
    KompetenceCardState,
    toggleLaegekompetenceListRefreshPing
} from "../cards/kompetenceCardSlice";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {LaegekompetenceStatusEnum} from "../../../core/sharedmodels/kompetence/laegekompetenceStatusEnum";
import "./kompetenceDetails.scss"
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {ModalType} from "../../../core/components/slideIn/modalType";
import {EditKlarmelding} from "../../klarmeldinger/components/editKlarmelding";
import useUser from "core/hooks/useUser";
import {ModalSize} from "../../../core/components/slideIn/modalSize";
import useScreenResolution from "core/hooks/useScreenResolution";
import ExternalLink from "core/components/link/ExternalLink";

export type KompetenceDetailsProps = {
    laegekompetence? : LaegekompetenceMeta;
}

export function KompetenceDetails({laegekompetence}: KompetenceDetailsProps) {
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const [currentLaegekompetence, setCurrentLaegekompetence] = useState<LaegekompetenceMeta>(new LaegekompetenceMeta());
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const { isMobile } = useScreenResolution();
    const [editKlarmeldingModelOpen, setEditKlarmeldingModelOpen] = useState<boolean>(false);
    const editKlarmeldingModalTarget = "edit-klarmelding-modal";
    const { currentUser } = useUser();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (laegekompetence){
            setCurrentLaegekompetence(laegekompetence);
        }
        else {
            setCurrentLaegekompetence(LaegekompetenceMeta.FromState(kompetenceCardState.activeLaegekompetenceMeta, forloebSliceState.forloebState));
        }
    }, [forloebSliceState.forloebState, kompetenceCardState.activeLaegekompetenceMeta, laegekompetence])

    function kompetenceKlarmeldingEditted() {
        setEditKlarmeldingModelOpen(false);
        dispatch(toggleLaegekompetenceListRefreshPing());
    }

    return (
        <>
            {
                currentLaegekompetence?.kompetenceId?.length > 0 &&
                <div className="margin-m">
                    { currentLaegekompetence.status === LaegekompetenceStatusEnum.Godkendt && currentLaegekompetence.godkendtDato !== undefined &&
                    <div className={`margin-bottom-m ${!isMobile ? "" : "godkend-info-mobile"}`}>
                        <div className="alert alert-success" role="alert">
                            {Localizer.kompetencePage_laegekompetenceGodkendtAf(currentLaegekompetence.godkendtDato?.dateWithoutTimeFormat(true) ?? "", currentLaegekompetence.godkendtAfNavn ?? "", currentLaegekompetence.godkendtAfRolleTitel ?? "", currentLaegekompetence.godkendtAfAuthId ?? "")}
                        </div>
                    </div>
                    }
                    { currentLaegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt && currentLaegekompetence.klarmeldtAt !== undefined &&
                    <div className={`margin-bottom-m ${!isMobile ? "" : "godkend-info-mobile"}`}>
                        <div className="alert alert-warning flex-row flex-space align-items-center" role="alert">
                            {Localizer.kompetencePage_laegekompetenceKlarmeldt() + currentLaegekompetence.klarmeldtAt}
                            { (currentUser.IsLaege() || currentUser.IsAdmin()) &&
                                <div className="align-self-center">
                                    <ActionIcon
                                        addtionalClassNames="flex-align-r"
                                        iconName="edit"
                                        action={() => setEditKlarmeldingModelOpen(true)}/>
                                </div>
                            }
                        </div>
                    </div>
                    }

                    <div className="margin-bottom-m">
                        <h5>{Localizer.global_kompetenceTitle()}</h5>
                        <p>{currentLaegekompetence.fullKompetenceTitle}</p>
                    </div>

                    <div className="margin-bottom-m">
                        <h5>{Localizer.global_konkretisering()}</h5>
                        <p className="kompetence-full-text">{currentLaegekompetence.konkretisering}</p>
                    </div>

                    <div className="margin-bottom-m">
                        <h5>{Localizer.global_teachingStrategy()}</h5>
                        <p className="kompetence-full-text">{currentLaegekompetence.laeringsstrategi}</p>
                    </div>

                    <div className="margin-bottom-m">
                        <h5>{Localizer.global_kompetenceVurderingsMetoder()}</h5>
                        {currentLaegekompetence.kompetenceVurderingsmetoderLink 
                            ? <ExternalLink link={currentLaegekompetence.kompetenceVurderingsmetoderLink} text={currentLaegekompetence.kompetenceVurderingsmetoder} classes="kompetence-full-text"/>
                            : <p className="kompetence-full-text"> {currentLaegekompetence.kompetenceVurderingsmetoder} </p>
                        }
                    </div>

                    <div className="margin-bottom-m">
                        <h5>{Localizer.global_laegeroller()}</h5>
                        {currentLaegekompetence.laegeroller.split(";").map((role) =>
                            <span className="color-primary badge badge-pill badge-default mr-1 padding-xs inherit-pills" key={role}>{role}</span>
                        )}
                    </div>

                    <div>
                        <h5>{Localizer.forloebOverviewPage_fase()}</h5>
                        <p className="kompetence-full-text">{currentLaegekompetence.fase ?? Localizer.kompetencePage_ingenFaseAngivet()}</p>
                    </div>
                </div>
            }


            {editKlarmeldingModelOpen &&
                <SlideIn id={editKlarmeldingModalTarget}
                         title={Localizer.kompetencePage_editKlarmelding()}
                         actionFinishedCallback={() => kompetenceKlarmeldingEditted()}
                         actionOnCloseCallback={() => setEditKlarmeldingModelOpen(false)}
                         bodyContent={<EditKlarmelding modalId={editKlarmeldingModalTarget}/>}
                         defaultOpen={true}
                         modalSize={ModalSize.md}
                         modalType={ModalType.originalModal}
                />
            }
        </>

    );
}
