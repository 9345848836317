import { ObligatoriskDokumentationModel } from "core/sharedmodels/obligatoriskDokumentation/obligatoriskDokumentationModel";
import { ObligatoriskDokumentationTypeEnum } from "core/sharedmodels/obligatoriskDokumentation/obligatoriskDokumentationTypeEnum";
import { RoutePaths } from "infrastructure/routes";
import { Link } from "react-router-dom";
import StpsStep from "pages/sendStpsAnsoegning/stpsStepEnum";
import Tooltip from "../tooltips/Tooltip";
import {Localizer} from "../../../infrastructure/localization/localizer";
import LinkTargets, { SecureLinkReferrerPolicy } from "../link/linkAttributs";

type DokumentationLinkProps = {
    text: string;
    url: string;
}

const DokumentationLink = ({text, url} : DokumentationLinkProps) => {
    return (
        <Link to={url} target={LinkTargets.Blank} rel={SecureLinkReferrerPolicy}>
            {text}
        </Link>
    );
}

type DefaultItemProps = {
    text: string;
    onClick?: () => void;
}

const DefaultItem = ({text, onClick} : DefaultItemProps) => {
    return (
        <p onClick={onClick} className={onClick ? "clickable" : undefined}>
            {text}
        </p>
    );
}

type ObligatoriskDokumentationLinkProps = {
    userId: string;
    forloebId: string;
    dokumentation: ObligatoriskDokumentationModel;
    ansoegningId?: string;
    parentFunction?: () => void;
}

export const ObligatoriskDokumentationItem = ({userId, forloebId, ansoegningId="", dokumentation, parentFunction} : ObligatoriskDokumentationLinkProps) => {

    const text = dokumentation.obligatoriskDokumentationNavn;

    switch(dokumentation.obligatoriskDokumentationType) {
        case ObligatoriskDokumentationTypeEnum.AlmenMedicin:
        case ObligatoriskDokumentationTypeEnum.KurserGodkendt:
        case ObligatoriskDokumentationTypeEnum.KurserFaellesInternMedicin:
        case ObligatoriskDokumentationTypeEnum.KurserGrundkursusIPsykoterapi:
        case ObligatoriskDokumentationTypeEnum.KurserKliniskBasisuddannelse:
        case ObligatoriskDokumentationTypeEnum.KurserSpecialespecifikkeIntrouddannelsen:
        case ObligatoriskDokumentationTypeEnum.KurserSpecialespecifikke:
        case ObligatoriskDokumentationTypeEnum.KurserHoveduddannelse:
        case ObligatoriskDokumentationTypeEnum.KurserForskningstraening:
        case ObligatoriskDokumentationTypeEnum.KurserIntroduktionsuddannelsen:
            return <DokumentationLink url={RoutePaths.Kurser([userId]).url} text={text}/>;

        case ObligatoriskDokumentationTypeEnum.KompetencerGodkendt:
            return <DokumentationLink url={RoutePaths.Kompetencer({uddannelseslaege: userId}).url} text={text}/>;

        case ObligatoriskDokumentationTypeEnum.Attestation: {
            return <DokumentationLink url={RoutePaths.Forloeb([userId, forloebId], true).url} text={text} />
        }

        case ObligatoriskDokumentationTypeEnum.AnsaettelseOgUddannelse: {
            if (!ansoegningId) {
                return <Tooltip title={Localizer.global_pressForberedDokumentationToUpload()}>{text}</Tooltip>;
            }

            const routePath = RoutePaths.SendStpsDocumentationPage({
                uddannelseslaege: userId,
                ansoegning: ansoegningId,
                step: StpsStep.AnsaettelseUddannelsesaftaler
            });

            return <DokumentationLink url={routePath.url} text={text}/>;
        }

        case ObligatoriskDokumentationTypeEnum.LaegeligBeskaeftigelse: {
            if (!ansoegningId) {
                return <Tooltip title={Localizer.global_pressForberedDokumentationToFill()}>{text}</Tooltip>;
            }

            const routePath = RoutePaths.SendStpsDocumentationPage({
                uddannelseslaege: userId,
                ansoegning: ansoegningId,
                step: StpsStep.Beskaeftigelse
            });

            return <DokumentationLink url={routePath.url} text={text}/>;
        }

        default:
           return <DefaultItem text={text} />;
     }
}
