import React, {useState} from 'react'
import {KurserList} from "./kurserList";
import {ContextButtonInfo, ContextButtonMenu} from "../../../core/components/contextMenu/contextButtonMenu";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {AccountService} from "../../../services/account/accountService";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {CreateLaegekursus} from "./createLaegekursus";
import {LaegekursusGruppeModel} from "../../../services/api/laegekursus/laegekursusGruppeModel";
import {
    GetLaegekursusGruppeTranslation,
    LaegekursusGruppeTypeEnum
} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {LogbogExportApi} from "../../../services/api/logbogExport/logbogExportApi";
import {FileDownloadHandler} from "../../../services/api/logbogFile/fileDownloadHandler";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {TildelSamletGodkendelseButton} from "../../../core/componentsPage/laegekursus/tildelSamletGodkendelseButton";
import {SamletGodkendelseInfo} from "./samletGodkendelseInfo";
import {ModalAcceptType, ModalSubmitMessage} from "../../../core/components/modalMessage/modalSubmitMessage";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {LogbogLaegekursusGruppeApi} from "../../../services/api/laegekursusGruppe/logbogLaegekursusGruppeApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {GivSamletGodkendelse} from "./givSamletGodkendelse";
import {NotificationModule} from "ditmer-embla";
import {EmblaIcons} from "../../../core/emblaIcons";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import Tooltip from "../../../core/components/tooltips/Tooltip";
import useUser from "../../../core/hooks/useUser";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

type KursusGruppeCardProps = {
    kursusGruppe: LaegekursusGruppeModel;
    kurserUpdatedCallback: () => void;
    forloebId?: string;
    brugerMaalbeskrivelseId: string;
    additionalCardClasses?: string;
}

const laegekursusGruppeApi = new LogbogLaegekursusGruppeApi(new HttpClientService(), new AccountService())

export function LaegekursusGruppeCard({kursusGruppe, kurserUpdatedCallback, ...props}: KursusGruppeCardProps) {
    const dispatch = useAppDispatch();
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const { currentUser } = useUser();

    const [givSamletGodkendelseModalOpen, setGivSamletGodkendelseModalOpen] = useState(false);
    const [createLaegeKursusModalOpen, setCreateLaegeKursusModalOpen] = useState(false);
    const exportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const [seSamletGodkendelseModalOpen, setSeSamletGodkendelseModalOpen] = useState(false);
    const [showFjernSamletGodkendelseModalOpen, setShowFjernSamletGodkendelseModalOpen] = useState<boolean>(false);
    const modalLaegekursusCreateTarget = "kursus-create-modal";
    const modalSamletGodkendelseInfoTarget = "samlet-godkendelse-info-modal";
    const fjernGodkendelseModalTarget = "fjern-samlet-godkendelse";
    const givSamletGodkendelseModalTarget = "giv-samlet-godkendelse";

    const kursusGruppeTypeIsAlmenMedicin = kursusGruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.AlmenMedicin;

    const showAddButton = !kursusGruppe.harSamletGodkendelse
        && ((kursusGruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.FaellesInternMedicin || kursusGruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.Specialespecifikke)
            && (currentUser.IsLaege() || currentUser.IsKursusleder() || currentUser.IsAdminOrVus()));

    const showNeedsApprovement = (kursusGruppe.isGroupWithSamletGodkendelse() || kursusGruppeTypeIsAlmenMedicin)
        && !kursusGruppe.harSamletGodkendelse
        && (kursusGruppe.kurser.length > 0 || kursusGruppeTypeIsAlmenMedicin);

    const brugerMaalbeskrivelseTilhoerendeForloeb = props.brugerMaalbeskrivelseId ?? forloebSliceState.forloebState.brugerMaalbeskrivelseId;

    const getLaegeKursusPdf = (): Promise<void> => {
        return exportApi.getPdfKursus(kursusGruppe.id, forloebSliceState.forloebState.brugerId, brugerMaalbeskrivelseTilhoerendeForloeb)
    }
    const showNeedsApprovementButton = showNeedsApprovement && (!kursusGruppe.kurser.some(k => k.status !== LaegekursusStatusEnum.Godkendt) || kursusGruppeTypeIsAlmenMedicin) && currentUser.UserId !== forloebSliceState.forloebState.brugerId;
    const showNeedsApprovementPill = !showNeedsApprovementButton && showNeedsApprovement && !kursusGruppe.harSamletGodkendelse && !kursusGruppeTypeIsAlmenMedicin;

    function givSamletGodkendelse() {
        setGivSamletGodkendelseModalOpen(true)
    }

    function createContextMenuButtons(): ContextButtonInfo[] {
        const showGetPdfButton = !currentUser.IsKonsulentForLaegeligVidereuddannelse();
        const showGivSamletGodkendelseButton =
            (currentUser.IsAdminOrVus())
            && !kursusGruppe.godkendtPaaAndetGrundlag
            && !kursusGruppe.harSamletGodkendelse
            && kursusGruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.Forskningstraening;

        return [
            ...showGetPdfButton ? [new ContextButtonInfo(getLaegeKursusPdf, Localizer.GetPdf())] : [],
            ...showGivSamletGodkendelseButton ? [new ContextButtonInfo(givSamletGodkendelse, Localizer.kompetencePage_givDispensation(), undefined, "", currentUser.AuthorizationId === undefined, Localizer.global_manglerAuthorisationsId())] : []
        ]
    }

    function createKursus() {
        setCreateLaegeKursusModalOpen(true);
    }

    const fjernGodkendelse = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: modalSamletGodkendelseInfoTarget}))
        const godkendelseFjernetId = await laegekursusGruppeApi.fjernSamletGodkendelseLaegekursus(kursusGruppe.id);
        if (godkendelseFjernetId) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: modalSamletGodkendelseInfoTarget}))
            NotificationModule.showSuccess(Localizer.kursusPage_godkendelseFjernet(), "");
            kurserUpdatedCallback();
        } else {
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: modalSamletGodkendelseInfoTarget}))
        }
    }

    function modalSubmitCancel() {
        setShowFjernSamletGodkendelseModalOpen(false);
        setSeSamletGodkendelseModalOpen(true)
    }

    const renderFjernSamletGodkendelseText = () => {
        return <>
            <p>{Localizer.kursusPage_areYourSureFjernSamletGodkendelse(GetLaegekursusGruppeTranslation(kursusGruppe.kursusGruppeType).toLowerCase())}</p>
            {kursusGruppe.godkendtPaaAndetGrundlag &&
                <p>{Localizer.kursusPage_FjernMeritBesked()}</p>
            }
        </>
    }

    function samletGodkendelseDisabled(){
        const isForskningstraeningGruppe = kursusGruppe.kursusGruppeType === LaegekursusGruppeTypeEnum.Forskningstraening;

        if(!isForskningstraeningGruppe)
            return (!currentUser.IsAdminOrVus() && !currentUser.hasRolleTitel(RolleTitelEnum.Hovedkursusleder));
        else
            return false;
    }

    const contextMenuButtons = createContextMenuButtons();

    return (
        <div className={`card ${props.additionalCardClasses ?? ""}`}>
            <div className="card-header">
                <div className="flex-space flex-column flex-grow">
                    <div className="flex-align-c">
                        <h3 className="card-title">{GetLaegekursusGruppeTranslation(kursusGruppe.kursusGruppeType)}
                            {IsMobile &&
                                <Tooltip title={Localizer.kursusPage_laegekursusBeskedOmDobbeltKurser()}>
                                    <EmblaIcon iconName={EmblaIcons.Warning}
                                               wrapInSpan={false}
                                               additionalClasses={"subtle margin-left-s"}/>
                                </Tooltip>
                            }
                            {!IsMobile && kursusGruppe.kursusGruppeType == LaegekursusGruppeTypeEnum.Specialespecifikke &&
                                <Tooltip title={Localizer.kursusPage_laegekursusSamletGodkendelseHovedkursusleder()}>
                                    <EmblaIcon iconName={EmblaIcons.Warning}
                                               wrapInSpan={false}
                                               additionalClasses={"subtle margin-left-s"}/>
                                </Tooltip>
                            }
                        </h3>
                        {showAddButton &&
                        <button type="button" className="btn btn-default rounded-circle btn-icon margin-right-s"
                                aria-label="Primary"
                                onClick={() => createKursus()}>
                            <EmblaIcon iconName="plus"/>
                        </button>
                        }
                        {contextMenuButtons.length > 0 &&
                            <ContextButtonMenu buttons={contextMenuButtons} border={true}/>
                        }

                    </div>
                    {kursusGruppe.harSamletGodkendelse &&
                    <div className="flex-column flex-grow margin-bottom-m">
                        <button className="btn btn-success"
                                onClick={() => setSeSamletGodkendelseModalOpen(true)}>
                            <EmblaIcon additionalClasses={"small"} iconName={"success"}/>
                            {Localizer.kursusPage_seSamletGodkendelse()}
                        </button>
                    </div>
                    }
                    {showNeedsApprovementPill &&
                    <div className="flex-column flex-grow margin-bottom-m">
                        <span className="badge badge-pill badge-default badge-lg">
                            {Localizer.kursusPage_manglerSamletGodkendelse()}
                        </span>
                    </div>
                    }
                    {showNeedsApprovementButton &&
                    <div className="flex-column flex-grow margin-bottom-m">
                        <TildelSamletGodkendelseButton
                            laegekursusKategori={GetLaegekursusGruppeTranslation(kursusGruppe.kursusGruppeType)}
                            kursusGruppeId={kursusGruppe.id}
                            isDisabled={samletGodkendelseDisabled()}
                            kursusOrKursusGruppeUpdatedCallback={kurserUpdatedCallback}/>
                    </div>
                    }
                </div>
            </div>
            {kursusGruppe.kurser.length > 0 && !kursusGruppeTypeIsAlmenMedicin &&
                <div className="card-body padding-0">
                    <KurserList kurser={kursusGruppe.kurser}
                                forloebId={props.forloebId}
                                brugerMaalbeskrivelseId={brugerMaalbeskrivelseTilhoerendeForloeb}
                                kurserUpdatedCallback={() => kurserUpdatedCallback()}
                                laegekursusGruppeGodkendt={kursusGruppe.harSamletGodkendelse}
                                gruppeKategoriTekst={GetLaegekursusGruppeTranslation(kursusGruppe.kursusGruppeType)}
                    />
                </div>
            }

            { kursusGruppeTypeIsAlmenMedicin &&
                <div className="card-body padding-0">
                    <div className="card-list">
                        <div className="flex-column ">
                            <p>{Localizer.kursusPage_AlmenMedicinKurserBeskrivelseEt()}</p>
                            <p>{Localizer.kursusPage_AlmenMedicinKurserBeskrivelseTo()}
                                <a href="https://www.speam.dk/" target="_blank" rel="noreferrer">
                                    <u>{Localizer.global_SPEAM()}</u>
                                </a>
                            </p>
                            <p>{Localizer.kursusPage_AlmenMedicinKurserBeskrivelseTre()}</p>
                        </div>
                    </div>
                </div>
            }

            {kursusGruppe.kurser.length === 0 && !kursusGruppeTypeIsAlmenMedicin &&
            <div className="card-body padding-0">
                <div className="card-list">
                    <div className="flex-column ">
                        <div className="flex-align-c ">
                            <h5>{Localizer.kursusPage_ingenKurserOprettet()}</h5>
                        </div>
                        {showAddButton &&
                        <div className="flex-align-c subtle margin-top-s">
                            <p className="subtle small">{Localizer.kursusPage_opretKurserVedAtKlikkePaaOpretKnappen()}</p>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }

            {createLaegeKursusModalOpen &&
            <SlideIn
                id={modalLaegekursusCreateTarget}
                title={Localizer.kursusPage_createKursus()}
                actionText={Localizer.kursusPage_creatingKursus()}
                actionFinishedCallback={() => kurserUpdatedCallback()}
                actionOnCloseCallback={() => setCreateLaegeKursusModalOpen(false)}
                bodyContent={<CreateLaegekursus brugerId={kursusGruppe.brugerId}
                                                modalId={modalLaegekursusCreateTarget}
                                                kursusGruppeId={kursusGruppe.id}
                                                laegekursusGruppeType={kursusGruppe.kursusGruppeType}
                />}
                defaultOpen={true}
            />
            }
            {seSamletGodkendelseModalOpen && kursusGruppe.godkendelsesInfo !== undefined &&
            <SlideIn
                id={modalLaegekursusCreateTarget}
                title={Localizer.kursusPage_kursusSamletGodkendelseTitelTekst(GetLaegekursusGruppeTranslation(kursusGruppe.kursusGruppeType))}
                actionText={Localizer.kursusPage_fjernerGodkendelse()}
                actionFinishedCallback={() => kurserUpdatedCallback()}
                actionOnCloseCallback={() => setSeSamletGodkendelseModalOpen(false)}
                bodyContent={<SamletGodkendelseInfo areYouSureModalId={fjernGodkendelseModalTarget + kursusGruppe.id}
                                                    godkendelsesInfo={kursusGruppe.godkendelsesInfo}
                                                    laegekursusGruppeId={kursusGruppe.id}
                                                    godkendtPaaAndetGrundlag={kursusGruppe.godkendtPaaAndetGrundlag}
                                                    laegekursusGruppeKategori={kursusGruppe.kursusGruppeType}
                                                    openAreYouSureModal={() => setShowFjernSamletGodkendelseModalOpen(true)}/>}
                defaultOpen={true}
            />
            }
            {showFjernSamletGodkendelseModalOpen &&
            <ModalSubmitMessage modalId={fjernGodkendelseModalTarget + kursusGruppe.id}
                                title={Localizer.kursusPage_fjernGodkendelse()}
                                description={renderFjernSamletGodkendelseText()}
                                cancelAction={() => modalSubmitCancel()}
                                acceptAction={() => fjernGodkendelse()}
                                modalAcceptType={ModalAcceptType.danger}
                                acceptButtonText={Localizer.kursusPage_fjernGodkendelse()}
            />
            }
            {givSamletGodkendelseModalOpen &&
            <SlideIn
                id={givSamletGodkendelseModalTarget}
                title={Localizer.kursusPage_samletGodkendelsePaaAndetGrundlag()}
                actionText={Localizer.kursusPage_tildelerSamletGodkendelse()}
                actionFinishedCallback={() => kurserUpdatedCallback()}
                actionOnCloseCallback={() => setGivSamletGodkendelseModalOpen(false)}
                bodyContent={<GivSamletGodkendelse modalId={givSamletGodkendelseModalTarget}
                                                   laegekursusGruppe={kursusGruppe}
                                                   kurserUpdatedCallback={() => kurserUpdatedCallback()}
                />}
                defaultOpen={true}
            />
            }
        </div>

    );
}
