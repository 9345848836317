import {useMediaQuery} from "react-responsive";
import {PublicTopMenu} from "./topMenu/publicTopMenu";
import {mobileMaxWidthQuery, ShouldBeFullscreen} from "./responsive";
import {Route} from "react-router-dom";
import {TitleBar} from "./titleBar/titleBar";

export const PublicRoute = ({children, isAuthenticated, showTitleBar = false, ...rest}: { [p: string]: any, children: any, isAuthenticated: boolean, showTitleBar?: boolean }) => {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    const path = {...rest}.path as string;

    const fullscreen = ShouldBeFullscreen(path);

    return (
        <>
            <Route
                {...rest}
                render={({location}) =>
                    <div className={"body-container"}>
                        <PublicTopMenu isAuthenticated={isAuthenticated}/>
                        <div className="page-content">
                            <div className="main-content">
                                {showTitleBar &&
                                    <TitleBar/>
                                }
                                <div
                                    className={"body-content" + (fullscreen ? " fullscreen" : "") + (IsMobile ? " bottommenuShown" : "")}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                }

            />
        </>
    )
}

