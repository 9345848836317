import {Localizer} from "../../../infrastructure/localization/localizer";
import React, {ReactFragment, useEffect, useState} from "react";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {Loading} from "../loading/loading";
import {ForloebOverviewConstants} from "./forloebOverviewConstants";
import {initAllTooltips} from "../../utils";
import {ForloebOverviewFilterModel} from "./ForloebOverviewFilterModel";
import {FilterParamsModel} from "../../sharedmodels/filter/FilterParamsModel";
import {PaginationForOverview} from "./stillingViewComponents/paginationForOverview";
import {useAppSelector} from "../../../app/hooks";
import {forloebOverviewFiltersReducer, ForloebOverviewFiltersState} from "./forloebOverviewFiltersSlice";
import {
    calculateAndSetYears,
    calculateForloebColumnNumber,
    cloneFilterFromState,
    overlappingCount
} from "./forloebOverviewUtils/forloebOverviewUtils";
import {LogbogUserInfoApi} from "../../../services/api/logbogUser/logbogUserInfoApi";
import {LaegeWithForloebModel} from "../../sharedmodels/forloeb/laegeWithForloebModel";
import {excludeClickEventIf} from "../../helpers/classNameHelper";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../userProfileAvatar/userProfileAvatarModel";
import {FravaerskodeEnum} from "../../sharedmodels/forloeb/fravaerskoderEnum";
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {ForloebOverviewNoFilterResults} from "./forloebOverviewNoFilterResults";
import {
    getDateFilterInput,
    getFilterEndDate,
    getFilterStartDate,
    getScrollForloebDateChartInput
} from "./ForloebOverviewDateFilterModel";
import {resetScrollToYearInForloebOverviewChart, scrollToYearInForloebOverviewChart} from "./scrollHelper";
import Overview from "./OverviewComponent/Overview";
import ForloebOverviewcontainer from "./forloebOverviewContainer";
import Tooltip from "../tooltips/Tooltip";
import {calculatePillColPaddings} from "./OverviewComponent/StyleVariables";
import dayjs from "dayjs";
import {HorizontalLineSpacer} from "./OverviewComponent/horizontalLineSpacer";
import useScreenResolution from "core/hooks/useScreenResolution";
import { HorizontalLine } from "./OverviewComponent/horizontalLine";
import { toDate } from "index";
import { HoverLine } from "./OverviewComponent/hoverLine";
import classNames from "classnames";

export interface ForloebOverviewBrugereViewProps {
    filter: ForloebOverviewFilterModel;
    filterParams: FilterParamsModel;
    handlePaginationCallback: (page: number) => void;
    setPaginationCountCallback: (filteredRecords: number, recordsTotal: number, xOfYisLoading: boolean) => React.ReactFragment;
    handleUddannelseslaegeClicked: (uddannelseslaegeId: string) => void;
    handleOnClickForloeb: (forloebId: string) => void;
    scrollContainerRef: React.RefObject<HTMLDivElement>,
    pageSize: number,
    overlappendeVisningState?: boolean,
    visHorizontaleLinjerState?: boolean;
}

const userApi = new LogbogUserInfoApi(new HttpClientService(), new AccountService());

export function ForloebOverviewBrugereView({
       filter,
       filterParams,
       handlePaginationCallback,
       setPaginationCountCallback,
       handleUddannelseslaegeClicked,
       handleOnClickForloeb,
       scrollContainerRef,
       pageSize,
       overlappendeVisningState = true,
       visHorizontaleLinjerState = true
   }: ForloebOverviewBrugereViewProps) {

    const forloebOverviewFilterSliceState = useAppSelector(forloebOverviewFiltersReducer) as ForloebOverviewFiltersState;
    const { isMobile } = useScreenResolution();

    //constants
    const months = ForloebOverviewConstants.months;
    const laegeRowStart = ForloebOverviewConstants.laegeRowStart;

    //states
    const [isLoading, setIsLoading] = useState(true)
    const [xOfYisLoading, setXOfYisLoading] = useState(true)
    const [laegerWithForloebState, setLaegerWithForloebState] = useState([] as LaegeWithForloebModel[]);
    const [filteredRecords, setFilteredRecords] = useState<number>(0);
    const [recordsTotal, setRecordsTotal] = useState<number>(0);
    const [years, setYears] = useState(ForloebOverviewConstants.years as number[]);
    const [rowCountState, setRowCountState] = useState<number>(1);

    useEffect(() => {
        initAllTooltips(isMobile);
        const getScrollInputModel = () => {
            const dateFilterInput = getDateFilterInput(forloebOverviewFilterSliceState);
            return getScrollForloebDateChartInput(dateFilterInput, years[0], scrollContainerRef);
        }
        const inputModel = getScrollInputModel();
        if(!isLoading) {
            scrollToYearInForloebOverviewChart(inputModel);
        } else {
            resetScrollToYearInForloebOverviewChart(inputModel);
        }

    }, [isMobile, forloebOverviewFilterSliceState, isLoading, scrollContainerRef, years]);

    useEffect(() => {
        setIsLoading(true);
        setRowCountState(1);
        const filterClone = cloneFilterFromState(forloebOverviewFilterSliceState, filter);
        const fetch = async () => {
            const result = await userApi.getLaegerWithForloebAsyncWithCancellation(filterClone, filterParams);
            setLaegerWithForloebState(result.data);

            const startYear = filterClone.year;

            if (startYear !== 0) {
                const calculatedYears = await calculateAndSetYears(Number(startYear), result.data);
                setYears(calculatedYears);
            }

            setIsLoading(false);
        }
        fetch();

        const fetchXofY = async () => {
            setXOfYisLoading(true);
            const XofY = await userApi.getXofYLaegerWithForloeb(filterClone, filterParams);
            setFilteredRecords(XofY.recordsFiltered);
            setRecordsTotal(XofY.recordsTotal);
        }
        fetchXofY().then(() => setXOfYisLoading(false));
    }, [filterParams, setIsLoading, forloebOverviewFilterSliceState, filter])

    useEffect(() => {
        function getRowCount() {
            if (overlappendeVisningState) {
                const amountOfOverlappingForloeb = overlappingCount(laegerWithForloebState.flatMap(x => x.forloebModels).sort((a, b) => a.afdelingName < b.afdelingName ? 1 : -1));
                return amountOfOverlappingForloeb + laegerWithForloebState.length + 1;
            } else {
                return pageSize > laegerWithForloebState.length ? laegerWithForloebState.length + 1 : pageSize +1;
            }
        }

        setRowCountState(getRowCount())
    },[laegerWithForloebState, overlappendeVisningState, pageSize])

    const renderLaegerWithForloeb = () => {
        const rows: JSX.Element[] = [];
        const alreadyRenderedLaegeIndexes: number[] = [];
        const rowIndexes: number[] = [];

        for (let counter = 0; counter < rowCountState; counter++) {
            const laegeRowIndex = counter + laegeRowStart;

            const amountOfOverlappingForloeb = (overlappendeVisningState && counter > 0)
                ? overlappingCount(laegerWithForloebState.slice(0, counter).flatMap(x => x.forloebModels) ?? [])
                : 0;

            if (laegerWithForloebState.length > counter) {
                const laege = laegerWithForloebState[counter];

                rows.push(
                    <>
                        {renderForloeb(laege, laegeRowIndex + amountOfOverlappingForloeb)}
                        {renderLaege(laegeRowIndex + amountOfOverlappingForloeb, laege)}
                        {visHorizontaleLinjerState &&
                            <HorizontalLine
                                key={`laege-${laegeRowIndex + amountOfOverlappingForloeb}-${laege.laegeId}`}
                                rowIndex={laegeRowIndex + amountOfOverlappingForloeb}
                                columnStart={ForloebOverviewConstants.timelineColumnStart}
                                columnEnd={ForloebOverviewConstants.months.length * toDate(forloebOverviewFilterSliceState.periodTo).getFullYear()}
                            />
                        }
                    </>
                );

                alreadyRenderedLaegeIndexes.push(laegeRowIndex + amountOfOverlappingForloeb);
            }
            rowIndexes.push(laegeRowIndex)
        }

        if (overlappendeVisningState) {
        //render empty laegebackground
            const missingRenderedLaegeIndexes = rowIndexes.filter((num) => !alreadyRenderedLaegeIndexes.includes(num));
            for (let missingRenderedLaegeIndex of missingRenderedLaegeIndexes) {
                rows.push(
                    <>
                        {renderLaegeBackground(missingRenderedLaegeIndex)}
                    </>
                );
            }
        }

        return rows;
    }

    const renderLaege = (laegeRowIndex: number, laege: LaegeWithForloebModel) => {
        const clickIsEnabled = laege?.laegeId;
        return (
            <div
                className={classNames("laege-profile", excludeClickEventIf(!clickIsEnabled), visHorizontaleLinjerState && "border-top")}
                onClick={() => clickIsEnabled && handleUddannelseslaegeClicked(laege.laegeId)}
                style={{"--laege-row-index": laegeRowIndex} as React.CSSProperties}
            >
                <div className="laege-avatar">
                    {laege.laegeId &&
                        <>
                            <UserProfileAvatar size={UserProfileAvatarSizeEnum.extraSmall}
                                               userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                                               userProfileAvatarModel={new UserProfileAvatarModel(laege.laegeNavn, laege.laegeInitialer, true)}
                            />
                        </>
                    }
                </div>
            </div>
        );
    }

    const renderLaegeBackground = (laegeRowIndex: number) => {
        return (
            <div className={`laege-profile`}
                style={{"--laege-row-index": laegeRowIndex} as React.CSSProperties}>
            </div>
        );
    }

    function anyOverlappingDates(current: ForloebModel, prev: ForloebModel): boolean {
        return current.startDate <= prev.endDate;
    }

    const renderForloeb = (laege: LaegeWithForloebModel, laegeRowIndex: number) => {
        const laegeForloebSortedByEndDate = laege.forloebModels
            .sort((a, b) => a.afdelingName < b.afdelingName ? 1 : -1)
            .sort((a, b) => a.endDate > b.endDate ? 1 : -1);
        return (
            <>
                {laegeForloebSortedByEndDate?.map((forloeb, index) => {
                    if(forloeb.fravaerskodeEnum === FravaerskodeEnum.FratraadtUddannelsesforloeb){
                        return (
                            <></>
                        )
                    }

                    const startMonth = forloeb.startDate.getMonth() as number;
                    const startYear = forloeb.startDate.getFullYear() as number;
                    const startColumn = calculateForloebColumnNumber(startMonth, startYear, years);

                    const shouldIncrementRow = index > 0 && anyOverlappingDates(forloeb, laege.forloebModels[index-1]) && overlappendeVisningState;
                    if (shouldIncrementRow)
                        laegeRowIndex++;

                    const endMonth = forloeb.endDate.getMonth() as number;
                    const endYear = forloeb.endDate.getFullYear() as number;
                    const endColumn = calculateForloebColumnNumber(endMonth, endYear, years, 1);

                    const clickIsEnabled = !forloeb.erFravaer;

                    const {left, right} = calculatePillColPaddings(dayjs(forloeb.endDate), dayjs(forloeb.startDate));

                    const style = {
                        "--laege-row-index": laegeRowIndex,
                        "--forloeb-column-start": startColumn,
                        "--forloeb-column-end": endColumn,
                        "--forloeb-column-l-pad": left,
                        "--forloeb-column-r-pad": right,
                    } as React.CSSProperties;

                    return (
                        <>
                            {visHorizontaleLinjerState &&
                                <HoverLine
                                    columnStart={ForloebOverviewConstants.timelineColumnStart}
                                    columnEnd={ForloebOverviewConstants.months.length * toDate(forloebOverviewFilterSliceState.periodTo).getFullYear()}
                                    rowColor={ForloebOverviewConstants.uddannelsesstedHorizontalLinesBackgroundColor}
                                    rowIndex={laegeRowIndex}
                                />
                            }

                            <div
                                key={forloeb.id}
                                className={`laege-forloeb ${excludeClickEventIf(!clickIsEnabled)}`}
                                onClick={() => clickIsEnabled && handleOnClickForloeb(forloeb.id)}
                                style={style}
                            >
                                <Tooltip
                                    className={"laege-afdeling text-truncate padding-left-s " + (forloeb.manueltAfsluttet ? "text-warning" : "") + (forloeb.erFravaer ? "color-fravaer-forloeb" : "") }
                                    title={forloeb.ToDataString()}
                                    tipClassName='forloeb-or-laege-tooltip-tip'>
                                    {forloeb.GetStedInfo()}
                                </Tooltip>
                            </div>
                        </>
                    )
                })}
            </>
        );
    }

    return (
        <ForloebOverviewcontainer>
            <div style={
                {
                    "--total-month-count": years.length * months.length,
                    "--month-count": months.length,
                    "--total-row-count": rowCountState
                } as React.CSSProperties}>
                <div className="forloeb-chart" ref={scrollContainerRef}>
                    <div className="laege-header flex-column align-items-start">
                        <h5>{Localizer.global_uddannelseslaeger()}</h5>
                        <div>{setPaginationCountCallback(filteredRecords, recordsTotal, xOfYisLoading)}</div>
                    </div>
                    {isLoading &&
                        <>
                            <div className={"forloeb-list-empty-state"}>
                                <Loading isLoading={isLoading} text={Localizer.forloebOverviewPage_getForloeb()}>
                                </Loading>
                            </div>
                            <HorizontalLineSpacer />
                            <PaginationForOverview filterParams={filterParams}
                                                   maxRowIndex={rowCountState}
                                                   filteredRecords={filteredRecords}
                                                   handlePaginationCallback={(page: number) => handlePaginationCallback(page)}/>
                        </>
                    }

                    {!isLoading &&
                        <React.Fragment>
                            {laegerWithForloebState.length > 0
                                ?
                                <Overview>
                                    <Overview.YearsHeader years={years}/>
                                    <Overview.VerticalLines
                                        years={years}
                                        rowSize={rowCountState}
                                        startDate={getFilterStartDate(getDateFilterInput(forloebOverviewFilterSliceState))}
                                        endDate={getFilterEndDate(getDateFilterInput(forloebOverviewFilterSliceState))}
                                        dateFilterType={forloebOverviewFilterSliceState.dateFilterType}
                                    />
                                    <Overview.HorizontalLines>
                                        {renderLaegerWithForloeb()}
                                    </Overview.HorizontalLines>
                                </Overview>
                                :
                                <ForloebOverviewNoFilterResults />
                            }
                            <PaginationForOverview filterParams={filterParams}
                                                   maxRowIndex={rowCountState}
                                                   filteredRecords={filteredRecords}
                                                   handlePaginationCallback={(page: number) => handlePaginationCallback(page)}/>
                        </React.Fragment>
                    }
                </div>
            </div>
        </ForloebOverviewcontainer>
    )
}
