/* eslint-disable no-extend-native */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './print.scss';
import App from './App';
import {persistor, store} from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import dayjs, { UnitTypeLong, UnitTypeLongPlural } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import {
    DateAndTimePostFormat, DateFormatWithoutZoneAndTime, DatePostFormat,
    DateTimeIsoFormatWithoutZone,
    DateWithoutTimeFormat, DateWithTimeFormat,
    TimeFormat
} from "./infrastructure/date/dateFormats";
import {PersistGate} from "redux-persist/integration/react";
import { v4 as uuid } from 'uuid';
import da from "dayjs/locale/da";
import ConfigLoader from 'config/ConfigLoader';

// Load global locale (Only danish for now)
// When starting to use Localization in project, find a work around for setting locale based upon currentUser.
dayjs.locale(da);

// Global Initialization of DayJS independent modules - https://day.js.org/docs/en/plugin/plugin
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
dayjs.extend(isBetween);

declare global {
    interface Date {
        dateAndTimeToApiPostFormat(): string;
        dateToApiPostFormat(): string;
        dateWithoutTimeFormat(convertToClientTime: boolean): string;
        dateWithTimeFormat(): string;
        timeFormat(convertToClientTime: boolean): string;
        getDayDiff(endDate: Date): number;
        getUtcWithoutTime(): Date;
        toIsoDateTimeString(): string;
        toIsoDateTimeUtcString(): string;
        addMonths(months: number): Date;
        isLastDayInMonth(): boolean;
        getEndOfMonth(): Date;
        subtractDays(days: number): Date;
    }

    interface Moment {
        datetimeFromApi(): Date;
    }
    interface Array<T> {
        /** Natural sorting orders text so that numbers in strings are treated as actual numbers. eg. "KV 2" will come before "KV 10"*/
        sortNaturallyBy(propSelector: (model: T) => string) : Array<T>;
        /** Sorts an array based upon property in descending/ascending order. desc = true (default) */
        sortBy(propSelector: (model: T) => any, desc?: boolean) : Array<T>;
        SortByThenBy(propertya: string, propertyb: string, desc: boolean) : void;
        complexSortByThenBy(primaryPropSelector: (model: T) => any, isPrimaryDesc: boolean, secondaryPropSelector: (model: T) => any, isSecondaryDesc: boolean) : Array<T>;
        selectMany<TOut>(selectListFn: (t: T) => Array<TOut>): Array<TOut>;
        sum(): number;
        first<TOut>(): TOut;
        isSame<T>(arr: T[]): boolean;
        filterDistinct(): Array<T>;
    }

    interface String {
        toInt(): number;
        appendCounter: (amount: number) => string;
        undefinedIfEmpty: () => string | undefined;
    }

    interface Dayjs {
        isWithin(start: Dayjs, end: Dayjs): boolean;
    }

}

const MonthUnitType: UnitTypeLong = 'month';
export const DayUnitType: UnitTypeLong = 'day';
const MonthsUnitType: UnitTypeLongPlural = 'months';
const DaysUnitType: UnitTypeLongPlural = 'days';

Array.prototype.sum = function() {
    return this.reduce((x, y) => { return x + y}, 0);
}

Array.prototype.first = function() {
    return this[0];
}

Array.prototype.isSame = function(arr) {
    return this.length !== arr.length ? false : this.every((value, index) => value === arr[index]);
}

Array.prototype.selectMany = function(x) {
    return this.map(x).reduce((a: any, b: any) => a ? a.concat(b) : [],  []);
};

Array.prototype.filterDistinct = function() {
    return this.filter((value, index, list) => list.indexOf(value) === index);
}

Date.prototype.getDayDiff = function ( endDate: Date): number {
    const startMoment = dayjs(this);
    const endMoment = dayjs(endDate);
    const diff = endMoment.diff(startMoment);
    const duration = dayjs.duration(diff);
    const amountDaysDiff = Math.round(Math.abs(duration.asDays()));
    return amountDaysDiff;
}

Date.prototype.getUtcWithoutTime = function (): Date {
    return new Date(
        this.getUTCFullYear(),
        this.getUTCMonth(),
        this.getUTCDate()
    );
};

Date.prototype.timeFormat = function (convertToClientTime : boolean): string {
    return dayjs(this).format(TimeFormat);
};

Date.prototype.dateWithoutTimeFormat = function (convertToClientTime: boolean): string {
    return dateWithoutTimeFormat(this);
};

Date.prototype.dateWithTimeFormat = function (): string {
    return dayjs(this).format(DateWithTimeFormat);
};

Date.prototype.dateAndTimeToApiPostFormat = function (): string {
    return dayjs(this).format(DateAndTimePostFormat);
};

Date.prototype.dateToApiPostFormat = function (): string {
    return dayjs(this).format(DatePostFormat);
};

Date.prototype.toIsoDateTimeString = function(): string {
    return dayjs(this).toISOString();
}

Date.prototype.toIsoDateTimeUtcString = function(): string {
    return dayjs(this).utc(true).toISOString();
}

Date.prototype.addMonths = function(months: number): Date {
    return months === 0 ? this : dayjs(this).add(months, MonthsUnitType).toDate();
}

Date.prototype.isLastDayInMonth = function(): boolean {
    const src = dayjs(this);
    return src.daysInMonth() === src.date();
}

Date.prototype.getEndOfMonth = function(): Date {
    return dayjs(this).endOf(MonthUnitType).toDate();
}

Date.prototype.subtractDays = function(days: number): Date {
    return days <= 0 ? this : dayjs(this).subtract(days, DaysUnitType).toDate();
}

String.prototype.toInt = function() {
    return +(this ?? "");
}

String.prototype.appendCounter = function(amount: number) {
    return `${this} (${amount})`;
};

String.prototype.undefinedIfEmpty = function() {
    return this === "" ? undefined : this as string;
};

export const toInt = (n: string | number | undefined) => {
    return !n ? undefined : typeof n === 'string' ? n.toInt() : n;
}

export const dateTimeStringFromApiToDate = (date : string) : Date => {
    return new Date(dayjs(date).utc(true).format(DateTimeIsoFormatWithoutZone)); // return the date as is without timezone
}

export const nullableDateTimeStringFromApiToDate = (date?: string) : Date | undefined => {
    return date ? dateTimeStringFromApiToDate(date) : undefined;
}

export const toDate = (date : string) : Date => {
    return dayjs(date).toDate();
}

export const toNullableDate = (date?: string) : Date | undefined => {
    return date ? toDate(date) : undefined;
}

export const toDateFromApiFormat = (dateStr: string, ) : Date => {
    return dayjs(dateStr, DateFormatWithoutZoneAndTime).toDate();
}

export const getDayDiff = (startDate: Date, endDate: Date): number => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const diff = end.diff(start);
    const duration = dayjs.duration(diff);
    const amountDaysDiff = Math.round(Math.abs(duration.asDays()));
    return amountDaysDiff;
}

export const dateWithoutTimeFormat = (date: Date): string => {
    return dayjs(date).format(DateWithoutTimeFormat);
};

export const addDays = (date: Date, days: number): Date => {
    return days === 0 ? date : dayjs(date).add(days, DaysUnitType).toDate();
}

export const todayDayjs = () => dayjs().startOf(DayUnitType);

export const tryToBool = (boolStr?: string) :  boolean | undefined => {
    const shouldConvert = boolStr !== undefined && boolStr !== '';
    return shouldConvert ? JSON.parse(boolStr + "") : undefined;
}

export const UUID = () => uuid();

export const hasAnyDefinedProperties = (obj: Object) =>  Object.values(obj).some((value) => value !== undefined && value.length > 0);

export function ContainsValue <T> (value: T, list?: T[]) {
    return list !== undefined && list.length > 0 ? list.includes(value) : false;
}

export const tryToString = (value?: unknown) : string => {
    try {
        return String(value || "");
    } catch (error) {
        return '';
    }
}


Array.prototype.sortNaturallyBy = function <T extends {}> (this: Array<T>, propSelector: (model: T) => string) : Array<T> {
    return this.sort((a, b) => propSelector(a).localeCompare(propSelector(b), undefined, { numeric: true, sensitivity: 'base' }));
}

Array.prototype.sortBy = function <T extends {}> (this: Array<T>, propSelector: (model: T) => any, desc?: boolean) : Array<T> {
    const isDescending = desc ?? true;

    if(isDescending) {
        return this.sort((a: T, b: T) => propSelector(a) > propSelector(b) ? 1 : -1);
    } else {
        return this.sort((a: T, b: T) => propSelector(a) < propSelector(b) ? 1 : -1);
    }
}

Array.prototype.complexSortByThenBy = function <T extends {}> (primaryPropSelector: (model: T) => string, isPrimaryDesc: boolean, secondaryPropSelector: (model: T) => string, isSecondaryDesc: boolean) {
    
    const firstBiggest = (desc: boolean) => desc ? 1 : -1;
    const secondBiggest = (desc: boolean) => desc ? -1 : 1;

    return this.sort((a: T, b: T) => {
        const primaryA = primaryPropSelector(a);
        const primaryB = primaryPropSelector(b);
        if (primaryA < primaryB) return firstBiggest(isPrimaryDesc);
        if (primaryA > primaryB) return secondBiggest(isPrimaryDesc);

        const secondaryA = secondaryPropSelector(a);
        const secondaryB = secondaryPropSelector(b);
        if (secondaryA < secondaryB) return firstBiggest(isSecondaryDesc);
        if (secondaryA > secondaryB) return secondBiggest(isSecondaryDesc);
        
        return 0;
    });
}

Array.prototype.SortByThenBy = function (firstPropertyName: string, secondPropertyName: string, desc: boolean): void {
    const direction = desc ? -1 : 1;

    this.sort(function (objectA, objectB) {

        let firstResult = -1;
        let seconfResult = -1;

        let objectAFirstProperty = objectA[firstPropertyName];
        let objectBFirstProperty = objectB[firstPropertyName];
        let objectASecondProperty = objectA[secondPropertyName];
        let objectBSecondProperty = objectB[secondPropertyName];

        if (typeof objectAFirstProperty === "boolean"){
            if (objectAFirstProperty === objectBFirstProperty){
                firstResult = 0;
            }
            else {
                firstResult = objectBFirstProperty ? 1 : -1
            }
        }
        else {
            objectAFirstProperty = typeof objectAFirstProperty === 'string' ? objectAFirstProperty.toLowerCase() : objectAFirstProperty.toString();
            objectBFirstProperty = typeof objectBFirstProperty === 'string' ? objectBFirstProperty.toLowerCase() : objectBFirstProperty.toString();
            firstResult = objectAFirstProperty.localeCompare(objectBFirstProperty, 'en', { numeric: true });
        }

        if (typeof objectASecondProperty === "boolean"){
            if (objectASecondProperty === objectBSecondProperty){
                seconfResult = 0;
            }
            else {
                seconfResult = objectBSecondProperty ? 1 : -1
            }
        }
        else {
            objectASecondProperty = typeof objectASecondProperty === 'string' ? objectASecondProperty.toLowerCase() : objectASecondProperty.toString();
            objectBSecondProperty = typeof objectBSecondProperty === 'string' ? objectBSecondProperty.toLowerCase() : objectBSecondProperty.toString();
            seconfResult = objectASecondProperty.localeCompare(objectBSecondProperty, 'en', { numeric: true });
        }


        if (firstResult !== 0){
            return firstResult * direction;
        }
        else {
            return seconfResult * direction;
        }
    });
}

export type Nullable<T> = T | null;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor()}>
            <ConfigLoader ready={() => <App/>}/>
        </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
