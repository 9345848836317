import {useAppDispatch, useAppSelector} from 'app/hooks';
import { Localizer } from 'infrastructure/localization/localizer';
import {setValidated, ValidatedDateReducer, VejledereValidate} from 'pages/vejledere/vejledereValidateDateSlice';
import { ModalMessage, showModalMessage } from '../modalMessage/modalMessage';
import useEffectAsync from "../../../infrastructure/effect";
import {AccountService} from "../../../services/account/accountService";
import {StamdataUserApi} from "../../../services/api/stamdataUser/stamdataUserApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {useHistory} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";

export function ValiderVejledereWarning() {
    const dispatch = useAppDispatch();
    const validatedVejledere = useAppSelector(ValidatedDateReducer) as VejledereValidate;

    const userService = new AccountService();
    const userApi = new StamdataUserApi(new HttpClientService(), userService);

    const user = userService.getUser();
    const modalId = "alert-modal"
    const validatedDate = new Date(validatedVejledere.validatedDate);

    const history = useHistory();

    const today = new Date();

    useEffectAsync(async () => {
        if(user.IsUddannelsesansvarlig()) {
            const currentValidatedDate = (await userApi.getValidatedDate());

            if(!currentValidatedDate || new Date(validatedDate.setMonth(validatedDate.getMonth() + 6)) < today) {
                showModalMessage(modalId);
            }

            if (currentValidatedDate){
                dispatch(setValidated(currentValidatedDate.dateWithoutTimeFormat(false)));
            }
            else {
                dispatch(setValidated(Localizer.notValidated()));
            }
        }
    }, [])

    const routeToVejledere = () => {
        history.push(RoutePaths.Vejledere.url);
    }

    return (
        <ModalMessage title={Localizer.validateVejleder()}
                      description={Localizer.validateVejlederBeskrivelse()}
                      primaryButtonText={Localizer.vejlederpage_gennemgaaListenSenere()}
                      secondaryButtonText={Localizer.vejlederpage_gaaTilListenNu()}
                      secondaryButtonAction={routeToVejledere}
                      modalId={modalId} />
    );
}
