import {Localizer} from "../../../../infrastructure/localization/localizer";
import {FormikProps} from "formik";
import {nameof} from "ts-simple-nameof";
import {ValidationInput} from "../../../../core/components/validation/components/validationInput";
import {CrudUddannelsesplanInputModel} from "./crudUddannelsesplanInputModel";
import {FileMetadata} from "../../../../core/sharedmodels/fileMetaData/fileMetaData";
import {ReactFileUploaderFile} from "../../../../core/components/fileUpload/reactFileUploader";
import {FileMetadataList} from "../../../../core/components/fileMetadata/fileMetadataList";
import {NotatType} from "../../../../core/sharedmodels/notat/notatType";
import {BasicNotatModel} from "../../../../core/sharedmodels/notat/basicNotatModel";
import {FileLocationEnum} from "../../../../core/sharedmodels/file/fileLocationEnum";
import InfoText from './infoText';
import useUser from "../../../../core/hooks/useUser";
import {EmblaIcon} from "../../../../core/components/emblaIcon/emblaIcon";
import SlideInAlert, { AlertTypes } from 'core/components/slideIn/slideInAlert';
import { ValidationTextEditor } from 'core/components/validation/components/validationTextEditor';

type UddannelsesplanCrudFieldsProps = {
    modalId: string
    formik: FormikProps<CrudUddannelsesplanInputModel>,
    godkendtAfName?: string,
    godkendtDate?: Date
    notatInfo? : {contextId : string, notatType : NotatType, userId: string};
    notat?: BasicNotatModel | null,
    fileUploadedCallback?: (result: ReactFileUploaderFile[]) => void,
    fileDeletedCallback?: (result: FileMetadata) => void,
    klarmeldtAt?: string,
    oprettetAfName?: string,
    oprettelsesDato?: Date,
}

export function UddannelsesplanCrudFields(props: UddannelsesplanCrudFieldsProps) {
    const enighedTilkendegivet = (props.godkendtDate !== undefined && props.godkendtAfName !== undefined);
    const { currentUser } = useUser();
    const showKlarmeldButton = (currentUser.IsLaege() || currentUser.IsAdmin()) && (props.notatInfo && props.notatInfo.contextId) && (props.godkendtAfName == undefined && props.godkendtDate == undefined);

    const klarmeld = async () => {
        if (!!props.klarmeldtAt) {
            props.formik.setFieldValue(nameof<CrudUddannelsesplanInputModel>(x => x.klarmeldtAt), undefined);
        } else {
            props.formik.setFieldValue(nameof<CrudUddannelsesplanInputModel>(x => x.klarmeldtAt), new Date());
        }
    }

    const render = (
        <>
            <div className="row">
                {(props.godkendtDate && props.godkendtAfName) &&
                    <SlideInAlert
                        text={Localizer.vejledningPage_vejlederVarEnigIUddannelsesplan(props.godkendtAfName, props.godkendtDate.dateWithoutTimeFormat(false))}
                        alertType={AlertTypes.Succes}
                    />
                }

                {(props.oprettelsesDato && props.oprettetAfName) && !(props.godkendtDate && props.godkendtAfName) &&
                    <SlideInAlert
                        text={Localizer.vejledningPage_navnHarTilknyttetUddannelsesplan(props.oprettetAfName, props.oprettelsesDato.dateWithoutTimeFormat(false))}
                        alertType={AlertTypes.Primary}
                    />
                }
                                
                <div className="col-sm-12">
                    <ValidationInput
                        model={{
                            label: Localizer.titel(),
                            placeholder: Localizer.titelPlaceholder(),
                            htmlName: nameof<CrudUddannelsesplanInputModel>(x => x.titel)
                        }}
                        inputId={nameof<CrudUddannelsesplanInputModel>(x => x.titel) + props.modalId}
                        formikProps={props.formik}
                        readOnly={enighedTilkendegivet}
                    />
                </div>

                    <div className="col-sm-12">
                        <ValidationTextEditor
                            model={{
                                label: Localizer.uddannelsesplan(),
                                placeholder: Localizer.uddannelsesplanPlaceholder(),
                                htmlName: nameof<CrudUddannelsesplanInputModel>(x => x.beskrivelse)
                            }}
                            inputId={nameof<CrudUddannelsesplanInputModel>(x => x.beskrivelse) + props.modalId}
                            formikProps={props.formik}
                            readOnly={enighedTilkendegivet}
                            value={props.formik.values.beskrivelse}
                        />
                    </div>

                    <div className="col-sm-12">
                        <InfoText text={Localizer.vejledningPage_uddannelsesplanTekstFeltInformation()} />
                    </div>

                    <div className="col-sm-12 margin-top-m">
                        <FileMetadataList fileMetadatas={props.notat?.fileMetadatas ?? []}
                                          filesUploadedCallbackOverride={props.fileUploadedCallback}
                                          fileDeletedCallbackOverride={props.fileDeletedCallback}
                                          notatInfo={props.notatInfo}
                                          notat={props.notat}
                                          showDeleteButton={!enighedTilkendegivet}
                                          withUploader={!enighedTilkendegivet}
                                          fileLocation={FileLocationEnum.Logbog}
                        />
                    </div>

                    {showKlarmeldButton &&
                        <div className="col-sm-12 margin-top-m">
                            <button className={"btn btn-primary"}
                                    onClick={klarmeld}
                                    type={"submit"}>
                                <EmblaIcon iconName={!!props.klarmeldtAt ? "remove" : "success"}/>
                                {!!props.klarmeldtAt ? Localizer.global_removeKlarmeld() : Localizer.global_klarmeld()}
                            </button>
                        </div>
                    }
                </div>
        </>
    );
    return <>{render}</>
}
