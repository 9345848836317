import {EvalueringType} from "./evalueringType";
import {KompetenceSektionModel} from "./kompetenceSektionModel";
import {dateTimeStringFromApiToDate} from "../../../index";
import {UserNameInfoModel} from "../../components/userProfile/userNameInfoModel";
import {
    KompetenceEvalueringResponseModel
} from "../../../services/api/logbogEvaluering/kompetenceEvalueringResponseModel";

export class KompetenceEvalueringModel {
    public static FromResponseModel(evaluering: KompetenceEvalueringResponseModel): KompetenceEvalueringModel{
        let model = new KompetenceEvalueringModel();

        model.kompetenceTitel = evaluering.kompetenceTitel;
        model.evalueringId = evaluering.evalueringId;
        model.laegekompetenceEvalueringId = evaluering.kompetenceEvalueringId;
        model.indsendt = evaluering.indsendt;
        model.evalueringTypeEnum = evaluering.evalueringTypeEnum;
        model.titel = evaluering.titel;
        model.tekst = evaluering.tekst;
        model.indsendtDato = dateTimeStringFromApiToDate(evaluering.indsendtDato);
        model.createdBy = evaluering.createdBy;
        model.laegeKompetenceId = evaluering.laegeKompetenceId;
        model.createdByUser = evaluering.createdByUser;
        model.sektioner = evaluering.sektioner.map(s => KompetenceSektionModel.FromResponseModel(s));

        return model;
    }

    evalueringId: string;
    titel: string;
    evalueringTypeEnum: EvalueringType;
    sektioner: KompetenceSektionModel[];
    laegekompetenceEvalueringId: string;
    kompetenceTitel?: string;
    laegeKompetenceId?: string;
    tekst: string;
    indsendt: boolean;
    indsendtDato: Date;
    createdBy?: string;
    createdByUser?: UserNameInfoModel;
}

