import Tooltip from "../tooltips/Tooltip";

interface TablePillOverflowProps {
    numberOfRecords: string;
    records: string;
}

export function TablePillOverflow(props: TablePillOverflowProps) {

    return (
        <div className="badge badge-pill badge-primary-light">
            <Tooltip className={"text-truncate"} title={props.records}>
                {"+"+props.numberOfRecords}
            </Tooltip>
        </div>
    );
}
