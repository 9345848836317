import {FileArchiveEnum} from "core/sharedmodels/fileMetaData/fileArchiveEnum";
import {FileMetadata} from "core/sharedmodels/fileMetaData/fileMetaData";
import {FileDownloadHandler} from "services/api/logbogFile/fileDownloadHandler";
import {LogbogFileApi} from "services/api/logbogFile/logbogFileApi";
import {HttpClientService} from "services/httpClient/httpClientService";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {StamdataEmailApi} from "../../../services/api/stamdataEmail/stamdataEmailApi";
import {AccountService} from "../../../services/account/accountService";
import {NotificationModule} from "ditmer-embla";
import {Localizer} from "../../../infrastructure/localization/localizer";

export interface ReactFileUploaderOptions {
    filesUploadedCallback: (result: ReactFileUploaderFile[]) => void,
    fileType: FileArchiveEnum
}

export interface ReactFileUploaderFile {
    fileName: string
    fileSizeBytes: number;
    fileSizePretty: string;
    fileMetadata: FileMetadata;
    file?: File;
}

const logbogFilApi = new LogbogFileApi(new HttpClientService(), new FileDownloadHandler());
const emailFileApi = new StamdataEmailApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
let fileMetadata: FileMetadata

export class ReactFileUploader {
    private options: ReactFileUploaderOptions;
    public selectorId: string;
    private element: JQuery;
    private fileLocation: FileLocationEnum;
    private uploadedFiles: ReactFileUploaderFile[];

    constructor(selector: string, options: ReactFileUploaderOptions, fileLocation: FileLocationEnum, defaultUploadedFiles?: ReactFileUploaderFile[]) {
        this.options = options;
        this.selectorId = selector;
        this.element = $(selector);
        this.fileLocation = fileLocation;
        this.uploadedFiles = defaultUploadedFiles ?? [];

        if (this.element.length) {
            this.element.off("change").on("change", (event: JQuery.ChangeEvent) => {
                const fileInputElement = event.currentTarget as HTMLInputElement;
                const files = fileInputElement.files;

                if (files) {
                    this.handleSelectedFiles(files);
                }
            });
        } else {
            console.error(`ReactFileUploader could not find element with selector: ${selector}`)
        }
    }

    private async handleSelectedFiles(files: FileList) {
        if (files.length > 0) {
            const uploadedFiles: ReactFileUploaderFile[] = [];

            for (let i = 0; i < files.length; i++) {
                const file = files.item(i);
                const fileAlreadyUploaded = this.uploadedFiles.findIndex(f => f.fileName === file?.name) >= 0;

                if (file && !fileAlreadyUploaded) {
                    const uploadedFile = await this.uploadFile(file);

                    uploadedFiles.push(uploadedFile);
                }

                if (fileAlreadyUploaded) {
                    NotificationModule.showError(Localizer.global_fileAlreadyUploaded(), "")
                }
            }

            if (uploadedFiles?.length > 0) {
                this.uploadedFiles.push(...uploadedFiles);
                this.options.filesUploadedCallback(uploadedFiles);
            }

            // Reset file input after upload
            this.element.val("");
        }
    }


    private formatBytes(bytes: number): string { // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
        if (bytes === 0) {
            return "0 Bytes";
        }

        const k = 1000;
        const dm = 0; // 0 decimals
        const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        let size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
        let unitName = units[i];

        if (i === 0) { // Do not use bytes, convert to KB
            size = parseFloat((size / 1000).toFixed(2));
            unitName = units[1];
        }

        return size + " " + unitName;
    }

    private async uploadFile(file: File): Promise<ReactFileUploaderFile> {
        if(this.fileLocation === FileLocationEnum.Logbog) {
            fileMetadata = await logbogFilApi.uploadFile(file, FileArchiveEnum.NoteAttachment);
        }
        else if(this.fileLocation === FileLocationEnum.Email) {
            fileMetadata = await emailFileApi.uploadAttachment(file);
        } 
        else if(this.fileLocation === FileLocationEnum.Stamdata) {
            //TODO: Create when file api is created in stamdata
            fileMetadata = new FileMetadata () ; // As of now, this component does not upload files to the Stamdata backend.
        }

        return {
            fileName: file.name,
            fileSizeBytes: file.size,
            fileSizePretty: this.formatBytes(file.size),
            fileMetadata: fileMetadata,
            file: this.fileLocation === FileLocationEnum.Stamdata ? file : undefined
        };
    }

    // Public methods, to manage internal state of ReactFileUploader (ref):

    /** Removes an already uploaded file (from internal state) 
     * 
     * Used for making sure, we can upload a file again, after deleting it (in this session)
    */
    public removeUploadedFileWithFileName(fileName: string) {
        const fileIndex = this.uploadedFiles.findIndex(f => f.fileName === fileName);

        if(fileIndex > -1) {
            this.uploadedFiles.splice(fileIndex, 1);
        }
    }
}
