import {
    brugereMenuLink,
    evalueringsstatistikMenuLink,
    forloebOverviewMenuLink,
    kursusBrugerUddannelseslaegeOverviewMenuLink} from "core/layout/shared/MenuLinkProps";
import {DynamicMenu} from "../DynamicMenu";
import MenuProps from "./menuProps";

export function KursuslederMenu({ showNotification, nyhederMenuLink }: MenuProps) {

    const menuLinks = [
        forloebOverviewMenuLink,
        kursusBrugerUddannelseslaegeOverviewMenuLink,
        brugereMenuLink,
        evalueringsstatistikMenuLink,
        nyhederMenuLink
    ];

    return (
        <DynamicMenu menuLinks={menuLinks} showNotifications={showNotification}/>
    );
}
