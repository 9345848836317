import React, {useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import useEffectAsync from "../../../infrastructure/effect";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {Loading} from "../../../core/components/loading/loading";
import {LineChart, LineChartColor, LineChartItem} from "../../../core/components/charts/lineChart";
import {UddannelsesplanModel} from "../../vejledning/uddannelsesplan/uddannelsesplanModel";
import {ForloebOverviewCard} from "./forloebOverviewCard";
import {ForloebOverviewEmptyCard} from "./forloebOverviewEmptyCard";
import {useHistory, useRouteMatch} from "react-router-dom";
import {RoutePaths} from "../../../infrastructure/routes";
import {ActionIcon} from "../../../core/components/actionIcon/actionIcon";
import {EmblaIcons} from "core/emblaIcons";
import useLogbogApi from "core/hooks/useLogbogApi";
import CalendarTimeDisplay from "core/components/timedisplayers/Calendar";
import Title from "core/components/titels/title";
import {ReactComponent as GreenCircle} from "../../../content/icons/greenCircle.svg";
import { Uddannelsesplan } from "pages/vejledning/uddannelsesplan/components/uddannelsesplan";

export interface ForloebUddannelsesplanerOverviewProps{
    showRightHeaderContent?: boolean
}

export function ForloebUddannelsesplanerOverview(props: ForloebUddannelsesplanerOverviewProps) {
    const { uddannelsesplanApi } = useLogbogApi();

    const [uddannelsesplaner, setUddannelsesplaner] = useState<UddannelsesplanModel[]>([]);
    const [currentUddannelsesplan, setCurrentUddannelsesplan] = useState<UddannelsesplanModel | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const routeMatch = useRouteMatch();

    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const uddannelseslaegeId = (routeMatch.params as { id: string }).id;

    const infoModalId = "ForloebOverviewUddannelsesplanCardInfoModal";

    useEffectAsync(async () => {
        const uddannelsesplaner = await uddannelsesplanApi.getUddannelsesplanerByForloeb(forloebSliceState.forloebState.id);
        setUddannelsesplaner(uddannelsesplaner);

        if(uddannelsesplaner.length > 0){
            const currentUddPlan = uddannelsesplaner?.reduce( (prev, current) => {
                return current.oprettelsesDato > prev.oprettelsesDato ? current : prev;
            });
            setCurrentUddannelsesplan(currentUddPlan);
        }

        setIsLoading(false);
    }, [forloebSliceState.forloebState.id]);


    const generateChartItems = (): LineChartItem[] => {
        const planlagtCount = uddannelsesplaner.filter(vs => !vs.godkendelseTilkendegivetAf).length;
        const godkendtCount = uddannelsesplaner.filter(vs => vs.godkendelseTilkendegivetAf).length;

        return [
            {count: planlagtCount, color: LineChartColor.info, title: Localizer.global_planlagt()},
            {count: godkendtCount, color: LineChartColor.success, title: Localizer.global_agree()},
        ];
    }

    const overviewContent = (
        <>
            <LineChart items={generateChartItems()}/>

            {currentUddannelsesplan &&
                <div className="card margin-top-m" role={"button"} onClick={() => navigateToCurrentUddannelsesplan(currentUddannelsesplan.id)}>
                    <div className="card-body">
                        <Uddannelsesplan
                            uddannelsesplan={currentUddannelsesplan}
                            readOnly
                            lastIndex
                        />
                    </div>
                </div>
            }
        </>
    )

    const navigateToUddannelsesplaner = () => {
        history.push(RoutePaths.Vejledning({
            uddannelseslaege: uddannelseslaegeId
        }).url)
    }

    const navigateToCurrentUddannelsesplan = (uddannelsesplanId: string) => {
        history.push(RoutePaths.Vejledning({
            uddannelseslaege: uddannelseslaegeId,
            uddannelsesplan: uddannelsesplanId
        }).url)
    }

    const overviewEmptyState = (
        <>
            <h4 className="margin-bottom-m">{Localizer.global_uddannelsesplaner()}</h4>


            <ForloebOverviewEmptyCard emptyText={Localizer.forloebpage_UddannelsesplaneOverviewEmptyInfo()}>
                <p>{Localizer.forloebpage_UddannelsesplaneOverviewEmptyInfo()}</p>

                {!forloebSliceState.forloebState.hasEnded &&
                    <button type="button" className="btn btn-success"
                            onClick={navigateToUddannelsesplaner}>{Localizer.global_planlaeg()}</button>
                }
            </ForloebOverviewEmptyCard>
        </>
    )

    return (
        <ForloebOverviewCard
            title={`${Localizer.global_uddannelsesplaner()} (${uddannelsesplaner?.length})`}
            infoModal={{text: Localizer.forloebpage_uddannelsesplanInfo(), id: infoModalId}}
            rightHeaderContent={<>
                {props.showRightHeaderContent &&
                    <div className="d-flex">
                        <ActionIcon iconName={EmblaIcons.ArrowRight} routeUrl={RoutePaths.Vejledning({
                            uddannelseslaege: uddannelseslaegeId
                        }).url} />
                    </div>
                }
            </>}
        >
            <Loading isLoading={isLoading} text={Localizer.forloebpage_getUddannelsesplaner()}>
                {uddannelsesplaner?.length > 0 &&
                    overviewContent
                }

                {uddannelsesplaner?.length < 1 &&
                    overviewEmptyState
                }
            </Loading>

        </ForloebOverviewCard>
    );
}
