import { Field } from "formik"
import { ToggleDatePickerFocus } from "../datePicker";

type JQueryDateInputProps = {
    id: string;
    useFormik: boolean;
    formName?: string;
    defaultValue?: string;
}

const JQueryDatePickerHiddenInputField = ({id, defaultValue, formName, useFormik}: JQueryDateInputProps) => {
    // Datepicker module input validation and calendar popover is connected to this input.
    
    return (
        <>
            {useFormik &&
                <Field
                    id={id}
                    type="text"
                    name={formName}
                    onBlur={() => ToggleDatePickerFocus(id, false)}
                    className="visually-hidden"
                    tabIndex="-1"
                />
            }

            {!useFormik &&
                <input
                    id={id}
                    type="text"
                    onBlur={() => ToggleDatePickerFocus(id, false)}
                    className="visually-hidden"
                    defaultValue={defaultValue}
                    tabIndex={-1}
                />
            }
        </>
    )
};

export default JQueryDatePickerHiddenInputField;
