import React, { useState } from 'react'
import {Form, Formik} from "formik";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    BuildNew,
    CheckIfAllIsChosen,
    CrudKlarmeldingInputModel,
    crudKlarmeldingValidationSchema
} from "./crudKlarmeldingInputModel";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {HandleError} from "../../../core/infrastructure/errors/errorBoundary";
import {KlarmeldingCrudFields} from "./klarmeldingCrudFields";
import {ValidationFormSubmitButton} from "../../../core/components/validation/components/validationFormButton";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ModalAcceptType} from "../../../core/components/modalMessage/modalSubmitMessage";
import {ActiveAndSelectedKompetenceList} from "./activeAndSelectedKompetenceList";
import {kompetenceCardReducer, KompetenceCardState} from "../../kompetencerPage/cards/kompetenceCardSlice";

type CreateKlarmeldingProps = {
    modalId: string,
    isKlarmeldState: boolean,
    updateKompetenceStatusCallback: (inputModel: CrudKlarmeldingInputModel) => Promise<void>,
}

export function CreateKlarmelding(props: CreateKlarmeldingProps) {
    const dispatch = useAppDispatch();
    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const [ disableSubmit, setDisableSubmit ] = useState(false);

    const createKlarmelding = async (inputModel: CrudKlarmeldingInputModel) => {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
        CheckIfAllIsChosen(inputModel);
        await props.updateKompetenceStatusCallback(inputModel)
    }

    const render = (
        <Formik
            initialValues={BuildNew()}
            validationSchema={props.isKlarmeldState ? crudKlarmeldingValidationSchema : undefined}
            onSubmit={async (values: CrudKlarmeldingInputModel) => {
                await createKlarmelding(values)
                    .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: props.modalId}))
                        HandleError(err);
                    });
            }}>
            {
                (formik) => (
                    <Form>
                        {props.isKlarmeldState ?
                            <KlarmeldingCrudFields
                                modalId={props.modalId}
                                formik={formik}
                                setSubmitIsDisabled={setDisableSubmit}
                            />
                            :
                            <div className="row">
                                <div className="col-sm-12 margin-bottom-m">
                                    <p>{kompetenceCardState.selectedLaegekompetenceMetas?.length > 1 ? Localizer.global_removeKlarmeldFromChosenKompetencer() : Localizer.global_removeKlarmeldFromChosenKompetence()}:</p>
                                    <ActiveAndSelectedKompetenceList/>
                                </div>
                            </div>
                        }
                        <div className="modal-actions">
                            <ValidationFormSubmitButton disabled={disableSubmit} formikProps={formik} buttonType={props.isKlarmeldState ? ModalAcceptType.primary : ModalAcceptType.danger}>
                                {props.isKlarmeldState ? Localizer.global_klarmeld() : Localizer.global_removeKlarmeld()}
                            </ValidationFormSubmitButton>
                        </div>
                    </Form>
                )
            }
        </Formik>
    )
    return <>{render}</>
}
