import {FileMetadata} from "../../sharedmodels/fileMetaData/fileMetaData";
import CreateValidationSchema from "../validation/createValidationSchema";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {array, string} from "yup";

export class CreateMailInputModel {
    public mailSkabelonId: string;
    public subject: string;
    public message: string;
    public fileMetadata: FileMetadata[];
}

export const BuildNew = () : CreateMailInputModel => {
    let inputModel = new CreateMailInputModel();

    inputModel.mailSkabelonId = "";
    inputModel.subject = " ";
    inputModel.message = "";
    inputModel.fileMetadata = []

    return inputModel;
}

export const CreateMailValidationSchema = CreateValidationSchema<CreateMailInputModel>({
    mailSkabelonId: string().notRequired(),
    subject: string().required(Localizer.validate_SubjectRequired()),
    message: string().required(Localizer.validate_MessageRequired()),
    fileMetadata: array().notRequired()
})
