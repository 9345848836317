import {KompetenceSvarModel} from "../../core/sharedmodels/evaluering/KompetencesvarModel";
import {KompetenceSpoergsmaalModel} from "../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import {SpoergsmaalType} from "../../core/sharedmodels/evaluering/spoergsmaalType";
import {Localizer} from "../../infrastructure/localization/localizer";
import React, {useMemo, useState} from "react";
import {SvarmulighedModel} from "../../core/sharedmodels/evaluering/svarmulighedModel";
import {DeleteEvalueringSvarModel} from "../../services/api/logbogEvaluering/deleteEvalueringSvarModel";
import {SvarInputTypeCompareMap} from "../kompetencerPage/inputSvar/svarInputTypeCompareMap";
import {MultiInputSvar} from "../kompetencerPage/inputSvar/multiInputSvar";

type KompetenceEvalueringSvarProps = {
    spoergsmaal: KompetenceSpoergsmaalModel;
    kompetenceEvalueringId: string;
    indsendt: boolean;
    deleteSvarCallback: (svar: DeleteEvalueringSvarModel) => void;
    updateSvarCallback: (svar: KompetenceSvarModel) => void;
    answerChosenCallback: () => void;
    sektionId: string;
}

export function KompetenceEvalueringSvar({
                                             spoergsmaal,
                                             indsendt,
                                             updateSvarCallback,
                                             answerChosenCallback,
                                             kompetenceEvalueringId,
                                             sektionId,
                                             deleteSvarCallback
                                         }: KompetenceEvalueringSvarProps) {

    const spoergsmaalHarMereEndEtSvar = spoergsmaal.antalSvar > 1;


    const kompetenceSvarList = useMemo(() => {
        return spoergsmaal.evalueringSvar.filter(x => x.spoergsmaalId === spoergsmaal.id).sortBy(x => x.svarIndex) ?? [];
    }, [spoergsmaal.evalueringSvar, spoergsmaal.id]);

    const removeSvar = async (svarmulighedId: string, isChecked: boolean, svarIndex?: number) => {
        const deleteSvarModel = {
            spoergsmaalId: spoergsmaal.id,
            kompetenceEvalueringId: kompetenceEvalueringId,
            svarmulighedId: svarmulighedId,
            evalueringSvarId: spoergsmaal.evalueringSvar.find(x => x.svarmulighedId === svarmulighedId)?.id,
            svarIndex: svarIndex,
        } as DeleteEvalueringSvarModel;
        deleteSvarCallback(deleteSvarModel);
    }

    const updateSvar = (spoegsmaalType: SpoergsmaalType, svarIndex: number) => {
        const updatedSvar: KompetenceSvarModel = {
            ...kompetenceSvarList.filter(x => x.spoergsmaalId === spoergsmaal.id)[svarIndex] ?? {} as KompetenceSvarModel,
            spoergsmaalId: spoergsmaal.id,
            kompetenceEvalueringId: kompetenceEvalueringId,
            spoergsmaalType: spoegsmaalType,
            svarIndex: svarIndex,
        };
        return updatedSvar;
    }

    const answerChosen = (updatedSvar: KompetenceSvarModel) => {
        updateSvarCallback(updatedSvar);
        answerChosenCallback();
    }

    const addMultiSvar = async (svarmulighed: SvarmulighedModel, checked: boolean,) => {
        const updatedSvar = updateSvar(SpoergsmaalType.Multi, 0);
        updatedSvar.svarmulighedId = svarmulighed.id;
        updatedSvar.ikkeRelevant = svarmulighed.svarVaerdi === null;
        updatedSvar.svarVaerdi = svarmulighed.svarVaerdi;
        answerChosen(updatedSvar);
    }

    const addTekstSvar = async (e: React.FocusEvent<HTMLTextAreaElement>, svarIndex: number) => {
        const updatedSvar = updateSvar(SpoergsmaalType.Tekst, svarIndex);
        updatedSvar.tekstSvar = e.currentTarget.value;
        answerChosen(updatedSvar);
    }

    const addDateSvar = async (svarIndex: number, dateSvar?: Date) => {
        const updatedSvar = updateSvar(SpoergsmaalType.Dato, svarIndex);
        updatedSvar.dateSvar = dateSvar;
        answerChosen(updatedSvar);
    }

    const addNummerSvar = async (nummer: number, svarIndex: number) => {
        const updatedSvar = updateSvar(SpoergsmaalType.Nummer, svarIndex);
        updatedSvar.nummerSvar = nummer;
        answerChosen(updatedSvar);
    }
    const addBooleanSvar = async (checked: boolean, svarIndex: number) => {
        const updatedSvar = updateSvar(SpoergsmaalType.Boolean, svarIndex);
        updatedSvar.booleanSvar = checked;
        answerChosen(updatedSvar);
    }

    const updateGodkendtSvar = (checked: boolean, svarIndex: number) => {
        const updatedSvar = updateSvar(SpoergsmaalType.DatoOgGodkendt, svarIndex);
        updatedSvar.booleanSvar = checked;
        answerChosen(updatedSvar);
    }

    const updateDateGodkendtSvar = (svarIndex: number, datoSvar: Date) => {
        const updatedSvar = updateSvar(SpoergsmaalType.DatoOgGodkendt, svarIndex);
        updatedSvar.dateSvar = datoSvar;
        answerChosen(updatedSvar);
    }

    return (
        <>
            {spoergsmaalHarMereEndEtSvar &&
                <p>{`${Localizer.evalueringPage_antalSvar()}: ${kompetenceSvarList?.length} ${Localizer.global_af()} ${spoergsmaal.antalSvar} ${Localizer.evalueringPage_mulige()}`}</p>
            }
            {Array.from({length: spoergsmaal.antalSvar}).map((_, svarNummerIndex) => {
                if (spoergsmaal.spoergsmaalTypeEnum === SpoergsmaalType.Multi)
                    return <></>
                const Component = SvarInputTypeCompareMap[spoergsmaal.spoergsmaalTypeEnum];
                return Component
                    ? <Component spoergsmaal={spoergsmaal}
                                 svarNummerIndex={svarNummerIndex}
                                 kompetenceSvarList={kompetenceSvarList}
                                 sektionId={sektionId}
                                 indsendt={indsendt}
                                 tekstCallback={addTekstSvar}
                                 dateCallback={addDateSvar}
                                 removeSvarCallback={removeSvar}
                                 nummerCallback={addNummerSvar}
                                 godkendtCallback={updateGodkendtSvar}
                                 dateGodkendtCallback={updateDateGodkendtSvar}
                                 booleanCallback={addBooleanSvar}/>
                    : <></>
            })}
            {spoergsmaal.spoergsmaalTypeEnum === SpoergsmaalType.Multi &&
                <MultiInputSvar spoergsmaal={spoergsmaal}
                                kompetenceSvarList={kompetenceSvarList}
                                sektionId={sektionId}
                                indsendt={indsendt}
                                multiSvarCallback={addMultiSvar}
                                removeSvarCallback={removeSvar}
                />
            }
        </>

    );
}
