import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {CrudVejlederInputModel} from "../../../pages/vejledere/components/crudVejlederInputModel";
import {StamdataVejlederCrudPostModel} from "./stamdataVejlederCrudPostModel";
import {StamdataVejlederPatchModel} from "./stamdataVejlederPatchModel";
import {VejlederEditResponseModel} from "./vejlederEditResponseModel";
import {VejlederEditModel} from "../../../pages/vejledere/components/vejlederEditModel";
import {VejlederDetailsResponseModel} from "./VejlederDetailsResponseModel";
import {VejlederAfdelingerModel} from "../../../pages/uddannelseslaegeOverview/components/vejlederAfdelingerModel";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";
import { VejlederListExportRequestModel } from "./vejlederListExportRequestModel";
import { IColumnOrderRequestModel } from "core/components/table/tableAsync";

export class StamdataVejlederApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;
    private fileDownloadHandler: FileDownloadHandler;


    private vejlederBaseUrl = () => config.stamdataApiUrl + "api/vejleder/";
    public vejlederDatalist = () => this.vejlederBaseUrl() + "datalist/";
    public vejlederEdit = () => this.vejlederBaseUrl() + "edit/";
    public vejlederAfdelingerUrl = () => this.vejlederBaseUrl() + "vejlederDetails/"
    public vejlederExcelUrl = () => this.vejlederBaseUrl() + "excel/"
    public vejlederEditUrl = () => this.vejlederBaseUrl() + "editVejleder/"

    constructor(httpClientService: HttpClientService, userService: AccountService, fileDownloadHandler: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.userService = userService;
        this.fileDownloadHandler = fileDownloadHandler;
    }

    async addVejleder(inputModel: CrudVejlederInputModel): Promise<string> {
        const postModel = new StamdataVejlederCrudPostModel(inputModel);
        const result = await this.httpClientService.Post<ApiResponse<string>, StamdataVejlederCrudPostModel>(this.vejlederBaseUrl(), postModel);
        return result.data;
    }

    async editVejleder(inputModel: CrudVejlederInputModel): Promise<string> {
        const patchModel = new StamdataVejlederPatchModel(inputModel);
        const result = await this.httpClientService.Post<ApiResponse<string>, StamdataVejlederPatchModel>(this.vejlederEditUrl(), patchModel);
        return result.data;
    }

    async removeVejleder(vejlederTilknytningId: string): Promise<string> {
        const url = this.vejlederBaseUrl() + vejlederTilknytningId;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async getVejlederEditModel(vejlederTilknytningId: string) : Promise<VejlederEditModel> {
        const url = this.vejlederEdit() + vejlederTilknytningId;
        const result = await this.httpClientService.Get<ApiResponse<VejlederEditResponseModel>>(url);
        return new VejlederEditModel(result.data);
    }

    async GetVejlederAfdelinger(): Promise<VejlederAfdelingerModel[]> {
        const result = (await this.httpClientService.Get<ApiResponse<VejlederDetailsResponseModel>>(this.vejlederAfdelingerUrl())).data;
        return result.vejlederAfdelingResponseModels.map(x => new VejlederAfdelingerModel(x))
    }

    async GetVejlederAfdelingDetailsForExport(search?: string, order?: string): Promise<void> {
        const orderModel = !!order ? JSON.parse(order) as IColumnOrderRequestModel : undefined;
        const searchModel = { regex: false, value: search ?? "" } as DataTables.AjaxDataRequestSearch;
        const filterModel = { search: searchModel, order: orderModel } as VejlederListExportRequestModel;
        const result = await this.httpClientService.DownloadFileWithBody(this.vejlederExcelUrl(), filterModel, "application/json");
        this.fileDownloadHandler.DownloadFile(result, "VejlederListe.xlsx");
    }
}
