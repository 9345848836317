import { Nullable } from 'index';
import React, {useEffect, useState} from 'react';
import { EmblaIcon } from '../emblaIcon/emblaIcon';
import { EmblaIconsType } from 'core/emblaIcons';

export type Tab = {
    content: JSX.Element;
    title: string;
    id: string;
    defaultActive: boolean;
    hide?: boolean;
    icon?: EmblaIconsType;
}

export type TabsProps = {
    tabs: Tab[]
    navTabRightSideContent?: JSX.Element
    tabClickedCallback?: (tab: Tab) => void;
    setActiveCallback?: (id: string) => void;
}

export const Tabs = (props: TabsProps) => {
    const [active, setActive] = useState(props.tabs.find(t => t.defaultActive) ?? props.tabs[0])

    useEffect(() => {
        setActive(props.tabs.find(t => t.defaultActive) ?? props.tabs[0]);
    }, [props.tabs])

    const tabClicked = async (tab: Tab) => {
        if (props.tabClickedCallback) {
            props.tabClickedCallback(tab);
        }

        if(props.setActiveCallback) {
            props.setActiveCallback(tab.id);
        }
        
        setActive(tab);
    }

    return (
        <div className="tabs-container">
            <ul className="nav nav-tabs">
                {props.tabs.map((tab) =>
                    <li hidden={tab.hide} className="nav-item" key={tab.id}>

                        <a className={`nav-link ${tab === active ? "active" : ""}`} onClick={() => tabClicked(tab)} href={`#${tab.id}`} aria-controls={`${tab.id}`} aria-expanded="true" role="tab" data-toggle="tab">
                            {tab.icon && <EmblaIcon iconName={tab.icon} additionalClasses='small'/> }
                            {tab.title}
                        </a>
                    </li>
                )}


                <div className={"flex-align-r margin-right-m"}>
                    {props.navTabRightSideContent}
                </div>
            </ul>

            {active &&
                <div className="tab-content" key={active.id + active.content.key}>
                    <div role="tabpanel" className={`tab-pane active`} id={`${active.id}`} key={active.id + active.content.key}>
                        {active.content}
                    </div>
                </div>
            }
        </div>
    );
}

export const isActiveTab = (tabId: string, activeTabId: Nullable<string>) => {
    return !activeTabId ? undefined : tabId === activeTabId;
};