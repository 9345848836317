import {nameof} from "ts-simple-nameof";
import {Localizer} from '../../../infrastructure/localization/localizer';
import {useCallback, useEffect, useRef, useState} from "react";
import {CrudForloebInputModel} from "./crudForloebInputModel";
import {FormikProps} from "formik";
import {ValidationSwitch} from "../../components/validation/components/ValidationSwitch";
import {ValidationDatepicker} from "../../components/validation/components/validationDatePicker";
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import {DatetimePickerModule} from "ditmer-embla";
import {DatePickerActionButton} from "core/components/datePicker/datePickerActionButton";
import {calculateEndDate} from "./forloebHelpers";
import Tooltip from "core/components/tooltips/Tooltip";
import {EmblaIcons} from "../../emblaIcons";
import { ValidationDropdown } from "core/components/validation/components/validationDropdown";
import { toDropdownOptions } from "core/sharedmodels/forSelectModel";
import { DropdownOption } from "core/components/dropdown/dropdown";
import { LedigStillingModel } from "core/sharedmodels/stilling/ledigStillingModel";
import useEnumDropdownOptions from "core/components/dropdown/hooks/useEnumDropdownOptions";
import { FravaerskodeEnum, GetFravaerskodeTranslation } from "core/sharedmodels/forloeb/fravaerskoderEnum";
import useLogbogApi from "core/hooks/useLogbogApi";

type forloebCrudProps = {
    modalId: string
    formik: FormikProps<CrudForloebInputModel>
    stillingReadonly?: boolean
    forloebIsAfsluttet?: boolean
    forloebIsGodkendt?: boolean
    forloebGodkendtDato?: string
    uddannelseslaegeId?: string
    forloebFraVU?: boolean,
    forloebIsEvalueret?: boolean
}

const useSelectedStilling = (formik: FormikProps<CrudForloebInputModel>, selectedStartDate?: Date, selectedEndDate?: Date) => {
    const [selectedStilling, setSelectedStilling] = useState<LedigStillingModel>();
    const [initialSelectedStillingOption, setIntialSelectedStillingOption] = useState<DropdownOption<string>>();
    const { logbogStillingApi } = useLogbogApi();

    useEffect(() => {
        const fetchInitialStilling = async () => {
            const initialStillingId = formik.initialValues.stillingId;
            const initialStartDato = formik.initialValues.startDato;
            const initialSlutDato = formik.initialValues.slutDato;

            if (!!initialStillingId && !!initialStartDato && !!initialSlutDato) {
                const initialSelectedStilling = await logbogStillingApi.getLedigStilling(initialStillingId, initialStartDato, initialSlutDato);
                const initialSelectedStillingOption = {
                    label: initialSelectedStilling.nummer,
                    value: initialSelectedStilling.id
                } as DropdownOption<string>;
                setIntialSelectedStillingOption(initialSelectedStillingOption);
            }
        }
        fetchInitialStilling();
    }, [formik.initialValues.slutDato, formik.initialValues.startDato, formik.initialValues.stillingId, logbogStillingApi]);

    const formikSetFieldValue = formik.setFieldValue;

    const onStillingSelected = async (id?: string) => {
        if (!id) {
            setSelectedStilling(undefined);
            formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.erFravaer), formik.initialValues.erFravaer);
            formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.skalIndgaaIEvalueringsstatistik), formik.initialValues.skalIndgaaIEvalueringsstatistik);
            formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.fravaerskode), formik.initialValues.fravaerskode);
            return;
        }

        if (!!selectedStartDate && !!selectedEndDate) {
            const stilling = await logbogStillingApi.getLedigStilling(id, selectedStartDate, selectedEndDate);
            setSelectedStilling(stilling);

            formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.erFravaer), stilling.erAltidFravaer);

            if(formik.initialValues.senesteDatoForEvaluering !== undefined && !formik.values.erFravaer){
                formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.skalIndgaaIEvalueringsstatistik), formik.initialValues.skalIndgaaIEvalueringsstatistik);
            } else if(formik.values.erFravaer){
                formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.skalIndgaaIEvalueringsstatistik), false);
            }
        }
    };

    return {
        initialSelectedStillingOption,
        selectedStilling,
        onStillingSelected
    }
}

const maxAmountDaysBeforeSkalIndgaaIEvalueringsstatistikIsSatToFalse = 90;
const monthsLatestEvalDateIsFromEndDate = 6;

export function ForloebCrudFields(props: forloebCrudProps) {
    const [selectedEndDate, setSelectedEndDate] = useState<Date | undefined>(props.formik.initialValues.slutDato);
    const [selectedSenestEvalueringsdato, setSelectedSenestEvalueringsdato] = useState<Date | undefined>(props.formik.initialValues.senesteDatoForEvaluering);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | undefined>(props.formik.initialValues.startDato);
    const [fravaerskoderTypeSelectOptions] = useEnumDropdownOptions<FravaerskodeEnum>(FravaerskodeEnum, GetFravaerskodeTranslation);

    const { logbogStillingApi, logbogUserInfoApi } = useLogbogApi();

    const { initialSelectedStillingOption, selectedStilling, onStillingSelected } = useSelectedStilling(props.formik, selectedStartDate, selectedEndDate);

    const prevEndDate = useRef<Date | undefined>(props.formik.values.slutDato);
    const prevStartDate = useRef<Date | undefined>(props.formik.values.startDato);

    const slutDateTimePickerModuleRef = useRef<DatetimePickerModule>(null);
    const senestEvalueringsdatoDateTimePickerModuleRef = useRef<DatetimePickerModule>(null);

    const forloebGodkendt = props.forloebGodkendtDato !== undefined && props.forloebGodkendtDato !== null;
    const forloebErEvalueret = props.formik.values.evalueretDato !== undefined && props.formik.values.evalueretDato !== null;

    // Solves dependency-array:
    const formikSetFieldValue = props.formik.setFieldValue;
    const prevSkalIndgaaIEvalueringsstatistik = useRef<boolean>(props.formik.values.skalIndgaaIEvalueringsstatistik);

    const getLatestEvalDate = useCallback((endDate: Date) => {
        const shouldKeepInitialDate = 
            !!props.formik.initialValues.senesteDatoForEvaluering
            && (!prevEndDate.current || !prevSkalIndgaaIEvalueringsstatistik.current);

        return shouldKeepInitialDate ? props.formik.initialValues.senesteDatoForEvaluering : endDate.addMonths(monthsLatestEvalDateIsFromEndDate);
    }, [props.formik.initialValues.senesteDatoForEvaluering]);

    useEffect(() => {
        prevSkalIndgaaIEvalueringsstatistik.current = props.formik.values.skalIndgaaIEvalueringsstatistik;
    }, [props.formik.values.skalIndgaaIEvalueringsstatistik]);

    useEffect(() => {

        const handleDateChange = () => {

            if (selectedEndDate === undefined || selectedStartDate === undefined) return;

            const hasEndDateChanged = selectedEndDate.dateWithoutTimeFormat(true) !== prevEndDate.current?.dateWithoutTimeFormat(true);
            const hasStartDateChanged = selectedStartDate.dateWithoutTimeFormat(true) !== prevStartDate.current?.dateWithoutTimeFormat(true);

            if (!hasEndDateChanged && !hasStartDateChanged) return;

            const senesteEvalueringsdato = getLatestEvalDate(selectedEndDate);
            
            formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.senesteDatoForEvaluering), senesteEvalueringsdato);
            setSelectedSenestEvalueringsdato(senesteEvalueringsdato);

            if(senesteEvalueringsdato)
                senestEvalueringsdatoDateTimePickerModuleRef.current?.setValue(senesteEvalueringsdato);

            if(props.formik.values.erFravaer){
                formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.senesteDatoForEvaluering), null);
                formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.skalIndgaaIEvalueringsstatistik), false);
                return;
            }

            const amountDaysDiff = selectedEndDate.getDayDiff(selectedStartDate);
            const isPeriodDurationMoreThanMaxAllowedDays = amountDaysDiff > maxAmountDaysBeforeSkalIndgaaIEvalueringsstatistikIsSatToFalse;

            formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.skalIndgaaIEvalueringsstatistik), isPeriodDurationMoreThanMaxAllowedDays);

            if(isPeriodDurationMoreThanMaxAllowedDays) {
                if(!senesteEvalueringsdato) {
                    senestEvalueringsdatoDateTimePickerModuleRef.current?.setValue(selectedEndDate.addMonths(monthsLatestEvalDateIsFromEndDate));
                }
                return;
            }

            prevEndDate.current = selectedEndDate;
            prevStartDate.current = selectedStartDate;
        }

        handleDateChange();
    }, [formikSetFieldValue, getLatestEvalDate, props.formik.values.erFravaer, selectedEndDate, selectedStartDate]);

    const fetchAsyncLedigeStillingerOptions = async (search: string) => {
        if(!!selectedStartDate && !!selectedEndDate) {
            const selectModels = await logbogStillingApi.getLedigeStillingerForSelect(selectedStartDate, selectedEndDate, search);
            return toDropdownOptions(selectModels);
        }

        return [];
    }

    const fetchAsyncLaegerOptions = async (search: string) => {
        const selectModels = await logbogUserInfoApi.getLaegerSelectForCreateForloeb(search);
        return toDropdownOptions(selectModels);
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-12">
                    {!props.formik.initialValues.brugerId &&
                        <ValidationDropdown
                            model={{
                                label: Localizer.laege(),
                                placeholder: Localizer.vaelgLaege(),
                                htmlName: nameof<CrudForloebInputModel>(x => x.brugerId)
                            }}
                            fetchAsyncOptions={fetchAsyncLaegerOptions}
                            fetchAsyncMinSearchLength={3}
                            formikProps={props.formik}
                        />
                    }
                </div>
            </div>
            {props.forloebGodkendtDato && forloebGodkendt &&
                <div className="row">
                    <div className="col-sm-12">
                        <div className="alert alert-success" role="alert">
                            {Localizer.forloebpage_KanIkkeRedigeresDaDetErGodkendt(props.forloebGodkendtDato)}
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-sm-12">
                        <ValidationDatepicker
                            model={{
                                label: Localizer.startdato(),
                                placeholder: Localizer.startdatoPlaceholder(),
                                htmlName: nameof<CrudForloebInputModel>(x => x.startDato),
                            }}
                            inputId={nameof<CrudForloebInputModel>(x => x.startDato) + props.modalId}
                            formikProps={props.formik}
                            dateSelected={(date) => setSelectedStartDate((previous) => {
                                prevStartDate.current = previous;

                                const dateHasChanged = date.dateWithoutTimeFormat(true) !== previous?.dateWithoutTimeFormat(true);

                                if (dateHasChanged) {
                                    const sixMonthsMinusADayFromDate = date.addMonths(6).subtractDays(1);
                                    slutDateTimePickerModuleRef.current?.setValue(sixMonthsMinusADayFromDate);
                                }

                                return date;
                            })}
                            readOnly={props.forloebIsAfsluttet || props.forloebIsGodkendt || props.forloebIsEvalueret}
                        />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <ValidationDatepicker
                        ref={slutDateTimePickerModuleRef}
                        model={{
                            label: Localizer.slutdato(),
                            placeholder: Localizer.slutdatoPlaceholder(),
                            htmlName: nameof<CrudForloebInputModel>(x => x.slutDato)
                        }}
                        inputId={nameof<CrudForloebInputModel>(x => x.slutDato) + props.modalId}
                        formikProps={props.formik}
                        dateSelected={(date) => setSelectedEndDate((prevState) => {
                            prevEndDate.current = prevState;
                            return date;
                        })}
                        readOnly={props.forloebIsAfsluttet || props.forloebIsGodkendt || props.forloebIsEvalueret}
                        additionalIconClasses={"sharp-border"}
                        appendToInputGroup={
                            <DatePickerActionButton
                                datePickerRef={slutDateTimePickerModuleRef}
                                disabled={selectedEndDate === undefined || props.forloebIsGodkendt}
                                actionText={Localizer.forloebEdit_AddMonths(6)}
                                onButtonClickedCallback={(module) => !!selectedEndDate && module.setValue(calculateEndDate(selectedEndDate), true)}
                            />
                        }
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <>
                        <ValidationDropdown
                            //Reinitialize dropdown when initialValue has fetched:
                            key={initialSelectedStillingOption?.value}
                            model={{
                                label: Localizer.stilling(),
                                placeholder: Localizer.vaelgStilling(),
                                htmlName: nameof<CrudForloebInputModel>(x => x.stillingId),
                            }}
                            fetchAsyncOptions={fetchAsyncLedigeStillingerOptions}
                            initialAsyncValue={initialSelectedStillingOption}
                            fetchAsyncMinSearchLength={3}
                            fetchAsyncDebounceTime={500}
                            fetchAsyncResetCacheOnChange={`${selectedStartDate}-${selectedEndDate}`}
                            readOnly={props.stillingReadonly || props.forloebIsGodkendt || props.forloebIsEvalueret || !selectedStartDate || !selectedEndDate}
                            formikProps={props.formik}
                            itemSelected={(newValue) => onStillingSelected(newValue?.value ?? "")}
                        />

                        {(!!selectedStilling?.erAltidFravaer) &&
                            <div className=" alert alert-warning">
                                {Localizer.forloebpage_fravaersStillingSelectedInfo()}
                            </div>
                        }
                        {(!!selectedStilling?.overlappendeForloeb && !selectedStilling.erAltidFravaer) &&
                            <div className=" alert alert-warning">
                                {Localizer.forloebpage_derErForloebSomOverlapperDato()}
                            </div>
                        }

                    </>
                </div>
            </div>
            {(!props.formik.initialValues.erFravaer && !selectedStilling?.erAltidFravaer) &&
                <div className="flex-row">
                    <div className={`${forloebErEvalueret ? "flex-space" : ""}`}>
                        <ValidationSwitch
                            model={{
                                label: Localizer.skalIndgaaIEvalueringsstatistik(),
                                placeholder: Localizer.skalIndgaaIEvalueringsstatistik(),
                                htmlName: nameof<CrudForloebInputModel>(x => x.skalIndgaaIEvalueringsstatistik)
                            }}
                            inputId={nameof<CrudForloebInputModel>(x => x.skalIndgaaIEvalueringsstatistik) + props.modalId}
                            formikProps={props.formik}
                            readOnly={props.forloebIsAfsluttet || props.forloebIsEvalueret}
                            onChangeCallback={(e) => {

                                const skalIndgaaIEvalueringsstatistik = e.target.checked;
                                if (selectedEndDate && skalIndgaaIEvalueringsstatistik) {
                                    const senesteEvalueringsdato = selectedEndDate.addMonths(monthsLatestEvalDateIsFromEndDate);
                                    formikSetFieldValue(nameof<CrudForloebInputModel>(x => x.senesteDatoForEvaluering), senesteEvalueringsdato);
                                    setSelectedSenestEvalueringsdato(senesteEvalueringsdato);

                                    if(senesteEvalueringsdato)
                                        senestEvalueringsdatoDateTimePickerModuleRef.current?.setValue(senesteEvalueringsdato, true);
                                }
                            }}
                        />
                        {forloebErEvalueret &&
                            <Tooltip className="text-truncate" title={Localizer.forloebEdit_forloebErEvalueret()}>
                                <EmblaIcon additionalClasses="subtle small" iconName={"info"}/>
                            </Tooltip>
                        }
                    </div>
                    {props.formik.values.skalIndgaaIEvalueringsstatistik &&
                        <div className={`${forloebErEvalueret ? "flex-space" : ""}`}>
                            <ValidationDatepicker
                                ref={senestEvalueringsdatoDateTimePickerModuleRef}
                                model={{
                                    label: Localizer.forloebEdit_SenesteDatoForEvaluering(),
                                    placeholder: Localizer.datoPlaceholder(),
                                    htmlName: nameof<CrudForloebInputModel>(x => x.senesteDatoForEvaluering)
                                }}
                                inputId={nameof<CrudForloebInputModel>(x => x.senesteDatoForEvaluering) + props.modalId}
                                formikProps={props.formik}
                                readOnly={props.forloebIsAfsluttet || props.forloebIsEvalueret || !props.formik.values.skalIndgaaIEvalueringsstatistik}
                                dateSelected={(date) => setSelectedSenestEvalueringsdato(date)}
                                appendToInputGroup={
                                    <DatePickerActionButton
                                        disabled={props.forloebIsAfsluttet || props.forloebIsEvalueret || !props.formik.values.skalIndgaaIEvalueringsstatistik}
                                        datePickerRef={senestEvalueringsdatoDateTimePickerModuleRef}
                                        actionText={forloebErEvalueret ? Localizer.global_evalueret() : Localizer.forloebEdit_AddMonths(6)}
                                        btnIcon={forloebErEvalueret ? EmblaIcons.Success : undefined}
                                        onButtonClickedCallback={(module) => selectedSenestEvalueringsdato && module.setValue(calculateEndDate(selectedSenestEvalueringsdato), true)}
                                    />
                                }
                            />
                        </div>

                    }
                </div>
            }
            {(!!selectedStilling?.erAltidFravaer) &&
                <div className="row">
                    <div className="col-sm-12">
                        <ValidationDropdown
                            model={{
                                label: Localizer.Fravaerskoder(),
                                placeholder: Localizer.SelectFravaerskoder(),
                                htmlName: nameof<CrudForloebInputModel>(x => x.fravaerskode)
                            }}
                            options={fravaerskoderTypeSelectOptions}
                            formikProps={props.formik}
                        />
                    </div>
                </div>
            }
        </>
    )
}
