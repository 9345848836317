import {Localizer} from '../../../infrastructure/localization/localizer';
import {EmblaIcon} from "../../components/emblaIcon/emblaIcon";
import {GetFravaerskodeTranslation} from "../../sharedmodels/forloeb/fravaerskoderEnum";
import {DaysLeftOnForloeb, ForloebSimpleInfoModel, GetUddannelsesstedInfo} from "../../sharedmodels/forloeb/forloebSimpleInfoModel";
import {UddannelseTypeEnum} from "../../sharedmodels/stilling/uddannelseTypeEnum";

type ForloebSimpleCardInfoProps = {
    forloeb : ForloebSimpleInfoModel
}

export function ForloebSimpleCardInfo(props: ForloebSimpleCardInfoProps) {
    const todayDate = new Date();
    
    const daysLeft = DaysLeftOnForloeb(props.forloeb);

    return (
        <>
            <div className='forloeb-header-txt'>
                <h5 className="margin-bottom-xxs">{GetUddannelsesstedInfo(props.forloeb)}</h5>
                <div>
                    {props.forloeb.specialeName}, {UddannelseTypeEnum[props.forloeb.uddannelseType]}
                </div>
            </div>
            <div className='forloeb-header-txt d-flex' key={props.forloeb.endDate.toString()}>
                {props.forloeb.startDate.dateWithoutTimeFormat(false)} - {props.forloeb.endDate.dateWithoutTimeFormat(false)}
                {daysLeft <= 30 && daysLeft >= 0 &&
                    <div className='margin-left-s text-lowercase'>
                        <p>({daysLeft} {Localizer.global_daysLeft()})</p>
                    </div>
                }
            </div>
            <div className='flex-align-c' key={""+props.forloeb.startDate + props.forloeb.endDate + props.forloeb.manueltAfsluttet + props.forloeb.evalueret + props.forloeb.godkendt}>
                <div className={"forloeb-badges"}>
                    {props.forloeb.erFravaer
                        ?  <span className="badge badge-pill badge-default padding-xs padding-right-s padding-left-s margin-right-s">
                                <div>{GetFravaerskodeTranslation(props.forloeb.fravaerskodeEnum)}</div>
                            </span>
                        : <div>
                            {props.forloeb.startDate > todayDate &&
                                <span className="badge badge-pill badge-default padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>{Localizer.global_fremtidigtForloeb()}</div>
                                </span>
                            }
                            {props.forloeb.endDate < todayDate &&
                                <span className="badge badge-pill badge-default padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>{Localizer.global_pastForloeb()}</div>
                                </span>
                            }
                            {props.forloeb.manueltAfsluttet &&
                                <span className="badge badge-pill badge-warning padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>{Localizer.global_manueltAfsluttet()}</div>
                                </span>
                            }
                            {props.forloeb.godkendt &&
                                <span
                                    className="badge badge-pill badge-primary-light padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>
                                        <EmblaIcon
                                        iconName="success"/>{Localizer.global_tidGodkendt()}
                                    </div>
                                </span>
                            }
                            {props.forloeb.evalueret &&
                                <span className="badge badge-pill badge-primary-light padding-xs padding-right-s padding-left-s margin-right-s">
                                    <div>
                                        <EmblaIcon iconName="success"/> {Localizer.global_evalueret()}
                                    </div>
                                </span>
                            }
                        </div>
                    }

                </div>
            </div>
        </>
    )
}
