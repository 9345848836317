import {useEffect, useState} from "react";
import {Localizer} from "infrastructure/localization/localizer";
import {useRouteMatch} from "react-router-dom";
import {Loading} from "../../core/components/loading/loading";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import {KompetenceCardList, KompetenceCardListType} from "./cards/kompetenceCardList";
import {KompetenceOverview} from "./details/kompetenceOverview";
import {
    kompetenceCardReducer,
    KompetenceCardState,
    scrollToActiveKompetence,
    setActiveLaegekompetenceMeta,
    setIsLoadingList,
    toggleLaegekompetenceListRefreshPing
} from "./cards/kompetenceCardSlice";
import {RoutePaths} from "../../infrastructure/routes";
import {LaegekompetenceMeta} from "../../core/sharedmodels/kompetence/laegekompetenceMeta";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {FileDownloadHandler} from "../../services/api/logbogFile/fileDownloadHandler";
import {AsyncButton} from "../../core/components/button/asyncButton";
import {LogbogExportApi} from "../../services/api/logbogExport/logbogExportApi";
import {AccountService} from "../../services/account/accountService";
import {DropdownButton} from "../../core/components/dropdownButton/dropdownButton";
import {ToSelectOptions_Deprecated} from "../../core/sharedmodels/forSelectModel";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import useRefreshablePage from "core/infrastructure/routing/hooks/useRefreshablePage";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";
import useUser from "core/hooks/useUser";
import { userKompetenceQueryParams } from "infrastructure/route/queryParams";
import useScreenResolution from "core/hooks/useScreenResolution";
import classNames from "classnames";
import { InitForloebState } from "pages/forloeb/forloebApi";
import useStamdataApi from "core/hooks/useStamdataApi";
import useLinkHistory from "infrastructure/route/useLinkHistory";

const exportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());

export type KompetencerPageLinkHistoryState = {
    laegeKompetenceId?: string;
    brugerMaalbeskrivelseId?: string;
}

export const KompetencerPage = () => {
    const routeMatch = useRouteMatch<userKompetenceQueryParams>();
    const dispatch = useAppDispatch();
    const { history, locationState, clearLocationState } = useLinkHistory<KompetencerPageLinkHistoryState>();
    const { pageKey } = useRefreshablePage();
    const { currentUser } = useUser();
    const {isMobile } = useScreenResolution();
    const { stamdataMaalbeskrivelseApi } = useStamdataApi();

    const [isLoading, setIsLoading] = useState(true);

    const [brugerMaalbeskrivelserSelectOptions, setBrugerBrugerMaalbeskrivelserSelectOptions] = useState<{ key: string; value: string; }[]>([]);
    const [selectedBrugerMaalbeskrivelseName, setSelectedBrugerMaalbeskrivelseName] = useState(Localizer.global_vaelgMaalbeskrivelse());
    const [selectedBrugerMaalbeskrivelse, setSelectedBrugerMaalbeskrivelse] = useState<string>();
    const [currentBrugerMaalbeskrivelse, setCurrentBrugerMaalbeskrivelse] = useState<string>();

    const kompetenceCardState = useAppSelector(kompetenceCardReducer) as KompetenceCardState;
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const uddannelseslaegeId = routeMatch.params.uddannelseslaege;
    const isForloebStateInitializedToCorrectLaege = forloebSliceState.forloebState.brugerId === uddannelseslaegeId;

    const activeTab = routeMatch.params.activeTabId;

    const activeKompetenceFromLink = !!locationState?.isLink && !!locationState?.laegeKompetenceId;
    const activeKompetenceId = activeKompetenceFromLink ? locationState?.laegeKompetenceId : kompetenceCardState?.activeLaegekompetenceMeta?.laegekompetenceId;

    useEffect(() => {
        if(activeKompetenceFromLink && activeKompetenceId !== kompetenceCardState?.activeLaegekompetenceMeta?.laegekompetenceId) {
            // If we have been linked: set list as loading (so that kompetenceOverview dont render/fetches before list has fetched)
            dispatch(setIsLoadingList(true));
        }
    }, [activeKompetenceFromLink, activeKompetenceId, dispatch, kompetenceCardState?.activeLaegekompetenceMeta?.laegekompetenceId]);

    useEffect(() => {
        if (!isForloebStateInitializedToCorrectLaege) {
            // Reinitialize forloeb if it has an old state (other laege)
            InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId, undefined, locationState?.brugerMaalbeskrivelseId);
        } else if(!!locationState?.isLink && !!locationState?.brugerMaalbeskrivelseId && forloebSliceState.forloebState.brugerMaalbeskrivelseId !== locationState?.brugerMaalbeskrivelseId) {
            // If we were linked, and a brugerMaalbeskrivelseId was defined: Init forloeb with correct maalbeskrivelse
            InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId, undefined, locationState?.brugerMaalbeskrivelseId);
        }
    }, [dispatch, forloebSliceState.forloebState, locationState?.brugerMaalbeskrivelseId, locationState?.isLink, isForloebStateInitializedToCorrectLaege, uddannelseslaegeId])

    useEffect(() => {
        if (!kompetenceCardState) {
            dispatch(scrollToActiveKompetence(false));
            dispatch(setActiveLaegekompetenceMeta(new LaegekompetenceMeta().ToState()));
        }
        setIsLoading(false);
    }, [dispatch, kompetenceCardState]);

    const getLaegekompetencerPdf = (): Promise<void> => {
        return exportApi.getPdfKompetencer(uddannelseslaegeId, forloebSliceState.forloebState.brugerMaalbeskrivelseId);
    }

    useEffect(() => {
        if (activeKompetenceFromLink) {
            if (isMobile && !isLoading && activeKompetenceId) {
                // On mobile: go directly to active-kompetence-page (when coming from a link):
                const routeParamaters = [uddannelseslaegeId, activeKompetenceId];
                history.push(RoutePaths.KompetencerOverview(routeParamaters).url);
            }

            dispatch(scrollToActiveKompetence(true));
        }

    }, [activeKompetenceFromLink, activeKompetenceId, dispatch, history, isLoading, isMobile, uddannelseslaegeId])

    useEffect(() => {
        const initializeAndFetchBrugermaalbeskrivelse = async () => {
            if (!!forloebSliceState.forloebState.brugerMaalbeskrivelseId && isForloebStateInitializedToCorrectLaege) {
                const currentBrugerMaalbeskrivelse = await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelse(forloebSliceState.forloebState.brugerMaalbeskrivelseId);
                setCurrentBrugerMaalbeskrivelse(currentBrugerMaalbeskrivelse.id);
                setSelectedBrugerMaalbeskrivelse(currentBrugerMaalbeskrivelse.id);

                const brugerMaalbeskrivelser = await stamdataMaalbeskrivelseApi.getBrugerMaalbeskrivelseSelectByUddannelse(uddannelseslaegeId, forloebSliceState.forloebState.stillingId, forloebSliceState.forloebState.brugerMaalbeskrivelseId);
                let brugerMaalbeskrivelserSelectOptions = ToSelectOptions_Deprecated(brugerMaalbeskrivelser, [currentBrugerMaalbeskrivelse.id]);
                brugerMaalbeskrivelserSelectOptions = brugerMaalbeskrivelserSelectOptions
                    .sort((a, b) => (a.selected === b.selected) ? 0 : a.selected ? -1 : 1);

                setSelectedBrugerMaalbeskrivelseName(brugerMaalbeskrivelserSelectOptions.find(x => x.key === currentBrugerMaalbeskrivelse.id)?.value ?? Localizer.global_vaelgMaalbeskrivelse());
                setBrugerBrugerMaalbeskrivelserSelectOptions(brugerMaalbeskrivelserSelectOptions);
            }
        }
        initializeAndFetchBrugermaalbeskrivelse();
    }, [forloebSliceState.forloebState.brugerMaalbeskrivelseId, forloebSliceState.forloebState.stillingId, isForloebStateInitializedToCorrectLaege, stamdataMaalbeskrivelseApi, uddannelseslaegeId])

    async function getLaegekompetencerToShow(brugerMaalbeskrivelseId: string | undefined) {
        if (brugerMaalbeskrivelseId) {
            clearLocationState(); //If we were linked with a laegeKompetence, clear this state.

            const brugerMaalbeskrivelseInDropdown = brugerMaalbeskrivelserSelectOptions.find(x => x.key === brugerMaalbeskrivelseId)?.value;
            setSelectedBrugerMaalbeskrivelseName(brugerMaalbeskrivelseInDropdown ?? Localizer.global_vaelgMaalbeskrivelse());
            setSelectedBrugerMaalbeskrivelse(brugerMaalbeskrivelseId);
            dispatch(setActiveLaegekompetenceMeta(kompetenceCardState.activeLaegekompetenceMeta));
            dispatch(toggleLaegekompetenceListRefreshPing())
        }
    }

    return (
        <Loading isLoading={isLoading} text={Localizer.kompetencePage_getKompetencer()} key={pageKey}>
            <ForloebSpecificPageWrapper hideAllContent={!isForloebStateInitializedToCorrectLaege}>
                {!isMobile &&
                    <ForloebHeader hideSeUddannelseAndForloebDetails={true}/>
                }

                <div className="row">
                    {kompetenceCardState?.activeLaegekompetenceMeta === undefined &&
                        <div className="col-sm-12">
                            <div className="alert alert-info">
                                <b>{Localizer.kompetencePage_noKompetencerOnForloeb()}</b>
                            </div>
                        </div>
                    }
                </div>

                {kompetenceCardState?.activeLaegekompetenceMeta !== undefined &&
                <div className="card top-level-container">
                    <div className={classNames("card-header", isMobile ? "flex-container-column wrap" : "flex-space")}>
                        <div className={classNames("d-flex", isMobile && "flex-align-l flex-row")}>
                            <h3 className="card-title">{Localizer.global_kompetencer()}</h3>
                        </div>
                        <div className={classNames("d-flex", isMobile && "flex-align-l margin-bottom-m flex-row")}>
                            {!currentUser.IsKonsulentForLaegeligVidereuddannelse() &&
                                <AsyncButton clickFunction={getLaegekompetencerPdf} buttonText={Localizer.GetPdf()}
                                            waitText={Localizer.gettingPdf()}/>
                            }

                            <div className={classNames(isMobile ? "margin-left-s" : "margin-left-m")}>
                                <DropdownButton id={"kompetenceside-dropdown-btn"}
                                                dropdownHeader={Localizer.global_maalbeskrivelser()}
                                                emptyDropdownHeader={Localizer.global_ingenMaalbeskrivelser()}
                                                objectClicked={(selectedOption: string) => getLaegekompetencerToShow(selectedOption)}
                                                showEditButton={false}
                                                selectOptions={brugerMaalbeskrivelserSelectOptions}
                                                buttonSelected={selectedBrugerMaalbeskrivelseName !== Localizer.global_vaelgMaalbeskrivelse()}
                                                selectedObjectName={selectedBrugerMaalbeskrivelseName}
                                                highlightSelectedItem={brugerMaalbeskrivelserSelectOptions.length > 1}
                                                />
                            </div>
                        </div>
                    </div>
                    <div className={"row no-gutters" + (!isMobile ? " height-75-vh" : "") }>
                        {!isMobile &&
                            <>
                                <div className="border-right col-5 card-list-custom column-height">
                                    <KompetenceCardList
                                        kompetenceListType={KompetenceCardListType.KompetencerByBruger}
                                        selectedBrugerMaalbeskrivelseId={selectedBrugerMaalbeskrivelse}
                                    />
                                </div>

                                {selectedBrugerMaalbeskrivelse &&
                                    <div className="col-7 h-100 overflow-auto">
                                        {!kompetenceCardState.isLoadingList &&
                                            <KompetenceOverview
                                                isReadOnly={selectedBrugerMaalbeskrivelse !== currentBrugerMaalbeskrivelse ||
                                                    kompetenceCardState?.activeLaegekompetenceMeta?.brugerMaalbeskrivelseId !== currentBrugerMaalbeskrivelse}
                                                currentBrugerMaalbeskrivelse={currentBrugerMaalbeskrivelse}
                                                getCurrentBrugerMaalbeskrivelse={(currentBrugerMaalbeskrivelse) => getLaegekompetencerToShow(currentBrugerMaalbeskrivelse)}
                                                initalKompetence={activeKompetenceId}
                                                initalUddannelseslaegeId={uddannelseslaegeId}
                                            />
                                        }
                                    </div>
                                }
                            </>
                        }

                        {isMobile &&
                            <div className="col-12">
                                <KompetenceCardList
                                    kompetenceListType={KompetenceCardListType.KompetencerByBruger}
                                    selectedBrugerMaalbeskrivelseId={selectedBrugerMaalbeskrivelse}
                                />
                            </div>
                        }
                    </div>
                </div>
                }
            </ForloebSpecificPageWrapper>
        </Loading>
    );
}

