import {ApplicationInsights, ITelemetryItem} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import configJson from 'config/config';

const useApplicationInsightsConfig = (enable: boolean) => {
    const reactPlugin = new ReactPlugin();
    
    if(!enable) {
        // Dont configure applicationInsights, if we shouldn't use it:
        return { reactPlugin }
    }   

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: configJson.applicationInsightConnectionstring,
            extensions: [reactPlugin],
            enableAutoRouteTracking: true,
            disableAjaxTracking: false,
            disableFetchTracking: false,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
        }
    });

    appInsights.loadAppInsights();
    
    appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
        env.tags = env.tags || [];
        env.tags["ai.cloud.role"] = "uddannelseslaege-frontend"; //TODO: what should this be?
    });

    return { reactPlugin }
}

export default useApplicationInsightsConfig;
