import React, {useEffect, useRef, useState} from 'react'
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {ReactComponent as GreenCircle} from "../../../content/icons/greenCircle.svg";
import {ReactComponent as BlueCircle} from "../../../content/icons/blueCircle.svg";
import {LaegekursusModel} from "../../../services/api/laegekursus/laegekursusModel";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {EditLaegekursus} from "./editLaegekursus";
import {ModalAcceptType, ModalSubmitMessage} from "../../../core/components/modalMessage/modalSubmitMessage";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useAppDispatch} from "../../../app/hooks";
import {LogbogLaegekursusApi} from "../../../services/api/laegekursus/logbogLaegekursusApi";
import {HttpClientService} from "../../../services/httpClient/httpClientService";
import {AccountService} from "../../../services/account/accountService";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {NotificationModule} from "ditmer-embla";
import { EmblaIcons } from 'core/emblaIcons';
import {GodkendKursusButton} from "./godkendKursusButton";
import DynamicButton from "../../../core/components/button/dynamicButton";

type KursusCardProps = {
    laegekursus: LaegekursusModel;
    laegekursusEditCallback: () => void;
    gruppeKategoriTekst: string;
    laegekursusGruppeGodkendt: boolean;
    isReadonly?: boolean;
    hideUdfyldDetails?: boolean;
    renderApproveButton?: boolean;
    brugerMaalbeskrivelseId: string;
    forloebId?: string;
}

const laegekursusApi = new LogbogLaegekursusApi(new HttpClientService(), new AccountService())

export function LaegekursusCard({renderApproveButton=false, ...props}: KursusCardProps) {
    const dispatch = useAppDispatch();
    const hasAnyNotater = props.laegekursus.notatFileCount > 0;
    const manglerHandling = (props.laegekursus.status === LaegekursusStatusEnum.ManglerHandling);
    const _currentUser = useRef(new AccountService().getUser());

    const [laegekursusModalIsOpenedByModal, setLaegekursusModalOpenedByModal] = useState(false);
    const [laegekursusModalOpen, setLaegekursusModalOpen] = useState(false);
    const [showFjernGodkendelseModalOpen, setShowFjernGodkendelseModalOpen] = useState<boolean>(false);
    const [showDeleteModalOpen, setShowDeleteModalOpen] = useState(false);

    const laegekursusModalTarget = "laegekursus-modal";
    const fjernGodkendelseModalTarget = "fjern-godkendelse";
    const confirmDeleteModalId = "laegekursus-delete-confirm-modal";

    const kursusErGrundkursusIPsykoterapi = props.laegekursus.gruppeType === LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi;

    function openUdfyldModal() {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: laegekursusModalTarget}))
        setLaegekursusModalOpen(true)
    }

    const openAreYouSureFjernGodkendelseModal = () => {
        setShowFjernGodkendelseModalOpen(true);
    }

    const renderLaegekursusEdit = (model: LaegekursusModel) => {
        return (
            <EditLaegekursus modalId={laegekursusModalTarget}
                             model={model}
                             forloebId={props.forloebId}
                             brugerMaalbeskrivelseId={props.brugerMaalbeskrivelseId}
                             laegekursusGruppeGodkendt={props.laegekursusGruppeGodkendt}
                             areYouSureFjernGodkendelseModalId={fjernGodkendelseModalTarget + model.id}
                             areYouSureDeleteModalId={confirmDeleteModalId + model.id}
                             openAreYouSureFjernGodkendelseModal={() => openAreYouSureFjernGodkendelseModal()}
                             openAreYouSureDeleteModal={() => setShowDeleteModalOpen(true)}
                             laegekursusStatusEditCallback={() => props.laegekursusEditCallback()}
            />
        )
    }

    const removeGodkendelse = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: laegekursusModalTarget}))
        const godkendelseFjernetLaegekursusId = await laegekursusApi.removeGodkendelseLaegekursus(props.laegekursus.id, props.brugerMaalbeskrivelseId);
        if (godkendelseFjernetLaegekursusId) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: laegekursusModalTarget}))
            NotificationModule.showSuccess(Localizer.kursusPage_godkendelseFjernet(), "");
            setLaegekursusModalOpen(false);
        } else
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: laegekursusModalTarget}))

        props.laegekursusEditCallback();
    }

    function getHeaderTitle(): string {
        if (manglerHandling)
            return Localizer.kursusPage_kursusEditTitelTekst(Localizer.kursusPage_udfyld(), props.gruppeKategoriTekst)
        else
            return Localizer.kursusPage_kursusEditTitelTekst(Localizer.global_edit(), props.gruppeKategoriTekst)
    }

    const deleteLaegekursus = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: laegekursusModalTarget}))
        let deletedLaegekursus = await laegekursusApi.deleteLaegekursus(props.laegekursus.id);
        if (deletedLaegekursus) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: laegekursusModalTarget}))
            NotificationModule.showSuccess(Localizer.kursusPage_kursusDeleted(), "");
        } else {
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: laegekursusModalTarget}))
        }
        props.laegekursusEditCallback();
    }

    function modalClosed() {
        setLaegekursusModalOpen(false);
        props.laegekursusEditCallback();
    }

    function modalFjernGodkendelseCancel() {
        setShowFjernGodkendelseModalOpen(false);
        setLaegekursusModalOpen(!laegekursusModalIsOpenedByModal);
        setLaegekursusModalOpenedByModal(false);
    }

    function modalDeleteCancel() {
        setShowDeleteModalOpen(false);
        setLaegekursusModalOpen(true);
    }

    function laegekursusEditFinishCallback() {
        setLaegekursusModalOpen(false);
        props.laegekursusEditCallback();
    }

    return (
        <>
            <div className="flex-container">
                <div className="flex-space flex-column flex-grow">
                    <div className="flex-align-c">

                        <div className="flex-space flex-row flex-grow">
                            <h5 className={"margin-right-m"}>{props.laegekursus.titel}</h5>
                            {(manglerHandling && !props.hideUdfyldDetails && !props.isReadonly) &&
                                <button className="btn btn-default rounded-pill flex-align-r"
                                        onClick={() => openUdfyldModal()}>{Localizer.kursusPage_udfyld()}
                                </button>
                            }
                        </div>

                        {!manglerHandling &&
                            <div className="flex-space">
                                {renderApproveButton && props.laegekursus.status !== LaegekursusStatusEnum.Godkendt &&
                                    <GodkendKursusButton
                                        modalId={laegekursusModalTarget}
                                        additionalClasses={"flex-align-r mr-1"}
                                        laegekursusId={props.laegekursus.id}
                                        doneCallBack={() => props.laegekursusEditCallback()}
                                        useIconWithNoText={true}
                                        brugerMaalbeskrivelseId={props.brugerMaalbeskrivelseId}
                                    />
                                }

                                {_currentUser.current.IsAdminOrVus() && props.laegekursus.status === LaegekursusStatusEnum.Godkendt &&
                                    <DynamicButton
                                        additionalClasses='mr-2 flex-align-r'
                                        disabled={props.laegekursusGruppeGodkendt}
                                        shouldRender={!props.laegekursusGruppeGodkendt}
                                        rounded={true}
                                        onClick={() => {
                                                    setLaegekursusModalOpenedByModal(true);
                                                    openAreYouSureFjernGodkendelseModal();
                                        }}
                                        modal={{
                                            dataTarget: `#${fjernGodkendelseModalTarget + props.laegekursus.id}`,
                                        }}
                                    >
                                        <DynamicButton.TextIconItem iconName={EmblaIcons.Remove} />
                                    </DynamicButton>
                                }

                                {!props.hideUdfyldDetails &&
                                    <div
                                        className={`laegekursus-edit flex-align-r btn-default ${kursusErGrundkursusIPsykoterapi ? "btn rounded-pill" : "round"}`}
                                        onClick={() => openUdfyldModal()}
                                        role="button">
                                        {kursusErGrundkursusIPsykoterapi && props.laegekursus.notatFileCount > 0
                                            ? Localizer.kursusPage_seFiler()
                                            : kursusErGrundkursusIPsykoterapi
                                                ? Localizer.kursusPage_tilfoejFiler()
                                                : <EmblaIcon iconName={EmblaIcons.Edit}/>
                                        }
                                    </div>
                                }
                            </div>
                        }

                    </div>
                    {!manglerHandling &&
                    <React.Fragment key={props.laegekursus.id}>
                        <div className="margin-top-s subtle small text-truncate">
                            {props.laegekursus.eventuelSpecifikation}
                        </div>
                        <div className="flex-align-c subtle margin-top-s">
                            {props.laegekursus.startDato && props.laegekursus.startDato && !kursusErGrundkursusIPsykoterapi &&
                                <>
                                    <div className="card-list-calender-icon">
                                        <EmblaIcon iconName="calendar"/>
                                    </div>
                                    <div>
                                        {props.laegekursus.startDato?.dateWithoutTimeFormat(false)}
                                    </div>
                                    <div
                                        className="margin-right-m">&nbsp;- {props.laegekursus.slutDato?.dateWithoutTimeFormat(false)}</div>
                                </>
                            }
                            {props.laegekursus.status === LaegekursusStatusEnum.Udfyldt && !kursusErGrundkursusIPsykoterapi &&
                                <div className="margin-right-m ">
                                    <span className="badge badge-pill badge-default padding-xs bg-white border ">
                                        <BlueCircle className="margin-right-xs"/>
                                        {Localizer.kursusPage_udfyldt()}
                                    </span>
                                </div>
                            }
                            {props.laegekursus.status === LaegekursusStatusEnum.Godkendt &&
                                <div className="margin-right-m">
                                    <span className="badge badge-pill badge-default padding-xs">
                                        <GreenCircle/> {Localizer.global_godkendt()}
                                    </span>
                                </div>
                            }
                            <div className={"margin-right-xs card-list-icon " + (hasAnyNotater ? "" : "subtle")}>
                                <EmblaIcon iconName="comment"/>
                                <span className={"margin-left-xs " + (hasAnyNotater ? "" : "subtle")}>
                                    {props.laegekursus?.notatFileCount}
                                </span>
                            </div>
                        </div>
                    </React.Fragment>
                    }
                </div>
            </div>

            {laegekursusModalOpen &&
            <SlideIn id={laegekursusModalTarget}
                     title={getHeaderTitle()}
                     bodyContent={renderLaegekursusEdit(props.laegekursus)}
                     defaultOpen={true}
                     actionOnCloseCallback={modalClosed}
                     actionFinishedCallback={laegekursusEditFinishCallback}
            />
            }

            {showFjernGodkendelseModalOpen &&
                <ModalSubmitMessage modalId={fjernGodkendelseModalTarget + props.laegekursus.id}
                                    title={Localizer.kursusPage_fjernGodkendelse()}
                                    description={<p>{Localizer.kursusPage_areYourSureFjernGodkendelse()}</p>}
                                    cancelAction={modalFjernGodkendelseCancel}
                                    acceptAction={removeGodkendelse}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.kursusPage_fjernGodkendelse()}
                />
            }

            {showDeleteModalOpen &&
                <ModalSubmitMessage modalId={confirmDeleteModalId + props.laegekursus.id}
                                    title={Localizer.areYouSure()}
                                    description={<p>{Localizer.kursusPage_laegekursusAreYouSureDelete(props.laegekursus.titel)}</p>}
                                    cancelAction={modalDeleteCancel}
                                    acceptAction={deleteLaegekursus}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.global_delete()}
                />
            }
        </>
    );
}
