const config: any = {};
export default config;

const loadConfig = () => {
    return fetch("/config.json")
    .then(result => {
        return result.json()
    })
    .then((newconfig) => {
        for(const prop in config) {
            delete config[prop]
        }
        for(const prop in newconfig) {
            config[prop] = newconfig[prop]
        }
        return config;
    });
}

export { loadConfig };
