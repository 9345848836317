import { useCallback, useEffect } from "react";
import { DataTableHeaderAction } from "./DataTableHeaderAction";

const showLoadingInHeaderAction = (show: boolean, htmlElement: HTMLElement, hideContentOnLoad: boolean) => {
  const $htmlElement = $(htmlElement);
  const loadingDiv = $htmlElement.find(".loading-container");
  const otherContent = loadingDiv.parent().children(":not(.loading-container)");

  if (loadingDiv.length > 0 && show) {
      loadingDiv.prop("hidden", false);
      otherContent.prop("hidden", hideContentOnLoad);

      $(htmlElement).attr("disabled", "disabled");
  }

  if (loadingDiv.length > 0 && !show) {
      loadingDiv.prop("hidden", true);
      otherContent.prop("hidden", false);

      $(htmlElement).removeAttr("disabled");
  }
}

export const useHeaderActions = (actions?: DataTableHeaderAction[], isTableInitializedAndAutoBind?: boolean) => {
  
  const initializeHeaderActions = useCallback(() => {
    if(!!actions) {
      actions.forEach((action) => {
        const buttonIdSelector = `#${action.buttonId}`;
        const hideContentOnLoad = !!action.onLoadingHideButtonContent;

        $(buttonIdSelector).off("click").on("click", async function () {
            
          showLoadingInHeaderAction(true, this, hideContentOnLoad);
          await action.clickMethod();
          showLoadingInHeaderAction(false, this, hideContentOnLoad);
        });
      });
    }
  }, [actions]);

  useEffect(() => {
    if(!!isTableInitializedAndAutoBind) {
      initializeHeaderActions();
    }
  }, [initializeHeaderActions, isTableInitializedAndAutoBind])

  return { initializeHeaderActions };
}